import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, fade, IconButton, InputBase, LinearProgress, makeStyles, Switch, TextField, withStyles } from '@material-ui/core';
import { ReactComponent as Check } from "../../assets/icons/check-icon.svg";
// import attachmentList from './attachments.json';
import { Close, PlayCircleOutline, Replay, Search } from '@material-ui/icons';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { connect } from 'react-redux';
import { getContacts } from '../../ducks/contacts';
import { getContactSearchResult } from '../../ducks/search';
import { useSnackbar } from 'notistack';
import { ChaseonsSkeleton } from '../Arena/ReactionsDialog';
import { postArena } from '../../ducks/arena';
import Axios from 'axios';
import { getGroupsList, uploadFilesToS3 } from '../../ducks/common';
import Poll from '../../assets/icons/survey-1.svg'
import noPreview from '../../assets/images/nopreview.png';
import { uniq } from 'underscore';
import { PublishTo } from './PublishTo';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F1F2F5',
        position: 'relative',
        // display: 'flex',
        // minHeight: 500,
        minHeight: 'calc(100vh - 64px)',
        // maxWidth: 600,
        maxHeight: 'calc(100vh - 64px)',
        // width: '70%',
        width: '100%',
        //     -webkit-transition: 'width 1s ease-in-out'
        // -moz-transition:width 1s ease-in-out;
        // -o-transition: width 1s ease-in-out;
        transition: 'width 0.5s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            // maxWidth: 400,
            maxHeight: 400,
            // minHeight: 400,
            minHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
            minHeight: '100vh'
        },
    },
    titleRoot: {
        marginRight: 5,
        backgroundColor: '#FFF',
        fontSize: 18,
        color: '#262626',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& p': {
            margin: '0px 50px'
        }
    },
    content: {
        padding: 20,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 171px)'
    },
    postPreview: {
        paddingBottom: 20,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: 82,
            height: 82,
            borderRadius: 4,
            objectFit: 'contain',
            backgroundColor: '#7f7f7f17'
        },
        '& video': {
            width: 82,
            height: 82,
            borderRadius: 4
        },
        '& .inputRoot': {
            // padding: '5px 20px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none !important',
            }
        },
        '& .textLength': {
            fontSize: 11,
            // paddingLeft: 20
        }
    },
    tagRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0',
        '& p': {
            cursor: 'pointer',
            margin: 0,
            paddingBottom: 3,
            fontSize: 16,
            width: 'fit-content',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& span': {
            fontSize: 12
        }
    },
    tagUsersListRoot: {
        width: '40%',
        backgroundColor: '#FFF',
        padding: '0 20px',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: 'calc(100% - 40px)',
            minHeight: '100vh',
            zIndex: 1
        },
        '& .title': {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '& p': {
                fontSize: 18,
            },
            '& span': {
                cursor: 'pointer',
                color: '#112386',
                fontSize: 14,
                '&:hover': {
                    textDecoration: 'underline'
                }

            }
        }
    },
    search: {
        position: 'relative',
        borderRadius: 20,
        backgroundColor: '#F1F2F5',
        '&:hover': {
            backgroundColor: fade('#F1F2F5', 0.75),
        },
        width: 'auto',
        // marginRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // maxWidth: 250,
        // minWidth: 175,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#C4C4C4'
    },
    searchInputRoot: {
        color: 'inherit',
        width: '100%',
    },
    searchInputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 20,
        fontSize: 12,
    },
    usersList: {
        height: '70vh',
        marginTop: 20,
        overflowY: 'auto',
        width: 'calc(100% + 16px)',
        // overflowX: 'hidden',
        maxWidth: '100%'
    },
    avatarStyles: {
        width: 28,
        height: 28,
        fontSize: 16
    },
    profileName: {
        fontSize: 13,
        fontWeight: 500,
    },
    secondaryText: {
        fontSize: 10
    },
    playIcon: {
        color: '#fff',
        position: 'absolute',
        left: 'calc(50% - 18px)',
        top: 'calc(50% - 18px)'
    },
    failedContent: {
        '& p': {
            color: '#FF3B30',
            margin: '7px 0 0',
        },
        '& span': {
            fontSize: 14,
        },
        '& button': {
            padding: 8
        }
    }
}));

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 14,
        padding: 0,
        display: 'flex',
        overflow: 'visible',
        marginRight: 10
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const CancelToken = Axios.CancelToken;
const TagUsers = ({ attachments, cropType, pollDetails, linkDetails = null, thumbnailUrl = "", getContacts, getContactSearchResult, getGroups, postArena, uploadFilesToS3, onSubmit, keycloak: { tokenParsed: { preferred_username, realm_access } } }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [text, setText] = useState('');
    const [isFeatured, setIsFeatured] = useState(false);
    const [isLearing, setIsLearning] = useState(false)
    const [loading, setLoading] = useState(false);
    const [openTagUsersList, setOpenTagUsersList] = useState(false);
    const [openPublishTo, setOpenPublishTo] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [taggedUsers, setTaggedUsers] = useState([]);
    const [query, setQuery] = useState('');
    const [source, setSource] = useState(CancelToken.source());
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [posting, setPosting] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isAll, setIsAll] = useState(true);

    useEffect(() => {
        setLoading(true);
        getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
        getGroups({
            onSuccessCB: (result) => {
                setLoading(false);
                setGroupsList(result.map(obj => ({ ...obj, checked: true })));
            },
            onErrorCB: (error) => {
                setLoading(false);
                setGroupsList([]);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch Groups.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })

        return () => {
            source.cancel('User Cancelled Uploading.');
            setSource(CancelToken.source());
        }
    }, []);


    const onGetContactsSuccessCB = (result) => {
        setLoading(false);
        const filteredResult = result.filter(item => item.username !== preferred_username);
        if (taggedUsers.length > 0) {
            const existingTaggedUsers = taggedUsers.map(obj => obj.username);
            setUsersList(filteredResult.map(obj => ({ ...obj, checked: existingTaggedUsers.includes(obj.username) })));
        }
        else {
            setUsersList(filteredResult);
        }
    }

    const onGetContactsErrorCB = (error) => {
        setUsersList([]);
        enqueueSnackbar(`Failed to fetch Chaseons.`, {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
    }

    const handleChangeQuery = (e) => {
        let value = e.target.value;
        setQuery(value);
        if (value.length === 0) {
            setLoading(true);
            getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
        }
    }
    const handleKeyPress = e => {
        if (e.nativeEvent.keyCode === 13 && query.length > 0) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                handleSearch(query);
                // return false;
            }
        }
    }

    const handleSearch = (query) => {
        if (query.length > 0) {
            setLoading(true);
            getContactSearchResult({ query, onSearchSuccessCB: onGetContactsSuccessCB, onSearchErrorCB: onGetContactsErrorCB });
        }
        else {
            setUsersList([]);
        }
    }

    const handleCheckbox = (index) => {
        let usersListDup = JSON.parse(JSON.stringify(usersList));
        usersListDup[index].checked = !usersListDup[index].checked;
        setUsersList([...usersListDup]);
        // let taggedUsersDup = JSON.parse(JSON.stringify(taggedUsers));
        // const index = taggedUsersDup.findIndex(user => user.username === userObj.username);
        // if (index >= 0) {
        //     taggedUsersDup.splice(index, index + 1);
        // }
        // else {
        //     taggedUsersDup.push(userObj);
        // }
        // setTaggedUsers([...taggedUsersDup]);
    }


    const handleGroupsCheckbox = (index, isAll) => {
        if (index === 'ALL') {
            let groupsListDup = JSON.parse(JSON.stringify(groupsList));
            if (!isAll) {
                // setLoading(true);
                groupsListDup = groupsListDup.map(obj => ({ ...obj, checked: true }));
                setGroupsList([...groupsListDup]);
                // setTimeout(() => {
                //     setLoading(false);
                // }, 500);
            }
            else {
                groupsListDup = groupsListDup.map(obj => ({ ...obj, checked: false }));
                setGroupsList([...groupsListDup]);
            }
            setIsAll(!isAll);
        }
        else {
            let groupsListDup = JSON.parse(JSON.stringify(groupsList));
            groupsListDup[index].checked = !groupsListDup[index].checked;
            const checkedCount = groupsListDup.filter(obj => obj.checked)?.length;
            if (checkedCount > 0 && checkedCount !== groupsList?.length) {
                setIsAll(false);
            }
            else if (checkedCount === groupsList?.length)
                setIsAll(true);
            setGroupsList([...groupsListDup]);
        }
    }

    const handleSubmitGroups = () => {
        const selectedGroups = groupsList.filter(obj => obj.checked);
        if (isAll)
            setSelectedGroups([]);
        else
            setSelectedGroups(uniq(selectedGroups, x => x.id));
        setOpenPublishTo(false);
    }

    const onUploadSuccessCB = (result, file) => {
        // finalAttachmentsCount++;
        if (result.url) {
            setUploadedAttachments(prevState => ([...prevState, { url: result.url, thumbnailUrl: result.thumbnailUrl || thumbnailUrl, mimeType: file.mimeType, index: file.index }]));
            // setUploadErrorText('');  
        }
        // else setUploadErrorText('Failed');
        // if (finalAttachmentsCount === attachmentsCount) {
        //     setUploading(false);
        // }
    }

    const onUploadErrorCB = (result, file) => {
        setIsFailed(true);
        source.cancel('User Cancelled Uploading.');
        setSource(CancelToken.source());
        // console.log('onUploadErrorCB ', result.response);
        if (result.response) {
            enqueueSnackbar(`Failed to Upload ${file.name}.`, {
                variant: 'error'
            });
        }
    }

    const handleRetry = () => {
        setIsFailed(false);
        if (uploadedAttachments.length === attachments.length) {
            submitPost();
        }
        else
            handleSubmitPost();
    }

    useEffect(() => {
        if (uploadedAttachments.length > 0 && uploadedAttachments.length === attachments.length) {
            submitPost();
        }
    }, [uploadedAttachments]);

    const submitPost = async () => {
        let groups = [];
        if (isFeatured) groups.push('FEATURED');
        if (isLearing) groups.push('LEARNING');
        let requestObj = {
            // category: isFeatured ? 'FEATURED' : 'REGULAR',
            groups: groups,
            type: (uploadedAttachments.length || linkDetails) ? 'IMAGE' : 'TEXT',
            text: text,
            userGroups: isAll ? [] : selectedGroups.map(obj => obj.id),
            ratio: cropType.value === 'SQUARE' ? "" : `${cropType.ratio}`
            // tags: taggedUsers
        };
        if (uploadedAttachments.length) requestObj.attachments = [...uploadedAttachments].sort((a, b) => (a.index > b.index) ? 1 : -1);
        if (pollDetails?._id) {
            requestObj.attachments = [{ url: pollDetails._id, mimeType: 'SURVEY', thumbnailUrl: pollDetails.question?.title }]
            requestObj.ratio = pollDetails.question?.options?.filter(obj => obj.label).length
        }
        if (linkDetails && linkDetails.url) {
            const response = await fetch(`https://rlp-proxy.herokuapp.com/v2?url=${linkDetails.url}`);
            const json = await response.json();
            const details = json.metadata;
            requestObj.attachments = [{ mimeType: 'LINK', url: details.url, siteName: details?.hostname || '', siteDescription: details.description || '', thumbnailUrl: linkDetails?.image || '' }]
        }
        if (taggedUsers.length) requestObj.tags = taggedUsers.map(obj => obj.username);
        // if (addBackground) requestObj.background = details.background ? details.background : ((props.backgroundColorsList && props.backgroundColorsList.length > 0) ? props.backgroundColorsList[0] : defaultBackground);
        // props.setLoading(true);
        // console.log('requestObj ', requestObj, pollDetails);
        setPosting(true);
        // console.log(requestObj)
        postArena({ requestObj, onSuccessCB, onErrorCB });
    }

    const srcToFile = (src, fileName, mimeType) => {
        return (fetch(src)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
        );
    }

    const handleSubmitPost = () => {
        if (attachments.length > 0) {
            attachments.forEach((item, i) => {
                srcToFile(
                    item.url, item.fileName, item.fileType
                ).then((file) => {
                    // console.log('attachements ', file);
                    let formData = new FormData();
                    formData.append("file", file);
                    setPosting(true);
                    uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: { mimeType: item.mimeType, index: i, name: item.fileName }, cancelToken: source.token });
                });
            });
        }
        else {
            submitPost();
        }
    }

    const onSuccessCB = (result) => {
        // console.log(result);
        enqueueSnackbar(`Post Created successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        onSubmit();
        setPosting(false);
        // props.handleOnNewPost(new Date().getTime());
        // props.setLoading(false);
        // props.tagChaseOns({ postId: result.id, requestObj: { user_ids: selectedChaseOns.map(user => user.username) }, onTagChaseOnsSuccessCB, onTagChaseOnsErrorCB });
    }

    const onErrorCB = (error) => {
        setIsFailed(true);
        console.log('onErrorCB ', error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to post Arena.`, {
            variant: 'error',
            preventDuplicate: true
        });
        // props.setLoading(false);
    }

    const handleTextChange = (e) => {
        setText(e.target.value);
    }

    const handleSubmitTags = () => {
        const unCheckedUsers = usersList.filter(obj => !obj.checked).map(obj => obj.username);
        const totalTaggedUsers = [...taggedUsers.filter(obj => !unCheckedUsers.includes(obj.username)), ...usersList.filter(obj => obj.checked)];
        setTaggedUsers(uniq(totalTaggedUsers, x => x.username));
        setOpenTagUsersList(false);
    }

    // console.log('attachments ', attachments);
    return (
        <div style={{ backgroundColor: '#FFF', width: '100%', display: 'flex' }}>
            <div className={classes.root} style={{ width: openTagUsersList && '60%' }}>
                <div className={classes.titleRoot}>
                    <p>New Post</p>
                    {
                        (!isFailed && !posting) &&
                        <IconButton title="Post" className="checkIcon" onClick={() => handleSubmitPost()}>
                            <Check />
                        </IconButton>
                    }
                </div>
                <div className={classes.content}>
                    <div className={classes.postPreview}>
                        {attachments[0] ? (attachments[0].mimeType === 'IMAGE' ?
                            <img src={attachments[0].url} alt="image" />
                            :
                            attachments[0].mimeType === 'PDF' ?
                                <img src={attachments[0].thumbnailUrl} alt="image" />
                                :
                                <div style={{ position: 'relative' }}>
                                    <video src={attachments[0].url} autoPlay={false} />
                                    <PlayCircleOutline
                                        className={classes.playIcon}
                                        fontSize="large"
                                    />
                                </div>
                        )
                            : (pollDetails?._id) ? <img src={Poll} alt="image" />
                                : (linkDetails && <img src={linkDetails?.image || noPreview} alt="image" />)
                        }
                        <div style={{ width: '100%', padding: '5px 20px' }}>
                            {posting ?
                                [
                                    isFailed ?
                                        <div className={classes.failedContent}>
                                            <p>FAILED</p>
                                            <span>Please try again...</span>
                                            <IconButton title="Retry" onClick={() => handleRetry()}>
                                                <Replay />
                                            </IconButton>
                                        </div>
                                        :
                                        <>
                                            <p>POSTING...</p>
                                            <LinearProgress color="primary" />
                                        </>

                                ]
                                :
                                <>
                                    <TextField
                                        id="outlined"
                                        fullWidth
                                        placeholder="Write a caption which describes better about your Post..."
                                        multiline
                                        // variant="outlined"
                                        rows={2}
                                        rowsMax={3}
                                        inputProps={{ maxLength: 200 }}
                                        value={text}
                                        onChange={(e) => handleTextChange(e)}
                                        InputProps={{ classes: { root: 'inputRoot' } }}
                                    />
                                    <span className="textLength">{text.length}/200</span>
                                </>
                            }
                        </div>
                    </div>
                    <Divider variant="fullWidth" component="div" />
                    <div className={classes.tagRoot}>
                        <p onClick={() => { setOpenTagUsersList(!openTagUsersList); setOpenPublishTo(false); }}>Tag people</p>
                        <span>{taggedUsers.map(obj => obj.name).join(', ') || 'Type in names of people whom you want to tag'}</span>
                    </div>
                    <Divider variant="fullWidth" component="div" />
                    {
                        realm_access.roles.includes('manage_featured_posts') &&
                        <div className={classes.tagRoot} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <p onClick={() => setIsFeatured(!isFeatured)}>Add to Featured</p>
                                <span>Publish as a highlighted content</span>
                            </div>
                            <AntSwitch checked={isFeatured} onChange={() => setIsFeatured(!isFeatured)} name="checkedC" />
                        </div>

                    }
                    {
                        realm_access.roles.includes('manage_learning_content') &&
                        <>
                            <Divider variant="fullWidth" component="div" />
                            <div className={classes.tagRoot} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <p onClick={() => setIsLearning(!isLearing)}>Add to LearnHub</p>
                                    <span>Publish as a learning content</span>
                                </div>
                                <AntSwitch checked={isLearing} onChange={() => setIsLearning(!isLearing)} name="checkedL" />
                            </div>
                        </>
                    }
                    <Divider variant="fullWidth" component="div" />
                    <div className={classes.tagRoot}>
                        <p onClick={() => { setOpenPublishTo(!openPublishTo); setOpenTagUsersList(false); }}>Publish to</p>
                        <span>{selectedGroups.map(obj => obj.groupName).join(', ') || 'Publish to all the teams & players of the selected league/s'}</span>
                    </div>
                </div>
            </div>
            {
                openTagUsersList &&
                <div className={classes.tagUsersListRoot}>
                    <div className="title" >
                        <p>Tag people</p>
                        <span onClick={() => handleSubmitTags()}>Done</span>
                    </div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <InputBase
                            placeholder="Search for people who are tagged in the post"
                            classes={{
                                root: classes.searchInputRoot,
                                input: classes.searchInputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            value={query}
                            onChange={handleChangeQuery}
                            onKeyUp={handleKeyPress}
                        />
                    </div>
                    <div className={classes.usersList}>
                        {
                            loading ?
                                [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={true} key={i} />)
                                :
                                usersList.length > 0 ? usersList.map((user, i) => (
                                    <div key={i}>
                                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <UserProfileCard
                                                key={`tag-${i}`}
                                                name={user.name}
                                                username={user.username}
                                                avatarUrl={user.photo_url}
                                                avatarStyles={classes.avatarStyles}
                                                profileNameStyles={classes.profileName}
                                                secondaryTextStyles={classes.secondaryText}
                                                style={{ margin: '12px 2px', alignItems: 'center' }}
                                                // follower={true}
                                                showFollowStatus={true}
                                            // onProfileClick={() => history.push(getProfileUrl(item.userId))}
                                            />
                                            <Checkbox style={{ display: 'inline-table' }} checked={user.checked} onChange={() => handleCheckbox(i)} color="primary" />
                                        </div>
                                        <Divider style={{ margin: '0 12px 0 38px' }} variant="fullWidth" component="div" />
                                    </div>
                                ))
                                    : <p style={{ fontSize: 14, textAlign: 'center' }}>No Chaseons found</p>
                        }
                    </div>
                </div>
            }
            {
                openPublishTo &&
                <PublishTo classes={classes} loading={loading} groupsList={groupsList} isAll={isAll} handleGroupsCheckbox={handleGroupsCheckbox} handleSubmitGroups={handleSubmitGroups} />
            }
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    keycloak: state.keycloak,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
        postArena: (options) => dispatch(postArena(options)),
        getContacts: (options) => dispatch(getContacts(options)),
        getContactSearchResult: (options) => dispatch(getContactSearchResult(options)),
        getGroups: (options) => dispatch(getGroupsList(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagUsers);