import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { IconButton } from '@material-ui/core';
import { Info } from "@material-ui/icons";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import loading, { setLoading } from "../../ducks/loading";
import { getTeamRaceLiveScore, getPlayerRaceLiveScore } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { groupBy, sortBy } from "underscore";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { ChevronLeft } from '@material-ui/icons';
import { GetDates } from ".";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import TeamSummaryDivisionWise from "./TeamSummaryDivisionWise";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '8px 0',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    }
}));

export const isRuleBasedOnActuals = (kpi) => {
    return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
}

const RaceSummary = ({ setLoading, loading, seasonId = '', seasonFormat = '', matchId = '', filtersData, userDetails, getTeamRaceLiveScore, getPlayerRaceLiveScore, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const { path } = useRouteMatch();

    useEffect(() => {
        if (matchId && !filtersData.season?.divisions_enabled) {
            setLoading(true);
            if (seasonFormat === 'TEAM_RACE')
                getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
            else
                getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
    }, [matchId]);

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score || item.total_points || 0, selected: (filtersData.match?.type === 'TEAM_SPRINT') ? item._id === resheaders?.my_team_id : item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = (seasonFormat === 'TEAM_RACE' ? el.score || 0 : el.points || 0);
                    // obj['totalPoints'] = totalPoints += seasonFormat === 'TEAM_RACE' ? el.score : el.points;

                    if (index === 0) {
                        headers.push(el);
                    }
                    if (index === 0) {
                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`,//-target
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,//-Ach %
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        }, {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            console.log('finalResult dynamicHeaders ', finalResult);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                if (filtersData.season?.divisions_enabled)
                    setDataByDiv(groupBy(sortBy(finalResult, 'totalPoints').reverse(), 'division_name'));
                else
                    setData(sortBy(finalResult, 'totalPoints').reverse());
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setDataByDiv(null);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }


    const handleGoBack = () => {
        history.push(`/leagues/live-scores/${history.location?.search}`);
    }

    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            hidden: seasonFormat === 'TEAM_RACE',
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            editable: "onAdd",
            hidden: seasonFormat === 'TEAM_RACE',
            emptyValue: '--'
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--'
        },
        {
            title: "Total points earned",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        }
    ]

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                search: false,
                actionsColumnIndex: -1,
                showTitle: false,
                addRowPosition: 'first',
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25],
                pageSize: 25,
                paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {
                                seasonFormat === 'TEAM_RACE' ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[4]?.title}</TableCell>
                                    </>
                                    :
                                    seasonFormat === 'PLAYER_RACE' ?
                                        <>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[4]?.title}</TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>
                                        </>
                            }
                            {/* {console.log('dynamicHeaders ', dynamicHeaders)} */}
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={isRuleBasedOnActuals(obj) ? 2 : 4} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                        <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                        <TableCell align="center">Points</TableCell>
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    )

    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
            </div>
            {
                (data.length === 0 && !filtersData.season?.divisions_enabled) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    [
                        (filtersData.season?.divisions_enabled) ?
                            <>
                                {
                                    filtersData.season?.divisions.map((ele, index) => (
                                        <TeamSummaryDivisionWise key={ele._id} index={index} divisionObj={ele} limit={5000} columns={columns} filtersData={filtersData} type="DETAILED" decimalView={decimalView} setDecimalView={setDecimalView} />
                                    ))
                                }
                            </>
                            :
                            <div className={classes.root}>
                                <p className="table-title decimal-switch-root">
                                    <p>
                                        <span>{filtersData.match?.type === 'TEAM_SPRINT' ? 'Team' : 'Player'} performance : <span style={{ color: '#000', fontSize: 13 }}>{filtersData.match?.title}</span><GetDates filtersData={filtersData} /></span>
                                    </p>
                                    <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                </p>
                                {/* <p className={classes.title}><Info />{"Points will appear only after match is closed"}</p> */}
                                <SummaryTable data={data} />
                            </div>
                    ]
            }
        </div>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceSummary));