import { all, call, take, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { AUTHENTICATED } from "../../ducks/client";
import { fetchEmployees, saveMedia, changePassword, ARENA_GET_MEDIA_API, UPDATE_WORK_DETAILS_API, UPDATE_EDUCATION_DETAILS_API, GET_EMPLOYEE_DETAILS_API, UPDATE_BASIC_PROFILE_API, DELETE_WORK_DETAILS_API, DELETE_EDUCATION_DETAILS_API, GET_ORG_DETAILS_API } from "../../config";
import { setDetails, UPDATE_PROFILE, CHANGE_PASSWORD, GET_MEDIA_LIST, UPDATE_WORK_LIST, UPDATE_EDUCATION_LIST, UPDATE_BASIC_PROFILE, GET_ARENA_PROFILE, DELETE_WORK_EXPERIENCE, DELETE_EDUCATION, GET_UPDATED_PROFILE, getUpdatedProfile } from "../../ducks/user";
import { GET_PROFILE, setProfileDetails, setProfileMedias, setProfileDocuments, setProfileLinks, GET_MEDIA_FILES, GET_ORG_DETAILS, setOrgDetails } from "../../ducks/common";

const fetchOrgDetails = (orgCode) => {
    return Axios.get(GET_ORG_DETAILS_API(orgCode));
}

const fetchUserDetails = (username) => {
    return Axios.get(`${fetchEmployees}/${username}`);
}

const updateProfileDetails = (username, details) => {
    return Axios.put(`${fetchEmployees}/${username}`,
        {
            ...details,
        }
    )
}

const changeProfilePassword = (details) => {
    return Axios.post(`${changePassword}`,
        {
            ...details,
        }
    )
}

const fetchProfileMedias = (params) => {
    return Axios.get(`${saveMedia}`, {
        params,
    });
}



const fetchMediaList = (params) => {
    const { limit, page, type } = params
    return Axios.get(ARENA_GET_MEDIA_API(params.username), { params: { limit, page, type } });
}

const updateBasicProfileRequest = (employeeId, requestObj) => {
    return Axios.put(UPDATE_BASIC_PROFILE_API(employeeId), requestObj);
}

const updateWorkListRequest = (employeeId, requestObj) => {
    return Axios.put(UPDATE_WORK_DETAILS_API(employeeId), requestObj);
}

const updateEducationListRequest = (employeeId, requestObj) => {
    return Axios.put(UPDATE_EDUCATION_DETAILS_API(employeeId), requestObj);
}

const deleteEducationRequest = (details) => {
    return Axios.delete(DELETE_EDUCATION_DETAILS_API(details.employeeId, details.educationId));
}

const deleteWorkExperienceRequest = (details) => {
    return Axios.delete(DELETE_WORK_DETAILS_API(details.employeeId, details.workExperienceId));
}

const fetchArenaProfile = (username) => {
    return Axios.get(GET_EMPLOYEE_DETAILS_API(username));
}

function* watchForChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, function* changePassword(action) {
        try {
            let { data } = yield call(changeProfilePassword, action.payload.details);
            if (data) {
                yield call(action.payload.onSuccessCB, data);
                console.log('watchForChangePassword success');
            }
            else {
                throw data;
            }
        } catch (e) {
            yield call(action.payload.onErrorCB, e.response);
            console.log('watchForChangePassword catch err ', e);
        }
    });
}

function* watchForProfileUpdate() {
    // const { payload } = yield take(UPDATE_PROFILE);
    yield takeEvery(UPDATE_PROFILE, function* UpdateProfile(action) {
        const { postObj, onUpdateSuccessCB, onUpdateErrorCB } = action.payload;
        try {
            let { data } = yield call(updateProfileDetails, postObj.username, postObj.details);

            if (data) {
                yield call(onUpdateSuccessCB, data);
                yield put(setDetails(postObj.previousDetails));
            }

            console.log('Updated Profile success');

        } catch (e) {
            yield call(onUpdateErrorCB, e);
            console.log('updateProfileDetails catch err ', e);
        }
    });
}

function* watchForProfileDetails() {
    yield takeEvery(GET_PROFILE, function* getProfiles(action) {
        const { username, onFetchSuccessCB, onFetchErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchUserDetails, username);
            // let { data } = yield call(fetchUserDetails, payload)
            if (data) {
                yield put(setProfileDetails(data));
                yield call(onFetchSuccessCB, data);
            }

            console.log('Fetch others profile success');

        } catch (e) {
            yield call(onFetchErrorCB, e);
            console.log('Fetch others profile catch err ', e);
        }
    });
}

function* watchForProfileMedia() {
    yield takeEvery(GET_MEDIA_FILES, function* getProfiles(action) {
        try {
            let { data } = yield call(fetchProfileMedias, action.payload);
            let requestType = action.payload.type;
            let targetJid = action.payload.target_jid;
            if (data) {
                if (requestType === 'MEDIA') yield put(setProfileMedias({ targetJid, data }));
                else if (requestType === 'DOCUMENT') yield put(setProfileDocuments({ targetJid, data }));
                else if (requestType === 'LINK') yield put(setProfileLinks({ targetJid, data }));
            }

            console.log('watchForProfileMedia success');

        } catch (e) {
            console.log('watchForProfileMedia catch err ', e);
        }
    });
}

function* watchForUserDetails() {
    // const { payload } = yield take(AUTHENTICATED);
    const { payload } = yield take('LOGIN');

    try {
        yield put(setDetails({ username: payload.decoded_token.preferred_username }));
        let { data } = yield call(fetchUserDetails, payload.decoded_token.preferred_username)
        // const [employees, teams] = yield all([call(fetchContactsAPI, 'userName'), call(fetchTeamsAPI)]);
        if (data) {
            yield put(setDetails(data));
        }

        console.log('fetchUserDetails success');

    } catch (e) {
        console.log('fetchUserDetails catch err ', e);
    }
}



function* watchForMediaList() {
    yield takeEvery(GET_MEDIA_LIST, function* getMediaList(action) {
        const { params, onMediaListSuccess, onMediaListError } = action.payload;
        try {
            let { data, headers } = yield call(fetchMediaList, params);
            if (data) {
                console.log(onMediaListSuccess, data);

                yield call(onMediaListSuccess, data, headers);
                console.log('comments list data success');
            }
        } catch (e) {
            yield call(onMediaListError, e);
            console.log('comments list catch err ', e);
        }
    });
}

function* watchForBasicProfileUpdate() {
    yield takeEvery(UPDATE_BASIC_PROFILE, function* updateBasicProfile(action) {
        const { employeeId, requestObj, onBasicProfileUpdateSuccess, onBasicProfileUpdateError } = action.payload;
        try {
            let { data, headers } = yield call(updateBasicProfileRequest, employeeId, requestObj);
            if (data) {
                yield put(setDetails(requestObj));
                yield call(onBasicProfileUpdateSuccess, data, headers);
                console.log('watchForBasicProfileUpdate data success');
            }
        } catch (e) {
            yield call(onBasicProfileUpdateError, e);
            console.log('watchForBasicProfileUpdate catch err ', e);
        }
    });
}

function* watchForSetWorkList() {
    yield takeEvery(UPDATE_WORK_LIST, function* updateWorkList(action) {
        const { employeeId, username, requestObj, onWorkUpdateSuccess, onWorkUpdateError } = action.payload;
        try {
            let { data, headers } = yield call(updateWorkListRequest, employeeId, requestObj);
            if (data) {
                yield put(getUpdatedProfile({ username }));
                yield call(onWorkUpdateSuccess, data, headers);
                console.log('watchForSetWorkList data success');
            }
        } catch (e) {
            yield call(onWorkUpdateError, e);
            console.log('watchForSetWorkList catch err ', e);
        }
    });
}

function* watchForSetEducationList() {
    yield takeEvery(UPDATE_EDUCATION_LIST, function* updateEducationDetails(action) {
        const { employeeId, username, requestObj, onEducationUpdateSuccess, onEducationUpdateError } = action.payload;
        try {
            let { data, headers } = yield call(updateEducationListRequest, employeeId, requestObj);
            if (data) {
                yield put(getUpdatedProfile({ username }));
                yield call(onEducationUpdateSuccess, data, headers);
                console.log('watchForSetEducationList data success');
            }
        } catch (e) {
            yield call(onEducationUpdateError, e);
            console.log('watchForSetEducationList catch err ', e);
        }
    });
}

function* watchForDeleteEducation() {
    yield takeEvery(DELETE_EDUCATION, function* deleteEducationDetails(action) {
        const { details, onEducationDeleteSuccess, onEducationDeleteError } = action.payload;
        try {
            let { data, headers } = yield call(deleteEducationRequest, details);
            if (data) {
                yield put(setDetails(details.updatedObj));
                yield call(onEducationDeleteSuccess, data, headers);
                console.log('watchForDeleteEducation data success');
            }
        } catch (e) {
            yield call(onEducationDeleteError, e);
            console.log('watchForDeleteEducation catch err ', e);
        }
    });
}

function* watchForDeleteWorkExperience() {
    yield takeEvery(DELETE_WORK_EXPERIENCE, function* deleteWorkExperienceDetails(action) {
        const { details, onWorkExperienceDeleteSuccess, onWorkExperienceDeleteError } = action.payload;
        try {
            let { data, headers } = yield call(deleteWorkExperienceRequest, details);
            if (data) {
                yield put(setDetails(details.updatedObj));
                yield call(onWorkExperienceDeleteSuccess, data, headers);
                console.log('watchForDeleteWorkExperience data success');
            }
        } catch (e) {
            yield call(onWorkExperienceDeleteError, e);
            console.log('watchForDeleteWorkExperience catch err ', e);
        }
    });
}

function* watchForGetUpdatedProfile() {
    yield takeEvery(GET_UPDATED_PROFILE, function* getUpdatedProfileDetails(action) {
        console.log('watchForGetUpdatedProfile action ', action);
        const { username, onEmployeeDetailsSuccess, onEmployeeDetailsError } = action.payload;
        try {
            let { data } = yield call(fetchArenaProfile, username);
            if (data) {
                yield put(setDetails(data));
                // yield call(onEmployeeDetailsSuccess, data, headers);
                console.log('watchForGetUpdatedProfile data success');
            }
        } catch (e) {
            // yield call(onEmployeeDetailsError, e);
            console.log('watchForGetUpdatedProfile catch err ', e);
        }
    });
}

function* watchForArenaProfile() {
    yield takeEvery(GET_ARENA_PROFILE, function* getArenaProfileDetails(action) {
        console.log('watchForArenaProfile action ', action);
        const { username, onEmployeeDetailsSuccess, onEmployeeDetailsError } = action.payload;
        try {
            let { data, headers } = yield call(fetchArenaProfile, username);
            if (data) {
                yield call(onEmployeeDetailsSuccess, data, headers);
                console.log('watchForArenaProfile data success');
            }
        } catch (e) {
            yield call(onEmployeeDetailsError, e);
            console.log('watchForArenaProfile catch err ', e);
        }
    });
}

function* watchForOrgDetails() {
    yield takeEvery(GET_ORG_DETAILS, function* getOrgDetails(action) {
        console.log('watchForOrgDetails action ', action);
        const { orgCode, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchOrgDetails, orgCode);
            if (data) {
                // yield call(onSuccessCB, data);
                yield put(setOrgDetails(data));
                console.log('watchForOrgDetails data success');
            }
        } catch (e) {
            yield put(setOrgDetails({ failed: true }));
            // yield call(onErrorCB, e);
            console.log('watchForOrgDetails catch err ', e);
        }
    });
}

export default function* () {
    yield all([
        watchForUserDetails(),
        watchForProfileUpdate(),
        watchForChangePassword(),
        watchForProfileDetails(),
        watchForProfileMedia(),
        watchForMediaList(),
        watchForBasicProfileUpdate(),
        watchForSetWorkList(),
        watchForSetEducationList(),
        watchForArenaProfile(),
        watchForDeleteEducation(),
        watchForDeleteWorkExperience(),
        watchForGetUpdatedProfile(),
        watchForOrgDetails()
    ]);
}
