import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IllustrationView from '../LiveScores/IllustrationView';
import { connect } from 'react-redux';
import TableFooter from '@material-ui/core/TableFooter';
import { TablePagination } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(3.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const useStyles = makeStyles({
  table: {
    width: '100%',
  }
});


const MaterialUITable = ({ columns = [], data = [], spanning = false, components, loading, options = {}, onRowClick = () => { } }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log('data ', data, columns);
  return (
    <>
      {
        (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
          <div className="material-table">
            <Table className={classes.table} aria-label="spanning table">
              {components?.Header ?
                [components.Header({ columns })]
                :
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      !column.hidden && <TableCell key={index} align={column.align}>{column.title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              }
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : data
                ).map((row, rowIndex) => (
                  <TableRow key={row.desc} onClick={(e) => onRowClick(e, row)} style={{ cursor: options.rowClick && 'pointer' }} selected={row.selected || false}>
                    {
                      columns.map((col, i) => (
                        !col.hidden && <TableCell style={{ ...col?.cellStyle }} key={i} align={col.align}>{col.render ? col.render({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</TableCell>
                      ))
                    }
                  </TableRow>
                ))}
              </TableBody>
              {(data.length > rowsPerPage) && <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[...options.pageSizeOptions]}
                    // colSpan={3}
                    // component="div"
                    labelRowsPerPage="Rows"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>}
            </Table>
          </div >
      }
    </>
  );
}


const mapStateToProps = (state) => ({
  loading: state.loading
});


export default connect(mapStateToProps, null)(MaterialUITable);