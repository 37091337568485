import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getLeaguesList, getSeasonsList, getLiveMatches, getPlayersRankBySeason, getSeasonKpisList, getRoundTeamStatistics, getRoundPlayerStatistics, getPlayersStandingBySeason, getTeamRankBySeason, getTeamStandingBySeason, getPlayersPointsList, getPlayerCoreKpisStats } from "../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getNumberFormat, decodeEnum, getCurrencyFormat, getCurrencyFormatSuffix, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../helpers';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { setLoading } from '../../ducks/loading';
import { useSnackbar } from 'notistack';
import { RankLabelLatest } from '../PointsTables/RankLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "300px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "546px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  Box: {
    height: "138px",
    width: "160px",
    //left: "51px",
    //top: "228px",
    //marginTop: "17px",
    //borderRadius: "10px",
    border: "1px solid #EDEDED",
    // borderColor: '#EDEDED'
    margin: 10,
    marginTop: 16,
    boxShadow: "none"
  },

  cardHeader: {
    fontSize: 13, lineHeight: "19.5px", color: "#000000", marginLeft: 15, fontWeight: '600'
  },
  input: {
    color: "#CCCDFD",
    backgroundColor: "#CCCDFD",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#CCCDFD",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)"
    }
  },
  autocompleteRoot: {
    borderRadius: 30,
    padding: '5px 5px !important',
    fontSize: 25,
    backgroundColor: '#F1F0FD',
    // width: 280
    margin: '10px',
    marginTop: '10px',
    borderColor: '#EBEBFF',
    marginLeft: '45px',
    color: "#5D5FEF",
  },

  nameRoot: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 600,
    '& .name': {
      fontWeight: 500
    }
  },
  scoreCardsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      minWidth: 150,
      minHeight: 125,
      border: '1px solid #EDEDED',
      borderRadius: 3,
      margin: '16px 10px 10px 0',
      padding: 10
    },
    '& .title': {
      color: '#494949',
      fontWeight: 600,
      fontSize: 11,
      margin: '10px 0'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .scoreRoot': {
      fontSize: 11,
      color: '#000',
      margin: 0,
      '& .score': {
        fontSize: 28,
      },
      '& .scoreUnit': {
        fontSize: 12,
        marginLeft: 5
      }
    },
    '& .achievment': {
      fontSize: 12,
      color: '#000',
      marginBottom: 0
    }
  }
}));

export const formattedValue = (userDetails, value, item, units = true) => {
  return item.unit_of_measurement === 'CURRENCY' ?
    getCurrencyFormatSuffix(userDetails?.countryCode, item.unit, value, units)
    : (units ? `${getNumberFormat(userDetails?.countryCode, value)}${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? ` ${getDurationWithUnit(value, item.unit)}` : ''}` : getNumberFormat(userDetails?.countryCode, value));
}

const MyPerformance = ({ isSeasonLevel, roundId, getPlayersPointsList, getPlayerCoreKpisStats, getSeasonKpisList, filtersData, userDetails, setLoading, getRoundPlayerStatistics }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [playerDetails, setPlayerDetails] = useState({});
  const [playerRank, setPlayerRank] = useState({});
  const [playerKpiStats, setPlayerKpiStats] = useState([]);

  useEffect(() => {
    console.log('use effect performance', filtersData)
    if (filtersData?.season?._id && (isSeasonLevel ? true : roundId)) {
      let seasonId = filtersData.season?._id;
      getPlayersPoints(seasonId, roundId);
      if (isSeasonLevel)
        getPlayerKpiStats(seasonId);
    }
  }, [filtersData?.season?._id, roundId]);

  const getPlayersPoints = (seasonId, roundId) => {
    setLoading(true);
    if (isSeasonLevel) {
      getPlayersPointsList({
        params: { season_id: seasonId, limit: 5000, page: 1 },
        onSuccessCB: (result, headers) => {
          if (result) {
            const playerPoints = result.find(obj => obj.username === headers.username);
            if (playerPoints) {
              setPlayerDetails({ name: playerPoints.name, username: playerPoints.username, logo_url: playerPoints.photo_url });
              setPlayerRank(playerPoints);
            }
          }
          setLoading(false);
        }, onErrorCB: (error) => {
          setLoading(false);
          enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
          });
        }
      })
    }
    else {
      console.log('isSeasonLevel ', isSeasonLevel, roundId);
      getRoundPlayerStatistics({
        roundId, params: { limit: 5000, page: 1 },
        onSuccessCB: (result, headers) => {
          if (result) {
            const playerPoints = result.find(obj => obj.player_id === userDetails.username);
            if (playerPoints) {
              setPlayerDetails({ name: playerPoints.player_name, username: playerPoints.employee_id, logo_url: playerPoints.photo_url });
              setPlayerRank({ rank: playerPoints.position, prev_rank: playerPoints.previous_rank });
              setPlayerKpiStats(playerPoints.kpis);
            }
          }
          setLoading(false);
        }, onErrorCB: (error) => {
          setLoading(false);
          enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
          });
        }
      });
    }
  }

  const getPlayerKpiStats = (seasonId) => {
    setLoading(true);
    getPlayerCoreKpisStats({
      seasonId, params: { limit: 5000, page: 1 }, // employee_id: userDetails.username,
      onSuccessCB: (result, headers) => {
        if (result) {
          const playerStats = result.find(obj => obj.username === headers.username);
          console.log('getPlayersPoints ', playerStats.kpis);
          if (playerStats) {
            setPlayerKpiStats(playerStats.kpis);
          }
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch player stats', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    });
  }

  return (
    <>
      {playerDetails.username ?
        <>
          <div className={classes.nameRoot}>
            <AvatarComponent name={playerDetails.name} username={playerDetails.username} url={playerDetails.logo_url} onClick={() => { }} style={{ marginRight: 10, width: 34, height: 34, fontSize: 15 }} />
            <span className="name" style={{ color: '#232637' }}>{playerDetails.name}</span> {/* rowData.team && rowData.team.name */}
          </div>
          <div className={classes.scoreCardsRoot}>
            <div className={'scoreCard'}>
              <p className={'title'}>{'SEASON RANK'}</p>
              <p className={'rankRoot'}><span className={'rank'}>{playerRank.rank}</span><span><RankLabelLatest rank={playerRank.rank} prevRank={playerRank.prev_rank} /></span></p>
            </div>
            {
              playerKpiStats?.map((item, index) =>
                <div key={index} className={'scoreCard'}>
                  <p className={'title'}>{`${item.kpi_name}`}</p>
                  <p className={'scoreRoot'}>
                    {/* <span className={'score'}>
                    {formattedValue(userDetails, item.actual, item)}
                  </span> */}
                    <span className={'score'}>{formattedValue(userDetails, item.actual, item, false)}</span><span className={'scoreUnit'}>{item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? ` ${getDurationWithUnit(item.actual, item.unit)}` : item.unit}</span>
                  </p>
                  <p className={'scoreRoot'}><span>of {formattedValue(userDetails, item.target, item)}</span></p>
                  <p className={'achievment'}>Achieved: {`${getNumberFormat(userDetails?.countryCode, item.achieved)}`}%</p>
                </div>
              )
            }
          </div>
        </>
        : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>}
    </>
  )
}

const mapStateToProps = (state) => ({
  userDetails: state.user
});



const mapDispatchToProps = dispatch => {
  return {
    getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
    setLoading: value => dispatch(setLoading(value)),
    getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
    getPlayerCoreKpisStats: (options) => dispatch(getPlayerCoreKpisStats(options)),
    getRoundPlayerStatistics: (options) => dispatch(getRoundPlayerStatistics(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPerformance));

