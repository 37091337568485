import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, IconButton, makeStyles, useTheme, useMediaQuery, Grid, Button, GridListTileBar, GridList, GridListTile } from '@material-ui/core';
import { Cancel, Close, PlayCircleOutline } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import { openCreateArenaDialog } from '../../ducks/arena';
import { setLoading } from '../../ducks/loading';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import { RdxVideo, Overlay, Controls } from 'react-html5-video-editor';
import ReactVideoTrimmer from "react-video-trimmer";
import "react-video-trimmer/dist/style.css";
// import CreateArenaPost from '../Arena/CreateArenaPost';
import ImageCropper from './ImageCropper';
import Photo from "./Photo";
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import Gallery from 'react-photo-gallery';
import Reorder, { reorder } from 'react-reorder';
import getCroppedImg, { resizeImage } from './cropImage';
import EditMedia from './EditMedia';
import TagUsers from './TagUsers';
import PostTypeSelection from './PostTypeSelection';
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-1.svg";
import CreatePoll from './CreatePoll';
import CreateLink from './CreateLink';
import PdfPostView from './PdfPostView';
import { uploadFilesToS3 } from '../../ducks/common';

const PDFJS = window.pdfjsLib;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        maxWidth: 1000,
        width: 'calc(100% - 100px)',
        borderRadius: 15,
        // padding: '60px 90px',
        // height: '85%',
        display: 'flex',
        justifyContent: 'center',
        maxHeight: 'none',
        [theme.breakpoints.down('sm')]: {
            // padding: '60px 50px 20px'
            maxWidth: 400
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    closeIcon: {
        position: 'absolute',
        // right: 10,
        left: 10,
        top: 10,
        color: '#FFF',
        backgroundColor: '#00000080',
        padding: 0
    },
    sectionsWrapper: {
        display: 'flex',
        // backgroundColor: 'rgb(0 0 0 / 90%)',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            overflowY: 'auto'
        },
    },
    previewSection: {
        borderRight: '1px solid #dadbdd',
        position: 'relative',
        // minHeight: 500,
        minHeight: 'calc(100vh - 64px)',
        maxWidth: 600,
        maxHeight: 'calc(100vh - 64px)',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 400,
            maxHeight: 400,
            width: '100%',
            minHeight: 400,
        },
    },

    postDetails: {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 64px)',
        // maxHeight: '87vh',
        // maxHeight: 600,
        maxWidth: 400,
        // paddingBottom: 20,
        // position: 'relative',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: 'none',
            // display: 'none'
            // position: 'inherit',
            // marginBottom: 44
        },
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        // maxHeight: '85vh'

        // Gallery styles 
        '& .react-photo-gallery--gallery': {
            '& div': {
                justifyContent: 'center'
            }
        }
    },
    uploadButtonRoot: {
        margin: '20px 0px',
        textAlign: 'center'
    },
    netxButtonRoot: {
        margin: '20px 35px',
        // textAlign: 'right',
        display: 'flex',
        justifyContent: 'space-between',
        '& .next-arrow': {
            '& path': {
                fill: '#FFF'
            }
        }
    },
    noteStyles: {
        margin: 0,
        fontSize: 11,
        '& span': {
            fontWeight: 600
        }
    },
    gridList: {
        margin: '0px 40px !important'
    },
    listItem: {
        height: '100px !important',
        padding: '10px !important',
        cursor: 'pointer'
    },
    listItemTitle: {
        height: 20
    },
    videoStyles: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff',
        position: 'absolute',
        left: 'calc(50% - 18px)',
        top: 'calc(50% - 18px)'
    },
    imageStyle: {
        width: '100%',
        '&:hover': {
            transitionDuration: '500ms',
            width: '110%'
        }
    },
    imageRoot: {
        position: 'relative',
        '&:hover': {
            '& #delete-icon': {
                visibility: 'visible',
                '& svg': {
                    fontSize: '0.85rem',
                    backgroundColor: '#000',
                    color: '#FFF'
                },
            }
        }
    },
    deleteIcon: {
        position: 'absolute',
        right: '2px',
        top: '1px',
        visibility: 'hidden',
        cursor: 'pointer'
    },
    selectTextRoot: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        // color: '#FFF'
    }
}))

// RdxVideo.Props = {
//     autoPlay: false,
//     loop: false,
//     controls: true,
//     volume: 1.0,
//     preload: "auto",
//     cropEnabled: true
// }

const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items, onClick }) => (
    <Gallery margin={8} photos={items} onClick={onClick} renderImage={props => <SortablePhoto {...props} />} />
));

export const getFirstImage = (list = []) => {
    const index = list.findIndex(obj => obj.mimeType === 'IMAGE');
    return list.length > 0 ? { ...list[index], index } : null;
}

export const cropTypes = [
    { label: 'Square', value: 'SQUARE', ratio: 1, size: { width: 550, height: 550 }, actualSize: { width: 1080, height: 1080 } },
    { label: 'Wide', value: 'WIDE', ratio: 1.9, size: { width: 550, height: 289.25 }, actualSize: { width: 1080, height: 568 } },
    { label: 'Tall', value: 'TALL', ratio: 0.8, size: { width: 550, height: 687.5 }, actualSize: { width: 1080, height: 1350 } }
];

let attachmentsCount = 0;
let reordered = false;
const CreateArena = ({ createArena: { open, arena = {} }, handleOnNewPost, openCreateArenaDialog, uploadFilesToS3, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const [attachments, setAttachments] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [step, setStep] = useState('postType');
    const [pollDetails, setPollDetails] = useState(null);
    const [linkDetails, setLinkDetails] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [cropType, setCropType] = useState(cropTypes[0]);

    const handleClose = () => {
        openCreateArenaDialog({ open: false, arena: {} });
        setSelectedImage(null);
        setAttachments([]);
        setStep('postType');
    }

    const handlePostType = (type) => {
        switch (type) {
            case 'IMAGE':
                setStep('crop');
                break;
            case 'TEXT':
                setStep('tagUser');
                break;
            case 'POLL':
                setStep('poll');
                break;
            case 'LINK':
                setStep('link');
                break;
            case 'PDF':
                setStep('pdf');
                break;
            default:
                setStep('crop');
                break;
        }
    }

    useEffect(() => {
        if (attachments.length > 0 && attachments.length === attachmentsCount) {
            setSelectedImage(getFirstImage(attachments));
            attachmentsCount = 0;
        }
        else if (attachments.length === 0) attachmentsCount = 0;
    }, [attachments]);

    const handleUploadClick = () => {
        const element = document.getElementById(`hiddenFileInputForArenaCreation`);
        element.value = '';
        element.click();
    }

    const isValidCount = (newFiles, attachments) => {
        return (newFiles.length + attachments.length) <= 10;
    }

    const handleFileChange = (event) => {
        // attachmentsCount = 0;
        // finalAttachmentsCount = 0;
        let files = event.target.files;
        // console.log('handleFileChange ', files);
        if (files && files.length > 0 && isValidCount(files, attachments)) {
            attachmentsCount += files.length;
            for (let index = 0; index < files.length; index++) {
                let fileSize = files[index].size / 1024 / 1024; // in MB
                if ((files[index].type).startsWith('image/')) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        setAttachments(prevState => ([...prevState, { url: e.target.result, mimeType: (files[index].type.split('/').shift()).toUpperCase(), width: 4, height: 4, fileName: files[index].name, fileType: files[index].type }]));
                    }
                    reader.readAsDataURL(files[index]); // convert to base64 string
                }
                else if (files[index].type && (files[index].type).startsWith('video/')) {
                    let video = document.createElement('video');
                    video.preload = 'metadata';

                    video.onloadedmetadata = function () {
                        window.URL.revokeObjectURL(video.src);
                        let duration = video.duration;
                        // myVideos[myVideos.length - 1].duration = duration;
                        // updateInfos();
                        // console.log('VIdeo Duration ', Math.ceil(duration));
                        if (fileSize > 40) {
                            enqueueSnackbar('Video size cannot exceed 40 MB.', {
                                variant: 'error',
                                preventDuplicate: true
                            });
                            // setUploading(false);
                            if (event.target) event.target.value = "";
                        }
                        else {
                            // if (Math.ceil(duration) > 60) {
                            //     attachmentsCount -= 1;
                            //     enqueueSnackbar('Video length cannot exceed 60 sec.', {
                            //         variant: 'error',
                            //         preventDuplicate: true
                            //     });
                            //     if (event.target) event.target.value = "";
                            // }
                            // else {
                            let reader = new FileReader();
                            reader.onload = (e) => {
                                setAttachments(prevState => ([...prevState, { url: e.target.result, mimeType: (files[index].type.split('/').shift()).toUpperCase(), fileName: files[index].name, fileType: files[index].type }]));
                            }
                            reader.readAsDataURL(files[index]);
                            // }
                        }
                    }

                    video.src = URL.createObjectURL(files[index]);;

                    // if (fileSize > 20) {
                    //     enqueueSnackbar('Video size cannot exceed 20 MB.', {
                    //         variant: 'error',
                    //         preventDuplicate: true
                    //     });
                    //     // setUploading(false);
                    //     if (event.target) event.target.value = "";
                    // }
                    // else {
                    //     // setFile({ name: files[index].name });
                    //     let reader = new FileReader();
                    //     reader.onload = (e) => {
                    //         setAttachments(prevState => ([...prevState, { url: e.target.result, thumbnailUrl: e.target.result, mimeType: (files[index].type.split('/').shift()).toUpperCase() }]));
                    //     }
                    //     reader.readAsDataURL(files[index]); // convert to base64 string
                    // }
                }
            }
        }
        else {
            enqueueSnackbar('Total attachments count cannot exceed 10.', {
                variant: 'error',
                preventDuplicate: true
            });
        }
    }

    const handlePdfUploadClick = () => {
        const element = document.getElementById(`hiddenFileInputForPdfPost`);
        element.value = '';
        element.click();
    }

    const handlePdfFileChange = (event) => {
        let files = event.target.files;
        if (files?.length > 0) {
            let fileSize = files[0].size / 1024 / 1024; // in MB
            if (fileSize > 40) {
                enqueueSnackbar('File size cannot exceed 40 MB.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                if (event.target) event.target.value = "";
            }
            else {
                let reader = new FileReader();
                reader.onload = async (e) => {
                    const uri = URL.createObjectURL(files[0]);
                    const PDF = await PDFJS.getDocument({ url: uri });
                    const page = await PDF.getPage(1);
                    const viewport = page.getViewport(1);

                    const render_context = {
                        canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
                        viewport: viewport
                    };
                    setWidth(viewport.width);
                    setHeight(viewport.height);
                    await page.render(render_context);
                    const canvas = document.getElementById("pdf-canvas");
                    const img = canvas.toDataURL("image/png");
                    setAttachments(prevState => ([{ url: e.target.result, mimeType: (files[0]?.type?.split('/').pop()).toUpperCase(), fileName: files[0].name, fileType: files[0]?.type, thumbnailUrl: img }]));
                    srcToFile(img, 'thumbnail.png', "image/png").then((file) => {
                        let formData = new FormData();
                        formData.append("file", file);
                        uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: { mimeType: (files[0]?.type?.split('/').pop()).toUpperCase(), index: 0, name: 'thumbnail.png' } });
                    });
                }
                reader.readAsDataURL(files[0]);
            }
        }
    }

    const onUploadSuccessCB = (result, file) => {
        if (result.url) {
            setThumbnailUrl(result.url);
        }
    }

    const onUploadErrorCB = (result, file) => {
        if (result.response) {
            enqueueSnackbar(`Failed to Upload ${file.name}.`, {
                variant: 'error'
            });
        }
    }

    const srcToFile = (src, fileName, mimeType) => {
        return (fetch(src)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
        );
    }

    const handleDeletePdf = (e, index) => {
        let attachmentsDup = JSON.parse(JSON.stringify(attachments));
        attachmentsDup.splice(index, 1);
        setAttachments(attachmentsDup);
    }

    const handlePhotoClick = (event, { index, photo }, a) => {
        // console.log('handlePhotoClick ', event, photo, a);
        // if (!reordered) {
        setSelectedImage({ ...photo, index, newCrop: { x: 0, y: 0 } })
        // }
        // else {
        //     reordered = false;
        // }
    }

    const setCroppedImage = (img, index) => {
        let attachmentsDup = JSON.parse(JSON.stringify(attachments));
        // console.log('setCroppedImage ', index, selectedImage);
        attachmentsDup[index].url = img;
        attachmentsDup[index].isCropped = true;
        setAttachments([...attachmentsDup]);
        if (selectedImage.index === index) {
            setSelectedImage(prevState => ({ ...prevState, ...attachmentsDup[index] }))
        }
    }

    // const onChange = (index, value, entityType) => {
    //     setSelectedImage({ ...selectedImage, croppedAreaPixels: value })
    // }

    const handleDeletePhoto = (e, index) => {
        // console.log('handleDeletePhoto ', e, index);
        let attachmentsDup = JSON.parse(JSON.stringify(attachments));
        attachmentsDup.splice(index, 1);
        // console.log('attachmentsDup ', attachmentsDup);
        setAttachments(attachmentsDup);
        setSelectedImage(getFirstImage(attachmentsDup));
    }

    const handleNext = (from, editedImages = []) => {
        // console.log('handleNext ', from, editedImages);
        const attachmentsToCrop = from === 'edit' ? editedImages : attachments;
        let attachmentsDup = [];
        attachmentsDup = attachmentsToCrop.map((item, index) => {
            if (item.mimeType === 'IMAGE' && (from === 'crop' && !item.isCropped)) {
                // console.log('attachements from crop ');
                try {
                    const img = new Image();
                    img.onload = async function () {
                        // console.log('IMage ', this.width, this.height);
                        // let less = (this.width < this.height) ? 'width' : 'height';
                        // const defaultCropAreaPixel = { x: less === 'width' ? 0 : (this.width - this.height) / 2, y: less === 'height' ? 0 : (this.height - this.width) / 2, width: this[less], height: this[less] };
                        let less = (this.width < this.height) ? 'width' : 'height';
                        let defaultCropAreaPixel = {};
                        if (cropType.value === "SQUARE") {
                            defaultCropAreaPixel = { x: less === 'width' ? 0 : (this.width - this.height) / 2, y: less === 'height' ? 0 : (this.height - this.width) / 2, width: this[less], height: this[less] };
                        }
                        else if (cropType.value === "WIDE") {
                            const width = this.width;
                            const height = this.width / cropType.ratio;
                            const x = 0;
                            const y = (this.height - height) / 2;
                            defaultCropAreaPixel = { width, height, x, y }
                        }
                        else if (cropType.value === "TALL") {
                            const hasLessRatio = (this.width / this.height) < 0.8;
                            const width = hasLessRatio ? this.width : (this.height * cropType.ratio);
                            const height = hasLessRatio ? (this.width / cropType.ratio) : this.height;
                            const x = (this.width - width) / 2;
                            const y = (this.height - height) / 2;
                            defaultCropAreaPixel = { width, height, x, y }
                        }
                        console.log('IMage 2 ', less, this.width, this.height, defaultCropAreaPixel);

                        const croppedImage = await getCroppedImg(
                            item.url,
                            defaultCropAreaPixel,
                            0,
                            cropType.size, // Resize to
                        );
                        return ({ ...item, url: croppedImage });
                    }
                    img.src = item.url;
                    return img.onload();
                } catch (e) {
                    console.error('index', index, e)
                }
            }
            else {
                if (item.mimeType === 'IMAGE' && from === 'edit') {
                    // console.log('attachements from edit ', resizeImage(item.url));
                    return new Promise(async (resolve) => resolve({ ...item, url: await resizeImage(item.url, cropType?.actualSize) })); // i(tem.url, { width: 1080, height: 1080 })
                }
                else return new Promise((resolve) => resolve({ ...item }));
            }
        });
        Promise.all(attachmentsDup).then(res => {
            setAttachments(res);
            setStep(from === 'crop' ? res.some(obj => obj.mimeType === 'IMAGE') ? 'edit' : 'tagUser' : 'tagUser');
            // console.log('res ', res.forEach((element, index) => {
            // downloadFiles(element.url, `image-${index}`);
            // }));
        }).catch(e => console.log(e));
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setAttachments(arrayMove(attachments, oldIndex, newIndex));
    };

    const handleOnsubmit = () => {
        handleClose();
        history.replace('/wall', { hasNewPost: true });
    }

    const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        // reordered = true;
        setAttachments(reorder([...attachments], previousIndex, nextIndex));
    }

    const onPollSubmit = (pollData) => {
        setPollDetails(pollData);
        setStep('tagUser')
    }

    const onLinkSubmit = (linkData) => {
        if (linkData) {
            setLinkDetails(linkData);
            setStep('tagUser');
        }
        else {
            enqueueSnackbar('Please enter valid link or url.', {
                variant: 'error',
                preventDuplicate: true
            });
        }

    }

    // console.log('attachements ', attachments);
    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='lg'
                open={open}
                onClose={handleClose}
                aria-labelledby="create-arena-dialog"
                // style={{ zIndex: 1301 }}
                disableEscapeKeyDown
                disableBackdropClick
                classes={{ paper: classNames(classes.dialogPaper) }}
            >
                <>
                    <div className={classes.sectionsWrapper} style={{ backgroundColor: step === 'crop' ? '' : '#FFF' }}>
                        {
                            step === 'postType' ?
                                <PostTypeSelection handleClick={handlePostType} />
                                :
                                step === 'crop' ?
                                    <>
                                        <div id="cropper-root" className={classes.previewSection} >
                                            {(selectedImage && selectedImage.mimeType === 'IMAGE') ? // && !reordered
                                                [
                                                    // selectedImage.mimeType === 'IMAGE' ?
                                                    <ImageCropper {...selectedImage} cropType={cropType} setCropType={setCropType} setCroppedImage={setCroppedImage} />
                                                    // : null
                                                    // <ReactVideoTrimmer timeLimit={20} showEncodeBtn src={selectedImage.url} />
                                                ]
                                                : <div className={classes.selectTextRoot}>
                                                    {attachments.length > 0 ? <p>Select Image to Crop</p> : <p>Upload media to get started</p>}
                                                </div>
                                            }
                                        </div>
                                        <div className={classes.postDetails}>
                                            <div className={classes.uploadButtonRoot}>
                                                <Button variant="outlined" color="primary" size="medium" onClick={() => handleUploadClick()}>Upload media</Button>
                                                <input type="file"
                                                    multiple
                                                    id={`hiddenFileInputForArenaCreation`}
                                                    onChange={(e) => handleFileChange(e)}
                                                    style={{ display: 'none' }}
                                                    accept="image/*,video/*" // ,video/*
                                                />
                                            </div>
                                            <div style={{ overflowY: 'auto', height: '-webkit-fill-available', height: '100%' }}>
                                                {/* <Gallery margin={8} photos={attachments} onClick={handlePhotoClick} renderImage={props => */}
                                                <Reorder
                                                    reorderId="my-list" // Unique ID that is used internally to track this list (required)
                                                    reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                                                    // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                                    // component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                                    // placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                                    // draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                                    // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                                    // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                                    // touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                                    // mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                                    onReorder={(...args) => onReorder(...args)} // Callback when an item is dropped (you will need this to update your state)
                                                    // autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                                    // disabled={false} // Disable reordering (optional), defaults to false
                                                    // disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                                    placeholder={
                                                        <div className="custom-placeholder" style={{ width: 100, height: 100, margin: 8 }} /> // Custom placeholder element (optional), defaults to clone of dragged element
                                                    }
                                                    style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}
                                                >
                                                    {

                                                        attachments.map((obj, index) =>
                                                            <div key={index} className={classes.imageRoot}>
                                                                {
                                                                    obj.mimeType === 'IMAGE' ?
                                                                        <Photo index={index} margin={8} photo={{ ...obj }} onClick={handlePhotoClick} />
                                                                        :
                                                                        <div style={{ position: 'relative' }}>
                                                                            <video src={obj.url} autoPlay={false} style={{ cursor: 'pointer', margin: 8, width: 100, height: 100 }} />
                                                                            <PlayCircleOutline
                                                                                className={classes.playIcon}
                                                                                fontSize="large"
                                                                            />
                                                                        </div>
                                                                }
                                                                <span id="delete-icon" className={classes.deleteIcon}>
                                                                    <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index)} />
                                                                </span>
                                                                <p style={{ margin: '-10px 10px 0', fontSize: 12 }}>{index + 1}</p>
                                                            </div>
                                                        )
                                                    }
                                                </Reorder>
                                                {/* } /> */}
                                            </div>
                                            {(attachments.length > 0) && <div className={classes.netxButtonRoot}>
                                                <p className={classes.noteStyles}><span>*Note:</span> By clicking on Next the media will get cropped automatically</p>
                                                <Button title="Next" variant="contained" color="primary" size="medium" onClick={() => handleNext('crop')}><ArrowRight className="next-arrow" /></Button>
                                            </div>}
                                        </div>
                                    </>
                                    :
                                    step === 'edit' ?
                                        <EditMedia cropType={cropType} attachments={attachments} handleEditSubmit={(data) => handleNext('edit', data)} />
                                        :
                                        step === 'poll' ?
                                            <CreatePoll handlePoll={onPollSubmit} />
                                            :
                                            step === 'link' ?
                                                <CreateLink handleLink={onLinkSubmit} linkDetails={linkDetails} setLinkDetails={setLinkDetails} />
                                                :
                                                step === 'pdf' ?
                                                    <PdfPostView classes={classes} attachments={attachments} handlePdfFileChange={handlePdfFileChange} handlePdfUploadClick={handlePdfUploadClick} handleDeletePdf={handleDeletePdf} handleNext={handleNext} canvas={{ width: width, height: height }} />
                                                    :
                                                    <TagUsers cropType={cropType} pollDetails={pollDetails} linkDetails={linkDetails} attachments={attachments} onSubmit={handleOnsubmit} thumbnailUrl={thumbnailUrl} />
                        }
                    </div>
                    <IconButton className={classes.closeIcon} onClick={handleClose}><Cancel fontSize="default" /></IconButton>
                </>
            </Dialog >
        </div>
    )
}

const mapStateToProps = (state) => ({
    createArena: state.arena.createArena,
});

const mapDispatchToProps = (dispatch) => {
    return {
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        setLoading: (value) => dispatch(setLoading(value)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CreateArena);