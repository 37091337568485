import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Avatar, Grid, TextareaAutosize, Button, Backdrop, CircularProgress, IconButton, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Close, Add, PlayCircleOutline, ExpandMore } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import imageCompression from 'browser-image-compression';
// import FilerobotImageEditor from 'filerobot-image-editor';
import { ReactComponent as AddPhotoIcon } from "../../assets/icons/add-photo.svg";
import { ReactComponent as AddBackgroundIcon } from "../../assets/icons/add-background.svg";
import { ReactComponent as TagChaseonsIcon } from "../../assets/icons/tag-chaseons-2.svg";
import { ReactComponent as FeaturedIcon } from '../../assets/icons/featured-icon.svg';
import { ReactComponent as NonFeaturedIcon } from '../../assets/icons/non-featured-icon.svg';
import { uploadFilesToS3 } from '../../ducks/common';
import { postArena, tagChaseOns, openEditArenaDialog, updateArena, getBackgroundColors } from '../../ducks/arena';
import { setLoading } from '../../ducks/loading';
import Axios from 'axios';
import ChaseOnsAutocomplete from './ChaseOnsAutocomplete';
import { getAvatarText } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatarRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px'
    },
    profileName: {
        marginLeft: 10,
        color: '#58595B',
        fontSize: 16,
        fontWeight: 600
    },
    content: {
        margin: '0px 15px 10px'
    },
    textareaRoot: {
        marginBottom: 15
    },
    textareaStyles: {
        border: 'none',
        fontSize: 15,
        color: '#838383',
        outline: 'none',
        width: '100%',
        resize: 'none',
        lineHeight: '20px'
    },
    backgroundPostTextareaStyles: {
        border: 'none',
        fontSize: 22,
        fontWeight: 600,
        color: '#838383',
        outline: 'none',
        width: 'calc(100% - 20px)',
        resize: 'none',
        lineHeight: '35px',
        backgroundColor: 'inherit',
        padding: '40px 10px 30px',
        textAlign: 'center'
    },
    actionRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 6,
        borderTop: '2px solid #F5F5F5',
    },
    actionButton: {
        fontSize: 13,
        padding: '6px 15px',
        textTransform: 'none',
        '&:hover': {
            background: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            '& span': {
                marginRight: 0,
            }
        }
    },
    tagChaseonsIcon: {
        // width: 26,
        // height: 26,
        '& path': {
            fill: theme.palette.primary.main,
        },
        // '& path[stroke="white"]': {
        //     stroke: theme.palette.primary.main,
        // }
    },
    chaseonsText: {
        marginTop: 4,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    buttonText: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    buttonRoot: {
        // display: 'flex',
        // justifyContent: 'flex-end',
        marginTop: 10,
        textAlign: 'right',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    attachmentsList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        height: 100,
        width: 120,
        backgroundColor: '#EEEEEE',
        textAlign: 'center',
        border: '1px solid #EEEEEE',
        position: 'relative',
        marginRight: 10,
        marginBottom: 10,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.5,
            '& #delete-icon': {
                visibility: 'visible',
                '& svg': {
                    fontSize: '0.85rem',
                    backgroundColor: '#000',
                    color: '#FFF'
                },
            }
        },
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
        }
    },
    attachmentPickerSection: {
        height: 100,
        width: 120,
        border: '2px dashed #58595B',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            opacity: 0.3
        }
    },
    uploadingRoot: {
        position: 'relative',
        height: 100,
        width: 120
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    deleteIcon: {
        position: 'absolute',
        right: '2px',
        top: '1px',
        visibility: 'hidden',
        cursor: 'pointer'
    },
    videoStyles: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#828282",
        position: "relative",
        textAlign: "center",
        color: "#fff",
        overflow: 'hidden',
        "&:hover": {
            cursor: "pointer"
        },
        '& button': {
            position: 'absolute'
        }
    },
    playIcon: {
        color: '#fff'
    },
    featuredButtom: {
        marginRight: 20,
        border: '1px solid #EEEEEE',
        padding: '2px 8px',
        width: 155,
        justifyContent: 'left',
        '&:hover': {
            background: 'none'
        }
    },
    featuredOptions: {
        padding: 0,
        '& li': {
            padding: '6px 4px',
            color: '#535353',
            fontSize: 14,
            lineHeight: 1.2,
            '& svg': {
                marginRight: 8
            }
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    backgroundColorsList: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '0px 25px 17px',
        '& div': {
            height: 24,
            width: 24,
            borderRadius: 6,
            cursor: 'pointer'
        }
    }
}));

export function ConfirmationDialog(props) {
    const { handleConfirmationDialog, open, deleteItem, ...other } = props;
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogContent>
                <p>{props.content || 'Are you sure, you want to cancel this post?'}</p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-evenly', padding: 25 }}>
                <Button variant='contained' onClick={() => handleConfirmationDialog(false)} >
                    Cancel
          </Button>
                <Button variant='contained' onClick={() => handleConfirmationDialog(true, "yes", deleteItem)} color="primary">
                    Yes
          </Button>
            </DialogActions>
        </Dialog>
    );
}

let attachmentsCount = 0;
let finalAttachmentsCount = 0;
const defaultDetails = {
    category: "REGULAR",
    text: "",
    attachments: [],
    tags: [],
    type: "TEXT"
}
const maxBgText = 100;
const CancelToken = Axios.CancelToken;

const defaultBackground = {
    text_color: "#FFFFFF",
    bg_color: { "type": "linear", "angle": 180, "start": "#80D0C7", "end": "#13547A" }
};

const CreateArenaPost = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);
    const [details, setDetails] = useState(props.fromEdit ? props.arena : defaultDetails);
    const [featured, setFeatured] = useState(false);
    const [open, setOpen] = useState(false);
    const [source, setSource] = useState(CancelToken.source());
    const [openConfirmDialog, SetOpenConfirmDialog] = useState(false);
    const [selectedChaseOns, setSelectedChaseOns] = useState(props.fromEdit ? props.arena.tags : []);
    const [openChaseons, setOpenChaseons] = useState((props.fromEdit && props.arena.tags && props.arena.tags.length > 0) ? true : false);
    const [addBackground, setAddBackground] = useState((props.fromEdit && props.arena.background) ? true : false);
    const [imageEditor, toggleImageEditor] = useState({ open: false, src: '' });
    const anchorRef = useRef(null);
    const prevOpen = useRef(open);

    const src = 'https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg';
    const [show, toggle] = useState(false);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (!props.backgroundColorsList || !props.backgroundColorsList.length)
            props.getBackgroundColors({ onGetColorsSuccessCB, onGetColorsErrorCB });
    }, [props.backgroundColorsList]);

    const onGetColorsSuccessCB = (result) => {

    }

    const onGetColorsErrorCB = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch background colors.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuItemClick = (event, value) => {
        setFeatured(value);
        handleClose(event);
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    function handleListKeyDown(event) {
        // if (event.key === 'Tab') {
        //     event.preventDefault();
        //     setOpen(false);
        // }
    }

    const handleChangeInput = (event) => {
        let id = event.target.id;
        let value = event.target.value;
        setDetails(prevState => {
            return { ...prevState, text: value }
        })
    }

    const handleCancelPost = () => {
        if (props.fromEdit) {
            props.openEditArenaDialog({ open: false, arena: {} })
        }
        else
            SetOpenConfirmDialog(true);
    }

    const handleConfirmationDialog = (value) => {
        if (value) {
            source.cancel('User Cancelled post.');
            setSource(CancelToken.source());
            setDetails(defaultDetails);
            SetOpenConfirmDialog(false);
        }
        else {
            SetOpenConfirmDialog(false);
        }
    }

    const handleSubmitPost = () => {
        let requestObj = {
            category: featured ? 'FEATURED' : 'REGULAR',
            type: details.attachments.length ? 'IMAGE' : 'TEXT',
            text: details.text,
            tags: selectedChaseOns.map(user => user.username)
        };
        if (details.attachments.length) requestObj.attachments = details.attachments;
        if (details.tags.length) requestObj.tags = details.tags;
        if (addBackground) requestObj.background = details.background ? details.background : ((props.backgroundColorsList && props.backgroundColorsList.length > 0) ? props.backgroundColorsList[0] : defaultBackground);
        props.setLoading(true);
        props.postArena({ requestObj, onSuccessCB, onErrorCB });
        setAddBackground(false);
    }

    const onSuccessCB = (result) => {
        console.log(result);
        setDetails(defaultDetails);
        setOpenChaseons(false);
        setSelectedChaseOns([]);
        enqueueSnackbar(`Post Created successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        props.handleOnNewPost(new Date().getTime());
        props.setLoading(false);
        // props.tagChaseOns({ postId: result.id, requestObj: { user_ids: selectedChaseOns.map(user => user.username) }, onTagChaseOnsSuccessCB, onTagChaseOnsErrorCB });
    }

    const onErrorCB = (error) => {
        console.log('onErrorCB ', error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to post Arena.`, {
            variant: 'error',
            preventDuplicate: true
        });
        props.setLoading(false);
    }

    const handleUpdatePost = () => {
        let requestObj = {
            category: featured ? 'FEATURED' : 'REGULAR',
            type: details.attachments.length ? 'IMAGE' : 'TEXT',
            text: details.text,
            deleteAttachmentIds: details.deleteAttachmentIds || [],
            tags: selectedChaseOns.map(user => user.username)
        };
        if (details.newAttachments && details.newAttachments.length > 0) requestObj.newAttachments = details.newAttachments;
        if (details.background) requestObj.background = details.background;
        props.setLoading(true);
        props.updateArena({ postId: details.postId, requestObj, onUpdateSuccessCB, onUpdateErrorCB });
    }

    const onUpdateSuccessCB = (result) => {
        console.log(result);
        // setDetails(defaultDetails);
        // setOpenChaseons(false);
        // setSelectedChaseOns([]);
        enqueueSnackbar(`Post Updated successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        props.handleClose();
        props.handleOnNewPost(new Date().getTime());
        props.setLoading(false);
    }
    const onUpdateErrorCB = (error) => {
        console.log('onErrorCB ', error);
        enqueueSnackbar(`Failed to Update Arena.`, {
            variant: 'error',
            preventDuplicate: true
        });
        props.setLoading(false);
    }

    const onTagChaseOnsSuccessCB = () => {
        setDetails(defaultDetails);
        setOpenChaseons(false);
        setSelectedChaseOns([]);
        enqueueSnackbar(`Post Created successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        props.handleOnNewPost(new Date().getTime());
        props.setLoading(false);
    }

    const onTagChaseOnsErrorCB = (error) => {
        console.log('onTagChaseOnsErrorCB ', error);
        enqueueSnackbar(`Failed to tag Chaseons.`, {
            variant: 'error',
            preventDuplicate: true
        });
        props.setLoading(false);
    }

    const handleAddPhoto = () => {
        const element = document.getElementById(`hiddenFileInputForArenaAttachments${props.fromEdit}`);
        element.value = '';
        element.click();
    }

    const handleAddBackground = () => {
        setDetails(prevState => ({
            ...prevState,
            ...defaultDetails,
            text: prevState.text ? prevState.text.substring(0, maxBgText) : '',
            background: prevState.background ? prevState.background : ((props.backgroundColorsList && props.backgroundColorsList.length > 0) ? props.backgroundColorsList[0] : defaultBackground)
        }));
        if (!props.fromEdit)
            setAddBackground(!addBackground);
    }

    const handleTagChaseons = () => {
        setOpenChaseons(!openChaseons);
        // setSelectedChaseOns([]);
    }

    const handleBackgroundChange = (obj) => {
        setDetails(prevState => ({ ...prevState, background: { ...obj } }))
    }

    const onUploadSuccessCB = (result, file) => {
        finalAttachmentsCount++;
        if (result.url) {
            if (props.fromEdit)
                setDetails(prevState => ({ ...prevState, newAttachments: [...prevState.newAttachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] }));
            else
                setDetails(prevState => ({ ...prevState, attachments: [...prevState.attachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] }));
            // setUploadErrorText('');  
        }
        // else setUploadErrorText('Failed');
        if (finalAttachmentsCount === attachmentsCount) {
            setUploading(false);
        }
    }

    const onUploadErrorCB = (result, file) => {
        finalAttachmentsCount++;
        console.log('onUploadErrorCB ', result);
        enqueueSnackbar(`Failed to Upload ${file.name}.`, {
            variant: 'error'
        });
        if (finalAttachmentsCount === attachmentsCount) {
            setUploading(false);
        }
    }

    const handleFileChange = (event) => {
        attachmentsCount = 0;
        finalAttachmentsCount = 0;
        let files = event.target.files;
        console.log('handleFileChange ', files);
        if (files && files.length > 0) {
            attachmentsCount = files.length;
            // setUploading(true);
            for (let index = 0; index < files.length; index++) {
                // const element = array[index];
                let fileSize = files[index].size / 1024 / 1024; // in MB
                if ((files[index].type).startsWith('image/')) {
                    const imageCompressionOptions = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(files[index], imageCompressionOptions)
                        .then((compressedFile) => {
                            let resultFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type, lastModified: compressedFile.lastModified });
                            let formData = new FormData();
                            formData.append("file", resultFile);
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                setDetails(prevState => ({ ...prevState, attachments: [...prevState.attachments, { url: e.target.result, thumbnailUrl: e.target.result, mimeType: (files[index].type.split('/').shift()).toUpperCase() }] }));
                            }
                            reader.readAsDataURL(files[index]); // convert to base64 string
                            // props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
                        })
                        .catch((error) => {
                            console.log(error.message);
                            setUploading(false);
                        });
                    // if (event.target) event.target.value = "";
                }
                else if (files[index].type && (files[index].type).startsWith('video/')) {
                    if (fileSize > 20) {
                        enqueueSnackbar('Video size cannot exceed 20 MB.', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                        setUploading(false);
                        if (event.target) event.target.value = "";
                    }
                    else {
                        // setFile({ name: files[index].name });
                        // var reader = new FileReader();
                        // reader.onload = function (e) {
                        // setFile({ base64: e.target.result, name: files[index].name, content: files[index] })
                        // }
                        // reader.readAsDataURL(files[index]); // convert to base64 string

                        var formData = new FormData();
                        formData.append("file", files[index]);
                        setUploading(true);
                        props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
                        // if (event.target) event.target.value = "";
                    }
                }
            }
        }
    }

    const handleDeletePhoto = (e, index, isNewAttachement) => {
        console.log('handleDeletePhoto ', e, index);
        let detailsDup = JSON.parse(JSON.stringify(details));
        if (props.fromEdit && isNewAttachement)
            detailsDup.newAttachments.splice(index, 1);
        else {
            if (props.fromEdit && detailsDup.attachments[index].id && !isNewAttachement)
                detailsDup.deleteAttachmentIds = [...(detailsDup.deleteAttachmentIds || []), detailsDup.attachments[index].id]

            detailsDup.attachments.splice(index, 1);
        }
        setDetails(detailsDup);
    }

    const handleChangeAutocomplete = (e, data) => {
        console.log('handleChangeAutocomplete ', data);
        const uniqueChaseons = [...new Map(data.map(item => [item['username'], item])).values()];
        setSelectedChaseOns(uniqueChaseons)
    }

    const getAttachmentsList = (
        <>
            {details.attachments && details.attachments.map((item, index) => (
                <div key={index} className={classes.attachment}>
                    {
                        item.mimeType === 'IMAGE' ?
                            <img onClick={() => { toggleImageEditor({ open: true, src: item.url || item.attachmentUrl }) }} alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} />
                            :
                            <div className={classes.videoStyles} >
                                <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} />
                                <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                            </div>
                    }
                    <span id="delete-icon" className={classes.deleteIcon}>
                        <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index)} />
                    </span>
                </div>
            ))}
            {props.fromEdit && details.newAttachments && details.newAttachments.map((item, index) => (
                <div key={index} className={classes.attachment}>
                    {
                        item.mimeType === 'IMAGE' ?
                            <img alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} />
                            :
                            <div className={classes.videoStyles} >
                                <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} />
                                <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                            </div>
                    }
                    <span id="delete-icon" className={classes.deleteIcon}>
                        <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index, true)} />
                    </span>
                </div>
            ))}
            {
                uploading ?
                    <div className={classes.uploadingRoot}>
                        <Backdrop className={classes.backdrop} open={uploading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                    :
                    details.attachments && details.attachments.length > 0 &&
                    <div title="Upload" className={classes.attachmentPickerSection} onClick={() => handleAddPhoto()}>
                        <Add fontSize="large" />
                    </div>
            }
        </>
    )

    const { background = { ...((props.backgroundColorsList && props.backgroundColorsList.length > 0) ? props.backgroundColorsList[0] : defaultBackground) } } = details;
    return (
        <div>
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.avatarRoot}>
                        <Avatar
                            alt={'User'}
                            src={props.user.photo_url || props.user.photoUrl}
                            classes={{ colorDefault: classes.avatarStyles }}
                        >
                            {props.user.name && getAvatarText(props.user.name)}
                        </Avatar>
                        <p className={classes.profileName}>{props.user.name}</p>
                    </div>
                    {props.keycloak.realm_access.roles.includes('manage_featured_posts') &&
                        <div>
                            <Button
                                ref={anchorRef}
                                aria-haspopup="true"
                                onClick={() => handleToggle()}
                                className={classes.featuredButtom}
                                startIcon={featured ? <FeaturedIcon /> : <NonFeaturedIcon />}
                            >
                                {featured ? 'Featured ' : 'Non-featured '}<ExpandMore style={{ marginLeft: 10, position: 'absolute', right: 5 }} />
                            </Button>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                                modifiers={{
                                    preventOverflow: {
                                        enabled: false
                                    }
                                }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper style={{ width: 155 }}>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} className={classes.featuredOptions} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    <MenuItem selected={featured} onClick={(e) => handleMenuItemClick(e, true)}><FeaturedIcon />Featured</MenuItem>
                                                    <MenuItem selected={!featured} onClick={(e) => handleMenuItemClick(e, false)}><NonFeaturedIcon />Non-featured</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    }
                </div>
                <div className={classes.content} style={{ minHeight: props.fromEdit ? 150 : 'auto' }}>
                    {
                        addBackground ?
                            <div className={classes.textareaRoot} style={{ background: `${background.bg_color.type}-gradient(${background.bg_color.angle}deg, ${background.bg_color.start}, ${background.bg_color.end})` }}>
                                <TextareaAutosize
                                    // color="primary"
                                    id="arena-background-post-text-field"
                                    placeholder="Share your thoughts..."
                                    value={details.text}
                                    className={classes.backgroundPostTextareaStyles}
                                    style={{ color: background.text_color }}
                                    onChange={handleChangeInput}
                                    rowsMin={3}
                                    rowsMax={8}
                                    maxLength={maxBgText}
                                />
                                <div className={classes.backgroundColorsList}>
                                    {
                                        props.backgroundColorsList &&
                                        props.backgroundColorsList.map(({ bg_color, text_color }) => (
                                            <div onClick={() => handleBackgroundChange({ bg_color, text_color })} style={{ background: `${bg_color.type}-gradient(${bg_color.angle}deg, ${bg_color.start}, ${bg_color.end})` }} />
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <>
                                <div className={classes.textareaRoot}>
                                    <TextareaAutosize
                                        color="primary"
                                        id="arena-text-field"
                                        placeholder="Share your thoughts..."
                                        value={details.text}
                                        className={classes.textareaStyles}
                                        onChange={handleChangeInput}
                                    />
                                </div>
                                <div className={classes.attachmentsList}>
                                    {getAttachmentsList}
                                </div>
                                <div>
                                    <h1>Filerobot Image Editor</h1>

                                    {/* <img src={src} onClick={() => { toggle(true) }} alt="example image" /> */}

                                    {/* <FilerobotImageEditor
                                        show={imageEditor.open}
                                        src={imageEditor.src}
                                        onClose={() => toggleImageEditor(false)}
                                        onComplete={(a, b, c) => console.log('onComplete ', a, b, c) }
                                        config={{
                                            reduceBeforeEdit: {
                                                mode: 'manual',
                                                widthLimit: 200,
                                                heightLimit: 200
                                            },
                                            tools: ['image', 'text'], // ['adjust', 'effects', 'filters', 'rotate', 'crop', 'resize', 'watermark', 'shapes', 'image', 'text']
                                            colorScheme: 'light'
                                        }}
                                    /> */}
                                </div>
                            </>
                    }
                </div>
                <>
                    <input type="file"
                        multiple
                        id={`hiddenFileInputForArenaAttachments${props.fromEdit}`}
                        onChange={(e) => handleFileChange(e)}
                        style={{ display: 'none' }}
                        accept="image/*,video/*"
                    />
                    <ChaseOnsAutocomplete startLabelText="with" openChaseons={openChaseons} selectedChaseOns={selectedChaseOns} handleChangeAutocomplete={handleChangeAutocomplete} />
                    <div className={classes.actionRoot}>
                        <Button variant="text" disabled={addBackground} className={classes.actionButton} startIcon={<AddPhotoIcon />} onClick={() => handleAddPhoto()} ><span className={classes.buttonText}>Add Photo/Video</span></Button>
                        <Button variant="text" disabled={!addBackground && details.text || (props.fromEdit && details.background === null)} className={classes.actionButton} startIcon={<AddBackgroundIcon />} onClick={() => handleAddBackground()} ><span className={classes.buttonText}>Add a Background</span></Button>
                        <Button variant="text" disabled={addBackground} className={classes.actionButton} startIcon={<TagChaseonsIcon className={classes.tagChaseonsIcon} color="primary" />} onClick={() => handleTagChaseons()} ><span className={classes.chaseonsText}>Tag Chaseons</span></Button>
                    </div>
                </>
            </div>
            <div className={classes.buttonRoot} style={{ display: (!details.text && !details.attachments.length && !(details.newAttachments && details.newAttachments.length)) ? 'none' : 'block', textAlign: props.fromEdit ? 'center' : 'right', marginBottom: 10 }}>
                <Button disabled={!details.text && !details.attachments.length && !(details.newAttachments && details.newAttachments.length)} variant="text" size="medium" className={classes.button} onClick={() => handleCancelPost()}>Cancel</Button>
                {
                    props.fromEdit ?
                        <Button disabled={!details.text && !details.attachments.length && !(details.newAttachments && details.newAttachments.length)} variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleUpdatePost()}>Update</Button>
                        : <Button disabled={!details.text && !details.attachments.length} variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSubmitPost()}>Post</Button>
                }
            </div>
            <ConfirmationDialog open={openConfirmDialog} handleConfirmationDialog={handleConfirmationDialog} />
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    keycloak: state.keycloak.decoded_token,
    backgroundColorsList: state.arena.backgroundColors
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
        postArena: (options) => dispatch(postArena(options)),
        getBackgroundColors: (options) => dispatch(getBackgroundColors(options)),
        updateArena: (options) => dispatch(updateArena(options)),
        tagChaseOns: (options) => dispatch(tagChaseOns(options)),
        openEditArenaDialog: (options) => dispatch(openEditArenaDialog(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateArenaPost);