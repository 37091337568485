import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getLiveMatches, getRoundsList, getMatchesList } from "../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { setLoading } from "../../ducks/loading";
import { CustomCard } from './CustomCard';
import { decodeEnum, formatDate } from "../../helpers";
import { RoundsListGroup } from '../LiveScores/RoundsListView';
import LiveMatches from './LiveMatches';
import MatchDetails from '../LiveScores/MatchDetails';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
    },
}));

const AllMatches = ({ filtersData, rounds, setFiltersData, userInfo }) => {
    const classes = useStyles();

    // console.log('filtersData ', filtersData);
    return (
        <div className={classes.root}>
            {filtersData?.round?._id &&
                <>
                    <div >
                        <RoundsListGroup rounds={rounds} selectedRound={filtersData.round} onRoundClick={(round) => setFiltersData(prevState => ({ ...prevState, round }))} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                    </div>
                    <div style={{ width: 'calc(100% - 100px)', padding: '0 50px', display: 'flex' }}>
                        <div style={{ minWidth: 250, maxWidth: 300, marginRight: 25 }}>
                            <LiveMatches isAllMatches={true} filtersData={filtersData} setFiltersData={setFiltersData} userInfo={userInfo} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <MatchDetails filtersData={filtersData} setFiltersData={setFiltersData} match={{ params: { matchId: filtersData.match?._id } }} />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllMatches));
