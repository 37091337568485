import { all, call, takeEvery, put } from "redux-saga/effects";
import Axios from "axios";
import { GET_LEAGUES_LIST_API, GET_MATCHES_LIST_API, GET_SEASONS_LIST_API, GET_ROUNDS_LIST_API, TEAM_MATCH_STATISTICS_API, OVERALL_TEAM_CORE_KPIS_STATS_API, OVERALL_TEAM_BONUS_KPIS_STATS_API, TEAM_CORE_KPIS_STATS_API, TEAM_BONUS_KPIS_STATS_API, GET_PLAYERS_LIST_API, GET_ROLES_LIST_API, GET_TEAMS_LIST_API, PLAYER_BONUS_KPIS_STATS_API, PLAYER_CORE_KPIS_STATS_API, GET_SEASON_KPIS_API, GET_TEAM_KPIS_TRENDS_API, GET_PLAYER_KPIS_TRENDS_API, GET_DIVISIONS_LIST_API, GET_TEAMS_POINTS_API, GET_PLAYERS_POINTS_API, GET_TEAM_BOUNS_LEADERBOARD_API, GET_TEAM_MATCH_LEADERBOARD_API, LIST_OF_ORG_REGIONS, GET_PLAYER_LEADERBOARD_API, GET_KPIWISE_PLAYER_LEADERBOARD_API, TEAM_RACE_LIVE_SCORE_API, PLAYER_RACE_LIVE_SCORE_API, GET_ROUND_TEAM_STATISTICS_API, GET_ROUND_PLAYER_STATISTICS_API, TEAM_PLAYER_STATISTICS_API, GET_MY_LIVE_PERFORMANCE_API, GET_MY_TEAM_LIVE_PERFORMANCE_API, GET_STAKE_HOLDERS_LIST_API, GET_MATCH_DETAILS_API, GET_LOGGED_IN_USER_ROLE_API, LIVE_MATCHES_API, OVERALL_TRENDS, GET_TOP_TEAM_AP, GET_TOP_PLAYER_API, GET_TOP_TEAM_API } from '../../config';
import { GET_LEAGUES_LIST, GET_MATCHES_LIST, GET_ROUNDS_LIST, GET_SEASONS_LIST, GET_OVERALL_TEAM_CORE_KPIS_STATS, GET_TEAM_MATCH_STATISTICS, GET_OVERALL_TEAM_BONUS_KPIS_STATS, GET_TEAM_CORE_KPIS_STATS, GET_TEAM_BONUS_KPIS_STATS, GET_PLAYER_BONUS_KPIS_STATS, GET_PLAYER_CORE_KPIS_STATS, GET_SEASON_PLAYERS, GET_SEASON_PLAYERS_ROLES, GET_SEASON_TEAMS, GET_SEASON_KPIS_LIST, GET_TEAM_KPIS_TRENDS, GET_PLAYER_KPIS_TRENDS, GET_DIVISIONS_LIST, GET_TEAMS_POINTS, GET_PLAYERS_POINTS, GET_TEAM_MATCH_LEADERBOARD, GET_TEAM_BONUS_LEADERBOARD, GET_LIST_OF_REGIONS, GET_PLAYER_LEADERBOARD, GET_KPIWISE_PLAYER_LEADERBOARD, GET_TEAM_RACE_LIVE_SCORE, GET_PLAYER_RACE_LIVE_SCORE, GET_ROUND_TEAM_STATISTICS, GET_PLAYER_MATCH_STATISTICS, GET_ROUND_PLAYER_STATISTICS, GET_MY_TEAM_LIVE_PERFORMANCE, GET_MY_LIVE_PERFORMANCE, GET_STAKE_HOLDERS_LIST, GET_MATCH_DETAILS, GET_LOGGEDIN_USER_ROLE, GET_LIVE_MATCHES_DATA, GET_OVERALL_TRENDS, GET_TOP_PLAYER, GET_TOP_TEAM } from '../../ducks/game';
import { orgRealm } from "../..";

const leaguesList = (params) => {
    return Axios.get(GET_LEAGUES_LIST_API, { params });
};

const seasonsList = (leagueId, params) => {
    return Axios.get(GET_SEASONS_LIST_API(leagueId), { params });
};

const fetchRoundsList = (seasonId, params) => {
    return Axios.get(GET_ROUNDS_LIST_API(seasonId), { params });
};

const matchesList = (seasonId, params) => {
    return Axios.get(GET_MATCHES_LIST_API(seasonId), { params });
}
const fetchSeasonPlayers = (seasonId, params) => {
    return Axios.get(GET_PLAYERS_LIST_API(seasonId), { params });
}
const fetchSeasonPlayersRoles = (seasonId, params) => {
    return Axios.get(GET_ROLES_LIST_API(seasonId), { params });
}
const fetchSeasonTeams = (seasonId, params) => {
    return Axios.get(GET_TEAMS_LIST_API(seasonId), { params });
}
const fetchTeamMatchStatistics = (matchId, params) => {
    return Axios.get(TEAM_MATCH_STATISTICS_API(matchId), { params });
};
const fetchPlayerMatchStatistics = (matchId, params) => {
    return Axios.get(TEAM_PLAYER_STATISTICS_API(matchId), { params });
};
const fetchOverallTeamCoreKpisStats = (seasonId, params) => {
    return Axios.get(OVERALL_TEAM_CORE_KPIS_STATS_API(seasonId), { params });
};
const fetchOverallTeamBonusKpisStats = (seasonId, params) => {
    return Axios.get(OVERALL_TEAM_BONUS_KPIS_STATS_API(seasonId), { params });
};
const fetchTeamCoreKpisStats = (seasonId, params) => {
    return Axios.get(TEAM_CORE_KPIS_STATS_API(seasonId), { params });
};
const fetchTeamBonusKpisStats = (seasonId, params) => {
    return Axios.get(TEAM_BONUS_KPIS_STATS_API(seasonId), { params });
};
const fetchPlayerCoreKpisStats = (seasonId, params) => {
    return Axios.get(PLAYER_CORE_KPIS_STATS_API(seasonId), { params });
};
const fetchPlayerBonusKpisStats = (seasonId, params) => {
    return Axios.get(PLAYER_BONUS_KPIS_STATS_API(seasonId), { params });
};
const fetchSeasonKpis = (seasonId, params) => {
    return Axios.get(GET_SEASON_KPIS_API(seasonId), { params });
};
const fetchTeamKpisTrends = (seasonId, params) => {
    return Axios.get(GET_TEAM_KPIS_TRENDS_API(seasonId), { params });
}

const fetchPlayerKpisTrends = (seasonId, params) => {
    return Axios.get(GET_PLAYER_KPIS_TRENDS_API(seasonId), { params });
}

const fetchDivisionList = (seasonId, params) => {
    return Axios.get(GET_DIVISIONS_LIST_API(seasonId), { params });
}

const fetchTeamPointsList = (params) => {
    return Axios.get(GET_TEAMS_POINTS_API, { params });
}

const fetchPlayersPointsList = (params) => {
    return Axios.get(GET_PLAYERS_POINTS_API, { params });
}

const fetchTeamMatchLeaderBoardList = (params) => {
    return Axios.get(GET_TEAM_MATCH_LEADERBOARD_API, { params });
}

const fetchTeamBonusLeaderBoardList = (params) => {
    return Axios.get(GET_TEAM_BOUNS_LEADERBOARD_API, { params });
}

const fetchListOfRegions = (params) => {
    return Axios.get(LIST_OF_ORG_REGIONS(orgRealm), { params });
}

const fetchPlayerLeaderBoardList = (params) => {
    return Axios.get(GET_PLAYER_LEADERBOARD_API, { params });
}

const fetchKpiWisePlayerLeaderBoardList = (params) => {
    return Axios.get(GET_KPIWISE_PLAYER_LEADERBOARD_API, { params });
}

const fetchTeamRaceLiveScore = (matchId, params) => {
    return Axios.get(TEAM_RACE_LIVE_SCORE_API(matchId), { params });
};

const fetchPlayerRaceLiveScore = (matchId, params) => {
    return Axios.get(PLAYER_RACE_LIVE_SCORE_API(matchId), { params });
};

const fetchRoundTeamStatistics = (roundId, params) => {
    return Axios.get(GET_ROUND_TEAM_STATISTICS_API(roundId), { params });
};

const fetchRoundPlayerStatistics = (roundId, params) => {
    return Axios.get(GET_ROUND_PLAYER_STATISTICS_API(roundId), { params });
};

const fetchMyTeamLivePerformance = (seasonId, params) => {
    return Axios.get(GET_MY_TEAM_LIVE_PERFORMANCE_API(seasonId), { params });
}

const fetchMyLivePerformance = (seasonId, params) => {
    return Axios.get(GET_MY_LIVE_PERFORMANCE_API(seasonId), { params });
}

const fetchStakeHoldersList = (seasonId, params) => {
    return Axios.get(GET_STAKE_HOLDERS_LIST_API(seasonId), { params });
}

const fetchMatchDetails = (matchId, params) => {
    return Axios.get(GET_MATCH_DETAILS_API(matchId), { params });
}


//Dashboard
const fetchLoggedinUserRole = (seasonId) => {
    return Axios.get(GET_LOGGED_IN_USER_ROLE_API(seasonId));
};

const fetchliveMatches = (seasonId, params) => {
    return Axios.get(LIVE_MATCHES_API(seasonId),{params});
};

const fetchOverallTrends = (seasonId) => {
    return Axios.get(OVERALL_TRENDS(seasonId));
};


const fetchTopTeam = (seasonId, params) => {
    return Axios.get(GET_TOP_TEAM_API(seasonId, params));
};

const fetchTopPlayer = (seasonId, params) => {
    return Axios.get(GET_TOP_PLAYER_API(seasonId, params));
};

function* getListOfLeagues() {
    yield takeEvery(GET_LEAGUES_LIST, function* getLeaguesList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(leaguesList, params);
            if (data) {
                console.log("WatchForListOfLeagues Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForListOfLeagues Data  catch err ", e);
        }
    })
};

function* getListOfSeasons() {
    yield takeEvery(GET_SEASONS_LIST, function* getSeasonsList(action) {
        const { leagueId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(seasonsList, leagueId, params);
            if (data) {
                console.log("WatchForListOfSeasons Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForListOfSeasons Data  catch err ", e);
        }
    })
};

function* getListOfMatches() {
    yield takeEvery(GET_MATCHES_LIST, function* getMatchList(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(matchesList, seasonId, params);
            if (data) {
                console.log("WatchForListOfMatches Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForListOfMatches Data  catch err ", e);
        }
    })
};

function* WatchForGetSeasonPlayers() {
    yield takeEvery(GET_SEASON_PLAYERS, function* getSeasonPlayers(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchSeasonPlayers, seasonId, params);
            if (data) {
                console.log("WatchForGetSeasonPlayers Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForGetSeasonPlayers Data  catch err ", e);
        }
    })
};

function* WatchForGetSeasonPlayersRoles() {
    yield takeEvery(GET_SEASON_PLAYERS_ROLES, function* getSeasonPlayersRoles(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchSeasonPlayersRoles, seasonId, params);
            if (data) {
                console.log("WatchForGetSeasonPlayersRoles Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForGetSeasonPlayersRoles Data  catch err ", e);
        }
    })
};

function* WatchForGetSeasonTeams() {
    yield takeEvery(GET_SEASON_TEAMS, function* getSeasonTeams(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchSeasonTeams, seasonId, params);
            if (data) {
                console.log("WatchForGetSeasonTeams Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("WatchForGetSeasonTeams Data  catch err ", e);
        }
    })
};

function* watchForGetRoundsList() {
    yield takeEvery(GET_ROUNDS_LIST, function* getRoundsList(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchRoundsList, seasonId, params);
            if (data) {
                console.log("watchForGetRoundsList Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetRoundsList Data  catch err ", e);
        }
    })
};

function* watchForGetTeamMatchStatistics() {
    yield takeEvery(GET_TEAM_MATCH_STATISTICS, function* getTeamMatchStatistics(action) {
        const { matchId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamMatchStatistics, matchId, params);
            if (data) {
                console.log("watchForGetTeamMatchStatistics Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamMatchStatistics Data  catch err ", e);
        }
    })
};

function* watchForGetPlayerMatchStatistics() {
    yield takeEvery(GET_PLAYER_MATCH_STATISTICS, function* getPlayerMatchStatistics(action) {
        const { matchId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerMatchStatistics, matchId, params);
            if (data) {
                console.log("watchForGetPlayerMatchStatistics Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayerMatchStatistics Data  catch err ", e);
        }
    })
};

function* watchForGetOverallTeamCoreKpisStats() {
    yield takeEvery(GET_OVERALL_TEAM_CORE_KPIS_STATS, function* getOverallTeamCoreKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchOverallTeamCoreKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetOverallTeamCoreKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetOverallTeamCoreKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetOverallTeamBonusKpisStats() {
    yield takeEvery(GET_OVERALL_TEAM_BONUS_KPIS_STATS, function* getOverallTeamBonusKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchOverallTeamBonusKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetOverallTeamBonusKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetOverallTeamBonusKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetTeamCoreKpisStats() {
    yield takeEvery(GET_TEAM_CORE_KPIS_STATS, function* getTeamCoreKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamCoreKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetTeamCoreKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamCoreKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetTeamBonusKpisStats() {
    yield takeEvery(GET_TEAM_BONUS_KPIS_STATS, function* getTeamBonusKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamBonusKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetTeamBonusKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamBonusKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetPlayerCoreKpisStats() {
    yield takeEvery(GET_PLAYER_CORE_KPIS_STATS, function* getPlayerCoreKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerCoreKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetPlayerCoreKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayerCoreKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetPlayerBonusKpisStats() {
    yield takeEvery(GET_PLAYER_BONUS_KPIS_STATS, function* getPlayerBonusKpisStats(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerBonusKpisStats, seasonId, params);
            if (data) {
                console.log("watchForGetPlayerBonusKpisStats Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayerBonusKpisStats Data  catch err ", e);
        }
    })
};

function* watchForGetSeasonKpis() {
    yield takeEvery(GET_SEASON_KPIS_LIST, function* getSeasonKpisList(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchSeasonKpis, seasonId, params);
            if (data) {
                console.log("watchForGetSeasonKpis Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetSeasonKpis Data  catch err ", e);
        }
    })
};

function* watchForGetTeamKpisTrends() {
    yield takeEvery(GET_TEAM_KPIS_TRENDS, function* getTeamKpisTrends(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamKpisTrends, seasonId, params);
            if (data) {
                console.log("watchForGetTeamKpisTrends Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamKpisTrends Data  catch err ", e);
        }
    })
};

function* watchForGetPlayerKpisTrends() {
    yield takeEvery(GET_PLAYER_KPIS_TRENDS, function* getPlayerKpisTrends(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerKpisTrends, seasonId, params);
            if (data) {
                console.log("watchForGetPlayerKpisTrends Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayerKpisTrends Data  catch err ", e);
        }
    })
};

function* watchForGetDivisionsList() {
    yield takeEvery(GET_DIVISIONS_LIST, function* getDivisionList(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchDivisionList, seasonId, params);
            if (data) {
                console.log("watchForGetDivisionsList Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetDivisionsList Data  catch err ", e);
        }
    })
};

function* watchForGetTeamPoints() {
    yield takeEvery(GET_TEAMS_POINTS, function* getTeamPointsList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamPointsList, params);
            if (data) {
                console.log("watchForGetTeamPoints Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamPoints Data  catch err ", e);
        }
    })
};


function* watchForGetPlayersPoints() {
    yield takeEvery(GET_PLAYERS_POINTS, function* getPlayersPointsList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayersPointsList, params);
            if (data) {
                console.log("watchForGetPlayersPoints Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayersPoints Data  catch err ", e);
        }
    })
};

function* watchForGetTeamMatchLeaderBoard() {
    yield takeEvery(GET_TEAM_MATCH_LEADERBOARD, function* getTeamMatchLeaderBoard(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamMatchLeaderBoardList, params);
            if (data) {
                console.log("watchForGetTeamMatchLeaderBoard Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayersPoints Data  catch err ", e);
        }
    })
};

function* watchForGetTeamBonusLeaderBoard() {
    yield takeEvery(GET_TEAM_BONUS_LEADERBOARD, function* getTeamBonusLeaderBoardList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamBonusLeaderBoardList, params);
            if (data) {
                console.log("watchForGetTeamBonusLeaderBoard Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamBonusLeaderBoard Data  catch err ", e);
        }
    })
};

function* watchForGetListOfRegions() {
    yield takeEvery(GET_LIST_OF_REGIONS, function* getListOfOrgRegions(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchListOfRegions, params);
            if (data) {
                console.log("watchForGetListOfRegions Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetListOfRegions Data  catch err ", e);
        }
    })
};

function* watchForPlayerLeaderBoardList() {
    yield takeEvery(GET_PLAYER_LEADERBOARD, function* getListOfPlayerLeaderBoard(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerLeaderBoardList, params);
            if (data) {
                console.log("watchForPlayerLeaderBoardList Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForPlayerLeaderBoardList Data  catch err ", e);
        }
    })
};

function* watchForKpiWisePlayerLeaderBoardList() {
    yield takeEvery(GET_KPIWISE_PLAYER_LEADERBOARD, function* getKpiWisePlayerLeaderBoard(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchKpiWisePlayerLeaderBoardList, params);
            if (data) {
                console.log("watchForKpiWisePlayerLeaderBoardList Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForKpiWisePlayerLeaderBoardList Data  catch err ", e);
        }
    })
};

function* watchForGetTeamRaceLiveScore() {
    yield takeEvery(GET_TEAM_RACE_LIVE_SCORE, function* getTeamRaceLiveScore(action) {
        const { matchId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchTeamRaceLiveScore, matchId, params);
            if (data) {
                console.log("watchForGetTeamRaceLiveScore Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetTeamRaceLiveScore Data  catch err ", e);
        }
    })
};

function* watchForGetPlayerRaceLiveScore() {
    yield takeEvery(GET_PLAYER_RACE_LIVE_SCORE, function* getPlayerRaceLiveScore(action) {
        const { matchId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchPlayerRaceLiveScore, matchId, params);
            if (data) {
                console.log("watchForGetPlayerRaceLiveScore Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetPlayerRaceLiveScore Data  catch err ", e);
        }
    })
};


function* watchForGetRoundTeamStatistics() {
    yield takeEvery(GET_ROUND_TEAM_STATISTICS, function* getRoundTeamStatistics(action) {
        const { roundId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchRoundTeamStatistics, roundId, params);
            if (data) {
                console.log("watchForGetRoundTeamStatistics Data success cb", headers)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetRoundTeamStatistics Data  catch err ", e);
        }
    })
};

function* watchForGetRoundPlayerStatistics() {
    yield takeEvery(GET_ROUND_PLAYER_STATISTICS, function* getRoundPlayerStatistics(action) {
        const { roundId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchRoundPlayerStatistics, roundId, params);
            if (data) {
                console.log("watchForGetRoundPlayerStatistics Data success cb",)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetRoundPlayerStatistics Data  catch err ", e);
        }
    })
};

function* watchForGetMyTeamLivePerformance() {
    yield takeEvery(GET_MY_TEAM_LIVE_PERFORMANCE, function* getMyTeamLivePerformance(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;

        try {
            let { data, headers } = yield call(fetchMyTeamLivePerformance, seasonId, params);
            if (data) {
                console.log("watchForGetMyTeamLivePerformance Data success cb",)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetMyTeamLivePerformance Data  catch err ", e);
        }
    })
};

function* watchForGetMyLivePerformance() {
    yield takeEvery(GET_MY_LIVE_PERFORMANCE, function* getMyLivePerformance(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchMyLivePerformance, seasonId, params);
            if (data) {
                console.log("watchForGetMyLivePerformance Data success cb",)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetMyLivePerformance Data  catch err ", e);
        }
    })
};

function* watchForGetStakeHoldersList() {
    yield takeEvery(GET_STAKE_HOLDERS_LIST, function* getStakeHoldersList(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchStakeHoldersList, seasonId, params);
            if (data) {
                console.log("watchForGetStakeHoldersList Data success cb",)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetStakeHoldersList Data  catch err ", e);
        }
    })
};

function* watchForGetMatchDetails() {
    yield takeEvery(GET_MATCH_DETAILS, function* getMatchDetails(action) {
        const { matchId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchMatchDetails, matchId, params);
            if (data) {
                console.log("watchForGetMatchDetails Data success cb",)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetMatchDetails Data  catch err ", e);
        }
    })



};

//dashboard
function* watchForGetLoggedInUserRole() {
    yield takeEvery(GET_LOGGEDIN_USER_ROLE, function* getLoggedInUserRole(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        console.log('getLoggedInUserRole action', action.payload)
        try {
            let { data, headers } = yield call(fetchLoggedinUserRole, seasonId);
            if (data) {
                console.log("watchForGetLoggedInUserRole Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetLoggedInUserRole Data  catch err ", e);
        }
    })
};

function* watchForGetLiveMatches() {
    yield takeEvery(GET_LIVE_MATCHES_DATA, function* getLiveMatches(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        console.log("watchForGetLiveMatches payload", action.payload)
        try {
            let { data, headers } = yield call(fetchliveMatches, seasonId, params);
            if (data) {
                console.log("watchForGetLiveMatches Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetLoggedInUserRole Data  catch err ", e);
        }
    })
};


function* watchForOverallTrends() {
    yield takeEvery(GET_OVERALL_TRENDS, function* getOverallTrends(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        console.log("watchForGetLiveMatches payload", action.payload)
        try {
            let { data, headers } = yield call(fetchOverallTrends, seasonId, params);
            if (data) {
                console.log("watchForGetLiveMatches Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetLoggedInUserRole Data  catch err ", e);
        }
    })
};


function* watchForGetTopTeam() {
    yield takeEvery(GET_TOP_TEAM, function* getTopTeam(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        console.log("watchForGetLiveMatches payload", action.payload)
        try {
            let { data, headers } = yield call(fetchTopTeam, seasonId, params);
            if (data) {
                console.log("watchForGetLiveMatches Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetLoggedInUserRole Data  catch err ", e);
        }
    })
};

function* watchForGetTopPlayer() {
    yield takeEvery(GET_TOP_TEAM, function* getTopPlayer(action) {
        const { seasonId, params, onSuccessCB, onErrorCB } = action.payload;
        console.log("watchForGetLiveMatches payload", action.payload)
        try {
            let { data, headers } = yield call(fetchTopPlayer, seasonId, params);
            if (data) {
                console.log("watchForGetLiveMatches Data success cb", data)
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetLoggedInUserRole Data  catch err ", e);
        }
    })
};
export default function* () {
    yield all([
        getListOfLeagues(),
        getListOfSeasons(),
        getListOfMatches(),
        WatchForGetSeasonPlayers(),
        WatchForGetSeasonPlayersRoles(),
        WatchForGetSeasonTeams(),
        watchForGetRoundsList(),
        watchForGetTeamMatchStatistics(),
        watchForGetPlayerMatchStatistics(),
        watchForGetOverallTeamCoreKpisStats(),
        watchForGetOverallTeamBonusKpisStats(),
        watchForGetTeamCoreKpisStats(),
        watchForGetTeamBonusKpisStats(),
        watchForGetPlayerCoreKpisStats(),
        watchForGetPlayerBonusKpisStats(),
        watchForGetSeasonKpis(),
        watchForGetTeamKpisTrends(),
        watchForGetPlayerKpisTrends(),
        watchForGetDivisionsList(),
        watchForGetTeamPoints(),
        watchForGetPlayersPoints(),
        watchForGetTeamMatchLeaderBoard(),
        watchForGetTeamBonusLeaderBoard(),
        watchForGetListOfRegions(),
        watchForPlayerLeaderBoardList(),
        watchForKpiWisePlayerLeaderBoardList(),
        watchForGetTeamRaceLiveScore(),
        watchForGetPlayerRaceLiveScore(),
        watchForGetRoundTeamStatistics(),
        watchForGetRoundPlayerStatistics(),
        watchForGetMyTeamLivePerformance(),
        watchForGetMyLivePerformance(),
        watchForGetStakeHoldersList(),
        watchForGetMatchDetails(),
        watchForGetLoggedInUserRole(),
        watchForGetLiveMatches(),
        watchForOverallTrends(),

    ])
};