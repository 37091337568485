import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CardActions,
  Box,
  Button
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Badge from "@material-ui/core/Badge";
import { getPlayersPointsList, getRoundPlayerStatistics, } from "../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import { useSnackbar } from 'notistack';
import { setLoading } from '../../ducks/loading';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../helpers';
import Rectangle_one from '../../assets/images/Rectangle_one.png'
import Rectangle_two from '../../assets/images/Rectangle_two.png'
import Rectangle_three from '../../assets/images/Rectangle_three.png'
import Rectangle_white from '../../assets/images/Rectangle_white.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "300px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 50,
    height: 50,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    //height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
    //display: 'flex',
    //justifyContent: 'space-between'
  },

  badge: {
    top: "10px",
    //left: "0px",
    marginLeft: "60px",
    //right: "auto",
    left: "250px",
    position: 'absolute',
  },
  parentFlexRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    //justify:"flex-start",
  },
  avatarStyles: {
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    height: 24,
    width: 24,
    border: '2px solid #FFF',
    fontWeight: 600
  },
  playerItem: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .points': {
      fontSize: 15,
      fontWeight: 600
    },

  },
  nameRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 600,
    '& .name': {
      fontWeight: 500,
      fontSize: 15,
      color: '#000000'
    },

  },

  teamname: {
    display: 'flex',
    alignItems: 'baseline',
    //textAlign: 'center',
    fontSize: 15,
    fontWeight: 600,
    flexDirection: 'column',
    position: 'relative',
    //overflow: 'hidden',
    '& .points': {
      fontWeight: 500,
      marginTop: 20,
      color: '#666666',
      textAlign: 'right'
    },
    '& .rankNew': {
      color: 'white',
      textAlign: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      fontSize: 10,
      fontWeight: 500,

    },
    '& .rank': {
      float: 'right',
      marginTop: -30,
      marginLeft: 30,
      fontSize: 10,
      fontWeight: 500,
      backgroundColor: 'orange',
      width: 30,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      //borderTop: '5px solid orange',
      borderLeft: '5px solid white',
      //transform: 'skewY(-11deg)',
      position: 'absolute',
      // top: 8,
      // right: 16,
    },

  },
  playername: {
    display: 'flex',
    //alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
    fontSize: 15,
    fontWeight: 600,
    flexDirection: 'column',
    // '& .points': {
    //   fontWeight: 500,
    //   position: 'absoulte',
    //   top: 0,
    //   // right: 0
    //   display: 'flex',
    //   flexflow: 'row- reverse wrap',
    //   float: 'right',
    // },
    '& .name': {
      fontWeight: 500,
      fontSize: 15,
      color: '#000000'
    },

    '& .team': {
      alignSelf: 'baseline',
      fontWeight: 400,
      fontSize: 13,
      color: '#666666',
    }
  },

  rightCorner: {
    // float: 'right',
    top: -15, //-30,
    right: 0,
    // marginLeft: 30,
    //backgroundColor: 'orange',
    width: 40,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    borderEndStartRadius: 25,
    '& .rank1': {
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 400,
      margin: 5,
      marginLeft: 18,
    }
  },


}));


const PlayerStandings = ({ getPlayersPointsList, setLoading, filtersData, userDetails }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [playerStandings, setPlayerStandings] = useState([]);

  useEffect(() => {
    // console.log('filtersData player standings', filtersData)
    // getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5, page: 1 } });
    if (filtersData?.season?._id)
      getPlayersPoints(filtersData?.season?._id);
  }, [filtersData?.season?._id]);

  const getPlayersPoints = (seasonId) => {
    setLoading(true);
    getPlayersPointsList({
      params: { season_id: seasonId, limit: 5, page: 1 },
      onSuccessCB: (result, headers) => {
        setPlayerStandings([...result].map(obj => ({ ...obj, selected: obj.username === userDetails?.username, logo_url: obj.photo_url })));
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }


  const onSuccessCB = (response) => {
    console.log("on onSuccessCB CB player standings", response)
    setPlayerStandings(response);
  }

  const onErrorCB = (error) => {
    console.log("on Error CB Dashboard", error)
  }


  return (
    <>
      <div>
        {playerStandings.length > 0 ? playerStandings.map((player, index) => (
          <>
            {(index >= 1 ? <Divider variant='fullWidth' /> : null)}
            <div className={classes.playerItem}>

              <div className={classes.nameRoot}>
                <AvatarComponent name={player.name} username={'rowData.team._id'} url={'/rowData.team.logo_url/'} onClick={() => { }} style={{ marginRight: 10, width: 50, height: 50, fontSize: 22 }} />
                <div className={classes.playername}>
                  <span className="name">{player.name}</span>
                  <span className='team'>{player.team?.name}</span>
                </div>
              </div>
              <div className={classes.teamname}>

                {player.rank === 1 ? <div className={classes.rightCorner} style={{ backgroundColor: '#D6AF36' }}>
                  <span className="rank1">{player.rank}<sup>st</sup> </span>
                </div> : player.rank === 2 ? <div className={classes.rightCorner} style={{ backgroundColor: '#A7A7AD' }}>
                  <span className="rank1">{player.rank}<sup>nd</sup> </span>
                </div> : player.rank === 3 ? <div className={classes.rightCorner} style={{ backgroundColor: '#A77044' }}>
                  <span className="rank1">{player.rank}<sup>rd</sup> </span>
                </div> : player.rank > 3 ? <div className={classes.rightCorner} style={{ backgroundColor: '#FFF', border: '1px solid #EAEAEA' }}>
                  <span className="rank1">{player.rank}<sup>th</sup> </span>
                </div> : null}

                {/* {player.rank === 1 ? <><img src={Rectangle_one} style={{ width: 44, height: 26 }} />
                  <span className="rankNew">{player.rank}<sup>st</sup> </span></> : player.rank === 2 ? <><img src={Rectangle_two} style={{ width: 44, height: 26 }} />
                    <span className="rankNew">{player.rank}<sup>nd</sup> </span></> : player.rank === 3 ? <><img src={Rectangle_three} style={{ width: 44, height: 26 }} />
                      <span className="rankNew">{player.rank}<sup>rd</sup> </span></> : player.rank > 3 ? <><img src={Rectangle_white} style={{ width: 44, height: 26 }} />
                        <span className="rankNew">{player.rank}<sup>st</sup> </span></> : null} */}

                <span className="points">{`${getNumberFormat(userDetails?.countryCode, player.total_points)}`} {'pts'}</span>

              </div>

            </div>

          </>
        )) : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>}
      </div>

    </>
  )
}
const mapStateToProps = (state) => ({
  userDetails: state.user
});



const mapDispatchToProps = dispatch => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
    // getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerStandings));

