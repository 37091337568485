import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import { decodeEnum, getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import { getRoundTeamStatistics, getTeamRaceLiveScore } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { ChevronLeft } from "@material-ui/icons";
import { useRouteMatch, withRouter } from "react-router-dom";
import { GetDates } from ".";
import './TablesLayout.css';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import TeamSummaryDivisionWise from "./TeamSummaryDivisionWise";
import { IconButton } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";



const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '15px 0 0px',
        marginBottom: 20,
        minHeight: 285
        // '& .material-table td div': {
        //     border: 'none !important'
        // }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600
    },
    title: {
        fontSize: 18,
        // margin: '25px 0 0',
        margin: 0,
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    },
    viewAllButton: {
        margin: '0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    radioGroup: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .viewByText': {
            fontWeight: 600,
            marginRight: 10,
            fontSize: 12
        },
        '& label': {
            height: 30,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    radioLabel: {
        '& span': {
            fontSize: 12
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    }
}));

const RoundTeamSummaryViewAll = ({ roundId, keycloak, filtersData, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog, setLoading, matchId = '', userDetails, loading, seasonFormat, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { path } = useRouteMatch();

    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    useEffect(() => {
        if (roundId && !filtersData.season?.divisions_enabled) {
            setLoading(true);
            getRoundTeamStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } })
            // setTeamDetails(result[0]);
        }
    }, [roundId]);

    useEffect(() => {
        if (matchId && (filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.divisions_enabled)) {
            setLoading(true);
            getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } })
        }
    }, [matchId]);

    // useEffect(() => {
    //     if (resData.response?.length > 0) {
    //         onSuccessCB(resData.response, resData.headers, true);
    //     }
    // }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        // if (!fromDecimalView) {
        //     setResData({ response, headers: resheaders });
        // }
        if (response.length) {
            let headers = [];
            // let teamData = myTeamDetails.team_id ? [{ ...myTeamDetails, selected: true, position: myTeamDetails.my_position }, ...response] : response;
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score, selected: item._id === resheaders?.my_team_id, hilighted: index === 0, kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis.forEach((el, i) => {
                    // obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    // obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    // obj['totalPoints'] = totalPoints += el.score;
                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        {
                            title: `${el.kpi_code || el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            cellStyle: { maxWidth: 150, minWidth: 100 },
                            templateRender: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`,
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id } }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }



    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            // title: viewBy === 'CAPTAIN' ? 'Captain' : viewBy === 'MENTOR' ? 'Mentor' : "Team",
            title: "Team",
            field: "team",
            cellStyle: { maxWidth: 200, width: 200 },
            emptyValue: '--',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
            // render: (rowData) => (rowData.team && viewBy === 'TEAM') ? (
            //     <div className={classes.nameRoot}>
            //         {rowData.selected && <p style={{ margin: '0 0 5px', fontSize: 9, textAlign: 'left' }}>My team</p>}
            //         <AvatarComponent name={rowData.team.name} username={rowData.team._id} url={rowData.team.logo_url} onClick={() => { }} style={{ margin: 'auto', border: '1px solid #dfe1e4' }} />
            //         <span >{rowData.team && rowData.team.name}</span>
            //     </div>
            // ) : (viewBy === 'CAPTAIN') ? <span style={{ fontSize: 12 }}>{rowData.captain}</span> : (viewBy === 'MENTOR') ? <span style={{ fontSize: 12 }}>{rowData.mentor}</span> : '--'
        },
        {
            title: "Score",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { maxWidth: 100, width: 100 },
            templateRender: (rowData) => (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                `${getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? '' : 's'}` : getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView),
            render: (rowData) => <span style={{ fontWeight: 600 }}>{(filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                `${getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? '' : 's'}` : getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
            // (rowData) => (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
            //     <div style={{ position: 'relative', margin: '8px 0' }}><div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div><div style={{ fontSize: 9, position: 'absolute', bottom: -8, left: 0, width: '100%' }}>{decodeEnum(filtersData.season?.scoring_unit)}{(rowData.totalPoints === 1 ? '' : 's')}</div></div>
            //     : <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div>,
        }
    ];

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                pageSize: 25,
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const handleGoBack = () => {
        history.push(`/leagues/live-scores/${history.location?.search}`);
    };

    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                {/* {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>KPI : {kpi?.name.toUpperCase()}</p>} */}
            </div>
            {
                (data.length === 0 && !filtersData.season?.divisions_enabled) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    [
                        (filtersData.season?.divisions_enabled) ?
                            <>
                                {
                                    filtersData.season?.divisions.map((ele, index) => (
                                        <TeamSummaryDivisionWise key={ele._id} index={index} divisionObj={ele} limit={5000} columns={columns} filtersData={filtersData} type="VIEWALL" decimalView={decimalView} setDecimalView={setDecimalView} />
                                    ))
                                }
                            </>
                            :
                            <div className={classes.root}>
                                <p className="table-title decimal-switch-root">
                                    <p>
                                        <span>{(!roundId && filtersData.match?.type === 'PLAYER_SPRINT') ? 'Player' : 'Team'} performance : <span style={{ color: '#000', fontWeight: 500 }}>{roundId ? filtersData.round?.name : filtersData.match?.title}</span></span>
                                        <GetDates filtersData={filtersData} />
                                    </p>
                                    <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                </p>
                                <SummaryTable data={data} />
                            </div>
                    ]
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundTeamStatistics: options => dispatch(getRoundTeamStatistics(options)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoundTeamSummaryViewAll));