import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { Send, InsertEmoticon, Add, Close, PlayCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import { sendMessage } from '../../ducks/messages';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// import { SvgIcon } from '@material-ui/core';
import { ClickAwayListener, ListItem, Backdrop, CircularProgress, TextareaAutosize, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import imageCompression from 'browser-image-compression';
import { getUser } from '../../selectors';
import { saveMediaDetails, uploadFilesToS3 } from '../../ducks/common';
import DocFormat from '../../assets/images/doc-format.png';
import PdfFormat from '../../assets/images/pdf-format.png';
import ExcelFormat from '../../assets/images/excel.svg';
import LinkIcon from '../../assets/images/link.png';
import { requestPushNotification } from '../../ducks/notification';
import { convertUnicode, replaceSpecialChars, generateMsg } from '../../helpers';
import { SendToChatTemplate } from '../Arena/ShareToChatDialog';

// const NS_SNIPPETS = "urn:xmpp:snippets:0";

const useStyles = makeStyles((theme) => ({
    componentRoot: {
        position: 'relative'
    },
    messageInput: {
        display: 'flex',
        alignItems: 'center'
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 8,
        boxShadow: 'none',
        height: 50
        // borderBottom: '2px solid',
        // borderBottomColor: theme.palette.primary.main,
    },
    input: {
        marginLeft: theme.spacing(1),
        fontSize: 14,
        flex: 1,
        color: 'inherit',
        background: 'inherit',
        border: 'none',
        fontFamily: 'inherit',
        resize: 'none',
        '&:focus': {
            outline: 'none'
        }
    },
    iconButton: {
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            padding: 5
        },
        // color: theme.palette.primary.main,
    },
    sendIcon: {
        // marginRight: '10px',
        // marginLeft: '10px',
        // color: '#C3C3BA',
        // '&:hover': {
        //     cursor: 'pointer'
        // }
    },
    inputIconsRoot: {
        display: 'flex',
        margin: '0 10px 0 20px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0px 0 15px'
        }
    },
    inputIcons: {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 7
        }
    },
    inputIcontsMiddle: {
        margin: '0 20px',
    },
    addIcon: {
        marginRight: 10,
        // color: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            // marginRight: 5
        }
    },
    emojiPicker: {
        position: "absolute",
        bottom: 60,
        padding: '0 15px',
        zIndex: 1,
        '& .emoji-mart': {
            width: 'auto !important'
        },
        '& .emoji-mart-scroll': {
            // transition: '0.5s ease-in-out',
            height: 170,
            // '&:hover': {
            //     height: 300
            // }
        }
    },
    inputRoot: {
        width: '100%',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '45vw'
        }
    },
    quotedMsgRootAlt: {
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        width: '100%',
        bottom: '100%',
        padding: '0px 4px',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.main,
        borderRadius: '4px 4px 0 0',
        '& #quoteLayout': {
            margin: '15px 25px 15px 15px'
        }
    },
    quoteMsg: {
        margin: '5px 0',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap'
    },
    paperRoot: {
        borderBottom: 'none',
        borderRadius: '0 0 4px 4px'
    },
    quoteMsgOf: {
        fontSize: 10,
        color: '#272523'
    },
    closeQuote: {
        position: "absolute",
        right: 3,
        top: 3
    },
    selectedImage: {
        maxWidth: 150,
        maxHeight: 70
    },
    fileRoot: {
        display: 'flex',
        // justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            marginLeft: 15,
            color: '#00000080'
        }
    },
    videoStyles: {
        width: '100px',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        textAlign: 'center',
        color: '#fff',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff'
    },
    linkItemRoot: {
        padding: 0,
        margin: '10px 0',
        height: 70,
        overflow: 'hidden'
    },
    linkItemContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#FFF',
        alignItems: 'center'
    },
    linkIconRoot: {
        minWidth: 70,
        backgroundColor: '#FFF',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid #F0F0F0'
    },
    primaryText: {
        margin: 0,
        paddingBottom: '7px',
        fontSize: 12,
        color: '#535353'
    },
    attachedFile: {
        position: 'relative',
        width: 'fit-content'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    quoteMsgLayout: {
        margin: '10px 0 16px'
    }
}));

let emojiPicker = null;

const mediaTypes = ['png', 'PNG', 'JPEG', 'JPG', 'SVG', 'jpeg', 'jpg', 'svg', 'gif', 'GIF', 'mp4', 'MP4', 'm4a', 'M4A', 'm4v', 'M4V', 'f4v', 'F4V', 'f4a', 'F4A', 'm4b', 'M4B', 'm4r', 'f4b', 'mov', 'MOV', '3gp', 'ogg', 'OGG', 'wmv', 'WMV', 'webm', 'WEBM', 'flv', 'FLV'];
const fileTypes = ['doc', 'docx', 'xls', 'xlsx', 'pdf'];

export const imageMsgTypes = ['png', 'PNG', 'JPEG', 'JPG', 'SVG', 'jpeg', 'jpg', 'svg', 'gif', 'GIF'];
// const fileMsgTypes = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];
export const fileMsgTypes = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'csv'];
export const videoMsgTypes = ['mp4', 'MP4', 'm4a', 'M4A', 'm4v', 'M4V', 'f4v', 'F4V', 'f4a', 'F4A', 'm4b', 'M4B', 'm4r', 'f4b', 'mov', 'MOV', '3gp', 'ogg', 'OGG', 'wmv', 'WMV', 'webm', 'WEBM', 'flv', 'FLV'];

const MessageInput = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();

    const [selectedMsg, setSelectedMsg] = useState(null);
    const [message, setMessage] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false);
    const [uploadErrorText, setUploadErrorText] = useState(null);
    // const onEmojiClick = (event, emojiObject) => {
    //     setChosenEmoji(emojiObject);
    // };

    useEffect(() => {
        console.log('MessageInput ');
        if (props.selectedMsg && props.selectedMsg.body.startsWith('%%%')) {
            let newMsg = JSON.parse(props.selectedMsg.body.replace(/%%%/gi, ''));
            setSelectedMsg({ ...props.selectedMsg, ...newMsg, body: newMsg.body, msgNotation: props.selectedMsg.body });
        }
        else {
            setSelectedMsg(props.selectedMsg)
        }
        setFile(null);
    }, [props.selectedMsg]);

    const handleShowEmojis = e => {
        setShowEmojis(true);
    };

    // const addEmoji = e => {
    //     let emoji = e.native;
    //     setMessage(prevState => {
    //         return prevState + emoji
    //     })
    // };

    const addEmoji = e => {
        let sym = e.unified.split('-')
        let codesArray = []
        sym.forEach(el => codesArray.push('0x' + el))
        let emoji = String.fromCodePoint(...codesArray)
        // this.setState({
        //     text: this.state.text + emoji
        // })
        setMessage(prevState => {
            return prevState + emoji
        })
    }

    const handleClickAway = e => {
        setShowEmojis(false);
    }

    const getFileCategory = (ext) => {
        if (mediaTypes.includes(ext)) return 'MEDIA';
        else if (fileTypes.includes(ext)) return 'DOCUMENT';
        return;
    }

    const getMsgType = (ext) => {
        if (imageMsgTypes.includes(ext)) return 'image';
        else if (videoMsgTypes.includes(ext)) return 'video';
        else if (fileMsgTypes.includes(ext)) return 'file';
        else return 'link';
    }

    const onUploadSuccessCB = (result) => {
        if (result.url) {
            setFile({ url: result.url });
            setUploadErrorText('');
        }
        else setUploadErrorText('Failed');
        setLoading(false);
    }

    const onUploadErrorCB = (result) => {
        console.log('onUploadErrorCB ', result);
        setUploadErrorText('Failed');
        setLoading(false);
    }

    const handleShareFileUpload = (file) => {
        console.log('handlePhotoChange ', file.content);
        var formData = new FormData();

        formData.append("file", file.content);

        props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
    }

    const handleSubmit = () => {
        let msg = message.trim();
        console.log('handleSubmit ');


        if (file && file.url) {
            let msg = message.trim();
            // console.log('onUploadSuccessCB ', file);
            let extension = file.url && file.url.split('.').pop();
            let fileCategory = getFileCategory(extension);
            let generatedMsg = "";
            if (getMsgType(extension) === 'image') {
                generatedMsg = `%%%{"msgType":"image", "url":"${file.url}", "body":${(msg && msg.length > 0) ? ('"' + replaceSpecialChars(msg) + '"') : '""'}}%%%`;
            }
            else if (getMsgType(extension) === 'file') {
                generatedMsg = `%%%{"msgType":"file", "fileType": "${extension}", "url":"${file.url}", "body":${(msg && msg.length > 0) ? ('"' + replaceSpecialChars(msg) + '"') : '""'}}%%%`;
            }
            else if (getMsgType(extension) === 'video') {
                generatedMsg = `%%%{"msgType":"video", "url":"${file.url}", "body":${(msg && msg.length > 0) ? ('"' + replaceSpecialChars(msg) + '"') : '""'}}%%%`;
            }
            props.saveMediaDetails({
                mimeType: extension,
                category: fileCategory,
                sourceUrl: file.url,
                targetJid: props.contact.split('@')[0]
            });
            sendMessage({
                to: props.contact,
                type: props.chat === 'group' ? 'groupchat' : 'chat',
                body: generatedMsg,
                from: props.client.jid
            });
            // console.log('handleSubmit 1', generatedMsg);
        }
        else if ((msg && msg.length > 0 && selectedMsg) && (selectedMsg.hasOwnProperty('msgType') && selectedMsg.msgType !== 'quote')) {
            let generateQuoteMsg = `%%%{"msgType":"quote","quoteMsg":"${replaceSpecialChars(selectedMsg.body || selectedMsg.text)}", "quoteType": "${selectedMsg.msgType !== 'post' ? getMsgType(selectedMsg.url.split('.').pop()) : 'post'}",  "url": "${selectedMsg.url || selectedMsg.thumbnailUrl}", "time":"${selectedMsg.time}","targetJid":"${selectedMsg.type === 'groupchat' ? selectedMsg.from.split('/')[1] : selectedMsg.from.split('/')[0]}","createdBy":"${selectedMsg.createdBy}","body":"${replaceSpecialChars(msg)}"}%%%`;
            sendMessage({
                to: props.contact,
                type: props.chat === 'group' ? 'groupchat' : 'chat',
                body: generateQuoteMsg,
                from: props.client.jid
            });
        }
        else if (msg && msg.length > 0) {
            console.log('sendMessage ', msg);

            if (selectedMsg) {
                console.log('selectedMsg ', selectedMsg);
                let sampleMsg = {
                    msgType: "quote",
                    // quoteMsg: replaceSpecialChars(selectedMsg.body), // getting /n for next line after stringify 
                    quoteMsg: selectedMsg.body,
                    time: selectedMsg.time,
                    targetJid: selectedMsg.type === 'groupchat' ? selectedMsg.from.split('/')[1] : selectedMsg.from.split('/')[0],
                    body: msg
                }
                // let generateQuoteMsg = `%%%{"msgType":"quote","quoteMsg":"${replaceSpecialChars(selectedMsg.body)}","time":"${selectedMsg.time}","targetJid":"${selectedMsg.type === 'groupchat' ? selectedMsg.from.split('/')[1] : selectedMsg.from.split('/')[0]}","body":"${replaceSpecialChars(msg)}"}%%%`;
                let generateQuoteMsg = generateMsg(sampleMsg);

                sendMessage({
                    to: props.contact,
                    type: props.chat === 'group' ? 'groupchat' : 'chat',
                    body: generateQuoteMsg,
                    from: props.client.jid
                });
            }
            else {
                if (msg.startsWith('http://') || msg.startsWith('https://')) {
                    let generateLinkMsg = `%%%{"msgType":"link","url":"${replaceSpecialChars(msg)}","body":""}%%%`;
                    // console.log('generateLinkMsg ', generateLinkMsg);
                    props.saveMediaDetails({
                        mimeType: 'link',
                        category: 'LINK',
                        sourceUrl: msg,
                        targetJid: props.contact.split('@')[0]
                    });
                    sendMessage({
                        to: props.contact,
                        type: props.chat === 'group' ? 'groupchat' : 'chat',
                        body: generateLinkMsg,
                        from: props.client.jid
                    });
                }
                else {
                    sendMessage({
                        to: props.contact,
                        type: props.chat === 'group' ? 'groupchat' : 'chat',
                        body: msg,
                        from: props.client.jid
                    });
                }
            }
        }

        setMessage('');
        setShowEmojis(false);
        setFile(null);
        props.handleOnClearQuote();
    };

    const onMessageSentSuccess = (msgObj) => {
        const { targetDetails, currentUser } = props;
        props.handleOnMessageSent(msgObj.to);
        // console.log('onMessageSentSuccess ', msgObj, targetDetails, currentUser);
        if (msgObj.type === 'groupchat') {
            props.requestPushNotification({
                event_type: "new_message",
                teams: [
                    msgObj.to.split('@')[0]
                ],
                data: {
                    body: msgObj.body,
                    active: 'true',
                    target_type: '1',
                    targetJid: msgObj.to,
                    team_name: msgObj.name,
                    image_url: msgObj.image_url,
                    sender: currentUser.name
                }
            });
        }
        else {
            props.requestPushNotification({
                event_type: "new_message",
                members: [
                    msgObj.to.split('@')[0]
                ],
                data: {
                    body: msgObj.body,
                    active: 'true',
                    target_type: '0',
                    targetJid: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                    name: currentUser.name,
                    image_url: currentUser.photoUrl,
                    sender: currentUser.name
                }
            });
        }
    }

    const onMessageSentFailed = (data) => {
        console.log('onMessageSentFailed ', data);
        enqueueSnackbar('Failed to send Message.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const sendMessage = obj => {
        // console.log('client sendMessage ', obj, props.targetDetails);
        props.sendMessage({ msg: { ...obj, name: (props.targetDetails.name || ''), image_url: props.targetDetails.image_url || '' }, onMessageSentSuccess, onMessageSentFailed, targetDetails: props.targetDetails });
    }

    const handleKeyDown = e => {
        // Prevent new line in text area when return pressed unless shift is also pressed
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
            }
        }
    }

    const handleKeyPress = e => {
        // console.log(e.key, message);
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                handleSubmit();
                return false;
            }
        }
    }

    const handleOnAddItemClick = () => {
        document.getElementById('hiddenFileInputForUploadingFiles').click();
    }

    const imageCompressionOptions = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }

    const handleFileChange = (event) => {
        let files = event.target.files;
        console.log('handleFileChange ', files);
        if (files && files[0]) {
            let fileSize = files[0].size / 1024 / 1024; // in MB
            if ((files[0].type).startsWith('image/')) {

                // if (fileSize > 5) {
                //     enqueueSnackbar('Image size cannot exceed 5 MB.', {
                //         variant: 'error',
                //         preventDuplicate: true
                //     });
                //     if (event.target) event.target.value = "";
                // }
                // else {
                setFile({ name: files[0].name });
                imageCompression(files[0], imageCompressionOptions)
                    .then((compressedFile) => {
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`, compressedFile); // smaller than maxSizeMB

                        var resultFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type, lastModified: compressedFile.lastModified });
                        // console.log('handleFileChange resultFile ', resultFile);

                        let formData = new FormData();
                        formData.append("file", resultFile);
                        setLoading(true);
                        props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
                        // return uploadToServer(compressedFile); // write your own logic
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
                // if (event.target) event.target.value = "";
                // }
            }
            else if (files[0].type && (files[0].type).startsWith('video/')) {
                if (fileSize > 10) {
                    enqueueSnackbar('Video size cannot exceed 10 MB.', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                    if (event.target) event.target.value = "";
                }
                else {
                    setFile({ name: files[0].name });
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setFile({ base64: e.target.result, name: files[0].name, content: files[0] })
                    }
                    reader.readAsDataURL(files[0]); // convert to base64 string

                    var formData = new FormData();
                    formData.append("file", files[0]);
                    setLoading(true);
                    props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
                    // if (event.target) event.target.value = "";
                }
            }
            else {
                if (fileSize > 2) {
                    enqueueSnackbar('File size cannot exceed 2 MB.', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                    if (event.target) event.target.value = "";
                }
                else {
                    setFile({ name: files[0].name });
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setFile({ base64: e.target.result, name: files[0].name, content: files[0] })
                    }
                    reader.readAsDataURL(files[0]); // convert to base64 string

                    var formData = new FormData();
                    formData.append("file", files[0]);
                    setLoading(true);
                    props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
                    // if (event.target) event.target.value = "";
                }
            }
        }
    }

    const handleOnClearQuote = () => {
        props.handleOnClearQuote()
    }

    const handleOnClearAttachments = () => {
        setFile(null);
    }

    // useEffect(() => {
    //     if (Object.keys(file).length) {
    //         console.log('file state ', file);
    //         // props.xmpp.publish(props.contact, 'ournode', {
    //         //     itemType: NS_SNIPPETS, // Again, `itemType` is required
    //         //     // value: 'Away it goes!'
    //         //     value: file.base64
    //         // })
    //     }
    // }, [file])

    const getFormatIcon = (type) => {
        switch (type) {
            case 'doc':
            case 'docx':
                return DocFormat;
            case 'pdf':
                return PdfFormat;
            case 'xls':
            case 'xlsx':
                return ExcelFormat;
            default:
                break;
        }
    }


    return (
        <div className={classes.componentRoot} onKeyPress={(e) => handleKeyPress(e)}>
            {showEmojis && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <span className={classes.emojiPicker} ref={el => (emojiPicker = el)}>
                        <Picker
                            onSelect={addEmoji}
                            emojiTooltip={true}
                            title=""
                            color={theme.palette.primary.main}
                        />
                    </span>
                </ClickAwayListener>
            )}
            <div className={classes.messageInput}>
                <IconButton disabled={selectedMsg && true} className={classes.addIcon} color="primary" onClick={() => handleOnAddItemClick()} ><Add fontSize="large" /></IconButton>

                <input type="file"
                    id="hiddenFileInputForUploadingFiles"
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: 'none' }}
                    accept=".xlsx,.xls,image/*,.doc,.docx,.pdf,video/*"
                />
                <div className={classes.inputRoot}>
                    {
                        selectedMsg &&
                        <div className={classes.quotedMsgRootAlt} >
                            <IconButton className={classes.closeQuote} size="small" onClick={() => handleOnClearQuote()} ><Close fontSize="small" /></IconButton>
                            <div id="quoteLayout">
                                <small className={classes.quoteMsgOf}>{selectedMsg.type === "groupchat" ? (selectedMsg.from.split('/')[1] === props.username ? 'You' : (props.members[selectedMsg.from.split('/')[1]] || selectedMsg.from.split('/')[1])) : (selectedMsg.from.split('/')[0] === props.userStatus.targetJid ? props.userStatus.name : 'You')}</small>
                                {
                                    (selectedMsg.hasOwnProperty('msgType') && selectedMsg.msgType !== 'quote') ?
                                        <div className={classes.quoteMsgLayout}>
                                            {selectedMsg.msgType === 'image' ?
                                                <img className={classes.selectedImage} src={selectedMsg.url} alt='image' />
                                                :
                                                selectedMsg.msgType === 'file' ?
                                                    <div className={classes.fileRoot}>
                                                        <img width="25" src={getFormatIcon(selectedMsg.fileType)} alt={selectedMsg.fileName} />
                                                        <span>{selectedMsg.url.split('-').pop()}</span>
                                                    </div>
                                                    :
                                                    selectedMsg.msgType === 'video' ?
                                                        <div className={classes.videoStyles} >
                                                            <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                                        </div>
                                                        :
                                                        selectedMsg.msgType === 'post' ?
                                                            <SendToChatTemplate
                                                                photoUrl={selectedMsg.thumbnailUrl}
                                                                text={selectedMsg.text}
                                                                postedBy={selectedMsg.createdBy}
                                                                textLimit={35}
                                                                quotedTemplate
                                                            />
                                                            :
                                                            selectedMsg.msgType === 'link' &&
                                                            <ListItem className={classes.linkItemRoot} button >
                                                                <div className={classes.linkIconRoot}>
                                                                    <img width="25" src={LinkIcon} alt={'Link'} />
                                                                </div>
                                                                <div className={classes.linkItemContent}>
                                                                    <div>
                                                                        <p className={classes.primaryText}>{selectedMsg.url.length > 100 ? selectedMsg.url.substring(0, 100) + '...' : selectedMsg.url}</p>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                            }
                                        </div>
                                        :
                                        <p className={classes.quoteMsg}>{convertUnicode(selectedMsg.body)}</p>
                                }
                            </div>
                        </div>
                    }
                    {
                        (!selectedMsg && file) &&
                        <div className={classes.quotedMsgRootAlt} >
                            <IconButton className={classes.closeQuote} size="small" onClick={() => handleOnClearAttachments()} ><Close fontSize="small" /></IconButton>
                            <div id="quoteLayout">
                                <div className={classes.attachedFile}>
                                    {
                                        file.url ?
                                            [
                                                getMsgType(file.url.split('.').pop()) === 'image' ?
                                                    <img className={classes.selectedImage} src={file.url} alt='image' />
                                                    :
                                                    getMsgType(file.url.split('.').pop()) === 'video' ?
                                                        <div className={classes.videoStyles} >
                                                            <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                                        </div>
                                                        :
                                                        getMsgType(file.url.split('.').pop()) === 'file' ?
                                                            <div className={classes.fileRoot}>
                                                                <img width="25" src={getFormatIcon(file.url.split('.').pop())} alt={file.url.split('-').pop()} />
                                                                <span>{file.url.split('-').pop()}</span>
                                                            </div>
                                                            :
                                                            <span>{file.url.split('-').pop()}</span>
                                            ]
                                            :
                                            <div className={classes.videoStyles} >
                                                <span>{uploadErrorText}</span>
                                            </div>
                                    }
                                    <Backdrop className={classes.backdrop} open={loading}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </div>
                                {/* {file.url && <p className={classes.quoteMsg}>File Attached</p>} */}
                            </div>
                        </div>
                    }
                    <Paper className={classNames(classes.root, (selectedMsg || file) && classes.paperRoot)}>
                        <IconButton disabled={!props.client.connected} color="primary" onClick={() => handleShowEmojis()} className={classes.iconButton} aria-label="menu">
                            <InsertEmoticon />
                        </IconButton>
                        <TextareaAutosize
                            disabled={!props.client.connected}
                            autoFocus
                            aria-label="Message Input textarea"
                            placeholder="Type a Message..."
                            className={classes.input}
                            value={message}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onKeyUp={(e) => handleKeyPress(e)}
                            onChange={(e) => setMessage(e.target.value)}
                            rowsMax={3}
                        />
                        {/* <InputBase
                            className={classes.input}
                            placeholder="Type a Message..."
                            inputProps={{ 'aria-label': 'type a message' }}
                            value={message}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onKeyUp={(e) => handleKeyPress(e)}
                            onChange={(e) => setMessage(e.target.value)}
                            rowsMax={3}
                        /> */}
                        {/* <Send className={classes.sendIcon} onClick={() => handleSubmit()} /> */}
                    </Paper>
                </div>
                <div className={classes.inputIconsRoot}>
                    <IconButton disabled={!props.client.connected} className={classes.inputIcons} onClick={() => handleSubmit()} ><Send className={classes.sendIcon} /></IconButton>
                    {/* <IconButton className={classes.inputIcons}><CameraAlt fontSize="small" /></IconButton>
                <IconButton className={classNames(classes.inputIcons, classes.inputIcontsMiddle)}><PhotoLibrary fontSize="small" /></IconButton>
                <IconButton className={classes.inputIcons}><Mic fontSize="small" /></IconButton> */}
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state, props) => ({
    userStatus: getUser(state.chats, props.contact),
    xmpp: window.client,
    client: state.client,
    messages: state.messages,
    currentUser: state.user
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        sendMessage: (msg) => dispatch(sendMessage(msg)),
        requestPushNotification: (msg) => dispatch(requestPushNotification(msg)),
        saveMediaDetails: (options) => dispatch(saveMediaDetails(options)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);