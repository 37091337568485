import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, Button, Grid } from '@material-ui/core';
import { Close, CodeSharp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { setLoading } from '../../ducks/loading';
import { shareLiveScoreDialog, getStakeHoldersList } from '../../ducks/game'
import { shareLiveScores } from '../../ducks/notification';
import { TextField } from '@material-ui/core';
import { toJpeg } from "html-to-image";
import { uploadFilesToS3 } from '../../ducks/common';
import Axios from 'axios';
import { orgRealm } from '../..';
import { formatDate } from '../../helpers';
import moment from 'moment';
import ChaseOnsAutocomplete from '../Arena/ChaseOnsAutocomplete';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
// import domtoimage from 'dom-to-image';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        // maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 46px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    content: {
        marginTop: 15
    },
    buttonRoot: {
        margin: '50px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    templateRoot: {
        margin: '20px 0 20px'
    },
    inputRoot: {
        borderRadius: 6,
    }
}))
const CancelToken = Axios.CancelToken;

//  Quill editor Modules

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
}
/* 
 * Quill editor formats
 */
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'color', 'background'
];

const ShareLiveScoreDialog = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { dialogDetails: { open, fixture, notify, matchDetails = null, details, filtersData }, shareLiveScoreDialog, playerMatchData, getStakeHoldersList, shareLiveScores, history, uploadFilesToS3 } = props;
    const [stakeHolders, setStakeHolders] = useState([]);
    const [posterUrl, setPosterUrl] = useState(null)
    const [source, setSource] = useState(CancelToken.source());
    const [convertedText, setConvertedText] = useState('');

    useEffect(() => {
        if (details.seasonId || details.matchId) {
            let playersData = [];
            if (details.matchId) {
                Object.keys(playerMatchData).forEach(teamId => {
                    playersData = [...playersData, ...playerMatchData[teamId].data.map(ele => {
                        return {
                            name: ele.player_name,
                            email: ele.email
                        }
                    })]
                })
            }
            toJpeg(document.getElementById(details?.imageId))
            // domtoimage.toJpeg(document.getElementById(details?.imageId), { quality: 0.50 })
                .then((dataUrl) => {
                    setPosterUrl(dataUrl);
                });
            getStakeHoldersList({
                seasonId: details.seasonId, params: { round_id: filtersData.round && filtersData.round?._id, match_id: details?.matchId }, onSuccessCB: (result) => {
                    if (playersData.length > 0) {
                        let holders = result.map(holder => {
                            if (!playersData.some(player => player.email === holder.email)) {
                                return holder
                            }
                        }).filter(holder => { return holder })
                        setStakeHolders([...holders, ...playersData])
                    }
                    else setStakeHolders([...result]);
                }, onErrorCB: (error) => {
                    enqueueSnackbar('Failed to get stake holders.', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            })
        }

    }, [details.seasonId, details.matchId]);



    const handleClose = () => {
        setStakeHolders([]);
        setConvertedText('');
        shareLiveScoreDialog({ open: false, details: {}, matchDetails: null })
    }

    const dataURItoBlob = (dataURI) => {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    };

    const handleChangeAutocomplete = (e, data) => {
        setStakeHolders(data)
    };

    const handleSharePost = () => {
        if (stakeHolders.length > 0) {
            const domainUrl = process.env.REACT_APP_BASE_URL.replace('api', orgRealm);
            const link = fixture === 'MATCH' ? `${domainUrl}${history.location.pathname}${history.location.search}` :
                notify === 'TEAM' ? `${domainUrl}/arena/live-scores/all-team-performances${history.location.search}`
                    : `${domainUrl}/arena/live-scores/all-player-performances${history.location.search}`;

            let formData1 = new FormData();
            formData1.append("file", dataURItoBlob(posterUrl));
            uploadFilesToS3({
                formData: formData1, onUploadSuccessCB: (data) => {
                    let sampleMsg = {
                        event_type: 'engagement',
                        email: stakeHolders.map(ele => { return ele.email }).join(","),
                        data: {
                            link: link,
                            message: convertedText,
                            poster: data.url,
                            button: fixture === 'MATCH' ? 'Click to view match details' : notify === 'TEAM' ? "Click to view all teams" : 'Click to view all players',
                            org_id: orgRealm,
                            subject: fixture === 'ROUND' ? `${filtersData.league?.name} - ${filtersData.season?.name} - ${filtersData.round ? filtersData.round?.name : ''} : as on ${formatDate(moment.now())}` :
                                fixture === 'MATCH' ? `${matchDetails?.teams[0].name} vs ${matchDetails?.teams[1].name} Match scores as on ${formatDate(moment.now())}` : ''
                        }
                    }
                    shareLiveScores({
                        body: sampleMsg, onSuccessCB: (data) => {
                            handleClose();
                            enqueueSnackbar("Shared successfully", {
                                variant: "success",
                                preventDuplicate: true
                            });
                        }, onErrorCB: (error) => {
                            enqueueSnackbar('Failed to share.', {
                                variant: 'error',
                                preventDuplicate: true
                            });
                        }
                    })
                }, onUploadErrorCB: () => {

                }, cancelToken: source.token
            });
        }
        else {
            enqueueSnackbar('Please add atleast one recipient to send.', {
                variant: 'error',
                preventDuplicate: true
            });
        }
    };


    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Email {<span style={{ fontWeight: 200, fontSize: 12 }}>(mail will be auto triggered to captains and mentors of all teams)</span>}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <div style={{ margin: '-20px 0px' }}>
                                    <ChaseOnsAutocomplete
                                        openChaseons={true}
                                        limitTags={4}
                                        inputLabelText="Add Recipients"
                                        selectedChaseOns={stakeHolders}
                                        fromMeeting={true}
                                        handleChangeAutocomplete={(...args) => handleChangeAutocomplete(args[0], args[1])}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <div>
                                    <ReactQuill
                                        theme='snow'
                                        placeholder="Type Email body here"
                                        value={convertedText}
                                        modules={modules}
                                        formats={formats}
                                        onChange={setConvertedText}
                                        style={{ height: '160px' }}
                                    />
                                </div>
                                {/* <TextField
                                    id="outlined"
                                    fullWidth
                                    placeholder="Type email body here"
                                    multiline
                                    variant="outlined"
                                    rows={8}
                                    rowsMax={8}
                                    value={formData.body}
                                    onChange={(e) => onChange(e.target.value, 'body')}
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                /> */}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <img src={posterUrl} style={{ height: 225, width: '100%' }} />
                            </Grid>
                        </Grid>
                        <div className={classes.buttonRoot} >
                            <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSharePost()}>Send</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state) => ({
    currentUser: state.user,
    dialogDetails: state.game.liveScoresShare,
    playerMatchData: state.game?.playerMatchData
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getStakeHoldersList: options => dispatch(getStakeHoldersList(options)),
        shareLiveScores: options => dispatch(shareLiveScores(options)),
        uploadFilesToS3: options => dispatch(uploadFilesToS3(options))
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareLiveScoreDialog);