// import orderBy from "lodash/orderBy";
// import differenceBy from "lodash/differenceBy";

import { makeConstant } from "./_helpers";

const constant = makeConstant("chase/arena");

export const POST_ARENA = constant("POST_ARENA");
export const TAG_CHASEONS = constant("TAG_CHASEONS");
export const GET_ARENA_LIST = constant("GET_ARENA_LIST");
export const SEND_REACTION = constant("SEND_REACTION");
export const DELETE_REACTION = constant("DELETE_REACTION");
export const POST_ARENA_COMMENTS = constant("POST_ARENA_COMMENTS");
export const GET_ARENA_COMMENTS_LIST = constant("GET_ARENA_COMMENTS_LIST");
export const GET_USER_LIST = constant("GET_USER_LIST");
export const DELETE_POST = constant("DELETE_POST")
export const GET_NOTIFICATIONS = constant("GET_NOTIFICATIONS")

export const POST_COMMENT_REPLY = constant("POST_COMMENT_REPLY");
export const GET_COMMENT_REPLIES = constant("GET_COMMENT_REPLIES");
export const OPEN_SHARE_TO_ARENA = constant("OPEN_SHARE_TO_ARENA");
export const OPEN_SHARE_TO_CHAT = constant("OPEN_SHARE_TO_CHAT");
export const SHARE_TO_ARENA = constant("SHARE_TO_ARENA");
export const OPEN_REACTIONS = constant("OPEN_REACTIONS");
export const GET_REACTIONS = constant("GET_REACTIONS");
export const OPEN_SHARES_LIST = constant("OPEN_SHARES_LIST");
export const GET_SHARES_LIST = constant("GET_SHARES_LIST");
export const OPEN_EDIT_ARENA = constant("OPEN_EDIT_ARENA");
export const UPDATE_ARENA = constant("UPDATE_ARENA");
export const READ_NOTIFICATION = constant("READ_NOTIFICATION");
export const GET_POST_BY_POSTID = constant("GET_POST_BY_POSTID");
export const GET_BACKGROUND_COLORS = constant("GET_BACKGROUND_COLORS");
export const SET_BACKGROUND_COLORS = constant("SET_BACKGROUND_COLORS");
export const ON_COMMENT_INPUT = constant("ON_COMMENT_INPUT");
export const OPEN_CREATE_ARENA = constant("OPEN_CREATE_ARENA");
export const OPEN_TAGGED_USERS = constant("OPEN_TAGGED_USERS");
export const GET_TAGGED_USERS_LIST = constant("GET_TAGGED_USERS_LIST");
export const CREATE_POLL = constant("CREATE_POLL");

export const postArena = (payload) => ({
    type: POST_ARENA,
    payload
});

export const updateArena = (payload) => ({
    type: UPDATE_ARENA,
    payload
});


export const tagChaseOns = (payload) => ({
    type: TAG_CHASEONS,
    payload
});

export const getArenaList = (payload) => ({
    type: GET_ARENA_LIST,
    payload
});

export const sendReaction = (payload) => ({
    type: SEND_REACTION,
    payload
});

export const deleteReaction = (payload) => ({
    type: DELETE_REACTION,
    payload
})

export const postArenaComments = (payload) => ({
    type: POST_ARENA_COMMENTS,
    payload
});

export const getArenaCommentsList = (payload) => ({
    type: GET_ARENA_COMMENTS_LIST,
    payload
});

export const postCommentReply = (payload) => ({
    type: POST_COMMENT_REPLY,
    payload
});

export const getCommentReplies = (payload) => ({
    type: GET_COMMENT_REPLIES,
    payload
});

export const openReactionsDialog = (payload) => ({
    type: OPEN_REACTIONS,
    payload
});

export const openShareToArenaDialog = (payload) => ({
    type: OPEN_SHARE_TO_ARENA,
    payload
})

export const openShareToChatDialog = (payload) => ({
    type: OPEN_SHARE_TO_CHAT,
    payload
})

export const sharePostToArena = (payload) => ({
    type: SHARE_TO_ARENA,
    payload
})

export const openSharesListDialog = (data) => ({
    type: OPEN_SHARES_LIST,
    payload: data
})

export const getSharesList = (value) => ({
    type: GET_SHARES_LIST,
    payload: value
})

export const getUserList = (payload) => ({
    type: GET_USER_LIST,
    payload
});

export const getReactionsOfPost = (payload) => ({
    type: GET_REACTIONS,
    payload
});

export const openEditArenaDialog = (payload) => ({
    type: OPEN_EDIT_ARENA,
    payload
});

export const openCreateArenaDialog = (payload) => ({
    type: OPEN_CREATE_ARENA,
    payload
});

export const deletePost = (payload) => ({
    type: DELETE_POST,
    payload
})

export const getBackgroundColors = (payload) => ({
    type: GET_BACKGROUND_COLORS,
    payload
})

export const setBackgroundColors = (payload) => ({
    type: SET_BACKGROUND_COLORS,
    payload
})

export const getPostByPostId = (payload) => ({
    type: GET_POST_BY_POSTID,
    payload
})

export const getNotifications = (payload) => ({
    type: GET_NOTIFICATIONS,
    payload
})

export const readNotification = (payload) => ({
    type: READ_NOTIFICATION,
    payload
})

export const openTaggedUsersList = (payload) => ({
    type: OPEN_TAGGED_USERS,
    payload
})

export const getTaggedUsersList = (payload) => ({
    type: GET_TAGGED_USERS_LIST,
    payload
})

export const onCommentInput = (payload) => ({
    type: ON_COMMENT_INPUT,
    payload
})

export const createPoll = (payload) => ({
    type: CREATE_POLL,
    payload
})

const initialState = {
    reactions: {
        open: false,
        postId: null,
        type: 'ALL',
        entityType: 'POST'
    },
    shareToArena: {
        open: false,
        details: {}
    },
    shareToChat: {
        open: false,
        details: {}
    },
    numberOfShareFaviourite: {
        open: false,
        postId: null,
    },
    editArena: {
        open: false,
        arena: {}
    },
    createArena: {
        open: false,
        // arena: {}
    },
    backgroundColors: [],
    inAppNotificationCount: 0,
    taggedUsers: {
        open: false,
        postId: null
    },
    comment: {
        entityId: null,
        entityType: 'POST',
        commentText: '',
    }
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_REACTIONS:
            console.log('OPEN_REACTIONS ', action);
            return {
                ...state,
                reactions: {
                    ...action.payload
                }
            }
        case OPEN_SHARE_TO_ARENA:
            return {
                ...state,
                shareToArena: {
                    ...action.payload
                }
            }
        case OPEN_SHARE_TO_CHAT:
            return {
                ...state,
                shareToChat: {
                    ...action.payload
                }
            }
        case OPEN_SHARES_LIST:
            return {
                ...state,
                numberOfShareFaviourite: {
                    ...action.payload
                }
            }
        case OPEN_EDIT_ARENA:
            return {
                ...state,
                editArena: {
                    ...action.payload
                }
            }
        case OPEN_CREATE_ARENA:
            return {
                ...state,
                createArena: {
                    ...action.payload
                }
            }
        case SET_BACKGROUND_COLORS:
            return {
                ...state,
                backgroundColors: [
                    ...action.payload
                ]
            }
        case OPEN_TAGGED_USERS:
            return {
                ...state,
                taggedUsers: {
                    ...action.payload
                }
            }
        case ON_COMMENT_INPUT:
            return {
                ...state,
                comment: {
                    ...action.payload
                }
            }
        default:
            return state;
    }
};
