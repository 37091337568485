import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import { getPlayerCoreKpisStats } from "../../../ducks/game";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import IllustrationView from "../../LiveScores/IllustrationView";
import MaterialUITable from "../../GlobalComponents/MaterialUITable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "../../LiveScores/CustomSwitch";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '-10px 0 0px',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const PlayerCoreKpisStats = ({ setLoading, loading, seasonId = '', seasonFormat = '', roundId = '', matchId = '', playerId, teamId, roleId, userDetails, getPlayerCoreKpisStats }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (roundId) {
            setLoading(true);
            getPlayerCoreKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { round_id: roundId === 'ALL' ? '' : roundId, employee_id: playerId, role_id: roleId, team_id: teamId, limit: 5000, page: 1 } });
        }
    }, [roundId, playerId, teamId, roleId]);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            getPlayerCoreKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, role_id: roleId, team_id: teamId, limit: 5000, page: 1 } });
        }
    }, [matchId, playerId, teamId, roleId]);

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, selected: item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points;

                    if (index === 0) {
                        headers.push(el);

                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`,//-target
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                    :
                                    [
                                        // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                        //     `No data available`
                                        //     : 
                                        `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                    ]

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    :
                                    [
                                        // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                        //     (isRuleBasedOnActuals(el) ? `No data available` : '')
                                        //     : 
                                        `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                    ]
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,//-Ach %
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) =>
                                // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' : 
                                getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        }, {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) =>
                                // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' :
                                getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            console.log('finalResult dynamicHeaders ', finalResult);
            if (finalResult.length > 0) {
                setData(finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers])
            }
            else {
                setData([]);
                setDynamicColumns([]);
                setDynamicHeaders([]);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Statistics', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const columns = [
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            editable: "onAdd",
            emptyValue: '--'
        },
        {
            title: "Team",
            field: "team",
            editable: 'never',
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            render: (rowData) => rowData.team ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.team.name} username={rowData._id} url={rowData.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.team && rowData.team.name}
                </div>
            ) : '--'
        },
        {
            title: "Total points earned",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)
        }
    ]

    return (
        <>
            { (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                <div className={classes.root}>
                    <div className="material-table">
                        <p className="table-title decimal-switch-root">
                            <p style={{ fontSize: 11, color: '#58595B', margin: '5px 0 10px' }}>*Note: Points will appear only after match is closed.</p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>

                        <MaterialUITable
                            title="Round Summary"
                            columns={[...columns, ...dynamicColumns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [5, 10, 25],
                                pageSize: 25,
                                paging: data.length > 5 ? true : false,
                                // fixedColumns: {
                                //     left: 4,
                                // }
                            }}
                            components={{
                                Header: props => (
                                    <TableHead className="custom-headers">
                                        <TableRow>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            {
                                                (seasonFormat !== 'PLAYER_RACE') &&
                                                <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                            }
                                            <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>
                                            {/* {console.log('dynamicHeaders ', dynamicHeaders)} */}
                                            {
                                                dynamicHeaders.map((obj, index) => (
                                                    <TableCell key={index} colSpan={isRuleBasedOnActuals(obj) ? 2 : 4} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                        {/* <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip> */}
                                                        {obj.kpi_name}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                dynamicHeaders.map((obj) => (
                                                    <>
                                                        {!isRuleBasedOnActuals(obj) && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                        <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                                        {!isRuleBasedOnActuals(obj) && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                        <TableCell key={3} align="center">Points</TableCell>
                                                    </>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                )
                            }}
                        />
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerCoreKpisStats: options => dispatch(getPlayerCoreKpisStats(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerCoreKpisStats));
