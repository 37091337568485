import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IconButton, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import { setLoading } from "../../ducks/loading";
import { getRoundPlayerStatistics, getPlayerRaceLiveScore } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { GetDates } from ".";
import { ChevronLeft } from "@material-ui/icons";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '10px 0',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    viewAllButton: {
        margin: 0,
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    }
}));

const RoundPlayerSummaryViewAll = ({ setLoading, history, seasonFormat = '', roundId = '', matchId, filtersData, userDetails, getRoundPlayerStatistics, getPlayerRaceLiveScore }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const { path } = useRouteMatch();
    useEffect(() => {
        if (roundId) {
            setLoading(true);
            // if (seasonFormat === 'TEAM_RACE')
            //     getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
            // else
            getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
    }, [roundId]);

    useEffect(() => {
        console.log(matchId)
        if (matchId) {
            getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
    }, [matchId])

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item.employee_id === userDetails?.username, totalPoints: item.total_points, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points;
                    // obj['totalPoints'] = totalPoints += el.points;

                    if (index === 0) {
                        headers.push(el);
                    }
                    if (index === 0) {
                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            cellStyle: { maxWidth: 150, minWidth: 100 },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            // console.log('finalResult dynamicHeaders ', finalResult[0]?.columns);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                // if (filtersData.season?.divisions_enabled)
                //     setDataByDiv(groupBy(finalResult, 'division_name'));
                // else
                setData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                // setDataByDiv(null);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            // setDataByDiv(null);
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200 },
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            // cellStyle: { color: '#333' },
            render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
        }
    ];

    const handleGoBack = () => {
        history.push(`/leagues/live-scores/${history.location?.search}`);
    };

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                search: false,
                actionsColumnIndex: -1,
                showTitle: false,
                addRowPosition: 'first',
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25],
                pageSize: 25,
                paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell >
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                            {/* <TableCell rowSpan={2}>{props.columns[props.columns.length - 1]?.title}</TableCell> */}
                        </TableRow>

                    </TableHead>
                )
            }}
        />
    )

    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                {/* {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>KPI : {kpi?.name.toUpperCase()}</p>} */}
            </div>            {
                (data.length === 0) ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :
                    <div className={classes.root}>
                        <p className="table-title decimal-switch-root">
                            <p>
                                <span>Player performance : <span style={{ color: '#000' }}>{roundId ? filtersData.round?.name : filtersData.match?.title}</span> <GetDates filtersData={filtersData} /></span>
                            </p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                        <SummaryTable data={data} />
                    </div>
            }
        </div>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
        getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoundPlayerSummaryViewAll));