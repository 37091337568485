import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import TeamStandings from "../TeamStandings";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";

const NonPlayerDashboard = ({ setLoading, filtersData, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard }) => {

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <CustomCard cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats}>
                        <OverallOrgStats filtersData={filtersData} />
                    </CustomCard>
                </Grid>

                <Grid item md={8}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={navigateToTeamLeaderboard}>
                                <TeamStandings filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={navigateToPlayerLeaderboard} >
                                <PlayerStandings filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={4} style={{ flexDirection: 'row' }}>
                    <LiveMatches filtersData={filtersData} userInfo={userInfo} />
                </Grid>

            </Grid>

        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NonPlayerDashboard));
