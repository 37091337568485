import React, { useEffect, useRef, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, useMediaQuery, useTheme, Icon, IconButton, Avatar, Tooltip, Button, TextareaAutosize, ClickAwayListener, Divider } from '@material-ui/core';
import { Cancel, PlayCircleOutline, InsertEmoticon, MoreHoriz, DescriptionOutlined, Fullscreen, CheckCircle, FiberManualRecord } from '@material-ui/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Moment from 'react-moment';
import { Picker } from "emoji-mart";
import { Player, ControlBar, Shortcut } from 'video-react';
import classNames from 'classnames';
import 'video-react/dist/video-react.css';
import { convertUnicode, getNumberFormat, getPollDuration } from '../../helpers';
import { ReactComponent as Like } from "../../assets/icons/like-arena.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { ReactComponent as Share } from "../../assets/icons/share-arena.svg";
import { ReactComponent as FavouriteUserIcon } from '../../assets/icons/add-favourite.svg';
import { ReactComponent as LikeIcon } from '../../assets/icons/like.svg';
import ArenaPostHeader from '../ArenaComponents/ArenaPostHeader';
import ArenaActions from '../ArenaComponents/ArenaActions';
import ArenaLikes from '../ArenaComponents/ArenaLikes';
import ArenaText from '../ArenaComponents/ArenaText';
import ArenaCommentInput from '../ArenaComponents/ArenaCommentInput';
import ArenaComments from '../ArenaComponents/ArenaComments';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import PDFViewer from '../PDFViewer/PDFViewer';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import noPreview from '../../assets/images/nopreview.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        maxWidth: 1000,
        width: 'calc(100% - 100px)',
        borderRadius: 15,
        backgroundColor: '#000000cc',
        // padding: '60px 90px',
        // height: '85%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            // padding: '60px 50px 20px'
            maxWidth: 400
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    dialogPaperSmall: {
        maxWidth: 400
    },
    sectionsWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            overflowY: 'auto'
        },
    },
    previewSection: {
        position: 'relative',
        maxWidth: 600,
        width: '70%',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 400,
            maxHeight: 400,
            width: '100%',
            // minHeight: 400,
        },

        '& .carousel-root': {
            minWidth: '100%'
        },
        '& .carousel .control-dots': {
            top: 0,
            bottom: 'auto',
            margin: 0,
            // bottom: 10
        },
        // width: '70%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%'
        // },
        '& .carousel-slider': {
            position: 'inherit'
        },
        // '& .slide': {
        //     background: 'none'
        // },
        // // backgroundColor: '#FFFFFF',
        '& li': {
            display: 'flex',
            justifyContent: 'center',
            '& img': {
                backgroundColor: '#FFF',
                // width: 'auto !important',
                maxWidth: 600,
                // maxHeight: 600,
                maxHeight: 'calc(100vh - 64px)',
                objectFit: 'contain',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: 400,
                    maxHeight: 400,
                },

            },
            '& .video-react': {
                width: '95%',
                maxWidth: 600,
                // maxHeight: 600,
                maxHeight: 'calc(100vh - 64px)',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: 400,
                    maxHeight: 400,
                },
                // margin: '0 auto',
                // height: '80vh',
                // maxWidth: '95%',
                // width: 'auto',
                // maxWidth: 750,
                // maxHeight: '70vh',
                '& video': {
                    outline: 'none',
                    position: 'relative',
                    objectFit: 'inherit'
                },
                '&  .video-react-big-play-button': {
                    top: 'calc(50% - 22px)',
                    left: 'calc(50% - 22px)',
                    width: '1.5em',
                    borderRadius: '0.8em'
                }
            }
        }
    },
    closeIcon: {
        position: 'absolute',
        // right: 10,
        left: 20,
        top: 20,
        color: '#FFF',
        backgroundColor: '#00000080',
        padding: 0
    },
    videoStyles: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        textAlign: 'center',
        color: '#fff',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff'
    },

    postDetails: {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',

        // maxHeight: '87vh',
        maxHeight: 'calc(100vh - 64px)',
        maxWidth: 400,
        // paddingBottom: 20,
        // position: 'relative',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: 'none',
            // display: 'none'
            // position: 'inherit',
            // marginBottom: 44
        },
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        // maxHeight: '85vh'
        borderLeft: '1px solid #eeeeee'
    },

    avatarRoot: {
        display: "flex",
        alignItems: "center",
        padding: "12px 15px"
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    profileName: {
        margin: "2px 10px 0px",
        color: "#58595B",
        fontSize: 16,
        fontWeight: 600
    },
    featuredBadge: {
        backgroundColor: '#585963',
        borderRadius: 5,
        color: '#FFFFFF',
        margin: '4px 10px 0',
        padding: '2px 4px',
        fontSize: 13,
        width: 'fit-content'
    },
    timestamp: {
        margin: "5px 10px 0px",
        fontSize: 14,
        color: "#838383"
    },

    arenaContentRoot: {
        margin: "0 15px"
    },
    arenaText: {
        whiteSpace: "pre-wrap",
        fontSize: 15,
        color: "#535353",
        lineHeight: "20px",
        marginTop: 5,
        wordBreak: "break-word"
    },


    totalComments: {
        display: "flex",
        justifyContent: "space-between",
        padding: '0px 15px 10px'
    },
    commentAlign: {
        alignItems: "end",
        fontSize: 13,
        padding: "5px",
    },
    commentStyle: {
        fontSize: "12px",
        color: "#717171"
    },
    viewComments: {
        fontSize: "14px",
        color: "#535353"
    },
    reactionElementCount: {
        margin: 5,
        textAlign: 'center',
        '& svg': {
            width: 20,
            height: 20
        },
    },
    likepadding: {
        marginLeft: "0.18rem"
    },
    actionsRoot: {
        position: 'absolute',
        bottom: 0,
        background: 'red',
        width: '100%'
    }
}));

const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    padding: 0
};

const seeMoreCharLimit = 125;
let emojiPickerPreview = null;
export const ArenaPreview = (props) => {
    const previewClasses = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { open, classes, arena, handlePreviewClose,
        postIndex, attachmentIndex,
        handleSeeMore, reactionsList,
        handleReactionsView,
        toggleCommentsSection,
        reactions, sendReaction,
        handleKeyPress, handleKeyDown,
        setCommentText, addEmoji, commentsSkeletonLoading,
        viewMoreComments, user, handleReplyToComment,
        setCommentReplyText,
        addReplyEmoji,
        urlify,
        isFromProfile,
        handleShareMenuOpen,
        openSharesListDialog,
        history,
        handleArenaMoreMenuOpen,
        handleCommentLike,
        handlereplyLike,
        handleNewComment,
        setOpenPDF,
        handlePollResponse,
        usersList
    } = props;

    let player = useRef(null);
    // let arena = props.arena;
    let allRefs = [];
    console.log('allRefs outside', allRefs);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showReplyEmojis, setShowReplyEmojis] = useState(false);

    const handleShowEmojis = e => {
        setShowEmojis(true);
    };

    const handleClickAway = e => {
        setShowEmojis(false);
    };

    const handleShowReplyEmojis = () => {
        setShowReplyEmojis(true);
    }

    const handleReplyClickAway = e => {
        setShowReplyEmojis(false);
    };

    // useEffect(() => {
    //     if (!isFromProfile) {
    //         toggleCommentsSection(postIndex);
    //     }
    // }, []);

    const handleChange = (index, e) => {
        // player.current.pause();
        console.log('handleChange ', index, e, player, allRefs);
        allRefs.forEach(element => {
            element.pause();
        });
        // for (const key in allRefs) {
        //     if (allRefs.hasOwnProperty(key)) {
        //         const element = allRefs[key];
        //         element.pause();
        //     }
        // }
    }

    const handleCommentClick = () => {
        let inputEl = document.getElementById('comment-input-box');
        if (inputEl) {
            inputEl.focus();
        }
    }

    const handleFavouriteShare = (postId) => {
        openSharesListDialog({ open: true, postId: postId })
    }
    return (
        <div className={previewClasses.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='lg'
                open={open}
                onClose={handlePreviewClose}
                aria-labelledby="responsive-dialog-title"
                classes={{ paper: classNames(previewClasses.dialogPaper, { [previewClasses.dialogPaperSmall]: arena.attachments.length === 0 }) }}
            >
                <>
                    <div className={previewClasses.sectionsWrapper}>
                        {arena.attachments.length > 0 &&
                            <div id="carousel-root" className={previewClasses.previewSection} style={isFromProfile ? { width: '100%', maxWidth: 'none' } : {}}>
                                {arena.attachments &&
                                    <Carousel
                                        selectedItem={attachmentIndex}
                                        // infiniteLoop={true}
                                        showStatus={false}
                                        showThumbs={false}
                                        useKeyboardArrows={true}
                                        onChange={(e) => handleChange(e)}
                                        renderArrowPrev={(onClickHandler, hasPrev) =>
                                            hasPrev && (
                                                <IconButton disableTouchRipple onClick={onClickHandler} style={{ ...arrowStyles, left: 15 }} >
                                                    <ArrowLeft />
                                                </IconButton>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext) =>
                                            hasNext && (
                                                <IconButton disableTouchRipple onClick={onClickHandler} style={{ ...arrowStyles, right: 15 }} >
                                                    <ArrowRight />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        {arena.attachments.map((item, index) => (
                                            <div key={index}>
                                                {
                                                    item.mimeType === 'IMAGE' ?
                                                        <img alt="" src={item.attachmentUrl || item.url} />
                                                        :
                                                        item.mimeType === 'LINK' ?
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <LinkPreview url={item.attachmentUrl} fallbackImageSrc={noPreview} width='calc(100% - 60px)' borderColor="#eeeeee" />
                                                            </div>
                                                            :
                                                            item.mimeType === 'PDF' ?
                                                                <div>
                                                                    <PDFViewer file={item.attachmentUrl} fromCard={true} />
                                                                    <IconButton title="Full Screen" className={classes.pdfIconStyles} onClick={() => setOpenPDF({ open: true, file: item.attachmentUrl, postedBy: arena.createdBy.name })} >
                                                                        <Fullscreen />
                                                                    </IconButton>
                                                                </div>
                                                                :
                                                                item.mimeType === 'SURVEY' ?
                                                                    <div style={{ padding: 15 }}>
                                                                        <div className={classes.pollCard}>
                                                                            <p>{item.pollDetails?.question?.question_title}</p>
                                                                            {item.pollDetails?.question?.options.map(option => (
                                                                                <>
                                                                                    {
                                                                                        (item.pollDetails?.responded || getPollDuration(item.pollDetails?.expire_date) === 'Poll closed') ?
                                                                                            <p className="responseView" style={{ background: `linear-gradient(to right, #f0f2f5 ${option.percentage}%, white 0%)` }}>{option.option} <span style={{ display: 'flex' }}>{(item.pollDetails?.question?.my_option === option.option) && <CheckCircle style={{ marginRight: 10, color: '#262626a1' }} />}{getNumberFormat(user?.countryCode, option.percentage)}%</span></p>
                                                                                            : <Button disabled={getPollDuration(item.pollDetails?.expire_date) === 'Poll closed'} variant="outlined" color="primary" onClick={() => handlePollResponse(option.option, item.pollDetails)}>
                                                                                                {option.option}
                                                                                            </Button>
                                                                                    }
                                                                                </>
                                                                            ))}
                                                                            <span className="votesRoot"><span>{item.pollDetails?.question?.total_responded_count} votes</span><FiberManualRecord /><span>{getPollDuration(item.pollDetails?.expire_date)}</span></span>
                                                                        </div>
                                                                    </div>

                                                                    :
                                                                    <Player
                                                                        poster={item.thumbnailUrl ? item.thumbnailUrl : false}
                                                                        ref={ref => allRefs = [...allRefs, ref]}
                                                                        fluid={false}
                                                                        width={'auto'}
                                                                        autoPlay={false}
                                                                    >
                                                                        <source src={item.attachmentUrl || item.url} />
                                                                        {/* <ControlBar autoHide /> */}
                                                                        <Shortcut shortcuts={[
                                                                            {
                                                                                keyCode: 32, // spacebar
                                                                                handle: function () { } // a function that does nothing
                                                                            },
                                                                            {
                                                                                keyCode: 37, // left arrow
                                                                                handle: function () { } // a function that does nothing
                                                                            },
                                                                            {
                                                                                keyCode: 39, // right arrow
                                                                                handle: function () { } // a function that does nothing
                                                                            },
                                                                        ]} />
                                                                    </Player>
                                                    // <video autoPlay={arena.attachments.length === 1} width="auto" height="500" controls >
                                                    //     <source src={item.attachmentUrl} type="video/mp4" />
                                                    // </video>

                                                }
                                            </div>
                                        ))}
                                    </Carousel>
                                }
                            </div>}
                        {!isFromProfile &&
                            <React.Fragment>
                                <div className={previewClasses.postDetails}>
                                    <ArenaPostHeader
                                        name={arena.createdBy.name}
                                        username={arena.createdBy.userName}
                                        avatarUrl={arena.createdBy.photoUrl}
                                        onProfileClick={() => history.push(`/wall/profile/${arena.createdBy.userName}`)}
                                        follower={arena.follower}
                                        featured={arena.category === "FEATURED"}
                                        timestamp={arena.createdAt}
                                        showMoreButton={true}
                                        handleMoreClick={(e) => handleArenaMoreMenuOpen(e, arena)}
                                    />
                                    <ArenaComments newComment={() => handleNewComment(arena.postId, postIndex)} style={{ overflowY: 'auto' }} postId={arena.parentPostId ? arena.parentPostId : arena.postId} />
                                    <div style={{ paddingBottom: 20 }}>
                                        <ArenaActions
                                            style={{ padding: 16 }}
                                            isLiked={arena.liked}
                                            onLikeClick={() => sendReaction(arena.parentPostId ? arena.parentPostId : arena.postId, postIndex, 'like')}
                                            onCommentClick={() => handleCommentClick()}
                                            onShareClick={(e) => handleShareMenuOpen(e, arena)}
                                        />
                                        {arena.likes > 0 &&
                                            <ArenaLikes
                                                firstLike={arena.firstLike ? { ...arena.firstLike, username: arena.firstLike && arena.firstLike.userName } : { name: user.name, photoUrl: user.photoUrl, username: user.username }}
                                                likesCount={arena.likes}
                                            />
                                        }
                                        {
                                            arena.text &&
                                            <ArenaText text={arena.text} username={arena.createdBy.userName} name={arena.createdBy.name} index={'preview'} seeMoreCharLimit={50} />
                                        }
                                        <ArenaCommentInput usersList={usersList} postId={arena.parentPostId ? arena.parentPostId : arena.postId} />
                                    </div>
                                </div>
                            </React.Fragment >
                        }
                    </div >
                    <IconButton className={previewClasses.closeIcon} onClick={handlePreviewClose}><Cancel fontSize="large" /></IconButton>
                </>
            </Dialog >
        </div >
    )
}