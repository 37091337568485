import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { getAvatarText } from '../../helpers';
import { withRouter } from 'react-router-dom';
import { getProfileUrl } from '../Arena';


const useStyles = makeStyles(theme => ({
  colorDefault: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
}));

const AvatarComponent = ({ name = '', username = '', url = '/', onClick = () => history.push(getProfileUrl(username)), history, staticContext, ...others }) => {
  const classes = useStyles();
  return (
    <Avatar
      alt={name}
      src={url}
      classes={{ colorDefault: classes.colorDefault }}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      {...others}
    >
      {getAvatarText(name)}
    </Avatar>
  )
}

export default withRouter(AvatarComponent);