import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getPlayersPointsList, getSeasonKpisList, getSeasonTeams, getSeasonPlayersRoles, getRegionsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import { TabBar } from '../TabBar';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import LeaderboardTable from './LeaderboardTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { commonColumnOptions } from '../../PointsTables/TeamPointsTable';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../../helpers';
import { Grid } from '@material-ui/core';
import IllustrationView from '../../LiveScores/IllustrationView';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import { CustomListGroup } from '../../LiveScores/RoundsListView';

const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    division: null
};

const subTabs = [{ label: 'Core KPI', value: 0, icon: '', path: 'core' }, { label: 'Bonus KPI', value: 1, icon: '', path: 'bonus' }, { label: 'Team', value: 2, icon: '', path: 'team' }, { label: 'Role', value: 3, icon: '', path: 'role' }, { label: 'Region', value: 4, icon: '', path: 'region' }]

const PlayerLeaderboard = ({ history, filtersData, location, setLoading, loading, getSeasonKpisList, getSeasonTeams, getSeasonPlayersRoles, getRegionsList, userDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [subTabsData, setSubTabsData] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('CARD');
    const [selectedTab, setSelectedTab] = useState(null);
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (filtersData.season) {
            if (filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints) {
                setSubTabsData([]);
                setCurrentTab(0);
            }
            else {
                if (currentTab <= 1) {
                    getSeasonKpis(filtersData.season._id, currentTab);
                }
                else if (currentTab === 2) {
                    getSeasonTeamsList(filtersData.season._id);
                }
                else if (currentTab === 3) {
                    getRolesList(filtersData.season._id);
                }
                else {
                    getListOfRegions()
                }
                setSearchQuery(history, { viewBy: subTabs[currentTab]?.path });
            }
        }
    }, [filtersData.season, currentTab]);

    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(subTabs.findIndex(obj => obj.path === groupByValue));
        }
    }, [groupByValue]);

    const handleSubTabChange = (newValue) => {
        setSearchQuery(history, { viewBy: subTabs[newValue?.value]?.path });
        // setViewType('CARD');
        setCurrentTab(newValue?.value);
    }

    const handleViewChange = (type, tab) => {
        setViewType(type);
        if (type === 'PAGE')
            setSelectedTab(tab);
        else
            setSelectedTab(null);
    }

    const getSeasonKpis = (seasonId, currentTab) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, tags: currentTab === 0 ? 'PLAYER_MATCH' : 'PLAYER_BONUS' },
            onSuccessCB: (result, headers) => {
                result = result.map(ele => {
                    ele.label = ele.name;
                    return ele;
                });
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasonTeamsList = (seasonId) => {
        setLoading(true);
        getSeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map(ele => {
                    ele.label = ele.name;
                    ele._id = ele._id;
                    return ele;
                });
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getRolesList = (seasonId) => {
        setLoading(true);
        getSeasonPlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    ele._id = ele.role_id;
                    return ele;
                })
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getListOfRegions = () => {
        setLoading(true);
        getRegionsList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    return ele;
                })
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const columns = (kpi) => [
        {
            name: "rank", label: "Rank", options: commonColumnOptions
        },
        {
            name: "player", label: "Player", options: {
                customBodyRender: (player) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={player.name} username={player._id} url={player.photo_url} onClick={() => { }} style={{ marginRight: 14 }} />
                        {player && player.name}
                    </div>

                )
            }
        },
        {
            name: "team", label: "Team", options: {
                display: filtersData.season?.season_format !== 'PLAYER_RACE',
                customBodyRender: (team) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={team.name} username={team._id} url={team.logo_url} onClick={() => { }} style={{ marginRight: 14 }} />
                        {team && team.name}
                    </div>

                )
            }
        },
        {
            name: "target", label: "Target", options: {
                ...commonColumnOptions,
                display: ((currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true,
                customBodyRender: target => (target ? kpi.unit_of_measurement === 'CURRENCY' ?
                    getCurrencyFormat(userDetails?.countryCode, kpi.unit, target)
                    : `${getNumberFormat(userDetails?.countryCode, target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(target, kpi.unit) : ''}`
                    : 0)
            }
        },
        {
            name: "actual", label: "Actual", options: {
                ...commonColumnOptions,
                display: ((currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true,
                customBodyRender: actual => (actual ? kpi.unit_of_measurement === 'CURRENCY' ?
                    getCurrencyFormat(userDetails?.countryCode, kpi.unit, actual)
                    : `${getNumberFormat(userDetails?.countryCode, actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(actual, kpi.unit) : ''}`
                    : 0)
            }
        },
        {
            name: "achieved", label: "Achievement %", options: {
                ...commonColumnOptions,
                display: ((currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "match_points", label: "Core Pts", options: {
                ...commonColumnOptions, display: currentTab >= 2,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
        {
            name: "bonus_points", label: "Bonus Pts", options: {
                ...commonColumnOptions, display: currentTab >= 2,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
        {
            name: "total_points",
            label: currentTab <= 1 ? ((subTabs[currentTab].label.toUpperCase()) === 'CORE KPI' ? "Core Pts" : "Bonus Pts") : "Total Pts",
            options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
    ];

    return (
        <>
            {/* <SeasonFilters filtersData={filtersData} setFiltersData={setFiltersData} defaultFilters={defaultFilters} /> */}
            {
                filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No player data is available, since player performance is not measured as a part of the selected season, which is of the format ’Team race’</p>
                    </div>
                    :
                    <div style={{ margin: '10px 0' }}>
                        {/* <TabBar tabs={subTabs} subTabVal={currentTab} handleSubTabChange={handleSubTabChange} /> */}
                        <CustomListGroup data={subTabs} selectedItem={subTabs.find((option) => option.value === currentTab)} onClick={handleSubTabChange} style={{ marginLeft: 0, maxWidth: 'max-content' }} />
                        <p className="table-title decimal-switch-root" style={{ marginBottom: -10 }}>
                            <p></p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                        {
                            subTabsData.length > 0 ?
                                [
                                    viewType === 'CARD' ?
                                        <Grid container spacing={2} style={{ marginTop: 0 }} >
                                            {
                                                subTabsData.map((tab, i) => (
                                                    <Grid key={i} item md={subTabsData.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                        <LeaderboardTable viewType={viewType} filtersData={filtersData} groupTab={currentTab} columns={columns(tab)} tabData={tab} handleViewChange={handleViewChange} loading={loading} />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        :
                                        <LeaderboardTable viewType={viewType} filtersData={filtersData} groupTab={currentTab} columns={columns(selectedTab)} tabData={selectedTab} tabType={subTabs[currentTab].label.toUpperCase()} handleViewChange={handleViewChange} loading={loading} />
                                ]
                                :
                                < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
                            // <p style={{ textAlign: 'center', fontSize: 12, color: '#000000a1', width: '100%', marginTop: 50 }}>** No data available **</p>
                        }
                    </div>
                // <>
                //     <TabBar tabs={subTabs} subTabVal={currentTab} handleSubTabChange={handleSubTabChange} />
                //     <PlayerLeaderBoardDataView
                //         tabsData={subTabsData}
                //         tabType={subTabs[currentTab].label.toUpperCase()}
                //         filtersData={filtersData}
                //         groupTab={currentTab} />
                // </>
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
        getSeasonTeams: (options) => dispatch(getSeasonTeams(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
        getRegionsList: (options) => dispatch(getRegionsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerLeaderboard));
