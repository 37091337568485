
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setSidebarIndex } from '../../ducks/sidebar';
import LandingImage from '../../assets/images/surveyimage.svg';
import SurveyList from './SurveyList';
import SurveyDetails from './SurveyDetails';
import SurveyQuestions from './SurveyQuestions';
import { Redirect, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        maxWidth: 290,
        zIndex: 10,
        backgroundColor: '#fff',
        transition: 'width 0.25s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            // width: '0px',
            // minWidth: 0,
            position: 'fixed',
            borderRight: '1.5px solid #eeeeee'
        },
    },
    mainRootWidth: {
        width: 0,
        minWidth: 0
    },
    root: {
        flexGrow: 1,
        maxWidth: 330,

    },
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'inherit',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: 225
            }
        }
    },
    surveyArea: {
        width: '100%',
        display: 'flex',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        maxHeight: '90vh',
        overflowY: 'auto'
    }

}));

const SurveyLayout = (props) => {
    const classes = useStyles();
    const [currentSurvey, setCurrent] = useState(null);
    const [questions, setQuestions] = useState(false);
    const [openList, setOpenList] = useState(false);
    const { path } = useRouteMatch();

    // console.log('SurveyLayout ', props);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 960) {
                setOpenList(false);
            }
            else {
                props.setSidebarIndex(null);
            }
        });
    }, []);

    useEffect(() => {
        // console.log('sidebar survey index ', props.sidebar, openList)
        if (window.innerWidth <= 960) {
            if (props.sidebar.index !== null) {
                setOpenList(false);
            }
            else setOpenList(true);
        }
    }, [props.sidebar]);

    useEffect(() => {
        setQuestions(false);
    }, [currentSurvey]);

    const handleOnSurveyClick = (value, existingCurrent) => {
        setCurrent(value);
        props.history.push(`/survey/${value._id}`);
        if (window.innerWidth <= 960 && !existingCurrent) {
            setOpenList(true);
            props.setSidebarIndex(null);
        }
    }

    const handleStartSurvey = () => {
        setQuestions(true);
    }

    return (
        <>
            <div className={classNames(classes.mainRoot, openList && classes.mainRootWidth)}>
                <SurveyList handleOnSurveyClick={handleOnSurveyClick} {...props} />
            </div>
            <div className={classes.surveyArea}>
                <Switch>
                    <Route path={`${path}/:survey_id`} exact >
                        {
                            questions ?
                                <SurveyQuestions {...currentSurvey} />
                                :
                                <SurveyDetails handleStartSurvey={handleStartSurvey} handleOnSurveyClick={handleOnSurveyClick} {...currentSurvey} />
                        }
                    </Route>
                    <Route path={path} exact >
                        <div className={classes.landingPage}>
                            <img height="300" src={LandingImage} alt="Landing_Page"></img>
                        </div>
                    </Route>
                    <Redirect to={'/'} />
                </Switch>
                {/* {
                    currentSurvey ?
                        [
                            questions ?
                                <SurveyQuestions {...currentSurvey} />
                                :
                                <SurveyDetails handleStartSurvey={handleStartSurvey} {...currentSurvey} />

                        ]

                        :
                        <div className={classes.landingPage}>
                            <img height="300" src={LandingImage} alt="Landing_Page"></img>
                        </div>
                } */}
            </div>
        </>

    );
}

const mapStateToProps = (state) => ({
    sidebar: state.sidebar
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setSidebarIndex: (options) => dispatch(setSidebarIndex(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyLayout));