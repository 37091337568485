import React from "react";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole } from "../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import MentorDashboard from "./MentorDashboard";
import PlayerDashboard from "./PlayerDashboard";
import NonPlayerDashboard from "./NonPlayerDashboard";

const Dashboard = ({ history, location, filtersData, rounds, userInfo, getOverallOrgStats, getLoggedInUserRole, getSeasonsList, }) => {

    const natigateToAllStats = () => {
        const historyPath = `${history.location?.search}&round_id=ALL`;
        history.push(`/leagues/stats/overall${historyPath}`)
    }
    const natigateToLiveScores = () => {
        history.push(`/leagues/live-scores${history.location?.search}`)
    }

    const navigateToTeamLeaderboard = () => {
        history.push(`/leagues/points-table/team${history.location?.search}`)
    }

    const navigateToPlayerLeaderboard = () => {
        history.push(`/leagues/points-table/player${history.location?.search}`)
    }

    return (
        <div style={{ padding: '24px 46px' }}>

            {
                userInfo?.role === 'PLAYER' ?
                    <PlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
                    : userInfo?.role === 'MENTOR' ? <MentorDashboard filtersData={filtersData} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToTeamLeaderboard={navigateToTeamLeaderboard} />
                        : <NonPlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));