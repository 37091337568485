import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/user");

// maybe leave here as a backup/global ?
export const SET_NICKNAME = constant("SET_NICKNAME");
export const SET_PRESENCE = constant("SET_PRESENCE");

export const SET_DETAILS = constant("SET_DETAILS");
export const UPDATE_PROFILE = constant("UPDATE_PROFILE");
export const CHANGE_PASSWORD = constant("CHANGE_PASSWORD");


export const GET_MEDIA_LIST = constant("GET_MEDIA_LIST");
export const UPDATE_WORK_LIST = constant("UPDATE_WORK_LIST");
export const UPDATE_EDUCATION_LIST = constant("UPDATE_EDUCATION_LIST");
export const DELETE_EDUCATION = constant("DELETE_EDUCATION");
export const DELETE_WORK_EXPERIENCE = constant("DELETE_WORK_EXPERIENCE");
export const UPDATE_BASIC_PROFILE = constant("UPDATE_BASIC_PROFILE");
export const GET_ARENA_PROFILE = constant("GET_ARENA_PROFILE");
export const GET_UPDATED_PROFILE = constant("GET_UPDATED_PROFILE");

export const changePassword = details => ({
  type: CHANGE_PASSWORD,
  payload: details
})

export const updateProfile = details => ({
  type: UPDATE_PROFILE,
  payload: details
})

export const setDetails = details => ({
  type: SET_DETAILS,
  payload: details
})

export const setNickname = nickname => ({
  type: SET_NICKNAME,
  payload: { nickname }
});

export const setPresence = (presence, rooms) => ({
  type: SET_PRESENCE,
  payload: { presence, rooms }
});

export const getMediaList = (payload) => ({
  type: GET_MEDIA_LIST,
  payload
});

export const updateBasicProfile = (payload) => ({
  type: UPDATE_BASIC_PROFILE,
  payload
});

export const updateWorkList = (payload) => ({
  type: UPDATE_WORK_LIST,
  payload
});

export const updateEducationList = (payload) => ({
  type: UPDATE_EDUCATION_LIST,
  payload
});

export const deleteEducation = (payload) => ({
  type: DELETE_EDUCATION,
  payload
});

export const deleteWorkExperience = (payload) => ({
  type: DELETE_WORK_EXPERIENCE,
  payload
});

export const getArenaProfile = (payload) => ({
  type: GET_ARENA_PROFILE,
  payload
});

export const getUpdatedProfile = (payload) => ({
  type: GET_UPDATED_PROFILE,
  payload
});

const initialState = {
  nickname: '',
  presence: {
    label: 'Available',
    value: 'available'
  },
  name: '',
  photoUrl: '',
  email: '',
  phone: '',
  designation: '',
  about: '',
  departmentName: '',
  username: '',
  chatId: ''
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case SET_DETAILS: {
      // console.log('SET_DETAILS ', action.payload)
      const details = action.payload;
      return {
        ...state,
        ...details,
        photoUrl: details && (details.photoUrl || details.photo_url),
        dateOfBirth: (details.dateOfBirth || details.birthDate),
        // name: details.name,
        // email: details.email,
        // phone: details.phoneNo,
        // designation: details.designation,
        // about: details.about,
        // departmentName: details.departmentName,
        // username: details.username,
        // chatId: details.chatId,
        // employeeId: details.employeeId
      }
    }

    case SET_NICKNAME: {
      return {
        ...state,
        nickname: action.payload.nickname
      };
    }

    case SET_PRESENCE: {
      return {
        ...state,
        presence: action.payload.presence
      };
    }

    default:
      return state;
  }
};
