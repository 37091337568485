import { orgRealm } from ".";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1`;
const baseUrlV2 = `${process.env.REACT_APP_BASE_URL}/api/v2`;

const chatService = `${process.env.REACT_APP_BASE_URL_CHAT_SERVICE}/api/v1`;

const teamsManagementBaseUrl = `${baseUrl}/teams-management`;
const productManagementBaseUrl = `${baseUrl}/pms`;
// const productManagementBaseUrl = `http://10.8.0.119:3005/api/v1/pms`;
// const devEnvPmsBaseUrl = 'http://dev.chase.wavelabs.in/api/v1/pms'
const accessManagementBaseUrl = `${baseUrl}/access-management`;

const chatServiceBaseUrl = `${chatService}/chat-service`;

// const searchBaseUrl = `${baseUrl}/search`;

const surveyManagementBaseUrl = `${baseUrl}/survey-management`;

const wallManagement = `${baseUrl}/wall-management`;
const wallManagementV2 = `${baseUrlV2}/wall-management`;
const wallManagementOrg = orgCode => `${baseUrl}/wall-management/organisations/${orgCode}`;

const searchManagement = `${baseUrl}/search`;
const searchManagementV2 = `${baseUrlV2}/search`;

const meetingManagement = `${baseUrl}/meeting-management`;

const platformManagement = `${baseUrl}/platform-management`;

const gameManagement = `${baseUrl}/games-management`;

const gameManagement2 = `${baseUrlV2}/games-management`;

export const setDeviceToken = (deviceId) => `${baseUrl}/notifications/devices/${deviceId}`;

export const requestPushNotificationAPI = `${baseUrl}/notifications/`

export const fetchTeams = `${teamsManagementBaseUrl}/employees/teams`;

export const teamDetails = (teamId) => `${teamsManagementBaseUrl}/teams/${teamId}`;

export const fetchEmployees = `${teamsManagementBaseUrl}/employees`;

export const fetchTeamsMembers = `${teamsManagementBaseUrl}/teams`;

export const fetchAccessKey = `${chatServiceBaseUrl}/users/accesskey`;

export const fetchRecentChats = `${chatServiceBaseUrl}/recentchat`;

export const fetchChatMessages = (username) => `${chatServiceBaseUrl}/users/${username}/messages`;

export const fetchSearchMessagesAPI = (timestamp, targetJid) => `${chatServiceBaseUrl}/users/${timestamp}/${targetJid}/messageshistory`;

export const s3FileUpload = `${teamsManagementBaseUrl}/files`;

export const saveMedia = `${chatServiceBaseUrl}/files-or-links`;

export const changePassword = `${accessManagementBaseUrl}/users/password`;

export const chatViewStatus = `${chatServiceBaseUrl}/read_count`;

export const globalSearch = `${searchManagement}/global`;

export const employeeSearch = `${searchManagement}/employee`;

export const getSurveysQuestions = (id) => `${surveyManagementBaseUrl}/surveys/${id}/questions`;

export const respondSurvey = (id) => `${surveyManagementBaseUrl}/surveys/${id}/responses`;

export const getUserSurveys = `${surveyManagementBaseUrl}/survey-targets/surveys`;

export const getSurveyDetails = (surveyId) => `${surveyManagementBaseUrl}/surveys/${surveyId}`;

// Arena

export const ARENA_POSTS_API = `${wallManagement}/posts`;
export const ARENA_POSTS_LIST_WITH_ORG_API = orgCode => `${wallManagementOrg(orgCode)}/posts`;

export const GET_BACKGROUND_COLORS_API = `${wallManagement}/posts/backgrounds`;

export const UPDATE_ARENA_API = (postId) => `${wallManagement}/posts/${postId}`;
export const GET_API_ARENA_API = (postId) => `${wallManagementV2}/posts/${postId}`;

export const SHARE_POST_TO_ARENA_API = `${wallManagement}/posts/share`;

export const TAG_CHASEONS_API = (postId) => `${wallManagement}/posts/${postId}/tags`;

export const ARENA_SEND_REACTION_API = `${wallManagement}/reactions`;

export const ARENA_DELETE_REACTION_API = (entityId, entityType) => `${wallManagement}/reactions/${entityType}/${entityId}`;

export const ARENA_GET_REACTIONS_API = (entityType, entityId) => `${wallManagement}/reactions/${entityType}/${entityId}`;

export const ARENA_COMMENTS_API = `${wallManagement}/comments`;

export const ARENA_GET_COMMENTS_API = (postId) => `${wallManagement}/posts/${postId}/comments`;


export const ARENA_GET_USER_POST_API = (userId) => `${wallManagementV2}/${userId}/posts`;

export const ARENA_GET_MEDIA_API = (userId) => `${wallManagement}/users/media/${userId}`;

export const UPDATE_BASIC_PROFILE_API = (employeeId) => `${teamsManagementBaseUrl}/employees/${employeeId}`;

export const UPDATE_WORK_DETAILS_API = (employeeId) => `${teamsManagementBaseUrl}/employees/${employeeId}/workexperience`;

export const UPDATE_EDUCATION_DETAILS_API = (employeeId) => `${teamsManagementBaseUrl}/employees/${employeeId}/education`;

export const DELETE_EDUCATION_DETAILS_API = (employeeId, educationId) => `${teamsManagementBaseUrl}/employees/${employeeId}/education/${educationId}`;
export const DELETE_WORK_DETAILS_API = (employeeId, workExperienceId) => `${teamsManagementBaseUrl}/employees/${employeeId}/workexperience/${workExperienceId}`;

export const ARENA_GET_COMMENT_REPLIES_API = (commentId) => `${wallManagement}/comments/${commentId}/comments`;

export const GET_FOLLOWER_USERS_API = (userId) => `${wallManagement}/users/${userId}/followers`;
export const GET_FOLLOWING_USERS_API = (userId) => `${wallManagement}/users/${userId}/favourites-to`;
export const GET_MUTUAL_FOLLOWING_USERS_API = (userId) => `${wallManagement}/users/${userId}/mutuals`;
export const FOLLOW_USER_API = (userId) => `${wallManagement}/users/${userId}/follow`;
export const UN_FOLLOW_USER_API = (userId) => `${wallManagement}/users/${userId}/unfollow`;
export const GET_FAVOURITE_STATUS_API = (userId) => `${wallManagement}/users/${userId}/favourites/find`;
export const GET_TAGGED_USERS_API = (postId) => `${wallManagement}/posts/${postId}/tags`;
export const GET_GROUPS_LIST_API = `${wallManagement}/groups`;

export const GET_EMPLOYEE_DETAILS_API = (userId) => `${teamsManagementBaseUrl}/employees/${userId}/`;
//Areana Search
export const ARENA_SEARCH_POSTS_API = `${searchManagementV2}/posts`
export const ARENA_SEARCH_MEDIA_API = `${searchManagement}/media`
//shares
export const GET_SHARES_LIST_API = (postId) => `${wallManagement}/posts/${postId}/shares`;
//deleteposts
export const DELETE_POST_API = (postId) => `${wallManagement}/posts/${postId}`;
// Poll
export const CREATE_POLL_API = `${surveyManagementBaseUrl}/surveys/wall_poll/question`;
export const GET_POLLS_LIST_API = `${surveyManagementBaseUrl}/surveys/wall_poll`;

// Chase-Meetings
export const GET_MEETINGS_LIST_API = `${meetingManagement}/meetings`;
export const UPDATE_SCHEDULED_MEETING_API = (meetingId) => `${meetingManagement}/meetings/${meetingId}`;
export const GET_INDIVIDUAL_MEETING_API = (meetingId) => `${meetingManagement}/meetings/${meetingId}`
export const UPDATE_MEEETING_STATUS_API = (meetingId, participantId, status) => `${meetingManagement}/meetings/${meetingId}/participants/${participantId}/status/${status}`

// Org Details
export const GET_ORG_DETAILS_API = (orgCode) => `${platformManagement}/public/organizations/${orgCode}`

//notifications
export const GET_IN_APP_NOTIFICATIONS_API = `${baseUrl}/notifications/`;
export const UPDATE_IN_APP_NOTIFICATION_READ_API = (notificationId) => `${baseUrl}/notifications/${notificationId}/read`
//PMS
export const GET_KANBANBORAD_DATA_API = (project_key) => `${productManagementBaseUrl}/projects/dashboard/${project_key}`;
export const GET_TASK_DETAILS_API = (task_id) => `${productManagementBaseUrl}/tasks/${task_id}`;
export const GET_PROJECT_LIST_API = () => `${productManagementBaseUrl}/projects/getBoards`;
export const GET_PROJECT_DETAILS_API = (projectId) => `${productManagementBaseUrl}/projects/${projectId}`;
export const CREATE_TASK_API = `${productManagementBaseUrl}/tasks`;
export const UPDATE_TASK_API = (taskId) => `${productManagementBaseUrl}/tasks/${taskId}`;
export const CREATE_COMMENT_API = `${productManagementBaseUrl}/comments`;
export const GET_COMMENTS_API = (taskId) => `${productManagementBaseUrl}/tasks/${taskId}/comments`
export const DELETE_TASK_API = (_id) => `${productManagementBaseUrl}/tasks/${_id}`;
export const UPDATE_COMMENT_API = (_id) => `${productManagementBaseUrl}/comments/${_id}`;
export const DETETE_COMMENT_API = (_id) => `${productManagementBaseUrl}/comments/${_id}`;
export const GET_PROJECT_MEMBERS_API = (_id) => `${productManagementBaseUrl}/projects/${_id}/members`;
export const CREATE_SUB_TASK_API = `${productManagementBaseUrl}/subtasks`;
export const GET_SUB_TASK_API = `${productManagementBaseUrl}/tasks/subtask_id`
export const CREATE_WORK_LOG_API = `${productManagementBaseUrl}/timelogs`;
export const GET_WORK_LOGS_LIST_API = `${productManagementBaseUrl}/timelogs`;
export const UPDATE_WORK_LOG_API = (_id) => `${productManagementBaseUrl}/timelogs/${_id}`;
export const DELETE_WORK_LOG_API = (_id) => `${productManagementBaseUrl}/timelogs/${_id}`;
export const ADD_NEW_LANE_API = (_id) => `${productManagementBaseUrl}/projects/${_id}/addeNewStage`;
export const CHANGE_LANE_TITLE_API = (_id) => `${productManagementBaseUrl}/projects/${_id}/changeStageTitle`;
export const CHANGE_LANE_POSITIONS_API = (_id) => `${productManagementBaseUrl}/projects/${_id}`;
export const DELETE_STAGE_API = (_id) => `${productManagementBaseUrl}/projects/${_id}/deleteStage`;
export const EXPORT_TASKS_STATUSASON_API = (boardId) => `${productManagementBaseUrl}/projects/${boardId}/statusOn_report_excel_export`;
export const EXPORT_TASKS_AGING_API = (boardId) => `${productManagementBaseUrl}/projects/${boardId}/ageing_report_excel_export`;
//Game management api's
export const GET_LEAGUES_LIST_API = `${gameManagement}/leagues`;
export const GET_SEASONS_LIST_API = (leagueId) => `${gameManagement}/leagues/${leagueId}/seasons`;
export const GET_ROUNDS_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/rounds`;
export const GET_MATCHES_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/matches`;
export const GET_PLAYERS_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/players`;
export const GET_ROLES_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/season_player_roles`;
export const GET_TEAMS_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/teams`;
export const TEAM_MATCH_STATISTICS_API = (matchId) => `${gameManagement}/matches/${matchId}/statistics`;
export const TEAM_PLAYER_STATISTICS_API = (matchId) => `${gameManagement2}/matches/${matchId}/player_match_statistics`;
export const OVERALL_TEAM_CORE_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/teams/core-kpis-performance`;
export const OVERALL_TEAM_BONUS_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/teams/bonus-kpis-performance`;
export const TEAM_CORE_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/teams/team-wise-core-kpis-performance`;
export const TEAM_BONUS_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/teams/team-wise-bonus-kpis-performance`;
export const PLAYER_CORE_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/players/player-wise-core-kpis-performance`;
export const PLAYER_BONUS_KPIS_STATS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/players/player-wise-bonus-kpis-performance`;
export const GET_SEASON_KPIS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/kpis`;
export const GET_TEAM_KPIS_TRENDS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/kpi-wise-teams-performance`;
export const GET_PLAYER_KPIS_TRENDS_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/kpi-wise-players-performance`;
export const GET_DIVISIONS_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/divisions`;
export const GET_TEAMS_POINTS_API = `${gameManagement2}/leaderboads/teams`;
export const GET_PLAYERS_POINTS_API = `${gameManagement}/leaderboards/players`;
export const GET_TEAM_BOUNS_LEADERBOARD_API = `${gameManagement}/leaderboads/teams/bonus-kpis`;
export const GET_TEAM_MATCH_LEADERBOARD_API = `${gameManagement}/leaderboads/teams/team-matches`;
export const LIST_OF_ORG_REGIONS = orgCode => `${GET_ORG_DETAILS_API(orgCode)}/regions`;
export const GET_PLAYER_LEADERBOARD_API = `${gameManagement}/leaderboards/players`;
export const GET_KPIWISE_PLAYER_LEADERBOARD_API = `${gameManagement}/leaderboards/kpi-wise-player-leaderboard`;
export const TEAM_RACE_LIVE_SCORE_API = (matchId) => `${gameManagement}/matches/${matchId}/team-sprint-statistics`;
export const PLAYER_RACE_LIVE_SCORE_API = (matchId) => `${gameManagement}/matches/${matchId}/player-sprint-statistics`;
export const GET_ROUND_TEAM_STATISTICS_API = (roundId) => `${gameManagement}/rounds/${roundId}/statistics`;
export const GET_ROUND_PLAYER_STATISTICS_API = (roundId) => `${gameManagement}/rounds/${roundId}/player_statistics`;
export const GET_MY_TEAM_LIVE_PERFORMANCE_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/kpi-wise-myteam-live-score`;
export const GET_MY_LIVE_PERFORMANCE_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/kpi-wise-my-live-score`;
export const GET_STAKE_HOLDERS_LIST_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/stakeholders`;
export const GET_MATCH_DETAILS_API = (matchId) => `${gameManagement}/matches/${matchId}`;

//Dashboard
export const GET_LOGGED_IN_USER_ROLE_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/playerInfo`;
export const LIVE_MATCHES_API = (seasonId) => `${gameManagement}/seasons/${seasonId}/matches`;
export const OVERALL_TRENDS = (seasonId) => `${gameManagement}/seasons/${seasonId}/trends`;
export const GET_TOP_TEAM_API = (seasonId) => `${gameManagement}/leaderboards/teams/team-matches`;
export const GET_TOP_PLAYER_API = (seasonId) => `${gameManagement}/leaderboards/kpi-wise-player-leaderboard`;
