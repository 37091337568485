import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import loading, { setLoading } from "../../ducks/loading";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, decodeEnum } from "../../helpers";
import { getPlayerMatchStatistics, getPlayerMatchData } from "../../ducks/game";
import IllustrationView from "./IllustrationView";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import { groupBy } from 'underscore';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5px 0 30px',
        '& .material-table': {
            // backgroundColor: '#f0f2f5',
            // borderRadius: 8
        },
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12
    },
    title: {
        margin: '0 0 10px',
        fontSize: 12,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    },
    fieldLabel: {
        color: '#000000c9',
        fontWeight: 600,
        margin: '30px 0px 5px'
    }, avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    }
}));

const lastRow = {
    totalTeam1Golas: 0,
    totalTeam2Golas: 0,
}

const PlayersMatchSummary = ({ setLoading, loading, matchId = '', matchDetails = {}, userDetails, getPlayerMatchStatistics, getPlayerMatchData, matchData }) => {

    console.log('player match Data--------------------------', matchData)
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            getPlayerMatchStatistics({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
    }, [matchId]);

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    const getTotalScore = (data, teamId) => {
        const result = [...data].map(obj => obj.teams?.find(team => team.team_id === teamId)).reduce((a, b) => a + b.score, 0);
        return result + ` ${decodeEnum(data[0]?.scoring_unit) + (result === 1 ? '' : 's')}`;
    }

    const handleTemplateData = (response) => {
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points || 0, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points;
                    if (index === 0) {
                        headers.push(el);
                    }
                    obj.columns = [...(obj.columns || []), {
                        title: `${el.kpi_code || el.kpi_name}`, //-Actual
                        field: `${el.kpi_id}-actual`,
                        editable: 'never',
                        emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        render: (rowData) =>
                            rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                    }]
                });

                return [...result, { ...obj, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            if (finalResult.length > 0) {
                let groupData = groupBy(finalResult, 'team_id');
                Object.keys(groupData).forEach(team => {
                    let obj = {
                        data: [], columns: [{ ...columns[0] }, ...(finalResult[0].columns || []),
                        {
                            title: "Points",
                            field: "totalPoints",
                            editable: 'never',
                            // cellStyle: { minWidth: 100 },
                            templateRender: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)
                        }]
                    }
                    // if (groupData[team].length > 9) {
                    //     groupData[team] = groupData[team].slice(0, 9);
                    // }
                    obj.data = groupData[team]
                    groupData[team] = obj;
                })
                getPlayerMatchData(groupData);
            }
            else {
                getPlayerMatchData({});
            }

        }
    }

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            handleTemplateData(response);
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points || 0, selected: item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points;
                    if (index === 0) {
                        headers.push(el);
                    }
                    obj.columns = [...(obj.columns || []), {
                        title: `${el.kpi_name}`,//-target
                        field: `${el.kpi_id}-target`,
                        editable: 'never',
                        emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        hidden: isRuleBasedOnActuals(el),
                        render: (rowData) =>
                            rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                        // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                    }, {
                        title: `${el.kpi_name}`, //-Actual
                        field: `${el.kpi_id}-actual`,
                        editable: 'never',
                        emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        render: (rowData) =>
                            rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                    }, {
                        title: `${el.kpi_name}`,//-Ach %
                        field: `${el.kpi_id}-achieved`,
                        editable: 'never',
                        emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        hidden: isRuleBasedOnActuals(el),
                        render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                    }, {
                        title: `${el.kpi_name}`,//-Points
                        field: `${el.kpi_id}-points`,
                        editable: 'never',
                        emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                    }]
                });

                return [...result, { ...obj, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            if (finalResult.length > 0) {
                setData(groupBy(finalResult, 'team_id'));
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers])
            }
            else {
                // getPlayerMatchData([]);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const columns = [
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            templateRender: (rowData) => rowData.player?.name,
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.team.name} username={rowData._id} url={rowData.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Total points earned",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)
        }
    ]

    const ViewTableData = ({ tableData }) => {
        return (
            <LiveScoresMuiTable
                title={'Match Summary'}
                columns={[...columns, ...dynamicColumns]}
                data={tableData}
                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    showTitle: false,
                    addRowPosition: 'first',
                    emptyRowsWhenPaging: false,
                    toolbar: false,
                    pageSizeOptions: [5, 10, 25],
                    pageSize: 25,
                    paging: false
                }}
                localization={{ body: { emptyDataSourceMessage: <p style={{ textAlign: 'center' }}>No data available</p> } }}
                components={{
                    Header: props => (
                        <TableHead className="custom-headers">
                            <TableRow>
                                <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                {/* <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell> */}
                                {/* <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>                                            {console.log('dynamicHeaders ', dynamicHeaders)} */}
                                {
                                    dynamicHeaders.map((obj, index) => (
                                        <TableCell colSpan={isRuleBasedOnActuals(obj) ? 2 : 4} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                            <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                                {
                                    dynamicHeaders.map((obj) => (
                                        <>
                                            {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target </TableCell>}
                                            <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                            {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                            <TableCell align="center">Points</TableCell>
                                        </>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                    )
                }}
            />
        )
    }

    return (
        <>
            {
                (Object.keys(data).length > 0) &&
                <div className={classes.root}>
                    {
                        Object.keys({ team1: matchDetails?.teams[0]?.team_id, team2: matchDetails?.teams[1]?.team_id }).map((team, index) => (
                            <div style={{ marginBottom: 20 }}>
                                <p className="table-title decimal-switch-root">
                                    <p>
                                        <span>{matchDetails?.teams[index]?.name} : <span style={{ color: '#000' }}>Players performances</span></span>
                                    </p>
                                    {index === 0 && <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                                </p>
                                <ViewTableData tableData={data[matchDetails?.teams[index]?.team_id]} />
                            </div>

                        ))
                    }
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    matchData: state.game?.playerMatchData
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerMatchStatistics: options => dispatch(getPlayerMatchStatistics(options)),
        getPlayerMatchData: options => dispatch(getPlayerMatchData(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayersMatchSummary));
