import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import NewMeeting from './NewMeeting';
import MeetingCalendar from './MeetingCalendar';
import CustomDialogComponent from './CustomDialogComponent';
import JoinToCodeScreen from './MeetDetails/JoinToCodeScreen';
import Participants from './MeetDetails/Participants';
import ShareMeetingToChatDialog from './ShareMeetingToChatDialog';
import JoinPreview from './JoinPreview';

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: '#f5f5f5',
        width: '100%',
        maxHeight: 'calc(100vh - 60px)',
        overflowY: 'auto',
        // padding: '35px 35px 0px',
        // display: 'flex',
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: 'calc(100vh - 80px)',
        //     maxWidth: `calc(100vw - 150px)`,
        //     padding: '20px 15px 0px',
        // },
    },
}))

export const MeetLayout = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    return (
        <div className={classes.root}>
            <Switch>
                <Route path={`${path}/schedule-meeting`} exact >
                    <NewMeeting />
                </Route>
                <Route path={`${path}/:id/edit`} exact >
                    <NewMeeting fromEdit={true} />
                </Route>
                <Route path={`${path}/:id/preview/:name/:code`} exact >
                    <JoinPreview />
                </Route>
                <Route path={path} exact >
                    <MeetingCalendar />
                </Route>
                {/* <Route path={`${path}/previousmeeting`} exact render={()=><PreviousMeetings/>} /> */}
                <Route path={`${path}/previousmeeting`} exact >
                    <Participants />
                </Route>

                <Route path={`${path}/jointocode`} exact>
                    <JoinToCodeScreen />
                </Route>
                <Redirect to={'/'} />
            </Switch>
            <CustomDialogComponent />
            <ShareMeetingToChatDialog />
        </div>
    )
}