import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import { ArrowForward, ChevronLeft } from '@material-ui/icons';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getTeamBonusLeaderBoard, getTeamMatchLeaderBoard } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import FieldSet from '../../GlobalComponents/FieldSet';

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 26,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
        marginTop: 20
    },
    tabRoot: {
        minHeight: 26,
        textTransform: 'capitalize',
        minWidth: 'auto',
        margin: '0px 8px',
        padding: '0px 15px',
        borderRadius: 13,
        backgroundColor: '#FFF',
        '&:first-child': {
            marginLeft: 0
        }
    },
    selectedTab: {
        color: '#FFF !important',
        backgroundColor: theme.palette.primary.main
    },
    disabledScrollBtn: {
        display: 'none'
    },

    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    viewAllButton: {
        margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 15,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    }
}))

const LeaderboardTable = ({ columns = () => [], kpi = {}, kpis = [], filtersData, viewType = "", handleViewChange, setLoading, loading, getTeamMatchLeaderBoard, getTeamBonusLeaderBoard, groupTab, userDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    // const [selectedKpi, setSelectedKpi] = useState(null);
    const [data, setData] = useState([]);
    const [showViewAllButton, setShowViewAllButton] = useState(false);
    // const [filteredKpis, setFilteredKpis] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    // useEffect(() => {
    //     const filteredKpis = kpis.filter(ele => ele.tags.includes(groupTab === 0 ? 'TEAM_MATCH' : 'TEAM_BONUS'));
    //     if (filteredKpis.length > 0) {
    //         setFilteredKpis(filteredKpis);
    //         setSelectedKpi({ ...filteredKpis[0] });
    //     }
    //     else setSelectedKpi(null)
    // }, [kpis, groupTab]);

    useEffect(() => {
        if (kpi && filtersData.season) {
            if (groupTab === 0) {
                getTeamMatchData(filtersData.season._id, kpi._id);
            }
            else if (groupTab === 1) {
                getTeamBonusData(filtersData.season._id, kpi._id);
            }
        }
    }, [kpi, groupTab]);

    const getTeamMatchData = (seasonId, kpiId) => {
        setLoading(true);
        getTeamMatchLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId, limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({ ...item, selected: item._id === headers?.my_team_id }));
                setData([...result]);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getTeamBonusData = (seasonId, kpiId) => {
        setLoading(true);
        getTeamBonusLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId, limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({ ...item, selected: item._id === headers?.my_team_id }));
                setData([...result]);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    return (
        <>
            {
                (viewType === 'CARD') ?
                    <FieldSet
                        title={kpi.name}
                        rootStyles={classes.filedSetRootStyles}
                    >
                        <CustomDataTable columns={columns(kpi, data)} data={data} options={{ pagination: false }} rootProps={{ style: { marginTop: 20 } }} loading={loading} />
                        {showViewAllButton && <span className={classes.viewAllButton} onClick={() => handleViewChange('PAGE', kpi)}>View all<ArrowForward /></span>}
                    </FieldSet>
                    :
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                            <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange('CARD', null)}><ChevronLeft /></IconButton>
                            {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>KPI : {kpi?.name.toUpperCase()}</p>}
                        </div>
                        <CustomDataTable columns={columns(kpi, data)} data={data} rootProps={{ style: { marginTop: 10 } }} loading={loading} />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    // loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamMatchLeaderBoard: (options) => dispatch(getTeamMatchLeaderBoard(options)),
        getTeamBonusLeaderBoard: (options) => dispatch(getTeamBonusLeaderBoard(options))
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardTable));
