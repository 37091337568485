import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardHeader, CardContent, Button, IconButton, Paper, TextareaAutosize, ClickAwayListener, Tooltip, Menu, MenuItem, Divider, Tabs, Tab } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { PlayCircleOutline, InsertEmoticon, MoreHoriz, MoreVert, DescriptionOutlined, Fullscreen, CheckCircle, FiberManualRecord } from "@material-ui/icons";
import moment from "moment";
import arena, { getArenaList, sendReaction, postArenaComments, getArenaCommentsList, openReactionsDialog, deleteReaction, getCommentReplies, postCommentReply, openShareToArenaDialog, openSharesListDialog, openEditArenaDialog, deletePost, getPostByPostId, openShareToChatDialog, openTaggedUsersList } from "../../ducks/arena";
// import { ReactComponent as Like } from "../../assets/icons/like-arena.svg";
// import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
// import { ReactComponent as Share } from "../../assets/icons/share-arena.svg";
import { ReactComponent as Like } from "../../assets/icons/heart.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment-1.svg";
import { ReactComponent as Share } from "../../assets/icons/share-post.svg";
import { ReactComponent as HomeAllIcon } from "../../assets/icons/arena-all-home.svg";
import { ReactComponent as FavouriteIcon } from "../../assets/icons/arena-favourite.svg";
import { ReactComponent as FavouriteUserIcon } from '../../assets/icons/add-favourite.svg';
import { handleImageViewer } from "../../ducks/imageViewer";
import InfiniteScroll from "react-infinite-scroll-component";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import Moment from "react-moment";
import { ArenaPreview } from './ArenaPreview';
import { convertUnicode, handleSeeMore, isInViewport, urlify, getPollDuration, getNumberFormat, getSearchValue, setSearchQuery } from '../../helpers';
import { ReactComponent as LikeIcon } from '../../assets/icons/like.svg';
import { ReactComponent as ApplauseIcon } from '../../assets/icons/applause.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { ReactComponent as InsightfulIcon } from '../../assets/icons/insightful.svg';
import { ReactComponent as SurprisedIcon } from '../../assets/icons/surprised.svg';
import { ReactComponent as CuriousIcon } from '../../assets/icons/curious.svg';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import { ReactComponent as ReactChatLogo } from "../../assets/icons/chat-1.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { ReactComponent as TagsIcons } from "../../assets/icons/tag-chaseons-3.svg";
import { ReactComponent as FollowIcon } from '../../assets/icons/follow.svg';
import { ReactComponent as FollowingIcon } from '../../assets/icons/following.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import EditArenaPost from "./EditArenaPost";
import { ConfirmationDialog } from "./CreateArenaPost";
import { searchArenaChaseonsOrPosts } from "../../ducks/search";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Player, ControlBar, Shortcut } from 'video-react';
import 'video-react/dist/video-react.css';
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import UserProfileCard from "../UserProfileCard/UserProfileCard";
import ArenaPostHeader from "../ArenaComponents/ArenaPostHeader";
import ArenaActions from "../ArenaComponents/ArenaActions";
import ArenaLikes from "../ArenaComponents/ArenaLikes";
import ArenaText from "../ArenaComponents/ArenaText";
import ArenaCommentInput from "../ArenaComponents/ArenaCommentInput";
import AreanSharedPostHeader from "../ArenaComponents/ArenaSharedPostHeader";
import { followUser, unFollowUser } from "../../ducks/favourites";
import PDFViewerDialog from "../PDFViewer";
import PDFViewer from "../PDFViewer/PDFViewer";
import { sendSurveyResponse } from "../../ducks/surveys";
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import noPreview from '../../assets/images/nopreview.png';
import { getContacts } from "../../ducks/contacts";

const COMMENT_LIST_LIMIT = 5;

const useStyles = makeStyles(theme => ({
  root: {
    // margin: "40px 0",
    maxWidth: 600,
    margin: 'auto'
  },
  rootdup: {
    maxWidth: 600,
    margin: 'auto',
    background: "#ffffff"
  },
  arenaRoot: {
    marginBottom: 10,
    backgroundColor: "#FFFFFF",
    paddingBottom: 20,
    "&:nth-child(1)": {
      borderRadius: "6px 6px 0px 0px"
    }
  },
  avatarRoot: {
    display: "flex",
    alignItems: "center",
    padding: "12px 15px",
    justifyContent: 'space-between'
  },
  profileName: {
    margin: "2px 10px 0px",
    color: "#58595B",
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer'
  },
  timestamp: {
    margin: "5px 10px 0px",
    fontSize: 14,
    color: "#838383"
  },
  actionRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: '0px 16px 20px',
    '& button': {
      padding: 0,
      '&:hover': {
        background: 'none'
      }
    }
    // padding: 6,
    // borderStyle: "solid",
    // borderWidth: "1.5px 0",
    // borderColor: "#EEEEEE"
  },
  actionButton: {
    alignItems: "end",
    fontSize: 13,
    padding: "6px 20px",
    '& svg': {
      width: 20,
      height: 20
    },
    "&:hover": {
      background: "none"
    },
    [theme.breakpoints.down("xs")]: {
      "& span": {
        marginRight: 0
      }
    }
  },
  actionIcon: {
    width: 20,
    height: 20
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  shareOptionStyles: {
    fontSize: 14,
    '& #share-to-arena': {
      width: 20,
      height: 20,
      marginRight: 10,
      // '& path': {
      //   fill: theme.palette.primary.main
      // },
      // '& path[fill="white"]': {
      //   fill: '#FFF',
      // },
      '& path': {
        stroke: theme.palette.primary.main
      }
    },
    '& #send-in-chat': {
      width: 20,
      height: 20,
      marginRight: 10,
      // '& path': {
      //   fill: theme.palette.primary.main
      // },
      // '& path[fill="white"]': {
      //   fill: '#FFF',
      // }
      '& path': {
        stroke: theme.palette.primary.main
      },
      '& path[fill]': {
        fill: theme.palette.primary.main,
      },
    }
  },
  arenaMoreOptionStyles: {
    fontSize: 14,
    '& svg': {
      width: 17,
      height: 17,
      marginRight: 10,
      '& path': {
        fill: theme.palette.primary.main
      }
    }
  },
  filtersRoot: {
    borderBottom: "1.5px solid #e6e6e6",
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center"
    },
    "& .isActive": {
      backgroundColor: "#828282",
      color: "#FFFFFF",
      "& svg": {
        "& .a": {
          fill: "#FFFFFF"
        }
      }
    }
  },
  filterButton: {
    borderRadius: "10px 10px 0 0",
    margin: "0 7px",
    backgroundColor: "#e6e6e6",
    width: 130,
    fontSize: 17,
    [theme.breakpoints.down("xs")]: {
      margin: "0 4px",
      width: 50,
      minWidth: 50,
      "& span": {
        marginRight: 0
      }
    },
    "&:hover": {
      backgroundColor: "#9b9b9b80"
    },
    "& svg": {
      "& .a": {
        fill: "#9b9b9b"
      }
    }
  },
  attachmentsRoot: {
    display: "flex",
    flexWrap: "wrap",
    // margin: "0 15px",
    marginBottom: 12,
    justifyContent: "space-between"
  },
  attachment: {
    width: '100%',
    margin: '0 auto',
    height: 'auto',
    // maxHeight: 600,

    position: "relative",
    // height: 210,
    // backgroundColor: "#828282",
    textAlign: "center",
    // border: "1px solid #f5f5f5",
    marginBottom: 4,
    "&:hover": {
      // cursor: "pointer"
      // opacity: 0.5,
    },
    "& img": {
      maxWidth: "100%",
      // maxHeight: 600,
      height: "100%",
      objectFit: 'contain'
    },
    '& .singleAttachementImage': {
      maxHeight: 'none',
      // height: 'auto',
      width: '100%'
    },
    '& .singleAttachementVideo': {
      width: '100%',
      height: 'auto'
    },

    '& .carousel.carousel-slider': {
      overflow: 'visible',
      '& #list-child': {
        // height: '100%'
      }
    },
    '& .carousel .control-dots': {
      bottom: '-30px'
    },
    '& .carousel .control-dots .dot': {
      margin: '0 2px',
      boxShadow: 'none',
      background: 'blue',

    },

    '& .video-react': {
      // width: '95%',
      maxWidth: 600,
      maxHeight: 600,
      minHeight: 600,
      display: 'flex',
      alignItems: 'center',
      // minHeight: 'auto',
      // [theme.breakpoints.down('sm')]: {
      //     maxWidth: 400,
      //     maxHeight: 400,
      // },
      // margin: '0 auto',
      // height: '80vh',
      // maxWidth: '95%',
      // width: 'auto',
      // maxWidth: 750,
      // maxHeight: '70vh',
      '& video': {
        outline: 'none',
        position: 'relative',
        objectFit: 'inherit'
      },
      '&  .video-react-big-play-button': {
        top: 'calc(50% - 22px)',
        left: 'calc(50% - 22px)',
        width: '1.5em',
        borderRadius: '0.8em'
      }
    }
  },
  videoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#828282",
    position: "relative",
    textAlign: "center",
    color: "#fff",
    overflow: 'hidden',
    "&:hover": {
      cursor: "pointer"
    },
    '& button': {
      position: 'absolute'
    },
    "& img": {
      maxWidth: 'initial',
      [theme.breakpoints.down("xs")]: {
        maxWidth: '100% !important'
      }
    }
  },
  avatarStyles: {
    width: 32,
    height: 32,
    fontSize: 16
  },
  paperRoot: {
    borderBottom: "none",
    borderRadius: "0 0 4px 4px"
  },
  playIcon: {
    color: '#fff'
  },
  attachmentCount: {
    position: 'absolute',
    top: 0,
    color: '#FFFFFF',
    backgroundColor: '#00000080',
    opacity: 0.8,
    fontSize: 60,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  featuredBadge: {
    backgroundColor: '#585963',
    borderRadius: 5,
    color: '#FFFFFF',
    margin: '4px 10px 0',
    padding: '2px 4px',
    fontSize: 13,
    width: 'fit-content'
  },
  reactionsPopper: {
    top: '15px !important'
  },
  reactionsTooltip: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid #b0b0b04d',
    maxWidth: 'initial'
  },
  reactionsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reactionElement: {
    margin: '5px 10px',
    cursor: 'pointer',
    textAlign: 'center',
    '& svg': {
      width: 24,
      height: 24
    },
    '& p': {
      margin: 0,
      textTransform: 'capitalize'
    }
  },
  reactionElementCount: {
    margin: 5,
    textAlign: 'center',
    '& svg': {
      width: 20,
      height: 20
    },
  },

  // skeleton styles
  skeletonRoot: {
    "& .card": {
      // marginTop: 20,
      marginBottom: 10,
      boxShadow: "none",
      borderRadius: 0,
      "&:nth-child(1)": {
        borderRadius: "6px 6px 0px 0px"
      }
    }
  },
  mediaRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px"
  },
  media: {
    height: 250,
    // width: "49%"
    width: "100%"
  },
  skeletonActionRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    '& .leftRoot': {
      display: "flex",
      justifyContent: "space-between",
      width: '20%',
      '& span': {
        width: '40%'
      }
    }
  },
  skeletonAction: {
    height: 25,
    width: "8%",
    borderRadius: 15
  },
  emojiPicker: {
    position: "absolute",
    bottom: 60,
    padding: "0 15px",
    zIndex: 1,
    "& .emoji-mart": {
      width: "auto !important"
    },
    "& .emoji-mart-scroll": {
      // transition: '0.5s ease-in-out',
      height: 170
      // '&:hover': {
      //     height: 300
      // }
    }
  },
  input: {
    width: "90%",
    marginLeft: theme.spacing(1),
    fontSize: 14,
    flex: 1,
    color: "inherit",
    background: "inherit",
    border: "none",
    fontFamily: "inherit",
    resize: "none",
    "&:focus": {
      outline: "none"
    }
  },
  comments: {
    borderRadius: "10px",
    background: "#EEEEEE",
    marginLeft: "10px",
    wordBreak: "break-word",
    color: "#535353",
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "11px",
    paddingRight: "25px",
    whiteSpace: 'pre-line'
  },
  w100: {
    width: "100%",
  },
  commentsPlaceholder: {
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    background: "#EEEEEE",
    width: "100%",
    marginLeft: "10px",
    border: "solid 1px #b4b4b4",
    minHeight: "40px"
  },
  iconButtonEmoji: {
    padding: 8
  },
  avatarRoot1: {
    display: "flex",
    alignItems: "center",
    padding: "5px 15px",
    fontSize: 13
  },
  avatarImageAlign: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px"
  },
  totalComments: {
    display: "flex",
    justifyContent: "space-between",
    padding: '10px 15px 10px'
  },
  scrollable: {
    maxHeight: "520px",
    overflow: "auto",
  },
  pl0: {
    paddingLeft: "0px"
  },
  commentAlign: {
    alignItems: "end",
    fontSize: 13,
    padding: "5px",
  },
  commentStyle: {
    fontSize: "12px",
    color: "#717171"
  },
  viewComments: {
    fontSize: "14px",
    color: "#535353"
  },
  totalCount: {
    fontSize: "14px",
    lineHeight: "1.36",
    color: "#535353"
  },
  commentedBy: {
    textTransform: "capitalize",
    marginRight: "5px",
    fontWeight: "bold",
    cursor: 'pointer'
  },
  // end of skeleton styles

  // Replies Section
  replyRoot: {
    display: 'flex',
    padding: 10
  },
  tagsRoot: {
    margin: '0 15px 10px',
    fontSize: 13,
    color: '#535353'
  },
  tags: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  emptyResultText: {
    textAlign: 'center',
    color: '#535353cc',
    fontSize: 15,
    fontWeight: 600,
    margin: ' 50px',
    lineHeight: '25px'
  },
  parentPostAction: {
    margin: 15,
    border: '1px solid #dbdbdb',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      opacity: 0.7
    }
  },
  backgroundContentRoot: {
    padding: '70px 20px',
    textAlign: 'center',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: 22,
      fontWeight: 600,
      wordBreak: 'break-word',
      lineHeight: '35px',
      whiteSpace: 'pre-wrap',
      '& a': {
        color: 'inherit'
      }
    }
  },
  likeComment: {
    paddingTop: 0,
    '& svg': {
      width: 18,
      height: 18
    }
  },
  likeCommentAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  commentDivider: {
    width: 1,
    margin: '0 20px',
    height: 15,
    backgroundColor: '#c1c1c1'
  },
  likeCommentRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 80
  },
  viewAllCommentsText: {
    fontSize: 14,
    color: '#8e8e8e',
    padding: '0px 16px 16px',
    cursor: 'pointer',
    margin: 0
  },
  filterTabsRoot: {
    position: 'fixed',
    top: 60,
    left: 0,
    backgroundColor: '#FFF',
    width: '100%',
    maxWidth: '100%',
    minHeight: 45,
    zIndex: 11,
    transition: 'top 0.2s ease-in-out',
  },
  filterTab: {
    minHeight: 45,
    textTransform: 'capitalize',
    color: '#262626 !important',
    minWidth: 'auto',
    margin: '0px 12px',
    padding: '6px 2px',
    [theme.breakpoints.up("sm")]: {
      // minWidth: 125
    }
  },
  tagsIconStyles: {
    position: 'absolute',
    bottom: 26,
    left: 16,
    padding: 5,
    backgroundColor: '#4d4d4fa1'
  },
  pdfIconStyles: {
    position: 'absolute',
    bottom: 26,
    right: 16,
    padding: 3,
    backgroundColor: '#4d4d4fa1',
    '& svg': {
      fill: '#FFF'
    }
  },
  flexContainerStyles: {
    // justifyContent: 'center',
    marginLeft: 34
  },
  pollCard: {
    backgroundColor: '#FFF',
    border: '1px solid #e0dce0',
    borderRadius: 6,
    padding: 16,
    textAlign: 'left',
    '& p': {
      fontSize: 15,
      marginTop: 0,
    },
    '& button': {
      width: '100%',
      borderRadius: 18,
      marginBottom: 15,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main} !important`,
      fontWeight: 600,
      fontSize: 13
    },
    '& .votesRoot': {
      '& span': {
        color: '#0000008a',
        fontSize: 12,
      },
      '& svg': {
        fontSize: 10,
        color: '#00000057',
        margin: '0 10px',
        verticalAlign: 'middle'
      }
    },
    '& .responseView': {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      color: '#262626',
      padding: 6,
      borderRadius: 6
    }
  }
}));

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 30,
  height: 30,
  cursor: 'pointer',
  padding: 0
};

export const ArenaBasicDetailsTemplate = ({ arena, index, handlePollResponse, history, handlePreview, handleArenaMoreMenuOpen, user = {}, handleShareMenuOpen, toggleCommentsSection, sendReaction, disableActions = false, openTaggedUsersList, openReactionsDialog, setOpenPDF }) => {
  const classes = useStyles();
  const { background } = arena;
  let allRefs = [];
  useEffect(() => {
    let scrollElement = document.getElementById("arena-list-root");
    let videoEl = document.getElementById(`player-${index}`);
    let filterTabsEl = document.getElementById('filter-tabs');
    let prevScrollTop = 0;
    scrollElement.addEventListener("scroll", () => {
      // console.log('scrollElement ', scrollElement.scrollHeight, scrollElement.scrollTop,);
      if (filterTabsEl) {
        if (prevScrollTop > scrollElement.scrollTop) {
          // console.log('scrollElement ', 'Going Top');
          filterTabsEl.style.top = '60px';
        }
        else {
          // console.log('scrollElement ', 'Going Down');
          filterTabsEl.style.top = 0;
        }
      }
      prevScrollTop = scrollElement.scrollTop;
      if (videoEl && allRefs.length > 0 && isInViewport(videoEl, index)) {
        allRefs.forEach(element => { if (element) element.pause(); });
      }
    });

    return () => {
      allRefs = [];
      scrollElement.removeEventListener('scroll', () => { });
    }
  }, []);

  const handlePreviewPost = (...args) => {
    handleChange();
    handlePreview(...args);
  }

  const handleChange = (index, e) => {
    // player.current.pause();
    // console.log('handleChange ', index, e, allRefs);
    allRefs.forEach(element => {
      if (element)
        element.pause();
    });
  }

  const handleTagsClick = (postId) => {
    openTaggedUsersList({ open: true, postId })
  }

  const handleLikesClick = (postId, entityType) => {
    openReactionsDialog({ open: true, postId, type: 'ALL', entityType })
  }

  const resultArena = arena.parentPostId ? arena.parentPost : arena;
  return (
    <>
      {
        arena.parentPostId > 0 &&
        <AreanSharedPostHeader
          name={arena.sharedby.name}
          username={arena.sharedby.userName}
          timestamp={arena.createdAt}
          showMoreButton={arena.sharedby.userName === user.username}
          handleMoreClick={(e) => handleArenaMoreMenuOpen(e, arena, 'sharedBy')}
        />
      }
      <ArenaPostHeader
        name={arena.createdBy.name}
        username={arena.createdBy.userName}
        avatarUrl={arena.createdBy.photoUrl}
        onProfileClick={() => history.push(`/wall/profile/${arena.createdBy.userName}`)}
        follower={arena.follower}
        featured={arena.groups?.includes('FEATURED') || false}
        learningHub={arena.groups?.includes('LEARNING') || false}
        timestamp={arena.parentPostId > 0 ? arena.parentCreatedAt : arena.createdAt}
        showMoreButton={true}
        handleMoreClick={(e) => handleArenaMoreMenuOpen(e, arena, 'createdBy')}
      />
      <>
        <div className={classes.attachmentsRoot}>
          {arena.attachments &&
            // arena.attachments.slice(0, (arena.attachments.length < 4) ? 1 : 1).map((item, i, array) => (
            <div className={classes.attachment}
            // style={{
            //   width: '100%',
            //   margin: '0 auto',
            //   height: 'auto',
            //   maxHeight: 600,
            // }}
            // onClick={() => handlePreview(arena, index, i)
            >
              <Carousel
                // selectedItem={0}
                // infiniteLoop={true}
                // swipeable={false}
                // transitionTime={25}
                // dynamicHeight
                autoPlay={false}
                showStatus={false}
                showThumbs={false}
                useKeyboardArrows={true}
                showIndicators={arena.attachments && arena.attachments.length > 1}
                onChange={(e) => handleChange(e)}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <IconButton disableTouchRipple onClick={onClickHandler} style={{ ...arrowStyles, left: 15 }} >
                      <ArrowLeft />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <IconButton disableTouchRipple onClick={onClickHandler} style={{ ...arrowStyles, right: 15 }} >
                      <ArrowRight />
                    </IconButton>
                  )
                }
              >
                {arena.attachments.map((item, i) => (
                  <div key={`posts-${item.postId}`} id="list-child">
                    {
                      item.mimeType === 'IMAGE' ?
                        <img alt="" src={item.attachmentUrl || item.url} />
                        :
                        item.mimeType === 'LINK' ?
                          <div id={`linkpreview-${index}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LinkPreview url={item.attachmentUrl} fallbackImageSrc={noPreview} width='430px' />
                          </div>
                          :
                          item.mimeType === 'PDF' ?
                            <div>
                              <PDFViewer file={item.attachmentUrl} fromCard={true} />
                              {!disableActions && <IconButton title="Full Screen" className={classes.pdfIconStyles} onClick={() => setOpenPDF({ open: true, file: item.attachmentUrl, postedBy: arena.createdBy.name })} >
                                <Fullscreen />
                              </IconButton>}
                            </div>
                            :
                            item.mimeType === 'SURVEY' ?
                              <div style={{ padding: 15 }}>
                                <div className={classes.pollCard}>
                                  <p>{item.pollDetails?.question?.question_title}</p>
                                  {item.pollDetails?.question?.options.map(option => (
                                    <>
                                      {
                                        (item.pollDetails?.responded || getPollDuration(item.pollDetails?.expire_date) === 'Poll closed') ?
                                          <p className="responseView" style={{ background: `linear-gradient(to right, #f0f2f5 ${option.percentage}%, white 0%)` }}>{option.option} <span style={{ display: 'flex' }}>{(item.pollDetails?.question?.my_option === option.option) && <CheckCircle style={{ marginRight: 10, color: '#262626a1' }} />}{getNumberFormat(user?.countryCode, option.percentage)}%</span></p>
                                          : <Button disabled={getPollDuration(item.pollDetails?.expire_date) === 'Poll closed'} variant="outlined" color="primary" onClick={() => handlePollResponse(option.option, item.pollDetails)}>
                                            {option.option}
                                          </Button>

                                      }
                                    </>
                                  ))}
                                  <span className="votesRoot"><span style={{ marginLeft: (arena.tagscount > 0) && 40 }}>{item.pollDetails?.question?.total_responded_count} votes</span><FiberManualRecord /><span>{getPollDuration(item.pollDetails?.expire_date)}</span></span>
                                </div>
                              </div>
                              :
                              <div id={`player-${index}`}>
                                <Player
                                  poster={item.thumbnailUrl ? item.thumbnailUrl : false}
                                  ref={ref => allRefs = [...allRefs, ref]}
                                  fluid={false}
                                  width={'auto'}
                                  // autoPlay={arena.attachments.length === 1}
                                  autoPlay={false}
                                >
                                  <source src={item.attachmentUrl || item.url} />
                                  {/* <ControlBar autoHide /> */}
                                  <Shortcut shortcuts={[
                                    {
                                      keyCode: 32, // spacebar
                                      handle: function () { } // a function that does nothing
                                    },
                                    {
                                      keyCode: 37, // left arrow
                                      handle: function () { } // a function that does nothing
                                    },
                                    {
                                      keyCode: 39, // right arrow
                                      handle: function () { } // a function that does nothing
                                    },
                                  ]} />
                                </Player>
                              </div>
                    }
                  </div>
                ))}
              </Carousel>
              {
                (arena.tagscount > 0) &&
                <IconButton className={classes.tagsIconStyles} style={(arena.attachments && arena.attachments[0]?.mimeType === 'SURVEY') ? { bottom: 30, left: 30 } : (arena.attachments && arena.attachments[0]?.mimeType === 'LINK') ? { bottom: 0, left: 15 } : {}} onClick={() => handleTagsClick(arena.parentPostId ? arena.parentPostId : arena.postId)} >
                  <TagsIcons />
                </IconButton>}
            </div>
            // ))
          }
        </div>
        {
          !disableActions &&
          <>
            <ArenaActions
              isLiked={arena.liked}
              onLikeClick={() => sendReaction(arena.parentPostId ? arena.parentPostId : arena.postId, index, 'like')}
              onCommentClick={() => handlePreviewPost(arena, index)}
              onShareClick={(e) => handleShareMenuOpen(e, arena)}
            />
            {arena.likes > 0 &&
              <ArenaLikes
                firstLike={arena.firstLike ? { ...arena.firstLike, username: arena.firstLike && arena.firstLike.userName } : { name: user.name, photoUrl: user.photoUrl, username: user.username }}
                likesCount={arena.likes}
                onLikesClick={() => handleLikesClick(arena.parentPostId ? arena.parentPostId : arena.postId, 'POST')}
              />
            }
            {
              arena.text &&
              <ArenaText text={arena.text} username={arena.createdBy.userName} name={arena.createdBy.name} index={index} seeMoreCharLimit={seeMoreCharLimit} />
            }
            {
              arena.commentsCount > 0 &&
              <p className={classes.viewAllCommentsText} onClick={() => handlePreviewPost(arena, index)}>{arena.commentsCount === 1 ? `View comments` : `View all ${arena.commentsCount} comments`}</p>
            }
            <ArenaCommentInput style={{ cursor: 'pointer' }} onClick={() => handlePreviewPost(arena, index)} autoFocus={false} readOnly={true} withAvatar={true} />
          </>
        }
      </>
    </>
  )
}

const ArenaTemplate = ({ arena, index, history, handlePreview, handleArenaMoreMenuOpen, user = {},
  handleReactionsView, toggleCommentsSection, handleFavouriteShare, reactions, handleShareMenuOpen,
  handleKeyDown, handleKeyPress, setCommentText, handleShowEmojis, showEmojis, handleClickAway,
  addEmoji, handleCommentLike, handleReplyToComment, setCommentReplyText, handleShowReplyEmojis,
  showReplyEmojis, handleReplyClickAway, addReplyEmoji, commentsSkeletonLoading, viewMoreComments,
  sendReaction, handlereplyLike
}) => {
  const classes = useStyles();
  return (
    <>
      <ArenaBasicDetailsTemplate arena={arena} index={index} history={history} handlePreview={handlePreview} handleArenaMoreMenuOpen={handleArenaMoreMenuOpen} user={user} toggleCommentsSection={toggleCommentsSection} handleShareMenuOpen={handleShareMenuOpen} />
    </>
  )
}

moment.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    ss: '%d s',
    m: "a minute",
    mm: "%d m",
    h: "an hour",
    hh: "%d h",
    d: "a day",
    dd: "%d d",
    w: "a week",
    ww: "%d w",
    M: "a month",
    MM: "%d m",
    y: "a year",
    yy: "%d y"
  }
});

const reactionsList = [
  { label: 'like', iconElement: <LikeIcon /> },
  { label: 'applause', iconElement: <ApplauseIcon /> },
  { label: 'support', iconElement: <SupportIcon /> },
  { label: 'insightful', iconElement: <InsightfulIcon /> },
  { label: 'surprised', iconElement: <SurprisedIcon /> },
  { label: 'curious', iconElement: <CuriousIcon /> },
]
const seeMoreCharLimit = 500;
let pagination = {
  page: 0,
  limit: 10
};
let lastPage = 0;
let emojiPicker = null;
let reactionIndex = null;
let commentReactionIndex = null
let replyreactionIndex = null

const filtersList = [{ id: 'ALL', name: 'All', path: 'ALL' }, { id: 'FEATURED', name: 'Featured', path: 'FEATURED' }, { id: 'FAVOURITES', name: 'Following', path: 'FOLLOWING' }, { id: 'LEARNING', name: 'LearnHub', path: 'LEARNING' }]
const ArenaList = props => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [arenaList, setArenaList] = useState([]);
  const [pollsList, setPollsList] = useState([]);
  const [filter, setFilter] = useState("ALL");
  const [showEmojis, setShowEmojis] = useState(false);
  const [showReplyEmojis, setShowReplyEmojis] = useState(false);
  const [openPDF, setOpenPDF] = useState({ open: false, file: '' });
  const [preview, setPreview] = useState({
    open: false,
    arena: null
  });
  const [shareAnchorEl, setShareAnchorEl] = React.useState({
    el: null,
    arena: null
  });
  const [arenaAnchorEl, setArenaAnchorEl] = React.useState({
    el: null,
    arena: null,
    type: ''
  });
  const [usersList, setUsersList] = useState([]);

  // const [deletestate, setDeleteState] = React.useState({});
  const isShareMenuOpen = Boolean(shareAnchorEl.el);
  const isArenaMoreMenuOpen = Boolean(arenaAnchorEl.el);
  const [openConfirmationDialog, setConfirmationDialog] = useState({ open: false, postId: null })
  // const [deletearenaPostId, setDeleteArenPostId] = useState({})

  useEffect(() => {
    if (props.location.state && props.location.state.hasNewPost) {
      // console.log('ArenaList 123 ', props);
      props.history.replace('/wall', null);
      setArenaList([]);
      setLoading(true);
      props.getArenaList({
        params: { limit: pagination.limit, page: 0, username: props.username, category: filter === 'ALL' ? '' : filter },
        pollParams: { limit: 1000, page: 1 },
        onSuccessCB,
        onErrorCB
      });
    }
  }, [props.location.state]);

  useEffect(() => {
    // console.log('useEffect arenaList ', props);
    setArenaList([]);
    setPollsList([]);
    if (!props.newPost && !props.fromSearch && !props.fromIndividual) {
      setLoading(true);
      props.getArenaList({
        params: { limit: pagination.limit, page: 0, username: props.username, category: filter === 'ALL' ? '' : filter },
        pollParams: { limit: 1000, page: 1 },
        onSuccessCB,
        onErrorCB
      });
    }
  }, [filter, props.username]);

  useEffect(() => {
    if (props.newPost && !props.fromSearch && !props.fromIndividual) {
      setLoading(true);
      props.getArenaList({
        params: { limit: pagination.limit, page: 0, username: props.username, category: filter === 'ALL' ? '' : filter },
        pollParams: { limit: 1000, page: 1 },
        onSuccessCB,
        onErrorCB
      });
      props.handleOnNewPost(null);
    }
    else if (props.newPost && props.fromSearch) {
      searchArenaList();
      props.handleOnNewPost(null);
    }
  }, [props.newPost]);

  useEffect(() => {
    if (props.fromSearch && props.query && !props.fromIndividual) {
      searchArenaList();
    }
    else {
      setArenaList([]);
    }
  }, [props.query]);


  useEffect(() => {
    if (props.postId && props.fromIndividual) {
      setLoading(true);
      props.getPostByPostId({
        postId: props.postId,
        onSuccessGetPostId, onErrorGetPostId
      });
    }
    else {
      setArenaList([]);
    }
  }, [props.fromIndividual]);

  useEffect(() => {
    let scrollElement = document.getElementById('arena-list-root');
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    props.getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
  }, []);

  const onGetContactsSuccessCB = (result) => {
    const { tokenParsed: { preferred_username, realm_access } } = props.keycloak;
    setLoading(false);
    const filteredResult = result.filter(item => item.username !== preferred_username).map(obj => ({ display: obj.name, id: obj.username }));
    setUsersList(filteredResult);
  }
  const onGetContactsErrorCB = (error) => {
    setUsersList([]);
    enqueueSnackbar(`Failed to fetch Chaseons.`, {
      variant: 'error',
      preventDuplicate: true
    });
    setLoading(false);
  }

  const searchArenaList = () => {
    setLoading(true);
    props.searchArenaChaseonsOrPosts({
      params: {
        limit: pagination.limit, page: 0,
        q: props.query,
        type: "POSTS"
      },
      pollParams: { limit: 1000, page: 1 },
      onSearchSuccessCB: onSuccessCB,
      onSearchErrorCB: onErrorCB
    });
  }

  const isAdmin = props.keycloak.decoded_token.realm_access.roles &&
    props.keycloak.decoded_token.realm_access.roles.length > 0 &&
    props.keycloak.decoded_token.realm_access.roles.includes('admin');

  const groupByValue = getSearchValue(props.history, 'viewBy');
  useEffect(() => {
    if (groupByValue) {
      setFilter(filtersList.find(obj => obj.path === groupByValue)?.id);
    }
  }, [groupByValue]);

  // useEffect(() => {
  //   if (deletestate.arena) {
  //     props.deletePost({ postId: deletestate.arena.postId, onDeletePostError, onSuccessDeletePost })
  //   }

  // }, [deletestate])

  const fetchMoreData = e => {
    if (props.fromSearch) {
      props.searchArenaChaseonsOrPosts({
        params: {
          limit: pagination.limit, page: pagination.page + 1,
          q: props.query,
          type: "POSTS"
        },
        pollParams: { limit: 1000, page: 1 },
        onSearchSuccessCB: onSuccessCB,
        onSearchErrorCB: onErrorCB
      });
    }
    else {
      props.getArenaList({
        params: { limit: pagination.limit, page: pagination.page + 1, username: props.username, category: filter === 'ALL' ? '' : filter },
        onSuccessCB,
        onErrorCB
      });
    }
  };

  const resultWithComments = result => {
    return result.map(res => {
      return {
        ...res,
        isCommentBoxOpen: false,
        comment: "",
        isCommentLoading: false,
        isCommentsAPICalled: false,
        commentsList: [],
        commentPagination: {
          page: 0,
          totalCount: 0,
          isLoading: false
        }
      };
    });
  };

  const onSuccessGetPostId = (result) => {
    if (result) {
      setArenaList(resultWithComments([{ ...result.data }]));
      setLoading(false)
    }
  }
  const onErrorGetPostId = (error) => {
    console.log("error.response", error.response);
    if (error.response && error.response.status !== 404) {
      enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Share Post.`, {
        variant: 'error',
        preventDuplicate: true
      });
    }
    setLoading(false)
  }
  const onSuccessCB = (result, headers, pollResult = [...pollsList], pollHeaders) => {
    setPollsList(pollResult);
    pagination.page = parseInt(headers.page);
    pagination.total_count = parseInt(headers.total_count);
    const resultData = result.map(obj => ({ ...obj, attachments: obj.attachments?.map(obj => ({ ...obj, pollDetails: (obj.mimeType === 'SURVEY') ? pollResult.find(poll => (poll._id === obj.attachmentUrl)) : null })) }));
    // console.log("headers ", resultData);
    if (parseInt(headers.page) === 0) {
      setArenaList(resultWithComments(resultData));
    } else {
      setArenaList(resultWithComments([...arenaList, ...resultData]));
    }
    setLoading(false);
  };

  const onErrorCB = error => {
    console.log(error);
    enqueueSnackbar(`Failed to fetch Arena list.`, {
      variant: "error",
      preventDuplicate: true
    });
    setLoading(false);
  };

  const onSuccessDeletePost = (result) => {
    enqueueSnackbar(`Post Deleted Successfully.`, {
      variant: "success",
      preventDuplicate: true
    });
    setArenaList([]);
    setLoading(true)
    if (props.fromSearch) {
      searchArenaList();
    }
    else if (props.fromIndividual) {
      props.history.push('/wall');
    }
    else {
      props.getArenaList({
        params: { limit: pagination.limit, page: 0, username: props.username, category: filter === 'ALL' ? '' : filter },
        onSuccessCB,
        onErrorCB
      });
    }
  };

  const onDeletePostError = error => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to delete post.`, {
      variant: "error",
      preventDuplicate: true
    });

    setLoading(false);
  }
  const handleFilterClick = value => {
    if (filter !== value) setFilter(value);
  };

  const toggleCommentsSection = index => {
    if (!arenaList[index].isCommentsAPICalled) {
      props.getArenaCommentsList({
        params: {
          limit: COMMENT_LIST_LIMIT,
          page: 0,
          postId: arenaList[index].postId,
          isSingleComment: false
        },
        onCommentListSuccess,
        onCommentListError
      });
    }
    const newArenaList = arenaList.map((arena, i) => {
      if (index === i) {
        arena.commentPagination.isLoading = !arena.isCommentsAPICalled;
        arena.isCommentBoxOpen = !arena.isCommentBoxOpen;
        arena.isCommentsAPICalled = true;
      }
      return arena;
    });
    setArenaList(newArenaList);
  };
  const handleFavouriteShare = (postId) => {
    props.openSharesListDialog({ open: true, postId: postId })
  }

  const handleReplyToComment = (index, commentIndex) => {
    if (!arenaList[index].commentsList[commentIndex].isRepliesAPICalled) {
      props.getCommentReplies({
        params: {
          limit: 100,
          page: 0,
          postIndex: index,
          commentIndex,
          postId: arenaList[index].postId,
          commentId: arenaList[index].commentsList[commentIndex].commentId,
          isSingleComment: false
        },
        onCommentRepliesSuccess,
        onCommentRepliesError
      })
    }
    else {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[index].commentsList[commentIndex].isReplySectionOpen = !arenaListDup[index].commentsList[commentIndex].isReplySectionOpen;
      // console.log('handleReplyToComment after ', arenaListDup[index].commentsList[commentIndex]);
      setArenaList(arenaListDup);
    }
  }

  const onCommentRepliesSuccess = (result) => {
    const { postIndex, commentIndex, repliesList } = result;
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    arenaListDup[postIndex].commentsList[commentIndex].repliesList = repliesList;
    arenaListDup[postIndex].commentsList[commentIndex].isReplySectionOpen = !arenaListDup[postIndex].commentsList[commentIndex].isReplySectionOpen;
    arenaListDup[postIndex].commentsList[commentIndex].isRepliesAPICalled = true;

    setArenaList(arenaListDup);
  }

  const onCommentRepliesError = (error) => {
    console.log('onCommentRepliesError ', error);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Replies.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }
  const handlereplyLike = (commentId, replyIndex, type = "Like", postId, arenaIndex, commentIndex) => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    reactionIndex = arenaIndex;
    commentReactionIndex = commentIndex
    replyreactionIndex = replyIndex
    if (arenaListDup[arenaIndex].postId === postId && arenaListDup[arenaIndex].commentsList.length > 0 && arenaListDup[arenaIndex].commentsList[commentIndex].repliesList.length > 0) {
      if (arenaListDup[arenaIndex].commentsList[commentIndex].repliesList[replyIndex].currentUserReaction === type.toUpperCase()) {
        const commentLike = "replyLike"
        props.deleteReaction({ entityId: commentId, entityType: 'COMMENT', commentLike, onDeleteReactionSuccessCB, onDeleteReactionErrorCB })
      }
      else {
        const requestObj = {
          entity_id: commentId,
          entity_Type: "COMMENT",
          reaction: type.toUpperCase()
        }
        const commentLike = "replyLike"
        props.sendReaction({ requestObj, commentLike, onSendReactionSuccessCB, onSendReactionErrorCB })
      }
    }
  }

  const handleNewComment = (postId, postIndex) => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    arenaListDup[postIndex].commentsCount += 1;
    setArenaList(arenaListDup);
  }

  const onCommentReplySuccess = (requestObj, postIndex, commentIndex, commentId) => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    arenaListDup[postIndex].commentsList[commentIndex].replyText = "";
    arenaListDup[postIndex].commentsList[commentIndex].repliesCount += 1;
    arenaListDup[postIndex].commentsCount += 1;
    arenaListDup[postIndex].commentsList[commentIndex].repliesList = [{ comment: requestObj.comment, commentedBy: props.user.name, photoUrl: props.user.photoUrl, created_at: new Date(), commentId }, ...arenaListDup[postIndex].commentsList[commentIndex].repliesList];
    setArenaList(arenaListDup);
  }

  const onCommentReplyError = (error) => {
    console.log('onCommentReplyError ', error);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to send reply.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const handleShowEmojis = e => {
    setShowEmojis(true);
  };

  const addEmoji = (e, index) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach(el => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    // this.setState({
    //     text: this.state.text + emoji
    // })
    setCommentText(arenaList[index].comment + emoji, index);
  };

  const handleClickAway = e => {
    setShowEmojis(false);
  };

  const handleShowReplyEmojis = () => {
    setShowReplyEmojis(true);
  }

  const handleReplyClickAway = e => {
    setShowReplyEmojis(false);
  };

  const addReplyEmoji = (e, postIndex, commentIndex) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach(el => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    // this.setState({
    //     text: this.state.text + emoji
    // })

    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    arenaListDup[postIndex].commentsList[commentIndex].replyText = arenaListDup[postIndex].commentsList[commentIndex].replyText ? (arenaListDup[postIndex].commentsList[commentIndex].replyText += emoji) : emoji;
    setArenaList(arenaListDup);
  };


  const setCommentReplyText = (text, postIndex, commentIndex) => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    arenaListDup[postIndex].commentsList[commentIndex].replyText = text;
    setArenaList(arenaListDup);
  };

  const viewMoreComments = (postId, index) => {
    props.getArenaCommentsList({
      params: {
        limit: COMMENT_LIST_LIMIT,
        page: arenaList[index].commentPagination.page + 1,
        postId: postId,
        isSingleComment: false
      },
      onCommentListSuccess,
      onCommentListError
    });
    const list = arenaList.map(arena => {
      if (arena.postId === postId) {
        arena.commentPagination = {
          ...arena.commentPagination,
          isLoading: true
        }
      }
      return arena;
    });
    setArenaList(list);
  };

  const onCommentError = error => {
    console.log(error);
  };

  const onCommentSuccess = success => {
    const { postId } = success;
    const list = arenaList.map(arena => {
      if (arena.postId === postId) {
        arena.comment = "";
        arena.commentsCount += 1;
      }
      return arena;
    });
    setArenaList(list);

    props.getArenaCommentsList({
      params: { limit: 1, page: 0, postId, isSingleComment: true },
      onCommentListSuccess,
      onCommentListError
    });
  };

  const setCommentText = (text, index) => {
    const list = arenaList.map((arena, i) => {
      if (index === i) {
        arena.comment = text;
      }
      return arena;
    });
    setArenaList(list);
  };

  const onCommentListError = error => {
    console.log(error);
  };

  const onCommentListSuccess = (success, headers) => {
    const { postId, commentsList, isSingleComment } = success;
    const list = arenaList.map(arena => {
      if (arena.postId === postId) {
        if (isSingleComment) {
          arena.commentsList = [...commentsList, ...arena.commentsList];
          arena.commentPagination = {
            ...arena.commentPagination,
            totalCount: parseInt(headers.total_count),
            isLoading: false
          };
        } else {
          arena.commentsList = [...arena.commentsList, ...commentsList];
          arena.commentPagination = {
            page: parseInt(headers.page),
            totalCount: parseInt(headers.total_count),
            isLoading: false
          };
        }
      }
      return arena;
    });
    setArenaList(list);
  };

  const handlePreview = (arenaObj, index, i) => {
    setPreview({ open: true, arenaObj, attachmentIndex: i, postIndex: index });
  }

  const handlePreviewClose = () => {
    setPreview({ open: false, arenaObj: null });
  }

  const sendReaction = (postId, postIndex, type) => {

    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    reactionIndex = postIndex;
    if (arenaListDup[postIndex].liked) {
      props.deleteReaction({ entityId: postId, entityType: 'POST', onDeleteReactionSuccessCB, onDeleteReactionErrorCB })
    }
    else {
      const requestObj = {
        entity_id: postId,
        entity_Type: "POST",
        reaction: type.toUpperCase()
      }
      props.sendReaction({ requestObj, onSendReactionSuccessCB, onSendReactionErrorCB })
    }
  }

  const onSendReactionSuccessCB = (result, payload, commentLike) => {
    if (!commentLike) {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].liked = true;
      arenaListDup[reactionIndex].reactionTypes = result.reactionTypes;
      arenaListDup[reactionIndex].likes = result.reactionsCount;
      reactionIndex = null;
      setArenaList(arenaListDup);
    }
    else if (commentLike === "replyLike") {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].repliesList[replyreactionIndex].currentUserReaction = payload.reaction;
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].repliesList[replyreactionIndex].reactionCount = result.reactionsCount;
      reactionIndex = null;
      commentReactionIndex = null;
      replyreactionIndex = null
      setArenaList(arenaListDup);
    }
    else {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].currentUserReaction = payload.reaction
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].reactionCount = result.reactionsCount
      commentReactionIndex = null;
      reactionIndex = null;
      setArenaList(arenaListDup);
    }
  }

  const onSendReactionErrorCB = (error) => {
    reactionIndex = null;
    commentReactionIndex = null;
    replyreactionIndex = null
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to send Reaction.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onDeleteReactionSuccessCB = (result, payload, commentLike) => {
    if (!commentLike) {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].reactionTypes = result.reactionTypes;
      arenaListDup[reactionIndex].liked = false;
      arenaListDup[reactionIndex].likes = result.reactionsCount;
      reactionIndex = null;
      setArenaList(arenaListDup);
    }
    else if (commentLike === "replyLike") {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].repliesList[replyreactionIndex].currentUserReaction = null;
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].repliesList[replyreactionIndex].reactionCount = result.reactionsCount;
      reactionIndex = null;
      commentReactionIndex = null;
      replyreactionIndex = null
      setArenaList(arenaListDup);
    }
    else {
      let arenaListDup = JSON.parse(JSON.stringify(arenaList));
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].currentUserReaction = null;
      arenaListDup[reactionIndex].commentsList[commentReactionIndex].reactionCount = result.reactionsCount
      commentReactionIndex = null;
      reactionIndex = null;
      setArenaList(arenaListDup);
    }
  }

  const onDeleteReactionErrorCB = (error) => {
    console.log('onDeleteReactionErrorCB ', error);
    reactionIndex = null;
    commentReactionIndex = null;
    replyreactionIndex = null
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to remove Reaction.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const reactions = (postId, postIndex) => (
    <div className={classes.reactionsRoot}>
      {reactionsList.map((item, index) => (
        <div key={index} className={classes.reactionElement} onClick={() => sendReaction(postId, postIndex, item.label)}>
          {item.iconElement}
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  )
  const handleCommentLike = (commentId, commentIndex, type = "Like", postId, arenaIndex) => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    reactionIndex = arenaIndex;
    commentReactionIndex = commentIndex
    if (arenaListDup[arenaIndex].postId === postId && arenaListDup[arenaIndex].commentsList.length > 0) {
      if (arenaListDup[arenaIndex].commentsList[commentIndex].currentUserReaction === type.toUpperCase()) {
        const commentLike = "commentLike"
        props.deleteReaction({ entityId: commentId, entityType: 'COMMENT', commentLike, onDeleteReactionSuccessCB, onDeleteReactionErrorCB })
      }
      else {
        const requestObj = {
          entity_id: commentId,
          entity_Type: "COMMENT",
          reaction: type.toUpperCase()
        }
        const commentLike = "commentLike"
        props.sendReaction({ requestObj, commentLike, onSendReactionSuccessCB, onSendReactionErrorCB })
      }
    }


  }
  const handleReactionsView = (postId) => {
    // props.openReactionsDialog({ open: true, postId, type: 'ALL' });
  }

  const skeletonLoading = (
    <div className={classes.skeletonRoot}>
      <Card className="card">
        <CardHeader
          avatar={<Skeleton variant="circle" width={40} height={40} />}
          action={null}
          title={
            <Skeleton height={12} width="40%" style={{ marginBottom: 6 }} />
          }
          subheader={<Skeleton height={10} width="20%" />}
        />
        <div className={classes.mediaRoot}>
          <Skeleton variant="rect" className={classes.media} />
          {/* <Skeleton variant="rect" className={classes.media} /> */}
        </div>
        <div className={classes.skeletonActionRoot}>
          <div className="leftRoot">
            <Skeleton variant="rect" className={classes.skeletonAction} />
            <Skeleton variant="rect" className={classes.skeletonAction} />
          </div>
          <Skeleton variant="rect" className={classes.skeletonAction} />
        </div>
        <CardContent>
          {
            <>
              <Skeleton height={10} style={{ marginBottom: 6 }} />
              <Skeleton height={10} style={{ marginBottom: 6 }} />
              <Skeleton height={10} width="70%" />
            </>
          }
        </CardContent>
        <Skeleton
          height={25}
          variant="rect"
          style={{ margin: "0px 16px 16px", borderRadius: 15 }}
        />
      </Card>
    </div>
  );

  const commentsSkeletonLoading = (
    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
      <Skeleton variant="circle" width={40} height={40} />
      <div style={{ marginLeft: 10, position: 'relative', width: '90%' }}>
        <Skeleton height={60} />
        <div style={{ width: "100%", position: 'absolute', display: 'flex' }}>
          <Skeleton style={{ marginRight: 25 }} height={10} width="15%" />
          <Skeleton style={{ marginRight: 25 }} height={10} width="15%" />
          <Skeleton style={{ marginRight: 25 }} height={10} width="15%" />
        </div>
      </div>
    </div>
  );

  const handleShareMenuOpen = (event, arena) => {
    setShareAnchorEl({ el: event.currentTarget, arena });
  };

  const handleShareMenuClose = () => {
    setShareAnchorEl({ el: null, arena: null });
  };

  const handleArenaMoreMenuOpen = (event, arena, type) => {
    // setDeleteArenPostId({ arena: arena })
    setArenaAnchorEl({ el: event.currentTarget, arena, type });
  };

  const handleArenaMoreMenuClose = () => {
    setArenaAnchorEl({ el: null, arena: null, type: '' })
  }

  const handleShare = (type) => {
    if (preview.open) handlePreviewClose();
    if (type === 'arena') {
      props.openShareToArenaDialog({ open: true, details: shareAnchorEl.arena });
    }
    else {
      props.openShareToChatDialog({ open: true, details: shareAnchorEl.arena })
    }
    handleShareMenuClose();
  }

  const renderShareOptions = (

    <Menu
      anchorEl={shareAnchorEl.el}
      classes={{ paper: classes.profilePopup }}
      id="share-options-menu"
      keepMounted
      open={isShareMenuOpen}
      onClose={handleShareMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      style={{ zIndex: 1301 }}
    >
      <MenuItem onClick={() => handleShare('arena')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-arena" />Share to Wall</MenuItem>
      <MenuItem onClick={() => handleShare('chat')} className={classes.shareOptionStyles} ><ReactChatLogo id="send-in-chat" />Send in chat</MenuItem>
    </Menu>
  );

  const handleConfirmationDialog = (value, text, postId) => {
    if (text === "yes") {
      // console.log("arenael", deletearenaPostId);
      props.deletePost({ postId, onDeletePostError, onSuccessDeletePost })
      setConfirmationDialog({ open: false, postId: null });
    }
    else {
      setConfirmationDialog({ open: value, postId })
    }
  }

  const handleArena = (type) => {
    if (preview.open) handlePreviewClose();
    switch (type) {
      case 'edit':
        if (arenaAnchorEl.arena && arenaAnchorEl.arena.parentPost)
          props.openShareToArenaDialog({ open: true, details: arenaAnchorEl.arena, isEdit: true });
        else
          props.openEditArenaDialog({ open: true, arena: arenaAnchorEl.arena });
        break;
      case 'delete':
        // handleArenaMoreMenuClose();
        handleConfirmationDialog(true, '', arenaAnchorEl.arena && arenaAnchorEl.arena.postId)
        break;

      default:
        break;
    }
    handleArenaMoreMenuClose();
  }

  const onFollowUserSuccess = result => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    const index = arenaListDup.findIndex(item => item.postId === arenaAnchorEl.arena.postId);
    if (arenaAnchorEl.type === 'sharedBy') {
      arenaListDup[index].sharedby.follower = true;
    }
    else {
      arenaListDup[index].follower = true;
    }
    setArenaList(arenaListDup);
    handleArenaMoreMenuClose();
  };
  const onFollowUserError = error => {
    handleArenaMoreMenuClose();
    console.log('onFollowUserError ', error.response);
  };

  const onUnFollowUserSuccess = result => {
    let arenaListDup = JSON.parse(JSON.stringify(arenaList));
    const index = arenaListDup.findIndex(item => item.postId === arenaAnchorEl.arena.postId);
    if (arenaAnchorEl.type === 'sharedBy') {
      arenaListDup[index].sharedby.follower = false;
    }
    else {
      arenaListDup[index].follower = false;
    }
    setArenaList(arenaListDup);
    handleArenaMoreMenuClose();
  }
  const onUnFollowUserError = error => {
    handleArenaMoreMenuClose();
    console.log('onUnFollowUserError ', error.response);
  }

  const handleClosePDFDialog = () => {
    setOpenPDF({ open: false, file: '' });
  }

  const renderArenaOptions = (
    <Menu
      anchorEl={arenaAnchorEl.el}
      classes={{ paper: classes.profilePopup }}
      id="arena-options-menu"
      keepMounted
      open={isArenaMoreMenuOpen}
      onClose={handleArenaMoreMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 1301 }}
    >
      {arenaAnchorEl.arena && (((arenaAnchorEl.type === 'sharedBy' && arenaAnchorEl.arena.sharedby.userName === props.user.username) || (arenaAnchorEl.type === 'createdBy' && arenaAnchorEl.arena.createdBy.userName === props.user.username) || isAdmin) ?
        <>
          {/* <MenuItem onClick={() => handleArena('edit')} className={classes.arenaMoreOptionStyles} ><EditIcon />Edit Post</MenuItem> */}
          <MenuItem onClick={() => handleArena('delete')} className={classes.arenaMoreOptionStyles} ><DeleteIcon />Delete Post</MenuItem>
        </>
        :
        [
          arenaAnchorEl.arena.follower ?
            <MenuItem onClick={() => props.unFollowUser({
              username: arenaAnchorEl.type === 'sharedBy' ? arenaAnchorEl.arena.sharedby.userName : arenaAnchorEl.arena.createdBy.userName,
              onUnFollowUserSuccess,
              onUnFollowUserError
            })} className={classes.arenaMoreOptionStyles} ><FollowingIcon />Unfollow</MenuItem>
            :
            <MenuItem onClick={() => props.followUser({
              requestObj: { username: arenaAnchorEl.type === 'sharedBy' ? arenaAnchorEl.arena.sharedby.userName : arenaAnchorEl.arena.createdBy.userName },
              onFollowUserSuccess,
              onFollowUserError
            })} className={classes.arenaMoreOptionStyles} ><FollowIcon />Follow</MenuItem>
        ]
      )}
    </Menu>
  )

  const handleFilterChange = (event, newValue) => {
    // console.log(newValue);
    if (filtersList[newValue])
      setFilter(filtersList[newValue].id);
    setSearchQuery(props.history, { viewBy: filtersList[newValue]?.path });
  }

  const handlePollResponse = (option, pollDetails) => {
    const requestObj = {
      answers: [{
        question_id: pollDetails?.question.question_id,
        question_type: "singleSelect",
        option: option
      }]
    }
    // console.log('pollDetails?.question ', requestObj, pollDetails);
    setLoading(true);
    props.sendSurveyResponse({ surveyId: pollDetails?._id, requestObj, onPostResponseSuccessCB, onPostResponseErrorCB });
  }
  const onPostResponseSuccessCB = () => {
    setPreview({ open: false, arena: null });
    props.getArenaList({
      params: { limit: pagination.limit, page: 0, username: props.username, category: filter === 'ALL' ? '' : filter },
      pollParams: { limit: 1000, page: 1 },
      onSuccessCB,
      onErrorCB
    });
  }
  const onPostResponseErrorCB = (error) => {
    setLoading(false);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Respond.`, {
      variant: 'error', preventDuplicate: true
    });
  }

  return (
    <div className={!props.fromIndividual ? classes.root : classes.rootdup}>
      {(!props.username && !props.fromSearch && !props.fromIndividual) &&
        <Tabs
          id="filter-tabs"
          value={filtersList.findIndex(obj => obj.id === filter)}
          onChange={handleFilterChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
          className={classes.filterTabsRoot}
          classes={{ flexContainer: classes.flexContainerStyles }}
        >
          {
            [...filtersList].map((obj, i) =>
              <Tab className={classes.filterTab} key={i} label={obj ? obj.name : `Group ${i - 2}`} />
            )
          }
        </Tabs>}
      {/* {(!props.username && !props.fromSearch && !props.fromIndividual) &&
        <div className={classes.filtersRoot}>
          <Button
            variant="text"
            className={classNames(
              classes.filterButton,
              filter === "ALL" && "isActive"
            )}
            startIcon={<HomeAllIcon />}
            onClick={() => handleFilterClick("ALL")}
          >
            <span className={classes.buttonText}>All</span>
          </Button>
          <Button
            variant="text"
            className={classNames(
              classes.filterButton,
              filter === "FAVOURITES" && "isActive"
            )}
            startIcon={<FavouriteIcon />}
            onClick={() => handleFilterClick("FAVOURITES")}
          >
            <span className={classes.buttonText}>Favourites</span>
          </Button>
          <Button
            variant="text"
            className={classNames(
              classes.filterButton,
              filter === "FEATURED" && "isActive"
            )}
            startIcon={<FeaturedIcon />}
            onClick={() => handleFilterClick("FEATURED")}
          >
            <span className={classes.buttonText}>Featured</span>
          </Button>
        </div>} */}
      <div>
        {loading && skeletonLoading}
        {arenaList.length > 0 ? (
          <InfiniteScroll
            dataLength={arenaList.length}
            next={fetchMoreData}
            hasMore={!props.fromIndividual ? arenaList.length !== pagination.total_count : false}
            loader={skeletonLoading}
            scrollableTarget="arena-list-root"
          >
            {arenaList &&
              arenaList.map((arena, index) => (
                <div key={index} className={classes.arenaRoot}>
                  <ArenaBasicDetailsTemplate arena={arena} index={index} handlePollResponse={handlePollResponse} handlePreview={handlePreview} handleArenaMoreMenuOpen={handleArenaMoreMenuOpen} toggleCommentsSection={toggleCommentsSection} handleShareMenuOpen={handleShareMenuOpen} sendReaction={sendReaction} user={props.user} openTaggedUsersList={props.openTaggedUsersList} openReactionsDialog={props.openReactionsDialog} history={props.history} setOpenPDF={setOpenPDF} />
                  {/* <ArenaTemplate index={index} arena={arena}
                    user={props.user} history={props.history}
                    handlePreview={handlePreview}
                    handleArenaMoreMenuOpen={handleArenaMoreMenuOpen}
                    handleReactionsView={handleReactionsView}
                    sendReaction={sendReaction}
                    toggleCommentsSection={toggleCommentsSection}
                    handleFavouriteShare={handleFavouriteShare}
                    reactions={reactions}
                    handleShareMenuOpen={handleShareMenuOpen}
                    // handleKeyDown={handleKeyDown}
                    // handleKeyPress={handleKeyPress}
                    setCommentText={setCommentText}
                    handleShowEmojis={handleShowEmojis}
                    showEmojis={showEmojis}
                    handleClickAway={handleClickAway}
                    addEmoji={addEmoji}
                    handleCommentLike={handleCommentLike}
                    handleReplyToComment={handleReplyToComment}
                    setCommentReplyText={setCommentReplyText}
                    handleShowReplyEmojis={handleShowReplyEmojis}
                    showReplyEmojis={showReplyEmojis}
                    handleReplyClickAway={handleReplyClickAway}
                    addReplyEmoji={addReplyEmoji}
                    commentsSkeletonLoading={commentsSkeletonLoading}
                    viewMoreComments={viewMoreComments}
                    handlereplyLike={handlereplyLike}
                  /> */}
                </div>
              ))
            }
          </InfiniteScroll >
        )
          :
          (!loading && <p className={classes.emptyResultText} style={props.fromSearch ? { margin: props.fromSearch && 0, paddingTop: 30, backgroundColor: '#FFF' } : { padding: props.fromIndividual && 15 }}>{filter === 'FAVOURITES' ? 'No posts from your favourites or you have not marked anyone as your favourite' : props.fromIndividual ? '*  Post you are looking for is deleted  *' : 'No Posts'}</p>)
        }
        {renderShareOptions}
        {renderArenaOptions}
        <ConfirmationDialog open={openConfirmationDialog.open} content={'Are you sure you want to Delete post?'} handleConfirmationDialog={(a, b) => handleConfirmationDialog(a, b, openConfirmationDialog.postId)} />
      </div >
      {
        preview.open &&
        <ArenaPreview open={preview.open}
          setOpenPDF={setOpenPDF}
          classes={classes}
          arena={arenaList[preview.postIndex]}
          user={props.user}
          handlePreviewClose={handlePreviewClose}
          attachmentIndex={preview.attachmentIndex}
          postIndex={preview.postIndex}
          handleSeeMore={handleSeeMore}
          toggleCommentsSection={toggleCommentsSection}
          handleReactionsView={handleReactionsView}
          reactionsList={reactionsList}
          reactions={reactions}
          sendReaction={sendReaction}
          // handleKeyDown={handleKeyDown}
          // handleKeyPress={handleKeyPress}
          setCommentText={setCommentText}
          addEmoji={addEmoji}
          commentsSkeletonLoading={commentsSkeletonLoading}
          viewMoreComments={viewMoreComments}
          urlify={urlify}
          handleReplyToComment={handleReplyToComment}
          setCommentReplyText={setCommentReplyText}
          addReplyEmoji={addReplyEmoji}
          handleShareMenuOpen={handleShareMenuOpen}
          handleArenaMoreMenuOpen={handleArenaMoreMenuOpen}
          openSharesListDialog={props.openSharesListDialog}
          history={props.history}
          handleCommentLike={handleCommentLike}
          handlereplyLike={handlereplyLike}
          handleNewComment={handleNewComment}
          handlePollResponse={handlePollResponse}
          usersList={usersList}
        />
      }
      {openPDF.open && <PDFViewerDialog
        open={openPDF.open}
        file={openPDF.file}
        postedBy={openPDF.postedBy}
        handleCloseDialog={handleClosePDFDialog}
      />}
    </div >
  );
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  keycloak: state.keycloak,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    // setLoading: (value) => dispatch(setLoading(value)),
    getArenaList: value => dispatch(getArenaList(value)),
    openSharesListDialog: options => dispatch(openSharesListDialog(options)),
    handleImageViewer: options => dispatch(handleImageViewer(options)),
    postArenaComments: options => dispatch(postArenaComments(options)),
    getArenaCommentsList: value => dispatch(getArenaCommentsList(value)),
    sendReaction: (options) => dispatch(sendReaction(options)),
    deleteReaction: (options) => dispatch(deleteReaction(options)),
    openReactionsDialog: (options) => dispatch(openReactionsDialog(options)),
    postCommentReply: options => dispatch(postCommentReply(options)),
    getCommentReplies: (options) => dispatch(getCommentReplies(options)),
    openShareToArenaDialog: (options) => dispatch(openShareToArenaDialog(options)),
    openShareToChatDialog: (options) => dispatch(openShareToChatDialog(options)),
    openEditArenaDialog: (options) => dispatch(openEditArenaDialog(options)),
    deletePost: (options) => dispatch(deletePost(options)),
    getPostByPostId: (options) => dispatch(getPostByPostId(options)),
    searchArenaChaseonsOrPosts: options => dispatch(searchArenaChaseonsOrPosts(options)),
    openTaggedUsersList: options => dispatch(openTaggedUsersList(options)),
    followUser: options => dispatch(followUser(options)),
    unFollowUser: options => dispatch(unFollowUser(options)),
    sendSurveyResponse: (options) => dispatch(sendSurveyResponse(options)),
    getContacts: (options) => dispatch(getContacts(options)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ArenaList);
