import { all, call, takeEvery, put } from "redux-saga/effects";
import Axios from "axios";
import { ARENA_POSTS_API, ARENA_SEND_REACTION_API, ARENA_COMMENTS_API, ARENA_GET_COMMENTS_API, ARENA_GET_REACTIONS_API, ARENA_DELETE_REACTION_API, ARENA_GET_COMMENT_REPLIES_API, ARENA_GET_USER_POST_API, TAG_CHASEONS_API, SHARE_POST_TO_ARENA_API, GET_SHARES_LIST_API, UPDATE_ARENA_API, DELETE_POST_API, ARENA_SEARCH_POSTS_API, ARENA_SEARCH_MEDIA_API, GET_API_ARENA_API, GET_BACKGROUND_COLORS_API, ARENA_POSTS_LIST_WITH_ORG_API, GET_TAGGED_USERS_API, CREATE_POLL_API, GET_POLLS_LIST_API } from "../../config";
import { POST_ARENA, GET_ARENA_LIST, SEND_REACTION, POST_ARENA_COMMENTS, GET_ARENA_COMMENTS_LIST, GET_REACTIONS, DELETE_REACTION, GET_COMMENT_REPLIES, POST_COMMENT_REPLY, GET_USER_LIST, TAG_CHASEONS, SHARE_TO_ARENA, GET_SHARES_LIST, UPDATE_ARENA, DELETE_POST, GET_POST_BY_POSTID, GET_BACKGROUND_COLORS, setBackgroundColors, GET_TAGGED_USERS_LIST, CREATE_POLL, } from "../../ducks/arena";
import { SEARCH_ARENA_CHASEONS_OR_POSTS, SEARCH_ARENA_MEDIA } from "../../ducks/search";
import { orgRealm } from "../..";

const postAreanRequest = (payload) => {
  return Axios.post(ARENA_POSTS_API, payload);
};

const getBackgroundColorsRequest = () => {
  return Axios.get(GET_BACKGROUND_COLORS_API, { params: { limit: 100, page: 0 } });
};

const updateAreanRequest = (postId, payload) => {
  return Axios.put(UPDATE_ARENA_API(postId), payload);
};

const sharePostToAreanRequest = (payload) => {
  return Axios.post(SHARE_POST_TO_ARENA_API, payload);
};

const getSharesListRequest = (postId) => {
  return Axios.get(GET_SHARES_LIST_API(postId));
};

const tagChaseOnsRequest = (postId, requestObj) => {
  return Axios.post(TAG_CHASEONS_API(postId), requestObj);
};

const fetchArenaListRequest = (params) => {
  return Axios.get(ARENA_POSTS_LIST_WITH_ORG_API(orgRealm), { params });
}

const searchArenaChaseonsOrPostsRequest = (params) => {
  return Axios.get(ARENA_SEARCH_POSTS_API, { params });
}

const searchArenaMediaRequest = (params) => {
  return Axios.get(ARENA_SEARCH_MEDIA_API, { params });
}

const sendReactionRequest = (payload) => {
  return Axios.post(ARENA_SEND_REACTION_API, payload);
};

const deleteReactionRequest = (entityId, entityType) => {
  return Axios.delete(ARENA_DELETE_REACTION_API(entityId, entityType));
};

const postAreanCommentsRequest = (payload) => {
  return Axios.post(ARENA_COMMENTS_API, payload);
};

const fetchArenaCommentListRequest = (postId, params) => {
  return Axios.get(ARENA_GET_COMMENTS_API(postId), { params });
};

const fetchUserList = (params) => {
  return Axios.get(ARENA_GET_USER_POST_API(params.username), { params });
};

const fetchCommentRepliesRequest = (commentId, params) => {
  return Axios.get(ARENA_GET_COMMENT_REPLIES_API(commentId), { params });
};

const fetchArenaReactionsRequest = (entityType, entityId) => {
  return Axios.get(ARENA_GET_REACTIONS_API(entityType, entityId));
};
const deletePostRequest = (postId) => {
  return Axios.delete(DELETE_POST_API(postId));
};

const getPostIdRequest = (postId) => {
  return Axios.get(GET_API_ARENA_API(postId))
}
const getTaggedUsersListRequest = (postId) => {
  return Axios.get(GET_TAGGED_USERS_API(postId), { params: { limit: 100, page: 0 } })
}

const createPollRequest = (payload) => {
  return Axios.post(CREATE_POLL_API, payload);
}
const fetchPollsListRequest = (params) => {
  return Axios.get(GET_POLLS_LIST_API, { params });
}

function* watchForPostArena() {
  yield takeEvery(POST_ARENA, function* postArena(action) {
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(postAreanRequest, requestObj);
      if (data) {
        console.log("watchForPostArena data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}

function* watchForGetBackgroundColors() {
  yield takeEvery(GET_BACKGROUND_COLORS, function* getBackgroundColors(action) {
    const { onGetColorsSuccessCB, onGetColorsErrorCB } = action.payload;
    try {
      let { data } = yield call(getBackgroundColorsRequest);
      console.log("watchForGetBackgroundColors data ");
      if (data) {
        yield put(setBackgroundColors(data));
        yield call(onGetColorsSuccessCB, data);
      }
    } catch (e) {
      yield call(onGetColorsErrorCB, e);
      console.log("watchForGetBackgroundColors catch err ", e);
    }
  });
}

function* watchForSearchArenaChaseonsOrPosts() {
  yield takeEvery(SEARCH_ARENA_CHASEONS_OR_POSTS, function* searchArenaChaseonsOrPosts(action) {
    const { params, pollParams, onSearchSuccessCB, onSearchErrorCB } = action.payload;
    try {
      if (pollParams) {
        const [arena, poll] = yield all([
          call(searchArenaChaseonsOrPostsRequest, params),
          call(fetchPollsListRequest, pollParams)
        ])
        if (arena.data, poll.data) {
          console.log("watchForSearchArenaChaseonsOrPosts arena & poll success ", arena.data, params, poll.data);
          yield call(onSearchSuccessCB, params.type === 'POSTS' ? arena.data?.map(i => i.posts) : arena.data, arena.headers, poll.data, poll.headers);
        }
      }
      else {
        const { data, headers } = yield call(searchArenaChaseonsOrPostsRequest, params);
        if (data) {
          console.log("watchForSearchArenaChaseonsOrPosts data success ", data, params);
          yield call(onSearchSuccessCB, data, headers);
        }
      }
    } catch (e) {
      yield call(onSearchErrorCB, e);
      console.log('watchForSearchArenaChaseonsOrPosts catch err ', e);
    }
  })
}

function* watchForSearchArenaMedia() {
  yield takeEvery(SEARCH_ARENA_MEDIA, function* searchArenaMedia(action) {
    const { params, onSearchSuccessCB, onSearchErrorCB } = action.payload;
    try {
      let { data, headers } = yield call(searchArenaMediaRequest, params)
      if (data) {
        yield call(onSearchSuccessCB, data, headers)
        console.log("watchForSearchArenaMedia data ");
      }
    } catch (e) {
      yield call(onSearchErrorCB, e);
      console.log('watchForSearchArenaMedia catch err ', e);
    }
  })
}

function* watchForUpdateArena() {
  yield takeEvery(UPDATE_ARENA, function* updateArena(action) {
    const {
      postId,
      requestObj,
      onUpdateSuccessCB,
      onUpdateErrorCB,
    } = action.payload;
    try {
      let { data } = yield call(updateAreanRequest, postId, requestObj);
      if (data) {
        console.log("watchForUpdateArena data success");
        yield call(onUpdateSuccessCB, data);
      }
    } catch (e) {
      yield call(onUpdateErrorCB, e);
      console.log("watchForUpdateArena catch err ", e);
    }
  });
}

function* watchForSharePostToArena() {
  yield takeEvery(SHARE_TO_ARENA, function* postArena(action) {
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(sharePostToAreanRequest, requestObj);
      if (data) {
        console.log("watchForSharePostToArena data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForSharePostToArena catch err ", e);
    }
  });
}

function* watchForGetSharesList() {
  yield takeEvery(GET_SHARES_LIST, function* getSharesList(action) {
    const { postId, onSuccessData, onErrorCB } = action.payload;
    try {
      let { data } = yield call(getSharesListRequest, postId);
      console.log("watchForGetSharesList data ");
      if (data) {
        yield call(onSuccessData, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForGetSharesList catch err ", e);
    }
  });
}

function* watchForTagChaseOns() {
  yield takeEvery(TAG_CHASEONS, function* postArena(action) {
    const {
      postId,
      requestObj,
      onTagChaseOnsSuccessCB,
      onTagChaseOnsErrorCB,
    } = action.payload;
    try {
      let { data } = yield call(tagChaseOnsRequest, postId, requestObj);
      if (data) {
        console.log("watchForTagChaseOns data success");
        yield call(onTagChaseOnsSuccessCB, data);
      }
    } catch (e) {
      yield call(onTagChaseOnsErrorCB, e);
      console.log("watchForTagChaseOns catch err ", e);
    }
  });
}

function* watchForFetchArenaList() {
  yield takeEvery(GET_ARENA_LIST, function* fetchArenasList(action) {
    const { params, pollParams, onSuccessCB, onErrorCB } = action.payload;
    try {
      let request = fetchArenaListRequest;

      if (params.username) {
        request = fetchUserList;
      }
      // delete params.username;
      if (pollParams) {
        const [arena, poll] = yield all([
          call(request, params),
          call(fetchPollsListRequest, pollParams)
        ])
        if (arena.data, poll.data) {
          console.log("watchForFetchArenaList arena & poll success ", arena.data, params, poll.data);
          yield call(onSuccessCB, arena.data, arena.headers, poll.data, poll.headers);
        }
      }
      else {
        const { data, headers } = yield call(request, params);
        if (data) {
          console.log("watchForFetchArenaList data success ", data, params);
          yield call(onSuccessCB, data, headers);
        }
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForFetchArenaList catch err ", e);
    }
  });
}

function* watchForSendReaction() {
  yield takeEvery(SEND_REACTION, function* sendReaction(action) {
    const { requestObj, commentLike, onSendReactionSuccessCB, onSendReactionErrorCB } = action.payload;
    try {
      let { data } = yield call(sendReactionRequest, requestObj);
      if (data) {
        yield call(onSendReactionSuccessCB, data, requestObj, commentLike);
        console.log('watchForSendReaction data success');
      }
    } catch (e) {
      yield call(onSendReactionErrorCB, e);
      console.log('watchForSendReaction catch err ', e);
    }
  });
}

function* watchForDeleteReaction() {
  yield takeEvery(DELETE_REACTION, function* deleteReaction(action) {
    const { entityId, entityType, commentLike, onDeleteReactionSuccessCB, onDeleteReactionErrorCB } = action.payload;
    try {
      let { data } = yield call(deleteReactionRequest, entityId, entityType);
      if (data) {
        yield call(onDeleteReactionSuccessCB, data, {}, commentLike);
        console.log('watchForDeleteReaction data success');
      }
    } catch (e) {
      yield call(onDeleteReactionErrorCB, e);
      console.log('watchForDeleteReaction catch err ', e);
    }
  });
}

function* watchForGetReactions() {
  yield takeEvery(GET_REACTIONS, function* getReactions(action) {
    const {
      entityType,
      entityId,
      onGetReactionsSuccessCB,
      onGetReactionsErrorCB,
    } = action.payload;
    try {
      let { data } = yield call(
        fetchArenaReactionsRequest,
        entityType,
        entityId
      );
      if (data) {
        yield call(onGetReactionsSuccessCB, data);
        console.log("watchForGetReactions data success");
      }
    } catch (e) {
      yield call(onGetReactionsErrorCB, e);
      console.log("watchForGetReactions catch err ", e);
    }
  });
}

function* watchForPostArenaComments() {
  yield takeEvery(POST_ARENA_COMMENTS, function* postArenaComments(action) {
    const { requestObj, onCommentSuccess, onCommentError } = action.payload;
    try {
      let { data } = yield call(postAreanCommentsRequest, requestObj);
      if (data) {
        console.log("watchForPostArena data success");
        yield call(onCommentSuccess, { ...data, postId: requestObj.entity_id });
      }
    } catch (e) {
      yield call(onCommentError, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}

function* watchForFetchArenaCommentsList() {
  yield takeEvery(GET_ARENA_COMMENTS_LIST, function* getArenaCommentsList(
    action
  ) {
    const { params, onCommentListSuccess, onCommentListError } = action.payload;
    try {
      let { data, headers } = yield call(
        fetchArenaCommentListRequest,
        params.postId,
        { limit: params.limit, page: params.page }
      );
      if (data) {
        console.log("comments list data success");
        yield call(
          onCommentListSuccess,
          {
            commentsList: data.sort((a, b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : -1),
            postId: params.postId,
            isSingleComment: params.isSingleComment,
          },
          headers
        );
      }
    } catch (e) {
      yield call(onCommentListError, e);
      console.log("comments list catch err ", e);
    }
  });
}

function* watchForUserList() {
  yield takeEvery(GET_USER_LIST, function* getUserList(action) {
    const { params, onUserListSuccess, onUserListError } = action.payload;
    try {
      let { data, headers } = yield call(fetchUserList, params);
      if (data) {

        yield call(
          onUserListSuccess,
          { ...data, userId: params.userId },
          headers
        );
        console.log("comments list data success");
      }
    } catch (e) {
      yield call(onUserListError, e);
      console.log("comments list catch err ", e);
    }
  });
}

function* watchForPostCommentReply() {
  yield takeEvery(POST_COMMENT_REPLY, function* sendCommentReply(action) {
    const {
      requestObj,
      postIndex,
      commentIndex,
      onCommentReplySuccess,
      onCommentReplyError,
    } = action.payload;
    try {
      let { data } = yield call(postAreanCommentsRequest, requestObj);
      if (data) {
        const { id } = data
        console.log("watchForPostCommentReply data success");
        yield call(onCommentReplySuccess, requestObj, postIndex, commentIndex, id);
      }
    } catch (e) {
      yield call(onCommentReplyError, e);
      console.log("watchForPostCommentReply catch err ", e);
    }
  });
}

function* watchForFetchCommentReplies() {
  yield takeEvery(GET_COMMENT_REPLIES, function* getCommentReplies(action) {
    const {
      params,
      onCommentRepliesSuccess,
      onCommentRepliesError,
    } = action.payload;
    try {
      let { data, headers } = yield call(
        fetchCommentRepliesRequest,
        params.commentId,
        { limit: params.limit, page: params.page }
      );
      if (data) {
        console.log("watchForFetchCommentReplies data success");
        yield call(
          onCommentRepliesSuccess,
          {
            repliesList: data.sort((a, b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : -1),
            postIndex: params.postIndex,
            commentIndex: params.commentIndex,
            commentId: params.commentId,
            postId: params.postId,
          },
          headers
        );
      }
    } catch (e) {
      yield call(onCommentRepliesError, e);
      console.log("watchForFetchCommentReplies catch err ", e);
    }
  });
}
function* watchForDeletePost() {
  yield takeEvery(DELETE_POST, function* deletePost(action) {
    console.log("action.payload", action.payload);
    const { postId, onSuccessDeletePost, onDeletePostError } = action.payload;
    try {
      if (postId) {
        console.log("watchDeletePost was callled");
        yield call(deletePostRequest, postId);
        yield call(onSuccessDeletePost);
      }
    } catch (error) {
      yield call(onDeletePostError, error);
      console.log("watchForDeletePost catch err ", error);
    }
  });
}
function* watchForGetIndividualPost() {
  yield takeEvery(GET_POST_BY_POSTID, function* getIndividualPost(action) {
    console.log(action.payload);
    const { postId, onSuccessGetPostId, onErrorGetPostId } = action.payload;
    try {
      if (postId) {
        console.log(postId, "postId");
        const data = yield call(getPostIdRequest, postId);
        if (data) {
          yield call(onSuccessGetPostId, data)
        }
        console.log("watchForGetIndividualPos data success");
      }
    } catch (e) {
      yield call(onErrorGetPostId, e);
      console.log("watchForGetIndividualPost catch err ", e);
    }
  });
}

function* watchForGetTaggedUsersList() {
  yield takeEvery(GET_TAGGED_USERS_LIST, function* getTaggedUsersList(action) {
    const { postId, onGetTaggedUsersSuccessCB, onGetTaggedUsersErrorCB } = action.payload;
    try {
      if (postId) {
        const { data, headers } = yield call(getTaggedUsersListRequest, postId);
        if (data) {
          yield call(onGetTaggedUsersSuccessCB, data, headers)
        }
        console.log("watchForGetTaggedUsersList data success");
      }
    } catch (e) {
      yield call(onGetTaggedUsersErrorCB, e);
      console.log("watchForGetTaggedUsersList catch err ", e);
    }
  });
}

function* watchForCreatePoll() {
  yield takeEvery(CREATE_POLL, function* createPoll(action) {
    const { requestObj, onCreateSuccess, onCreateError } = action.payload;
    try {
      let { data } = yield call(createPollRequest, requestObj);
      if (data) {
        console.log("watchForCreatePoll create success");
        yield call(onCreateSuccess, data);
      }
    } catch (e) {
      yield call(onCreateError, e);
      console.log("watchForCreatePoll catch err ", e);
    }
  });
}

export default function* () {
  yield all([
    watchForPostArena(),
    watchForGetBackgroundColors(),
    watchForSearchArenaChaseonsOrPosts(),
    watchForSearchArenaMedia(),
    watchForUpdateArena(),
    watchForSharePostToArena(),
    watchForGetSharesList(),
    watchForTagChaseOns(),
    watchForFetchArenaList(),
    watchForSendReaction(),
    watchForDeleteReaction(),
    watchForGetReactions(),
    watchForPostArenaComments(),
    watchForFetchArenaCommentsList(),
    watchForUserList(),
    watchForPostCommentReply(),
    watchForFetchCommentReplies(),
    watchForDeletePost(),
    watchForGetIndividualPost(),
    watchForGetTaggedUsersList(),
    watchForCreatePoll()
  ]);
}
