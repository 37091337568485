import React from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import PlayerPointsTable from './PlayerPointsTable';
import TeamPointsTable from './TeamPointsTable'
import { historyPushWithSearch } from '../../helpers';
import { CustomListGroup } from '../LiveScores/RoundsListView';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        // backgroundColor: '#FFF',
        width: '100%',
    },
    contentRoot: {
        padding: '0px 40px 20px',
        // overflowY: 'auto',
        // height: 'calc(100vh - 160px)',
        // width: 'calc(100% - 160px)'
    },
    tabsRoot: {
        // position: 'fixed',
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
            // minWidth: 125
        }
    },
    flexContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    scrollButtons: {
        // width: 25
    }
}))

const MenuOptions = [
    { label: 'Team', value: 'team', pathname: 'team' },
    { label: 'Player', value: 'player', pathname: 'player' }
];

const PointsTables = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    const handleTabChange = (newValue) => {
        historyPushWithSearch(`${path}/${newValue.pathname}`, props.history, props.location.search);
    }
    return (
        <div className={classes.root}>
            <div>
                {/* <Tabs
                    id="season-matches-tabs"
                    value={MenuOptions.map((option) => { return option.pathname }).indexOf(props.location.pathname.split('/')[3])}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabsRoot}
                    classes={{ flexContainer: classes.flexContainerStyles, scrollButtons: classes.scrollButtons }}
                >
                    {
                        [...MenuOptions].map((obj, i) =>
                            <Tab className={classes.tabRoot} key={i} label={obj.label} />
                        )}
                </Tabs> */}
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === props.location.pathname.split('/')[3])} onClick={handleTabChange} />
                <div id="points-table-data" className={classes.contentRoot} >
                    <Switch>
                        <Route path={`${path}/team`} exact>
                            <TeamPointsTable filtersData={props.filtersData} setFiltersData={props.setFiltersData} />
                        </Route>
                        <Route path={`${path}/player`} exact>
                            <PlayerPointsTable filtersData={props.filtersData} setFiltersData={props.setFiltersData} />
                        </Route>
                        <Redirect to={{ pathname: `${path}/team`, search: props.location.search }} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PointsTables);