import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getPlayersPointsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import RankLabel from '../RankLabel';
import { commonColumnOptions } from '../TeamPointsTable';
import { getNumberFormat } from '../../../helpers';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';

const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}))

const defaultFilters = {
    league: null,
    season: null,
    division: null
};

const PlayerPointsTable = ({ getPlayersPointsList, filtersData, setFiltersData, setLoading, loading, userDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [playersPoints, setPlayersPoints] = useState([]);
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints) {
            setPlayersPoints([])
        }
        else if (filtersData.season) {
            getPlayersPoints(filtersData.season._id)
        }
    }, [filtersData.season]);


    const getPlayersPoints = (seasonId) => {
        setLoading(true);
        getPlayersPointsList({
            params: { season_id: seasonId, limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setPlayersPoints([...result].map(obj => ({ ...obj, selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username } })));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const columns = (tableData) => [
        {
            name: "rank", label: "Rank", options: {
                customBodyRender: (rank, tableMeta) => {
                    const prevRank = playersPoints[tableMeta.rowIndex]?.prev_rank;
                    return <RankLabel rank={rank} prevRank={prevRank} />;
                },
            }
        },
        {
            name: "player", label: "Player", options: {
                customBodyRender: (player) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={player.name} username={player._id} url={player.photo_url} onClick={() => { }} style={{ marginRight: 14 }} />
                        {player && player.name}
                    </div>

                )
            }
        },
        {
            name: "team", label: "Team", options: {
                display: filtersData.season?.season_format !== 'PLAYER_RACE',
                customBodyRender: (team, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent name={team?.name} username={team?._id} url={team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                        </div>
                        <div style={{ textAlign: 'left', fontWeight: 600 }}>
                            {team && team.name}
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                                {tableData[tableMeta.rowIndex]?.captain && <span style={{ marginRight: 10 }}>C : {tableData[tableMeta.rowIndex]?.captain}</span>}
                                {tableData[tableMeta.rowIndex]?.mentor && <span>M : {tableData[tableMeta.rowIndex]?.mentor}</span>}
                            </div>
                        </div>
                    </div>

                )
            }
        },
        // { name: "captain", label: "Captain" },
        // { name: "mentor", label: "Mentor" },
        { name: "match_points", label: "Match/Core Pts", options: { ...commonColumnOptions, customBodyRender: (match_points) => getNumberFormat(userDetails?.countryCode, match_points, decimalView) } },
        { name: "bonus_points", label: "Bonus Points", options: { ...commonColumnOptions, customBodyRender: (bonus_points) => getNumberFormat(userDetails?.countryCode, bonus_points, decimalView) } },
        {
            name: "total_points", label: "Total Pts", options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
                customHeadRender: () =>
                    <th className="MuiTableCell-head" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Total Pts</span>
                        <Tooltip interactive arrow title={<p>Total Pts = Match/Core Pts + Bonus Pts</p>}>
                            <Info style={{ fontSize: 16, marginLeft: 5 }} />
                        </Tooltip>
                    </th>
            },
        },
    ];

    return (
        <>
            {/* <SeasonFilters filtersData={filtersData} setFiltersData={setFiltersData} defaultFilters={defaultFilters} /> */}
            {
                filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>Since player performance is not measured as a part of this selected season which is of ‘Team race’ format, we do not have any player points table to show here</p>
                    </div>
                    :
                    <div style={{ width: '100%' }}>
                        {!loading &&
                            <div style={{ marginTop: 24 }}>
                                <p className="table-title decimal-switch-root">
                                    <p></p>
                                    <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                </p>
                                <CustomDataTable columns={columns(playersPoints)} data={playersPoints} loading={loading} />
                            </div>
                        }
                    </div>
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerPointsTable));

