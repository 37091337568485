import React from 'react';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

export const RankLabelLatest = ({ rank, prevRank }) => (

    <span style={{ display: 'flex', alignItems: 'center', marginTop: -9, marginRight: 12, color: (rank < prevRank) ? 'green' : (rank > prevRank) ? 'red' : 'inherit' }}>
        {(rank < prevRank) ?
            <><ArrowDropUp style={{ fontSize: 48 }} /><span style={{ marginLeft: -8, fontSize: 12 }}>+{prevRank - rank}</span></>
            : (rank > prevRank) ?
                <><ArrowDropDown style={{ fontSize: 48 }} /><span style={{ marginLeft: -8, fontSize: 12 }}>{prevRank - rank}</span></>
                : <span style={{ margin: '9px 0 0 10px' }}>{(rank === prevRank && rank !== 0) ? '=' : '-'}</span>
        }
    </span>
)

const RankLabel = ({ rank, prevRank }) => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
        {rank || 'NA'}
        <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginLeft: 8, marginTop: -9, color: (rank < prevRank) ? 'green' : (rank > prevRank) ? 'red' : 'inherit' }}>
            {(rank < prevRank) ?
                <><ArrowDropUp style={{ fontSize: 28 }} /><span style={{ marginTop: -12 }}>+{prevRank - rank}</span></>
                : (rank > prevRank) ?
                    <><ArrowDropDown style={{ fontSize: 28 }} /><span style={{ marginTop: -12 }}>{prevRank - rank}</span></>
                    : <span style={{ margin: '9px 0 0 10px' }}>{(rank === prevRank && rank !== 0) ? '=' : '-'}</span>
            }
        </span>
    </span>
)

export default RankLabel;