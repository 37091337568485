import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import MyPerformance from "../MyPerformance";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";
import MyTeamPerformance from "../MyTeamPerformance";
import TeamStandings from "../TeamStandings";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F0F2F5",
        width: "100%",
        maxHeight: "calc(100vh - 116px)",
        // maxHeight: 'calc(100vh - 100px)',
        overflowY: "auto",
        padding: "56px 24px 0px",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            // maxHeight: 'calc(100vh - 80px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: "56px 0px 0px",
        },
    },
    gridContainer: {
        // width: '80%',
        // width: '90%',
        display: "flex",
        width: "100%",
        margin: "0 auto",
        maxWidth: 1250,
        [theme.breakpoints.down("md")]: {
            // width: '85%',
            // width: '90%',
        },
        [theme.breakpoints.down("sm")]: {
            // width: '90%',
        },
        [theme.breakpoints.down("xs")]: {
            // width: '100%',
        },
    },
    cardStyle: {
        height: "143px",
        width: "430px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },

    cardHeadertitle: {
        color: "#A0A0A0",
        fontSize: "13px",
        lineHeight: "19.5px",
        fontWeight: "500",
    },
    avatarStyles: {
        width: 36,
        height: 36,
        fontSize: 16,
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        border: "0.785714px solid #C4C4C4",
    },
    orgCardStyle: {
        height: "430px",
        width: "430px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    playerCardStyle: {
        height: "392px",
        width: "318px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    performanceCardStyle: {
        height: "392px",
        width: "446px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    formControl: {
        margin: theme.spacing(1),
        width: '120px',
    },
    tabs: {
        marginLeft: "10px",
        marginTop: "10px"
    },
    paper: {
        background: "#CCCDFD",
        color: "white"
    },
    input: {
        color: "#CCCDFD",
        backgroundColor: "#CCCDFD",
        "&:focus": {
            borderRadius: 4,
            borderColor: "#CCCDFD",
            boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
            background: "rgba(0,0,0,0)"
        }
    },
    autocompleteRoot: {
        borderRadius: 30,
        padding: '5px 5px !important',
        fontSize: 25,
        backgroundColor: '#F1F0FD',
        // width: 280
        margin: '10px',
        marginTop: '10px',
        borderColor: '#EBEBFF',
        marginLeft: '45px',
        color: "#5D5FEF",
    },
}));

const MentorDashboard = ({ setLoading, filtersData, userInfo, natigateToAllStats, natigateToLiveScores, navigateToPlayerLeaderboard, navigateToTeamLeaderboard }) => {
    const classes = useStyles();

    return (
        <>

            <Grid container spacing={3}>
                <Grid item md={4}>
                        <LiveMatches filtersData={filtersData} userInfo={userInfo} />
                    <CustomCard cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats} style={{ marginTop: 40 }}>
                        <OverallOrgStats />
                    </CustomCard>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={3}>
                        <Grid item md={7}>
                            <CustomCard cardTitle="My Team Performance" linkText="Live Score" onLinkClick={natigateToLiveScores}>
                                <MyTeamPerformance filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                        <Grid item md={5}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={navigateToPlayerLeaderboard} >
                                <PlayerStandings filtersData={filtersData} />
                            </CustomCard>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={navigateToTeamLeaderboard} style={{ marginTop: 40 }}>
                                <TeamStandings filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};


const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),

    };
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentorDashboard));