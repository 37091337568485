import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import { downloadFiles, getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import { getMyTeamLivePerformance, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog } from "../../ducks/game";
import IllustrationView from "./IllustrationView";
import { ArrowDownward, ArrowForward, Email, Share } from "@material-ui/icons";
import { useRouteMatch, withRouter } from "react-router-dom";
import { GetDates } from ".";
import { toJpeg } from "html-to-image";
import PerformanceTableTemplate from "../GlobalComponents/PerformanceTableTemplate";
import PerformanceTableEmailTemplate from "../GlobalComponents/PerformanceTableEmailTemplate";
import './TablesLayout.css';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { isRuleBasedOnActuals } from "./RaceSummary";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";



const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '15px 0 0px',
        minHeight: 285,
        marginBottom: 20
        // '& .material-table td div': {
        //     border: 'none !important'
        // }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    title: {
        fontSize: 18,
        // margin: '25px 0 0',
        margin: 0,
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    },
    viewAllButton: {
        margin: '0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    radioGroup: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .viewByText': {
            fontWeight: 600,
            marginRight: 10,
            fontSize: 12
        },
        '& label': {
            height: 30,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    radioLabel: {
        '& span': {
            fontSize: 12
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    }
}));

const TeamSummaryDivisionWise = ({ key, index, limit, type = "", keycloak, columns = [], divisionObj = {}, filtersData, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog, setLoading, userDetails, loading, dialog, history, decimalView, setDecimalView = () => { } }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [resData, setResData] = useState({ response: [], headers: null });
    // const [decimalView, setDecimalView] = useState(false);
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    useEffect(() => {
        if (filtersData.round) {
            if (index === 0) setLoading(true);
            getRoundTeamStatistics({ roundId: filtersData.round?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj._id, limit: limit, page: 1 } })
        }
    }, [filtersData.round?._id]);

    useEffect(() => {
        if (filtersData?.match && filtersData.season?.season_format === 'TEAM_RACE') {
            setLoading(true);
            getTeamRaceLiveScore({ matchId: filtersData.match?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj._id, limit: limit, page: 1 } })
        }
    }, [filtersData.match?._id]);

    useEffect(() => {
        if (resData.response?.length > 0) {
            if (type === 'DETAILED')
                onSuccessCBDetailed(resData.response, resData.headers, true);
            else
                onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCBDetailed = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score || item.total_points || 0, selected: item._id === resheaders?.my_team_id, kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.score;

                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                (['SR4', 'SR5','SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            hidden: (el.scoring_rule === 'SR2' || el.scoring_rule === 'SR3' || (['SR4', 'SR5','SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            hidden: filtersData.season?.season_format === 'HEAD_TO_HEAD',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id } }];
            }, []);
            // console.log('finalResult ', finalResult);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        setLoading(false);
    }


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score, selected: item._id === resheaders?.my_team_id, hilighted: index === 0, kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    // obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    // obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    // obj['totalPoints'] = totalPoints += el.score;
                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        {
                            title: `${el.kpi_code || el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            cellStyle: { maxWidth: 150, minWidth: 100 },
                            templateRender: (rowData) => <span style={{fontWeight: 600, fontSize: 18}}>{
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`}</span>,
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id } }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                pageSize: 5,
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {
                                filtersData.season?.season_format === 'TEAM_RACE' ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[4]?.title}</TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                    </>
                            }

                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        {type === 'DETAILED' ?
                                            <TableCell colSpan={(obj.scoring_rule === 'SR2' || obj.scoring_rule === 'SR3' || (['SR4', 'SR5','SR6'].includes(obj.scoring_rule) && obj.based_on === 'ACTUALS')) ? 2 : filtersData.season?.season_format === 'TEAM_RACE' ? 4 : 3} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                            :
                                            <TableCell>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                            </TableCell>}
                                    </>
                                ))
                            }
                        </TableRow>
                        {
                            type === 'DETAILED' &&
                            <TableRow>
                                {
                                    dynamicHeaders.map((obj) => (
                                        <>
                                            {
                                                filtersData.season?.season_format === 'TEAM_RACE' ?
                                                    <>
                                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                        <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                        <TableCell align="center">Points</TableCell>
                                                    </>
                                                    :
                                                    <>
                                                        <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>
                                                        <TableCell align="center" >Actual</TableCell>
                                                        {(obj.scoring_rule === 'SR1' || (['SR4', 'SR5','SR6'].includes(obj.scoring_rule) && obj.based_on === 'ACHIEVEMENT')) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                    </>
                                            }
                                        </>
                                    ))
                                }
                            </TableRow>
                        }
                    </TableHead>
                )
            }}
        />
    );
    const handleViewAll = () => {
        history.push(`${path}/all-team-performances${history.location?.search}`);
    };

    const handleDetailedScore = () => {
        history.push(`${path}/detailed-team-performances${history.location?.search}`);
    };

    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'TEAM', details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, imageId: id }, filtersData: filtersData });
    }

    const handleDownloadPerformance = async (id, title) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                downloadFiles(dataUrl, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-'));
            })
    }

    return (
        <div>
            {
                (data.length === 0 && index === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    data.length > 0 ?
                        <div key={key} className={classes.root}>
                            <p className="table-title decimal-switch-root">
                                <p>
                                    <span>(Division : {divisionObj?.name}) </span><span>{(!filtersData.round?._id && filtersData.match?.type === 'PLAYER_SPRINT') ? 'Player' : 'Team'} performance : <span>{filtersData.round?._id ? filtersData.round?.name : filtersData.match?.title}</span><GetDates filtersData={filtersData} /></span>
                                </p>
                                {index === 0 && <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                            </p>
                            <SummaryTable data={data} />
                            {
                                type === 'TOPFIVE' && <>
                                    {
                                        <>
                                        <PerformanceTableTemplate
                                        id={`team-perfomances-table-${divisionObj?.name}`}
                                        belongsTo='Teams'
                                        filtersData={filtersData}
                                        columns={[...columns, ...dynamicColumns]}
                                        data={data}
                                        divisionName={divisionObj?.name}
                                        render={(canDownload || dialog?.open) && limit === 5}
                                    />
                                    <PerformanceTableEmailTemplate
                    id={`team-perfomances-table1-${divisionObj?.name}`}
                    belongsTo="Teams"
                    filtersData={filtersData}
                    columns={[...columns, ...dynamicColumns]}
                    data={data}
                    divisionName={divisionObj?.name}
                    render={(canDownload || dialog?.open) && limit === 5}
                  />
                                    </>
                                    }
                                    {(data.length > 0 && limit === 5) &&
                                        <p className="table-footer">
                                            <span className={classes.viewAllButton} onClick={() => handleDetailedScore()}>View Detailed score<ArrowForward /></span>
                                            {isAdmin &&
                                                <>
                                                    <span className={classes.viewAllButton} onClick={() => handleShareScore(`team-perfomances-table1-${divisionObj?.name}`)}>Email <Email /></span>
                                                    <span className={classes.viewAllButton} onClick={() => handleDownloadPerformance(`team-perfomances-table-${divisionObj?.name}`, `Team_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : filtersData.match?.title}_${divisionObj?.name}`)}>Download<ArrowDownward /></span>
                                                </>
                                            }
                                            <span className={classes.viewAllButton} onClick={() => handleViewAll()}>View all<ArrowForward /></span>
                                        </p>
                                    }
                                </>
                            }
                        </div>
                        : null
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundTeamStatistics: options => dispatch(getRoundTeamStatistics(options)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getMyTeamLivePerformance: (options) => dispatch(getMyTeamLivePerformance(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamSummaryDivisionWise));
