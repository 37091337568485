import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import { ArrowForward, ChevronLeft } from '@material-ui/icons';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getKpiWisePlayerLeaderBoard, getPlayerLeaderBoard } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import FieldSet from '../../GlobalComponents/FieldSet';

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 26,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
        marginTop: 20
    },
    tabRoot: {
        minHeight: 26,
        textTransform: 'capitalize',
        minWidth: 'auto',
        margin: '0px 8px',
        padding: '0px 15px',
        borderRadius: 13,
        backgroundColor: '#FFF',
        '&:first-child': {
            marginLeft: 0
        }
    },
    selectedTab: {
        color: '#FFF !important',
        backgroundColor: theme.palette.primary.main
    },
    disabledScrollBtn: {
        display: 'none'
    },

    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    viewAllButton: {
        margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 15,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    }
}))

const LeaderboardTable = ({ columns = [], tabData = {}, tabType, kpis = [], filtersData, viewType = "", handleViewChange, setLoading, loading, getPlayerLeaderBoard, getKpiWisePlayerLeaderBoard, groupTab, userDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    // const [selectedKpi, setSelectedKpi] = useState(null);
    const [data, setData] = useState([]);
    const [showViewAllButton, setShowViewAllButton] = useState(false);
    // const [filteredKpis, setFilteredKpis] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    // useEffect(() => {
    //     const filteredKpis = kpis.filter(ele => ele.tags.includes(groupTab === 0 ? 'TEAM_MATCH' : 'TEAM_BONUS'));
    //     if (filteredKpis.length > 0) {
    //         setFilteredKpis(filteredKpis);
    //         setSelectedKpi({ ...filteredKpis[0] });
    //     }
    //     else setSelectedKpi(null)
    // }, [kpis, groupTab]);

    useEffect(() => {
        if (tabData && filtersData.season) {
            if (groupTab <= 1) {
                getKpiWisePlayerLeaderBoardList(filtersData.season._id, tabData._id);
            }
            else if (groupTab >= 2) {
                getPlayerLeaderBoardList(filtersData.season._id, tabData._id);
            }
        }
    }, [tabData]);

    const getPlayerLeaderBoardList = (seasonId, tabId) => {
        setLoading(true);
        getPlayerLeaderBoard({
            params: {
                season_id: seasonId,
                team_id: groupTab === 2 ? tabId : null,
                role_id: groupTab === 3 ? tabId : null,
                region_id: groupTab === 4 ? tabId : null
                , limit: viewType === 'CARD' ? 5 : 5000, page: 1
            },
            onSuccessCB: (result, headers) => {
                setData([...result].map(obj => ({ ...obj, selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username } })));
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getKpiWisePlayerLeaderBoardList = (seasonId, kpiId) => {
        setLoading(true);
        getKpiWisePlayerLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId, type: (groupTab === 0) ? 'CORE' : 'BONUS', limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setData([...result].map(obj => ({ ...obj, selected: obj.username === userDetails?.username, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username }, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id } })));
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    return (
        <>
            {
                (viewType === 'CARD') ?
                    <FieldSet
                        title={tabData.name}
                        rootStyles={classes.filedSetRootStyles}
                    >
                        <CustomDataTable columns={columns} data={data} options={{ pagination: false }} rootProps={{ style: { marginTop: 20 } }} loading={loading} />
                        {showViewAllButton && <span className={classes.viewAllButton} onClick={() => handleViewChange('PAGE', tabData)}>View all<ArrowForward /></span>}
                    </FieldSet>
                    :
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange('CARD', null)}><ChevronLeft /></IconButton>
                            {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>{tabType} : {tabData?.name.toUpperCase()}</p>}
                        </div>
                        <CustomDataTable columns={columns} data={data} rootProps={{ style: { marginTop: 10 } }} loading={loading} />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    // loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerLeaderBoard: (options) => dispatch(getPlayerLeaderBoard(options)),
        getKpiWisePlayerLeaderBoard: (options) => dispatch(getKpiWisePlayerLeaderBoard(options))
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardTable));