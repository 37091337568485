import React from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Equalizer } from '@material-ui/icons';
import OverallStats from './OverallStats';
import TeamStats from './TeamStats';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import PlayerStats from './PlayerStats';
import OverallTrends from './OverallTrends';
import TeamPerformanceTrends from './TeamPerformanceTrends';
import PlayerPerformanceTrends from './PlayerPerformanceTrends';
import { historyPushWithSearch } from '../../helpers';
import { CustomListGroup } from '../LiveScores/RoundsListView';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        // backgroundColor: '#FFF',
        width: '100%',
    },
    tabsRoot: {
        // position: 'fixed',
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
            // minWidth: 125
        }
    },
    flexContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    scrollButtons: {
        // width: 25
    }
}))

const MenuOptions = [
    { label: 'Overall', value: 0, icon: <Equalizer />, pathname: 'overall' },
    { label: 'Team', value: 1, icon: <Equalizer />, pathname: 'team' },
    { label: 'Player', value: 2, icon: <Equalizer />, pathname: 'player' },
];

const PerformanceStats = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    const handleTabChange = (newValue) => {
        historyPushWithSearch(`${path}/${MenuOptions[newValue?.value].pathname}`, props.history, props.location.search);
    }
    return (
        <div className={classes.root}>
            <div>
                {/* <Tabs
                    id="season-matches-tabs"
                    value={MenuOptions.map((option) => { return option.pathname }).indexOf(props.location.pathname.split('/')[3])}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabsRoot}
                    classes={{ flexContainer: classes.flexContainerStyles, scrollButtons: classes.scrollButtons }}
                >
                    {
                        [...MenuOptions].map((obj, i) =>
                            <Tab className={classes.tabRoot} key={i} label={obj.label} />
                        )}
                </Tabs> */}
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === props.location.pathname.split('/')[3])} onClick={handleTabChange} style={{ maxWidth: 'max-content' }} />
                <div id="performance-data" style={{ width: '100%' }}>
                    <Switch>
                        {
                            props.type === 'stats' ?
                                <>
                                    <Route path={`${path}/overall`} exact>
                                        <OverallStats />
                                    </Route>
                                    <Route path={`${path}/team`} exact>
                                        <TeamStats />
                                    </Route>
                                    <Route path={`${path}/player`} exact>
                                        <PlayerStats />
                                    </Route>
                                </>
                                :
                                <>
                                    <Route path={`${path}/overall`} exact>
                                        <OverallTrends />
                                    </Route>
                                    <Route path={`${path}/team`} exact>
                                        <TeamPerformanceTrends />
                                    </Route>
                                    <Route path={`${path}/player`} exact>
                                        <PlayerPerformanceTrends />
                                        {/* <p style={{ textAlign: 'center', padding: '80px 28px 20px', width: 'calc(100% - 56px)' }}>Player Performance Trends(Coming Soon)</p> */}
                                    </Route>
                                </>
                        }
                        <Redirect to={{ pathname: `${path}/overall`, search: props.location.search }} />
                    </Switch>
                </div>
            </div>
        </div >
    )
}

export default withRouter(PerformanceStats);