import { makeConstant } from "./_helpers";
const constant = makeConstant("chase/game");

export const GET_LEAGUES_LIST = constant("GET_LEAGUES_LIST");
export const GET_SEASONS_LIST = constant("GET_SEASONS_LIST");
export const GET_ROUNDS_LIST = constant("GET_ROUNDS_LIST");
export const GET_MATCHES_LIST = constant("GET_MATCHES_LIST");
export const GET_SEASON_TEAMS = constant("GET_SEASON_TEAMS");
export const GET_SEASON_PLAYERS = constant("GET_SEASON_PLAYERS");
export const GET_SEASON_PLAYERS_ROLES = constant("GET_SEASON_PLAYERS_ROLES");
export const GET_TEAM_MATCH_STATISTICS = constant("GET_TEAM_MATCH_STATISTICS");
export const GET_PLAYER_MATCH_STATISTICS = constant("GET_PLAYER_MATCH_STATISTICS");
export const GET_OVERALL_TEAM_CORE_KPIS_STATS = constant("GET_OVERALL_TEAM_CORE_KPIS_STATS");
export const GET_OVERALL_TEAM_BONUS_KPIS_STATS = constant("GET_OVERALL_TEAM_BONUS_KPIS_STATS");
export const GET_TEAM_CORE_KPIS_STATS = constant("GET_TEAM_CORE_KPIS_STATS");
export const GET_TEAM_BONUS_KPIS_STATS = constant("GET_TEAM_BONUS_KPIS_STATS");
export const GET_PLAYER_CORE_KPIS_STATS = constant("GET_PLAYER_CORE_KPIS_STATS");
export const GET_PLAYER_BONUS_KPIS_STATS = constant("GET_PLAYER_BONUS_KPIS_STATS");
export const GET_SEASON_KPIS_LIST = constant("GET_SEASON_KPIS_LIST");
export const GET_TEAM_KPIS_TRENDS = constant("GET_TEAM_KPIS_TRENDS");
export const GET_PLAYER_KPIS_TRENDS = constant("GET_PLAYER_KPIS_TRENDS");
export const GET_DIVISIONS_LIST = constant("GET_DIVISIONS_LIST");
export const GET_TEAMS_POINTS = constant("GET_TEAMS_POINTS");
export const GET_PLAYERS_POINTS = constant("GET_PLAYERS_POINTS");
export const GET_TEAM_MATCH_LEADERBOARD = constant("GET_TEAM_MATCH_LEADERBOARD");
export const GET_TEAM_BONUS_LEADERBOARD = constant("GET_TEAM_BONUS_LEADERBOARD");
export const GET_LIST_OF_REGIONS = constant("GET_LIST_OF_REGIONS");
export const GET_KPIWISE_PLAYER_LEADERBOARD = constant("GET_KPIWISE_PLAYER_LEADERBOARD");
export const GET_PLAYER_LEADERBOARD = constant("GET_PLAYER_LEADERBOARD");
export const GET_TEAM_RACE_LIVE_SCORE = constant("GET_TEAM_RACE_LIVE_SCORE");
export const GET_PLAYER_RACE_LIVE_SCORE = constant("GET_PLAYER_RACE_LIVE_SCORE");
export const GET_ROUND_TEAM_STATISTICS = constant("GET_ROUND_STATISTICS");
export const GET_ROUND_PLAYER_STATISTICS = constant("GET_ROUND_PLAYER_STATISTICS");
export const GET_MY_TEAM_LIVE_PERFORMANCE = constant("GET_MY_TEAM_LIVE_PERFORMANCE");
export const GET_MY_LIVE_PERFORMANCE = constant("GET_MY_LIVE_PERFORMANCE");
export const OPEN_LIVESCORE_SHARE = constant("OPEN_LIVESCORE_SHARE");
export const GET_STAKE_HOLDERS_LIST = constant("GET_STAKE_HOLDERS_LIST");
export const PLAYER_MATCH_DATA = constant("PLAYER_MATCH_DATA");
export const TEAM_MATCH_DATA = constant("TEAM_MATCH_DATA");
export const GET_MATCH_DETAILS = constant("GET_MATCH_DETAILS");
export const GET_LOGGEDIN_USER_ROLE = constant('GET_LOGGEDIN_USER_ROLE');
export const GET_LIVE_MATCHES_DATA = constant('GET_LIVE_MATCHES_DATA');
export const GET_OVERALL_TRENDS = constant('GET_OVERALL_TRENDS');
export const GET_TOP_TEAM = constant('GET_TOP_TEAM');
export const GET_TOP_PLAYER = constant('GET_TOP_PLAYER');

export const getLeaguesList = (payload) => ({
    type: GET_LEAGUES_LIST,
    payload
});
export const getSeasonsList = (payload) => ({
    type: GET_SEASONS_LIST,
    payload
})
export const getRoundsList = (payload) => ({
    type: GET_ROUNDS_LIST,
    payload
})
export const getMatchesList = (payload) => ({
    type: GET_MATCHES_LIST,
    payload
});
// export const getPlayersList = (payload) => ({
//     type: GET_PLAYERS_LIST,
//     payload
// });
// export const getRolesList = (payload) => ({
//     type: GET_ROLES_LIST,
//     payload
// });
// export const getTeamsList = (payload) => ({
//     type: GET_TEAMS_LIST,
//     payload
// });
export const getTeamMatchStatistics = (payload) => ({
    type: GET_TEAM_MATCH_STATISTICS,
    payload
});
export const getPlayerMatchStatistics = (payload) => ({
    type: GET_PLAYER_MATCH_STATISTICS,
    payload
});
export const getOverallTeamCoreKpisStats = (payload) => ({
    type: GET_OVERALL_TEAM_CORE_KPIS_STATS,
    payload
});
export const getOverallTeamBonusKpisStats = (payload) => ({
    type: GET_OVERALL_TEAM_BONUS_KPIS_STATS,
    payload
});
export const getTeamCoreKpisStats = (payload) => ({
    type: GET_TEAM_CORE_KPIS_STATS,
    payload
});
export const getTeamBonusKpisStats = (payload) => ({
    type: GET_TEAM_BONUS_KPIS_STATS,
    payload
});
export const getPlayerCoreKpisStats = (payload) => ({
    type: GET_PLAYER_CORE_KPIS_STATS,
    payload
});
export const getPlayerBonusKpisStats = (payload) => ({
    type: GET_PLAYER_BONUS_KPIS_STATS,
    payload
})
export const getSeasonTeams = (payload) => ({
    type: GET_SEASON_TEAMS,
    payload
})
export const getSeasonPlayers = (payload) => ({
    type: GET_SEASON_PLAYERS,
    payload
})
export const getSeasonPlayersRoles = (payload) => ({
    type: GET_SEASON_PLAYERS_ROLES,
    payload
})
export const getSeasonKpisList = (payload) => ({
    type: GET_SEASON_KPIS_LIST,
    payload
})

export const getTeamKpisTrends = (payload) => ({
    type: GET_TEAM_KPIS_TRENDS,
    payload
})

export const getPlayerKpisTrends = (payload) => ({
    type: GET_PLAYER_KPIS_TRENDS,
    payload
})

export const getDivisionsList = (payload) => ({
    type: GET_DIVISIONS_LIST,
    payload
})

export const getTeamPointsList = (payload) => ({
    type: GET_TEAMS_POINTS,
    payload
})

export const getPlayersPointsList = (payload) => ({
    type: GET_PLAYERS_POINTS,
    payload
})

export const getTeamMatchLeaderBoard = (payload) => ({
    type: GET_TEAM_MATCH_LEADERBOARD,
    payload
})

export const getTeamBonusLeaderBoard = (payload) => ({
    type: GET_TEAM_BONUS_LEADERBOARD,
    payload
})

export const getRegionsList = (payload) => ({
    type: GET_LIST_OF_REGIONS,
    payload
})

export const getKpiWisePlayerLeaderBoard = (payload) => ({
    type: GET_KPIWISE_PLAYER_LEADERBOARD,
    payload
});

export const getPlayerLeaderBoard = (payload) => ({
    type: GET_PLAYER_LEADERBOARD,
    payload
});

export const getTeamRaceLiveScore = (payload) => ({
    type: GET_TEAM_RACE_LIVE_SCORE,
    payload
});

export const getPlayerRaceLiveScore = (payload) => ({
    type: GET_PLAYER_RACE_LIVE_SCORE,
    payload
});

export const getRoundTeamStatistics = (payload) => ({
    type: GET_ROUND_TEAM_STATISTICS,
    payload
});

export const getRoundPlayerStatistics = (payload) => ({
    type: GET_ROUND_PLAYER_STATISTICS,
    payload
});

export const getMyTeamLivePerformance = (payload) => ({
    type: GET_MY_TEAM_LIVE_PERFORMANCE,
    payload
});

export const getMyLivePerformance = (payload) => ({
    type: GET_MY_LIVE_PERFORMANCE,
    payload
});

export const shareLiveScoreDialog = (payload) => ({
    type: OPEN_LIVESCORE_SHARE,
    payload
});

export const getStakeHoldersList = (payload) => ({
    type: GET_STAKE_HOLDERS_LIST,
    payload
});

export const getPlayerMatchData = (payload) => ({
    type: PLAYER_MATCH_DATA,
    payload
});

export const getTeamMatchData = (payload) => ({
    type: TEAM_MATCH_DATA,
    payload
});

export const getMatchDetails = (payload) => ({
    type: GET_MATCH_DETAILS,
    payload
});

//dashboard

export const getLoggedInUserRole = (payload) => ({
    type: GET_LOGGEDIN_USER_ROLE,
    payload
})

export const getLiveMatches = (payload) => ({
    type: GET_LIVE_MATCHES_DATA,
    payload
})

export const getOverallTrends = (payload) => ({
    type: GET_OVERALL_TRENDS,
    payload
})

export const getTopTeam = (payload) => ({
    type: GET_TOP_TEAM,
    payload
})
export const getTopPlayer = (payload) => ({
    type: GET_TOP_PLAYER,
    payload
})
const initialState = {
    liveScoresShare: {
        open: false,
        matchDetails: null,
        fixture: 'ROUND',
        details: {},
        notify: 'TEAM',
        filtersData: {}
    },
    playerMatchData: {},
    teamMatchData: {
        data: [],
        columns: [],
        headers: []
    }
};
//Reducers
export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LIVESCORE_SHARE: {
            return {
                ...state,
                liveScoresShare: { ...action.payload }
            }
        }
        case TEAM_MATCH_DATA: {
            return {
                ...state,
                teamMatchData: {
                    ...action.payload
                }
            }
        }
        case PLAYER_MATCH_DATA: {
            return {
                ...state,
                playerMatchData: { ...action.payload }
            }
        }
        default: return state
    }
}

