import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getLiveMatches, getRoundsList, getMatchesList } from "../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { setLoading } from "../../ducks/loading";
import { CustomCard } from './CustomCard';
import { getNumberFormat, decodeEnum, formatDateToMonth, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    //width: width * 0.29861111111,
    width: '430px',
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "11px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "446px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  circle: {
    display: 'flex',
    width: '22px',
    height: '22px',
    backgroundColor: '#EF5DA8',
    borderRadius: ' 50%',
    marginLeft: -90
  },
  matchItem: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#7D7D7D',
    '& .goals': {
      fontSize: 12
    }
  },
  selectedMatch: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000',
    '& .goals': {
      fontSize: 12,
      fontWeight: 500,
    }
  },
  nameRoot: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    '& .name': {
      fontSize: 15,
      fontWeight: 500,
    }
  },
  goalsDiff: {
    color: '#C20000',
    fontSize: 11,
    marginBottom: 0
  },
  cardRoot: {
    padding: '12px 16px'
  },
}));

const LiveMatches = ({ filtersData, setFiltersData, getLiveMatches, userDetails, userInfo, isAllMatches = false }) => {
  const classes = useStyles();
  const [resLive, setResLive] = useState([])

  useEffect(() => {
    if (filtersData?.season?._id) {
      let seasonId = filtersData.season?._id;
      const params = { page: 1, limit: 5000, ...(isAllMatches ? { round_id: filtersData?.round?._id } : { status: 'live', team_id: userInfo?.myTeams || [] }) };
      getLiveMatches({ seasonId, params, onSuccessCB, onErrorCB })
    }
  }, [filtersData?.season?._id, filtersData?.round?._id]);


  const onSuccessCB = (response) => {
    console.log('On sucess CB live matches', response)
    setResLive(response);
    setFiltersData(prevState => ({ ...prevState, match: response[0] }))
  }

  const onErrorCB = (error) => {
    console.log("on Error CB Dashboard", error)
  }


  const differnce = (a, b) => {
    return (a - b);

  }


  const topTeam = (matchDetails) => {
    for (var i = 0; i < matchDetails.length; i++) {
      for (var j = 1; j < matchDetails.length; j++) {
        if (matchDetails[0].score > matchDetails[1].score) {
          return ` Team ${matchDetails[0].name} is leading by ${differnce(matchDetails[0].score, matchDetails[1].score)}`
        } else if (matchDetails[1].score > matchDetails[0].score) {
          return ` Team ${matchDetails[1].name} is leading by ${differnce(matchDetails[1].score, matchDetails[0].score)}`
        } else {
          return 0;
        }
      }
    }
  }


  return (
    <>

      {resLive.length > 0 ? resLive.map((match, index) =>
        <CustomCard selected={match?._id === filtersData.match?._id} cardTitle={isAllMatches ? "" : (index === 0 && (resLive.length > 1 ? "My Live Matches" : "My Live Match"))} linkText={null} onLinkClick={() => { }} onCardClick={() => setFiltersData(prevState => ({ ...prevState, match }))} >
          <p className={classes.cardHeadertitle}>{match.title} || {`${formatDateToMonth(match.start_date)} - ${formatDateToMonth(match.end_date)}`}</p>
          {
            match.teams.map((team) => (
              <>
                <div className={userInfo?.myTeams?.includes(team.team_id) ? classes.selectedMatch : classes.matchItem}>
                  <div className={classes.nameRoot}>
                    <AvatarComponent name={team.name} username={team.name} url={team.logo_url} onClick={() => { }} style={{ marginRight: 10, width: 24, height: 24, fontSize: 10 }} />
                    <span className="name">{team.name}</span>
                  </div>

                  <span className="goals">{team.score ? `${getNumberFormat(userDetails?.countryCode, team.score)}` : `${getNumberFormat(userDetails?.countryCode, team.points)}`} {`${decodeEnum(match.scoring_unit)}${team.score === 1 ? '' : 's'}`}</span>
                </div>
              </>
            ))}

          {topTeam(match.teams) === 0 ? <span className={classes.goalsDiff}> Both teams are equal </span> : <span className={classes.goalsDiff}>  {topTeam(match.teams)} </span>}


        </CustomCard>
      ) :
        <CustomCard cardTitle={"My Live Matches"} linkText={null} onLinkClick={() => { }} >
          <p style={{ padding: 24, textAlign: 'center' }}>No Data Available</p>
        </CustomCard>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  userDetails: state.user
});



const mapDispatchToProps = dispatch => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getLiveMatches: (options) => dispatch(getLiveMatches(options)),
    getRoundsList: (options) => dispatch(getRoundsList(options)),
    getMatchesList: (options) => dispatch(getMatchesList(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveMatches));
