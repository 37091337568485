import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MatchSummary from './TeamsMatchSummary';
import { Avatar, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { groupBy } from 'underscore';
import { getLeaguesList, getSeasonsList, getMatchesList, getRoundsList, shareLiveScoreDialog } from '../../ducks/game';
import { formatDate, getAvatarText, getBase64FromUrl, getSearchValue, historyPushWithSearch, QueryTypes, setSearchQuery } from "../../helpers";
import classNames from 'classnames';
import { setLoading } from "../../ducks/loading";
import RoundsListView, { RoundsListGroup, roundStatuses } from "./RoundsListView";
import gameIllustration from '../../assets/images/game_Illustration.png';
import FieldSet from '../GlobalComponents/FieldSet';
import MatchListView from '../PerformanceStats/MatchListView';
import RaceSummary from './RaceSummary';
import IllustrationView from './IllustrationView';
import { TabBar } from '../Leaderboards/TabBar';
import RoundSummary from './RoundSummary';
import MaterialUITable from '../GlobalComponents/MaterialUITable';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { Chip } from '@material-ui/core';
import MatchDetails from './MatchDetails';
import TopFiveTeamSummary from './TopFiveTeamSummary';
import RoundPlayerSummaryViewAll from './RoundPlayerSummaryViewAll';
import RoundTeamSummaryViewAll from './RoundTeamSummaryViewAll';
import TopFivePlayerSummary from './TopFivePlayerSummary';
import RoundPlayerSummary from './RoundPlayerSummary';
import MyPerformanceCards from './MyPerformanceCards';
import RoundMatchesCard from './RoundMatchesCard';
import { FiberManualRecord } from '@material-ui/icons';
import ShareLiveScoreDialog from './ShareLiveScoreDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
        // display: 'flex',
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: 'calc(100vh - 80px)',
        //     maxWidth: `calc(100vw - 150px)`,
        //     padding: '20px 15px 0px',
        // },
    },
    filtersRoot: {
        marginBottom: 15,
        padding: '20px 50px 0px',
        width: 'calc(100% - 100px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
        // marginRight:5,
    },
    cardHeights: {
        maxWidth: '100%',
        // minHeight: '24vh',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
        // minHeight: 150,
        // maxHeight: 153
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        // fontStyle: 'oblique',
        padding: '0px',
        // backgroundColor: '#c4c4c4',
        // borderRadius: 10,
        // height: 34,
        // width: 34,
        textAlign: 'center',
        color: '#0000008a'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchTitle: {
        fontSize: 14,
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            // display: 'inline-grid'
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    legendRootStyles: {
        fontSize: 14
    },
    viewDetailsButton: {
        // margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 12,
        // float: 'right',
        cursor: 'pointer',
        // fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline',
        },
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
}));

export const matchStatusList = [
    {
        name: 'Fixture',
        id: 'fixtures'
    },
    {
        name: 'Live',
        id: 'live'
    },
    {
        name: 'Awaiting',
        id: 'completed'
    },
    {
        name: 'Closed',
        id: 'closed'
    },
];

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};

export const GetDates = ({ filtersData }) => {
    return (
        <span style={{ marginLeft: 10 }}>
            {filtersData.round ?
                <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.start_date : filtersData.round?.start_date)} - {formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.end_date : filtersData.round?.end_date)})</span>
                :
                filtersData.match ?
                    <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.start_date : filtersData.match?.start_date)} - {formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.end_date : filtersData.match?.end_date)})</span>
                    : null}
        </span>
    )
}

const subTabs = [{ label: 'All', icon: '', path: 'all' }, { label: 'By Match', icon: '', path: 'byMatch' }]

const LiveScores = ({ getLeaguesList, filtersData, setFiltersData, getSeasonsList, getMatchesList, getRoundsList, setLoading, loading = true, history, location, orgDetails, userDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { path } = useRouteMatch();
    const [tenantLogoDataUrl, setTenantLogoDataUrl] = useState(null);

    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [matches, setMatches] = useState([]);
    const [matchesByDiv, setMatchesByDiv] = useState(null);
    const [sprintMatches, setSprintMatches] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('LIST');
    const [selectedMatch, setSelectedMatch] = useState(null);

    useEffect(() => {
        // getLeagues();
    }, []);

    useEffect(() => {
        // if (filtersData.league) getSeasons(filtersData.league._id);
        // setViewType('LIST');
    }, [filtersData.league]);

    // console.log('filtersData ', filtersData);
    useEffect(() => {
        if (orgDetails?.logo_url) {
            getBase64FromUrl(orgDetails?.logo_url).then(imageData => {
                setTenantLogoDataUrl(imageData)
            })
        }
    }, [orgDetails?.logo_url])

    useEffect(() => {
        if (filtersData.season?._id) {
            if (filtersData.season.season_format === 'HEAD_TO_HEAD') getRounds(filtersData.season._id);
            else getMatches(filtersData.season?._id, null, filtersData.season.season_format === 'TEAM_RACE' ? 'TEAM_SPRINT' : 'PLAYER_SPRINT');
            setViewType('LIST');
        }
    }, [filtersData.season?._id]);

    useEffect(() => {
        if (filtersData.round) {
            if (['ROUND_ROBIN', 'MANUAL_FORMAT_WITH_ROUND'].includes(filtersData.season?.fixture_format) ? (currentTab === 1) : true) {
                getMatches(filtersData.season?._id, filtersData.round._id, 'TEAM_MATCH');
            }
            setSearchQuery(history, { viewBy: currentTab === 0 ? 'all' : 'match' });
            setViewType('LIST');
        }
        else {
            setMatches([]);
            setMatchesByDiv(null);
            setViewType('LIST');
            if (filtersData.season?.season_format === 'TEAM_MATCH') {
                setSprintMatches([]);
                // setFiltersData(prevState => ({ ...prevState, match: null }));
            }
        }
    }, [filtersData.round, currentTab]);

    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(groupByValue === 'match' ? 1 : 0);
        }
    }, [groupByValue])

    const handleChangeAutocomplete = (data, field) => {
        setSearchQuery(history, { [field]: data._id });
        if (field === 'match') setFiltersData(prevState => ({ ...prevState, [field]: data, round: null }));
        else setFiltersData(prevState => ({ ...prevState, [field]: data }));
    };

    const handleSubTabChange = (e, newValue) => {
        setViewType('LIST');
        setSelectedMatch(null);
        setSearchQuery(history, { viewBy: newValue === 1 ? 'match' : 'all' });
        setCurrentTab(newValue);
    }

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasons = (leagueId) => {
        setLoading(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1, status: "live" },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                    setRounds([]);
                    setMatches([]);
                    setMatchesByDiv(null);
                    setSprintMatches([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getRounds = (seasonId) => {
        setLoading(true);
        getRoundsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRounds([...result]);
                    const searchValue = getSearchValue(history, 'round');
                    !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                    setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id)) }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                    setRounds([]);
                    setMatches([]);
                    setMatchesByDiv(null);
                    setSprintMatches([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getMatches = (seasonId, roundId, type) => {
        setLoading(true);
        getMatchesList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, type, round_id: roundId },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const searchValue = getSearchValue(history, 'match');
                    if (type === 'TEAM_MATCH') {
                        if (filtersData.season?.divisions_enabled)
                            setMatchesByDiv(groupBy(result.map(obj => ({
                                ...obj,
                                team1: obj.teams[0],
                                team2: obj.teams[1],
                            })), 'division_name'));
                        else
                            setMatches(result.map(obj => ({
                                ...obj,
                                team1: obj.teams[0],
                                team2: obj.teams[1],
                            })));

                        const groupByValue = getSearchValue(history, 'viewBy');
                        if (groupByValue) {
                            if (searchValue) {
                                setViewType('DETAILS');
                                setSelectedMatch(result?.find(obj => obj._id === searchValue));
                            }
                            else {
                                setViewType('LIST');
                                setSelectedMatch(null);
                            }
                            // setCurrentTab(groupByValue === 'match' ? 1 : 0);
                        }
                        setFiltersData(prevState => ({ ...prevState, match: searchValue ? result?.find(obj => obj._id === searchValue) : (result?.find(obj => obj.status === 'live') || result[0]) }));
                    }
                    else {
                        setFiltersData(prevState => ({ ...prevState, round: null, match: searchValue ? result?.find(obj => obj._id === searchValue) : (result?.find(obj => obj.status === 'live') || result[0]) }));
                        setSprintMatches([...result]);
                        !searchValue && setSearchQuery(history, { match: (result?.find(obj => obj.status === 'live') || result[0])?._id });
                    }
                }
                else {
                    setMatches([]);
                    setMatchesByDiv(null);
                    setSprintMatches([]);
                    setFiltersData(prevState => ({ ...prevState, match: null }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setMatches([]);
                setMatchesByDiv(null);
                setSprintMatches([]);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const onRoundClick = (round) => {
        setSearchQuery(history, { round: round._id });
        setFiltersData(prevState => ({ ...prevState, round }));
    }

    const onMatchClick = (match) => {
        setSearchQuery(history, { match: match._id });
        setFiltersData(prevState => ({ ...prevState, match }));
    }

    const MatchCard = ({ match, key }) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={key} >
            <div onClick={() => onMatchClick(match)} className={classNames(classes.matchView, { [classes.selectedMatch]: match._id === filtersData.match?._id })}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <p className={classes.teamName} title={match.teams[0]?.name} style={{ textAlign: 'right', marginLeft: 5 }}>{match.teams[0]?.name}</p>
                    <Avatar
                        title={match.teams[0]?.name}
                        src={match.teams[0].logo_url}
                        classes={{ root: classes.avatarStyles }}
                    >
                        {(match.teams[0]?.name && getAvatarText(match.teams[0]?.name))}
                    </Avatar>
                </span>
                <p className={classes.vsStyles} style={{ color: 'inherit' }}>vs</p>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        title={match.teams[1]?.name}
                        src={match.teams[1].logo_url}
                        classes={{ root: classes.avatarStyles }}
                    >
                        {(match.teams[1]?.name && getAvatarText(match.teams[1]?.name))}
                    </Avatar>
                    <p className={classes.teamName} style={{ textAlign: 'left', marginRight: 5 }} title={match.teams[1]?.name}>{match.teams[1]?.name}</p>
                </span>
            </div>
        </Grid>
    )

    const handleViewChange = (type, match) => {
        setViewType(type);
        if (type === 'DETAILS') {
            setSelectedMatch(match);
            setFiltersData(prevState => ({ ...prevState, match }));
            setSearchQuery(history, { match: match._id });
        }
        else {
            setSearchQuery(history, { match: "" });
            setFiltersData(prevState => ({ ...prevState, match: null }));
            setSelectedMatch(null);
        }
        historyPushWithSearch(`${path}/match/${match._id}`, history, location.search);
    }

    const columns = [
        {
            title: "Match Title",
            field: "title",
            cellStyle: { minWidth: 120 },
            editComponent: props => (
                <TextField
                    value={props.value}
                    id="outlined-required"
                    label="Match Title"
                    onChange={e => props.onChange(e.target.value)}
                    error={props.value < 0}
                    variant="outlined"
                    fullWidth
                    required
                    style={{ minWidth: 125 }}
                    autoComplete="off"
                />
            )
        }, {
            title: "Team - 1",
            field: "team1",
            editable: "never",
            emptyValue: '--',
            cellStyle: { minWidth: 200 },
            render: (rowData) => (rowData.team1 && rowData.type === 'TEAM_MATCH') ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.team1.name} username={rowData.team1._id} url={rowData.team1.logo_url} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.team1 && rowData.team1.name}
                </div>
            ) : '--'
        },
        {
            title: "Captain (Team - 1)",
            field: "team1",
            editable: "never",
            emptyValue: '--',
            render: (rowData) => rowData.team1.captain || '--'
        },
        {
            title: "Team - 2",
            field: "team2",
            editable: "never",
            emptyValue: '--',
            cellStyle: { minWidth: 200 },
            render: (rowData) => (rowData.team2 && rowData.type === 'TEAM_MATCH') ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.team2.name} username={rowData.team2._id} url={rowData.team2.logo_url} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.team2 && rowData.team2.name}
                </div>
            ) : '--'
        },
        {
            title: "Captain (Team - 2)",
            field: "team2",
            editable: "never",
            emptyValue: '--',
            render: (rowData) => rowData.team2.captain || '--'
        },
        {
            title: "Result",
            field: "winner_id",
            editable: false,
            cellStyle: { minWidth: 120 },
            render: (rowData) => (rowData.winner_id && rowData.type === 'TEAM_MATCH') ? (
                <div className={classes.nameRoot} style={{ textAlign: 'center' }}>
                    {rowData.teams.find(obj => obj.team_id === rowData.winner_id)?.name} <br /> has won
                </div>
            ) : rowData.type === 'TEAM_MATCH' ? (rowData.closed ? 'Match drawn' : '--') : '--'
        },
        {
            title: "Status",
            field: "status",
            emptyValue: '--',
            editable: false,
            render: rowData => rowData.status ? <Chip label={rowData.closed ? 'Closed' : matchStatusList.find(obj => obj.id === rowData.status).name} style={{ color: rowData.closed ? '#949084' : (rowData.status === "live" ? "#228B22" : rowData.status === "fixtures" ? "#F77D1D" : "red"), width: 'auto' }} /> : '--'
        },
        {
            title: "Action",
            field: "action",
            emptyValue: '--',
            editable: false,
            render: rowData => <span className={classes.viewDetailsButton} onClick={() => handleViewChange('DETAILS', rowData)}>View Details</span>
        },
    ]

    return (
        <div className={classes.root}>
            <div >
                <RoundsListGroup rounds={rounds} selectedRound={filtersData.round} onRoundClick={(round) => setFiltersData(prevState => ({ ...prevState, round }))} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
            </div>
            {/* <div className={classes.filtersRoot}>
                <FieldSet title={"Season Selection"} rootStyles={classes.filedSetRootStyles} legend={classes.legendRootStyles}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-leagues"
                                options={leagues}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.league}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} variant="outlined" label="League" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'league')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-seasons"
                                options={seasons}
                                getOptionLabel={(option) => option.name || ''}
                                value={seasons.length && filtersData?.season}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Season" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'season')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                                <Autocomplete
                                    id="List-of-rounds"
                                    options={rounds}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={rounds.length && filtersData?.round}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Round" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                    onChange={(...args) => handleChangeAutocomplete(args[1], 'round')}
                                    renderOption={(option) => <div title={option.round_status} style={{ display: 'flex', alignItems: 'center' }}>{option.name}{option.round_status === 'LIVE' && <FiberManualRecord fontSize="small" style={{ color: 'red', fontSize: 13, marginLeft: 5 }} />}</div>}
                                />
                                :
                                <Autocomplete
                                    id="List-of-sprints"
                                    options={sprintMatches}
                                    getOptionLabel={(option) => option?.title || ''}
                                    value={sprintMatches.length && filtersData?.match}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Sprint" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                    onChange={(...args) => handleChangeAutocomplete(args[1], 'match')}
                                // renderOption={(option) => <div title={option.round_status} style={{ display: 'flex', alignItems: 'center' }}>{option.name}{option.round_status === 'LIVE' && <FiberManualRecord fontSize="small" style={{ color: 'red', fontSize: 13, marginLeft: 5 }} />}</div>}
                                />
                            }
                        </Grid>

                    </Grid>
                </FieldSet>

            </div > */}
            <div style={{ width: 'calc(100% - 100px)', padding: '0 50px' }}>
                <Switch>
                    <Route path={`${path}/detailed-team-performances`} exact>
                        {(filtersData.season?.season_format === 'HEAD_TO_HEAD') &&
                            <RoundSummary roundId={filtersData.round?._id} filtersData={filtersData} loading={loading} />
                        }
                        {filtersData.match && (filtersData.season?.season_format !== 'HEAD_TO_HEAD' &&
                            <RaceSummary loading={loading} seasonFormat={filtersData.season?.season_format} seasonId={filtersData.season?._id} matchId={filtersData.match?._id} filtersData={filtersData} />)
                        }
                    </Route>
                    <Route path={`${path}/detailed-player-performances`} exact>
                        {(filtersData.season?.season_format === 'HEAD_TO_HEAD') &&
                            <div>
                                <RoundPlayerSummary roundId={filtersData.round?._id} filtersData={filtersData} loading={loading} />
                            </div>
                        }
                        {filtersData.match && (filtersData.season?.season_format !== 'HEAD_TO_HEAD' &&
                            <RaceSummary loading={loading} seasonFormat={filtersData.season?.season_format} seasonId={filtersData.season?._id} matchId={filtersData.match?._id} filtersData={filtersData} />)}
                    </Route>
                    <Route path={`${path}/all-team-performances`} exact>
                        <RoundTeamSummaryViewAll roundId={filtersData.round?._id} matchId={filtersData.match?._id} filtersData={filtersData} />
                    </Route>
                    <Route path={`${path}/all-player-performances`} exact>
                        <RoundPlayerSummaryViewAll roundId={filtersData.round?._id} matchId={filtersData.match?._id} filtersData={filtersData} />
                    </Route>
                    <Route path={`${path}/match/:matchId`} exact>
                        <MatchDetails filtersData={filtersData} />
                    </Route>
                    <Route path={`${path}`}>
                        {filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                            <Grid container spacing={2} >
                                {/* <Grid item xs={3}>
                                    <RoundMatchesCard filtersData={filtersData} handleViewChange={handleViewChange} />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div>
                                        <Switch>
                                            <Route path={`${path}`} exact>
                                                {
                                                    (filtersData.season?.season_format !== 'PLAYER_RACE') &&
                                                    <TopFiveTeamSummary tenantLogoDataUrl={tenantLogoDataUrl} roundId={filtersData.round?._id} filtersData={filtersData} matchId={filtersData.match?._id} seasonFormat={filtersData.season?.season_format} />
                                                }
                                                {
                                                    ((filtersData.season?.season_format === 'HEAD_TO_HEAD' || filtersData.season?.season_format === 'PLAYER_RACE') || (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints)) &&
                                                    <TopFivePlayerSummary tenantLogoDataUrl={tenantLogoDataUrl} roundId={filtersData.round?._id} filtersData={filtersData} matchId={filtersData.match?._id} seasonFormat={filtersData.season?.season_format} />
                                                }
                                            </Route>
                                            <Redirect to={`${path}`} />
                                        </Switch>
                                    </div>
                                </Grid>
                            </Grid> :
                            <Grid container spacing={2} >
                                {/* <Grid item xs={1}>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div>
                                        <Switch>
                                            <Route path={`${path}`} exact>
                                                {
                                                    (filtersData.season?.season_format !== 'PLAYER_RACE') &&
                                                    <TopFiveTeamSummary tenantLogoDataUrl={tenantLogoDataUrl} roundId={filtersData.round?._id} filtersData={filtersData} matchId={filtersData.match?._id} seasonFormat={filtersData.season?.season_format} />
                                                }
                                                {
                                                    ((filtersData.season?.season_format === 'HEAD_TO_HEAD' || filtersData.season?.season_format === 'PLAYER_RACE') || (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints)) &&
                                                    <TopFivePlayerSummary tenantLogoDataUrl={tenantLogoDataUrl} roundId={filtersData.round?._id} filtersData={filtersData} matchId={filtersData.match?._id} seasonFormat={filtersData.season?.season_format} />
                                                }
                                            </Route>
                                            <Redirect to={`${path}`} />
                                        </Switch>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={1}>
                                </Grid> */}
                            </Grid>
                        }

                    </Route>
                    <Redirect to={`${path}`} />
                </Switch>
            </div>

            <ShareLiveScoreDialog />
        </div >
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    userDetails: state.user,
    orgDetails: state.common.orgDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveScores));
