import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
  formatDate,
  getBase64WithUrl,
  decodeEnum,
  downloadFiles,
  getCurrencyFormat,
  getDurationWithUnit,
  getNumberFormat,
} from "../../helpers";
import { useSnackbar } from "notistack";
import moment from "moment";
import { setLoading } from "../../ducks/loading";
import {
  getMyTeamLivePerformance,
  getRoundTeamStatistics,
  getTeamRaceLiveScore,
  shareLiveScoreDialog,
} from "../../ducks/game";

const useStyles = makeStyles((theme) => ({
  imageTitle: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    margin: -25,
    "& div:first-child": {
      marginRight: 10,
      textAlign: "right",
      "& p": {
        margin: 0,
        "&:last-child": {
          fontSize: 11,
        },
      },
    },
  },
  tableTitle: {
    fontSize: 30,
    margin: "30px 0 0",
    "& p": {
      margin: 0,
      "&:last-child": {
        fontSize: 16,
        marginTop: -10,
        "& .divider": {
          margin: "0 10px",
          color: "orange",
          fontSize: 30,
          fontWeight: 500,
          verticalAlign: "middle",
        },
      },
    },
  },
}));

const PerformanceTableEmailTemplate = ({
  roundId,
  render = false,
  id = "",
  filtersData,
  data,
  columns,
  components,
  orgDetails,
  divisionName = "",
  belongsTo = "Teams",
  getRoundTeamStatistics,
  getTeamRaceLiveScore,
  shareLiveScoreDialog,
  setLoading,
  matchId = "",
  userDetails,
  loading,
  seasonFormat,
  dialog,
  history,
}) => {
  const classes = useStyles();

  const [orgLogo, setOrgLogo] = useState("");

  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicHeaders, setDynamicHeaders] = useState([]);
  const [data_, setData_] = useState([]);

  const [resData, setResData] = useState({ response: [], headers: null });
  const [decimalView, setDecimalView] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (orgDetails?.logo_url) {
      getBase64WithUrl(orgDetails?.logo_url)
        .then((res) => {
          //console.log('res org logo ', res);
          setOrgLogo(res);
        })
        .catch((err) => console.log(err.response));
    }
  }, [orgDetails?.logo_url]);

  useEffect(() => {
    console.log(
      "useEffect >>> Performance Table Email Template filtersData.match",
      filtersData.match
    );

    if (filtersData.match == null && filtersData.round._id) {
      let roundId = filtersData.round._id;
      getRoundTeamStatistics({
        roundId,
        onSuccessCB,
        onErrorCB,
        params: { limit: 5000, page: 1 },
      });
    }
  }, [roundId]);

  useEffect(() => {
    if (filtersData.round == null && filtersData.match._id) {
      console.log("useEffect >>> Performance Table Email Template inside IF");
      let matchId = filtersData.match._id;
      getTeamRaceLiveScore({
        matchId,
        onSuccessCB,
        onErrorCB,
        params: { limit: 5000, page: 1 },
      });
    }
  }, [matchId]);

  const onSuccessCB = (response, resheaders, fromDecimalView) => {
    if (!fromDecimalView) {
      setResData({ response, headers: resheaders });
    }

    console.log("onSuccessCB    ", response);
    if (response.length) {
      let headers = [];
      const finalResult = response.sort((a, b) => parseFloat(b.total_score) - parseFloat(a.total_score)).reduce((result, item, index) => {
        let obj = {
          ...item,
          totalPoints: item.total_score,
          selected: item._id === resheaders?.my_team_id,
          hilighted: index === 0,
          kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id ? 1 : -1)),
        };
        // let totalPoints = 0;
        obj.kpis.forEach((el, i) => {
          // obj[`${el.kpi_id}-target`] = el.target;
          obj[`${el.kpi_id}-actual`] = el.actual;
          // obj[`${el.kpi_id}-achieved`] = el.achieved;
          obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
          obj[`${el.kpi_id}-unit`] = el.unit;
          // obj['totalPoints'] = totalPoints += el.score;
          if (index === 0) {
            headers.push(el);
            obj.columns = [
              ...(obj.columns || []),
              {
                title: `${el.kpi_code || el.kpi_name}`,
                field: `${el.kpi_id}-actual`,
                editable: "never",
                emptyValue: "--",
                cellStyle: { maxWidth: 150, minWidth: 100 },
                templateRender: (rowData) =>
                  rowData[`${el.kpi_id}-unit_of_measurement`] === "CURRENCY"
                    ? getCurrencyFormat(
                        userDetails?.countryCode,
                        rowData[`${el.kpi_id}-unit`],
                        rowData[`${el.kpi_id}-actual`]
                      )
                    : `${getNumberFormat(
                        userDetails?.countryCode,
                        rowData[`${el.kpi_id}-actual`],
                        decimalView
                      )} ${
                        rowData[`${el.kpi_id}-unit_of_measurement`] ===
                        "PERCENTAGE"
                          ? "%"
                          : rowData[`${el.kpi_id}-unit_of_measurement`] ===
                            "DURATION"
                          ? getDurationWithUnit(
                              rowData[`${el.kpi_id}-actual`],
                              rowData[`${el.kpi_id}-unit`]
                            )
                          : ""
                      }`,
                render: (rowData) =>
                  rowData[`${el.kpi_id}-unit_of_measurement`] === "CURRENCY"
                    ? getCurrencyFormat(
                        userDetails?.countryCode,
                        rowData[`${el.kpi_id}-unit`],
                        rowData[`${el.kpi_id}-actual`]
                      )
                    : `${getNumberFormat(
                        userDetails?.countryCode,
                        rowData[`${el.kpi_id}-actual`],
                        decimalView
                      )} ${
                        rowData[`${el.kpi_id}-unit_of_measurement`] ===
                        "PERCENTAGE"
                          ? "%"
                          : rowData[`${el.kpi_id}-unit_of_measurement`] ===
                            "DURATION"
                          ? getDurationWithUnit(
                              rowData[`${el.kpi_id}-actual`],
                              rowData[`${el.kpi_id}-unit`]
                            )
                          : ""
                      }`,
                // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
              },
            ];
          }
        });
        return [
          ...result,
          {
            ...obj,
            team: {
              name: obj.name || obj.team_name,
              logo_url: obj.logo_url || obj.team_logo_url,
              _id: obj.team_id,
            },
          },
        ];
      }, []);
      if (finalResult.length > 0) {
        setDynamicColumns(finalResult[0].columns || []);
        setDynamicHeaders([...headers]);
        setData_(finalResult);
      } else {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData_([]);
      }
    } else {
      setDynamicColumns([]);
      setDynamicHeaders([]);
      setData_([]);
    }
    setLoading(false);
  };

  const onErrorCB = (error) => {
    setDynamicColumns([]);
    setDynamicHeaders([]);
    setData_([]);
    setLoading(false);
    enqueueSnackbar(
      error.response && error.response.data
        ? error.response.data.error_description
        : "Failed to fetch summary",
      {
        variant: "error",
        preventDuplicate: true,
      }
    );
  };

  return (
    <div style={{ position: "absolute", zIndex: -1, top: 0, left: 0 }}>
      {" "}
      {/* to hide position: 'absolute', zIndex: -1 */}
      <div id={id} className="custom-table">
        <div className={classes.imageTitle}>
          <div>
            <p>{filtersData.league?.name}</p>
            <p>{filtersData.season?.name}</p>
          </div>
          <div>
            <img src={orgLogo} alt="logo" width="40" height="40" />
          </div>
        </div>
        {render && (
          <>
            <div className={classes.tableTitle}>
              <p>
                {filtersData?.season?.season_format === "HEAD_TO_HEAD"
                  ? `Round- ${belongsTo}`
                  : `Sprint- ${belongsTo}`}{" "}
                ({`as on ${formatDate(moment.now())}`})
              </p>
              <p>
                <span>
                  {filtersData.round
                    ? filtersData.round?.name
                    : filtersData.match
                    ? filtersData.match?.title
                    : ""}
                </span>
                <span>
                  <span className="divider">|</span>
                  {formatDate(
                    filtersData.round
                      ? filtersData.round?.start_date
                      : filtersData.match?.start_date
                  )}{" "}
                  -{" "}
                  {formatDate(
                    filtersData.round
                      ? filtersData.round?.end_date
                      : filtersData.match?.end_date
                  )}
                </span>
                {filtersData.season?.divisions_enabled && divisionName && (
                  <span>
                    <span className="divider">|</span>
                    {/* {divisionName} */}
                    {filtersData.season.divisions.map((division, index) => (
                      <span>{(index ? '& ' : '') + division.name} </span>
                    ))}{""}
                  </span>
                )}
              </p>
            </div>
            <table>
              {components?.Header ? (
                [components.Header({ columns })]
              ) : (
                <thead>
                  <tr>
                    {columns.map((col, i) => (
                      <th key={i}>{col.title}</th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {data_.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map(
                      (col, i) =>
                        !col.hidden && (
                          <td
                            style={{ ...col?.cellStyle, color: "#000" }}
                            key={i}
                            align={col.align}
                          >
                            {col.templateRender
                              ? col.templateRender({
                                  ...row,
                                  tableData: { id: rowIndex },
                                })
                              : row[col.field]}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgDetails: state.common.orgDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
    getRoundTeamStatistics: (options) =>
      dispatch(getRoundTeamStatistics(options)),
    getTeamRaceLiveScore: (options) => dispatch(getTeamRaceLiveScore(options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceTableEmailTemplate);
