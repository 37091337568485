import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Menu, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { getSearchValue, historyPushWithSearch, setSearchQuery } from "../../helpers";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole } from "../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import MentorDashboard from "./MentorDashboard";
import PlayerDashboard from "./PlayerDashboard";
import NonPlayerDashboard from "./NonPlayerDashboard";
import { Fade } from "@material-ui/core";
import Dashboard from "./Dashboard";
import LiveScores from "../LiveScores";
import PointsTables from "../PointsTables";
import PerformanceStats from "../PerformanceStats";
import Leaderboards from "../Leaderboards";
import MatchDetails from "../LiveScores/MatchDetails";
import LiveMatches from "./LiveMatches";
import AllMatches from "./AllMatches";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "446px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: '120px'
  },
  paper: {
    background: "#CCCDFD",
    color: "white"
  },

  input: {
    color: "#CCCDFD",
    backgroundColor: "#CCCDFD",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#CCCDFD",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)"
    }
  },
  autocompleteRoot: {
    borderRadius: 30,
    padding: '0px 16px !important',
    fontSize: 20,
    backgroundColor: '#ebebfe',
    // width: 280
    margin: '12px 10px 0px',
    borderColor: '#EBEBFF',
    marginLeft: 32,
    color: "#5D5FEF",
  },

  tabsContainerStyles: {
    // justifyContent: 'center',
    marginLeft: 34
  },
  tabsRoot: {
    // position: 'fixed',
    // top: 60,
    // left: 0,
    // backgroundColor: '#FFF',
    width: '100%',
    maxWidth: '100%',
    minHeight: 40,
    zIndex: 11,
    transition: 'top 0.2s ease-in-out',
    boxShadow: '-4px 3px 10px -7px #000'
  },
  tabStyles: {
    minHeight: 40,
    textTransform: 'capitalize',
    color: '#262626',
    minWidth: 'auto',
    // margin: '0px 12px',
    padding: '6px 12px 0px',
    fontSize: 13,
  },
  selectedTab: {
    fontWeight: 600
  },
  button: {
    borderRadius: 30,
    color: "#5D5FEF",
    backgroundColor: "#EBEBFF",
    padding: "5px 14px",
    fontSize: "20",
    fontWeight: 500,
    marginLeft: 24,

  },
  rightIcon: {
    padding: "0px 0 0 20px",
  },

  paperRoot: {
    minWidth: 200
  },
  customWidth: {
    '& div': {
      // this is just an example, you can use vw, etc.
      width: '350px',
    }
  }
}));

const defaultFilters = {
  league: null,
  season: null,
  round: null,
  status: 'LIVE',
  match: null
};

const MenuOptions = [
  { id: 'DASHBOARD', name: 'Dashboard', pathname: 'dashboard' },
  { id: 'LIVE_SCORES', name: 'Live Score', pathname: 'live-scores' },
  { id: 'POINTS_TABLE', name: 'Points Table', pathname: 'points-table' },
  { id: 'ALL_MATCHES', name: 'All Matches', pathname: 'matches' },
  { id: 'STATS', name: 'Stats', pathname: 'stats' },
  { id: 'TRENDS', name: 'Trends', pathname: 'trends' },
  { id: 'LEADERBOARD', name: 'Leaderboard', pathname: 'leaderboard' },
];

const Leagues = ({ history, location, getLeaguesList, getRoundsList, getMatchesList, getOverallOrgStats, getLoggedInUserRole, getSeasonsList, }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('DASHBOARD');
  const [filtersData, setFiltersData] = useState({ ...defaultFilters });
  const [leagues, setLeagues] = useState([]);
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const [seasons, setSeasons] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);

  useEffect(() => {
    getLeagues();
    setSearchQuery(history, { match: "" });
    setFiltersData(prevState => ({ ...prevState, match: null }));
    // historyPushWithSearch(`${path}`, history, location.search);
  }, []);

  useEffect(() => {
    if (filtersData.season?._id) {
      let seasonId = filtersData.season?._id;
      getLoggedInUserRole({ seasonId, onSuccessCB, onErrorCB });
      getRounds(seasonId);
    }
  }, [filtersData.season?._id]);

  useEffect(() => {
    if (filtersData.league) getSeasons(filtersData.league._id);
  }, [filtersData.league]);

  const getLeagues = () => {
    setLoading(true);
    console.log('Inside get leagues')
    getLeaguesList({
      params: { limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        //console.log('Get leagues list', result, headers)
        setLeagues([...result]);
        const searchValue = getSearchValue(history, 'league');
        !searchValue && setSearchQuery(history, { league: result[0]?._id });
        setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
        setLoading(false);

      }, onErrorCB: (error) => {
        setLoading(false);
        setFiltersData(prevState => ({ ...defaultFilters }));

      }
    })
  };

  const getSeasons = (leagueId) => {
    // setLoading(true);
    setLoadingSeasons(true);
    getSeasonsList({
      leagueId: leagueId,
      params: { limit: 5000, page: 1, status: "live" },
      onSuccessCB: (result, headers) => {
        if (result.length > 0) {
          setSeasons([...result]);
          const searchValue = getSearchValue(history, 'season');
          !searchValue && setSearchQuery(history, { season: result[0]?._id });
          if (!(filtersData.season?._id)) { setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null })); }
          setSelectedSeason(searchValue ? result.find(obj => obj._id === searchValue) : result[0]);
        }
        else {
          // setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
          setSeasons([]);
          setRounds([]);
        }
        setLoadingSeasons(false);
      }, onErrorCB: (error) => {
        setLoadingSeasons(false);
        setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));

      }
    })
  }

  const getRounds = (seasonId) => {
    setLoading(true);
    getRoundsList({
      seasonId: seasonId,
      params: { limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        if (result.length > 0) {
          setRounds([...result]);
          const searchValue = getSearchValue(history, 'round');
          !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
          setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id)) }));
        }
        else {
          setRounds([]);
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }

  const onSuccessCB = (response) => {
    if (response) {
      setUserInfo({ ...response });
    }
  }

  const onErrorCB = (error) => {
    console.log("on Error CB Dashboard", error)
  }


  // const handleChangeTab = (event, newValue) => {
  //   if (MenuOptions[newValue])
  //     setValue(MenuOptions[newValue].id);
  // };

  const handleTabChange = (e, newValue) => {
    historyPushWithSearch(`${path}/${MenuOptions[newValue].pathname}`, history, location.search);
  }

  const handleRightClick = (event) => {
    event.preventDefault();
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleSeasonClick = (season) => {
    setAnchorEl(null);
    setMenuPosition(null);
    setSelectedSeason(season);
    setFiltersData(prevState => ({ ...prevState, season }));
  }

  const [loadingSeasons, setLoadingSeasons] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleItemClick = (event, league) => {
    setAnchorEl(event.currentTarget);
    console.log('handleItemClick ', event, league);
    setFiltersData(prevState => ({ ...prevState, league }));
    // if (league) getSeasons(league._id);
  };

  return (
    <>

      <div style={{ display: "flex", flexDirection: 'column', backgroundColor: "#F1F0FD", width: '100%', height: "calc(100vh - 60px)", overflowY: 'auto' }}>
        <div style={{ width: '100%' }}>
          <div style={{ marginLeft: 26, margin: 10 }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              onClick={handleRightClick}
              className={classes.button}
            //onMouseOver={handleRightClick}
            >
              {selectedSeason ? selectedSeason.name : filtersData.league?.name}
              <KeyboardArrowDownOutlined className={classes.rightIcon} />

            </Button>



            <Menu
              id="simple-menu"
              open={!!menuPosition}
              onClose={() => setMenuPosition(null)}
            // className={classes.customWidth}

            >
              {leagues.length > 0 && leagues.map((league) => (
                <MenuItem onClick={(e) => handleItemClick(e, league)} style={{ justifyContent: "space-between" }}>{league.name}
                  <ChevronRightIcon className={classes.rightIcon} />
                </MenuItem>
              ))
              }
            </Menu>
            {<Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "center", horizontal: "left" }}
              anchorPosition="right"
              style={{ minWidth: 200 }}
              classes={{ paper: classes.paperRoot }}
            >
              {loadingSeasons ? <p style={{ margin: 16, textAlign: 'center' }}>Loading....</p> : [
                (seasons.length > 0) ? seasons.map((season) => (
                  <MenuItem onClick={() => handleSeasonClick(season)}>{season.name}</MenuItem>
                ))
                  : <p style={{ margin: 16, textAlign: 'center' }}>No Seasons Found</p>
              ]}
            </Menu>}
          </div>
        </div>


        <Tabs
          id="filter-tabs"
          value={MenuOptions.map((option) => option.pathname).indexOf(location.pathname.split('/')[2])}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          // scrollButtons="on"
          aria-label="scrollable auto tabs example"
          className={classes.tabsRoot}
          classes={{ flexContainer: classes.tabsContainerStyles }}
        >
          {
            [...MenuOptions].map((obj, i) =>
              <Tab className={classes.tabStyles} classes={{ selected: classes.selectedTab }} key={i} label={obj.name} />
            )
          }
        </Tabs>
        <Switch>
          <Route path={`${path}/dashboard`}>
            <Dashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} />
          </Route>
          <Route path={`${path}/live-scores`}>
            <LiveScores filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/points-table`} >
            <PointsTables filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/matches`} >
            <AllMatches filtersData={filtersData} setFiltersData={setFiltersData} rounds={rounds} userInfo={userInfo} />
          </Route>
          <Route path={`${path}/stats`} >
            <PerformanceStats type='stats' filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/trends`} >
            <PerformanceStats type='trends' filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/leaderboard`} >
            <Leaderboards filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Redirect to={{ pathname: `${path}/dashboard`, search: location.search }} />
        </Switch>
        {/* <div style={{ padding: '24px 46px' }}>
          {
            userInfo?.role === 'PLAYER' ?
              <PlayerDashboard filtersData={filtersData} setFiltersData={setFiltersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
              : userInfo?.role === 'MENTOR' ? <MentorDashboard filtersData={filtersData} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToTeamLeaderboard={navigateToTeamLeaderboard} />
                : <NonPlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
          }
        </div> */}
      </div>

    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
    getSeasonsList: (options) => dispatch(getSeasonsList(options)),
    getLeaguesList: (options) => dispatch(getLeaguesList(options)),
    getRoundsList: (options) => dispatch(getRoundsList(options)),
    getMatchesList: (options) => dispatch(getMatchesList(options)),

  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Leagues));