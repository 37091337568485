import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { Close } from '@material-ui/icons';
import { openCustomDialog } from '../../ducks/meetings';
import { DialogTitle, DialogContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { getEllipsisText } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paperFullScreen: {
        width: 400
    },
    scrollPaper: {
        justifyContent: 'flex-end'
    },
    dialogTitle: {
        padding: "14px 50px 14px 25px",
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: "#f5f6f7",
        color:"#000000",
        fontWeight:"bold",
        
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 0,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CustomDialogComponent = ({ dialogState: { open, title, children }, openCustomDialog }) => {
    const classes = useStyles();

    const handleClose = () => {
        openCustomDialog({ open: false, children: null, title: '' });
    };

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} classes={{ paperFullScreen: classes.paperFullScreen, scrollPaper: classes.scrollPaper }} TransitionComponent={Transition}>
                <DialogTitle
                    disableTypography
                    onClose={handleClose}
                    className={classes.dialogTitle}
                >
                    {getEllipsisText(title, 20)}
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dialogState: state.meetings.customDailog
});

const mapDispatchToProps = (dispatch) => {
    return {
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogComponent);