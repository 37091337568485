import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import { getTeamCoreKpisStats } from "../../../ducks/game";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import IllustrationView from "../../LiveScores/IllustrationView";
import MaterialUITable from "../../GlobalComponents/MaterialUITable";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '15px 0 0px',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const TeamCoreKpisStats = ({ setLoading, loading, seasonId = '', roundId = '', matchId = '', userDetails, getTeamCoreKpisStats }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);

    useEffect(() => {
        if (roundId) {
            setLoading(true);
            getTeamCoreKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { round_id: roundId === 'ALL' ? '' : roundId, limit: 5000, page: 1 } });
        }
    }, [roundId]);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            getTeamCoreKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, limit: 5000, page: 1 } });
        }
    }, [matchId]);

    const onSuccessCB = (response, resheaders) => {
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item._id === resheaders?.my_team_id, kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            render: (rowData) =>
                                (['SR4', 'SR5','SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                        :
                                        [
                                            // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                            //     `No data available`
                                            //     : 
                                            `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                        ]

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    :
                                    [
                                        // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                        //     ''
                                        //     : 
                                        `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                    ]
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            hidden: (['TBSR1', 'TBSR2', 'TBSR4','SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS'),
                            render: (rowData) =>
                                // (roundId === 'ALL' && ((rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' : 
                                getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: obj.name, logo_url: obj.logo_url, _id: obj._id } }];

            }, []);
            console.log('finalResult ', finalResult, headers);
            if (finalResult.length > 0) {
                setData(finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
            }
            else {
                setData([]);
                setDynamicColumns([]);
                setDynamicHeaders([]);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Statistics', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const columns = [
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 180 },
            emptyValue: '--',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--'
        }
    ]

    return (
        <>
            { (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                <div className={classes.root}>
                    <div className="material-table">
                        <MaterialUITable
                            title="Round Summary"
                            columns={[...columns, ...dynamicColumns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [5, 10, 25],
                                pageSize: 25,
                                paging: data.length > 5 ? true : false
                            }}
                            localization={{ body: { emptyDataSourceMessage: <p style={{ textAlign: 'center' }}>No data available</p> } }}
                            components={{
                                Header: props => (
                                    <TableHead className="custom-headers">
                                        <TableRow>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            {/* <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell> */}
                                            {
                                                dynamicHeaders.map((obj, index) => (
                                                    <TableCell colSpan={(['SR4', 'SR5','SR6','TBSR1', 'TBSR2','TBSR4'].includes(obj.scoring_rule) && obj.based_on === 'ACTUALS') ? 2 : 3} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                        {/* <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip> */}
                                                        {obj.kpi_name}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                dynamicHeaders.map((obj) => (
                                                    <>
                                                        <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>
                                                        <TableCell align="center" >Actual</TableCell>
                                                        {((['TBSR1', 'TBSR2','TBSR4', 'SR4', 'SR5','SR6'].includes(obj.scoring_rule) && obj.based_on === 'ACHIEVEMENT') || ['SR1', 'SR2', 'SR3', 'TBSR3'].includes(obj.scoring_rule)) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                    </>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                )
                            }}
                        />
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamCoreKpisStats: options => dispatch(getTeamCoreKpisStats(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamCoreKpisStats));
