import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getLeaguesList, getSeasonsList, getMatchesList, getRoundsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import RoundsListView, { roundStatuses } from "../../LiveScores/RoundsListView";
import TeamCoreKpisStats from './TeamCoreKpisStats';
import TeamBonusKpisStats from './TeamBonusKpisStats';
import FieldSet from '../../GlobalComponents/FieldSet';
import MatchListView from '../MatchListView';
import { getSearchValue, setSearchQuery } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    filtersRoot: {
        padding: '0px 40px 20px',
        // padding: '80px 80px 20px',
        // width: 'calc(100% - 160px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyles: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
    },
    cardHeights: {
        maxWidth: '100%',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        padding: '0px',
        textAlign: 'center'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: 20,
    },
    filedSetRootStyles1: {
        padding: 20,
        minHeight: 300
    },
    legendRootStyles: {
        fontSize: 14
    }
}));

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    match: null,
    bonusMatch: null,
    status: 'LIVE'
};

const OverallStats = ({ getLeaguesList, getSeasonsList, getMatchesList, getRoundsList, setLoading, loading, history }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [matches, setMatches] = useState([]);
    const [sprintMatches, setSprintMatches] = useState([]);

    useEffect(() => {
        getLeagues();
    }, []);

    useEffect(() => {
        if (filtersData.league) getSeasons(filtersData.league._id);
    }, [filtersData.league]);

    useEffect(() => {
        if (filtersData.season) {
            if (filtersData.season.season_format === 'HEAD_TO_HEAD') {
                getRounds(filtersData.season._id);
                getMatches(filtersData.season?._id, null, 'TEAM_BONUS');
            }
            else {
                getMatches(filtersData.season?._id, null, filtersData.season.season_format === 'TEAM_RACE' ? 'TEAM_SPRINT' : 'PLAYER_SPRINT');
                getMatches(filtersData.season?._id, null, filtersData.season.season_format === 'TEAM_RACE' ? 'TEAM_BONUS' : 'INDIVIDUAL_BONUS');
            }
        }
    }, [filtersData.season]);

    // useEffect(() => {
    //     if (filtersData.round) getMatches(filtersData.season?._id, null, 'TEAM_BONUS');
    //     else {
    //         setMatches([]);
    //         setSprintMatches([]);
    //         setFiltersData(prevState => ({ ...prevState, match: null, bonusMatch: null }));
    //     }
    // }, [filtersData.round]);

    const handleChangeAutocomplete = (data, field) => {
        setSearchQuery(history, { [field]: data._id });
        if (field === 'season') {
            setFiltersData(prevState => ({ ...prevState, [field]: data, bonusMatch: null, round: null, match: null }));
        }
        else
            setFiltersData(prevState => ({ ...prevState, [field]: data }));
    };

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0] }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasons = (leagueId) => {
        setLoading(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1, status: 'live' },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null, match: null, bonusMatch: null }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                    setRounds([]);
                    setMatches([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getRounds = (seasonId) => {
        setLoading(true);
        getRoundsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const modifiedResult = [{ name: 'All', round_status: 'ALL', _id: 'ALL' }, ...result];
                    setRounds([...modifiedResult]);
                    const searchValue = getSearchValue(history, 'round');
                    !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                    setFiltersData(prevState => ({ ...prevState, round: (searchValue ? modifiedResult?.find(obj => obj._id === searchValue) : modifiedResult?.find(obj => obj._id === headers?.latest_round_id)) })); // result?.find(obj => obj._id === headers?.latest_round_id)
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                    setRounds([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getMatches = (seasonId, roundId, type) => {
        setLoading(true);
        getMatchesList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, type, round_id: roundId },
            onSuccessCB: (result, headers) => {
                // setMatches([]);
                // setSprintMatches([]);
                if (result.length > 0) {
                    result.unshift({ title: 'All', match_status: 'ALL', _id: 'ALL' })
                    if (type === 'TEAM_BONUS' || type === 'INDIVIDUAL_BONUS') {
                        setMatches([...result]);
                        const searchValue = getSearchValue(history, 'bonusMatch');
                        !searchValue && setSearchQuery(history, { bonusMatch: (result?.find(obj => obj.status === 'live') || result[0])?._id });
                        setFiltersData(prevState => ({ ...prevState, bonusMatch: searchValue ? result?.find(obj => obj._id === searchValue) : (result?.find(obj => obj.status === 'live') || result[0]) }));
                    }
                    else {
                        setSprintMatches([...result]);
                        const searchValue = getSearchValue(history, 'match');
                        !searchValue && setSearchQuery(history, { match: (result?.find(obj => obj.status === 'live') || result[0])?._id });
                        setFiltersData(prevState => ({ ...prevState, match: searchValue ? result?.find(obj => obj._id === searchValue) : (result?.find(obj => obj.status === 'live') || result[0]) }));
                    }
                }
                else {
                    if (type === 'TEAM_BONUS' || type === 'INDIVIDUAL_BONUS') {
                        setMatches([]);
                    }
                    else {
                        setSprintMatches([]);
                    }
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, round: prevState.round }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    // const getMatches = (seasonId, roundId) => {
    //     setLoading(true);
    //     getMatchesList({
    //         seasonId: seasonId,
    //         params: { limit: 5000, page: 1, type: 'TEAM_BONUS' },
    //         onSuccessCB: (result, headers) => {
    //             if (result.length > 0) {
    //                 result.unshift({ title: 'All', match_status: 'ALL', _id: 'ALL' })
    //                 setMatches([...result]);
    //                 setFiltersData(prevState => ({ ...prevState, match: result[0] }));
    //             }
    //             else {
    //                 setMatches([]);
    //                 setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, round: prevState.round }));
    //             }
    //             setLoading(false);
    //         }, onErrorCB: (error) => {
    //             setLoading(false);
    //             enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
    //                 variant: 'error',
    //                 preventDuplicate: true
    //             });
    //         }
    //     })
    // }

    const onRoundClick = (round) => {
        setSearchQuery(history, { round: round._id });
        setFiltersData(prevState => ({ ...prevState, round }));
    }

    const onBonusMatchClick = (bonusMatch) => {
        setSearchQuery(history, { bonusMatch: bonusMatch._id });
        setFiltersData(prevState => ({ ...prevState, bonusMatch }));
    }

    const onMatchClick = (match) => {
        setSearchQuery(history, { match: match._id });
        setFiltersData(prevState => ({ ...prevState, match }));
    }

    return (
        <div className={classes.root}>
            <div className={classes.filtersRoot}>
                {/* <FieldSet title={"Season Selection"} rootStyles={classes.filedSetRootStyles} legend={classes.legendRootStyles}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-leagues"
                                options={leagues}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.league}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="League" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'league')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-seasons"
                                options={seasons}
                                getOptionLabel={(option) => option.name || ''}
                                value={seasons.length && filtersData?.season}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Season" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'season')}
                            />
                        </Grid>
                    </Grid>
                </FieldSet> */}
                <Grid style={{ marginTop: 20 }} container spacing={2} >
                    <Grid item md={6} xs={12}>
                        <FieldSet title={"Core KPI"} rootStyles={classes.filedSetRootStyles1} legend={classes.legendRootStyles}>
                            {
                                (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                                    <RoundsListView rounds={rounds} filtersData={filtersData} onRoundClick={onRoundClick} />
                                    :
                                    <MatchListView title={'Sprints'} type={'SPRINT'} matches={sprintMatches} filtersData={filtersData} loading={loading} onMatchClick={onMatchClick} />
                            }
                            {(filtersData.round || filtersData.match) && <TeamCoreKpisStats seasonId={filtersData.season?._id} roundId={filtersData.round?._id} matchId={filtersData.match?._id} loading={loading} />}
                        </FieldSet>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FieldSet title={"Bonus KPI"} rootStyles={classes.filedSetRootStyles1} legend={classes.legendRootStyles}>
                            <MatchListView title={'Bonus Matches'} type={'BONUS'} matches={matches} filtersData={filtersData} loading={loading} onMatchClick={onBonusMatchClick} />
                            {filtersData.bonusMatch && <TeamBonusKpisStats seasonId={filtersData.season?._id} matchId={filtersData.bonusMatch?._id} loading={loading} />}
                        </FieldSet>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OverallStats));
