import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { KeyboardArrowRight } from '@material-ui/icons';
import { PerformanceLineGraphGreen, PerformanceLineGraphRed, PerformanceLineGraphAmber } from '../PerformanceStats/OverallTrends/PerformanceGraph';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getOverallTrends, getTopPlayer, getTopTeam, getTeamMatchLeaderBoard, getKpiWisePlayerLeaderBoard } from "../../ducks/game";
import { setLoading } from '../../ducks/loading';
import { useSnackbar } from 'notistack';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../helpers';
import { handleDurationChange } from 'video-react/lib/actions/video';
import Box from "@material-ui/core/Box";
import { formatDateUTC } from '../../helpers';
import { formattedValue } from './MyPerformance';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "300px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "446px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },


  actual: {
    fontSize: 14,
    margin: 0,
    color: '#232637',
    // paddingRight: 5,
    textAlign: 'right',
    '& .achievmentPercent': {
      fontSize: 11,
      color: '#1CBF67',
      margin: 0,
    }
  },
  scoreCardsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      minWidth: 140,
      minHeight: 125,
      border: '1px solid #EDEDED',
      borderRadius: 3,
      margin: '16px 10px 10px 0',
      padding: 10,
    },
    '& .title': {
      color: '#000000',
      fontWeight: 700,
      fontSize: 12,
      margin: '10px 0'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      marginTop: 20,
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .scoreRoot': {
      fontSize: 12,
      color: '#000',
      margin: 0,
      fontWeight: 700,
      '& .score': {
        fontSize: 28,
      }
    },
    '& .achievment': {
      fontSize: 12,
      color: '#000',
      marginBottom: 0
    }
  },

  cardRoot: {
    minWidth: 120,
    minHeight: 125,
  },
  viewRightTopContainer: {
    top: "-10px",
    left: "11px",
    position: "relative",
    padding: "10px",
    cursor: "pointer",
    width: "fit-content",
    float: "right",
    backgroundColor: "#D6AF36",
    "&>*": {
      marginLeft: "5px",
      fontSize: 12,
    },
    borderEndStartRadius: 40,


  }
}));


const OverallOrgStats = ({ filtersData = {}, getOverallTrends, getTopPlayer, getTopTeam, getTeamMatchLeaderBoard, getKpiWisePlayerLeaderBoard, userDetails }) => {
  const classes = useStyles();

  const [trends, setTrends] = useState([])
  const [kpi_id, setKpi_id] = useState('')
  const { enqueueSnackbar } = useSnackbar();
  const [topTeams, setTopTeam] = useState([])
  const [topPlayer, setTopPlayer] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [labelData, setLabelData] = useState([])


  useEffect(() => {
    // console.log('filtersData player Overallorgstats', filtersData)
    if (filtersData.season?._id) {
      let seasonId = filtersData.season?._id;
      getOverallOrgStats(seasonId)
      //getOverallTrends({ seasonId, onSuccessCB, onErrorCB });
      //getTeamMatchLeaderBoard({ onSuccessCB, onErrorCB, params: { seasonId, limit: 1, page: 1 } });
    }

  }, [filtersData.season?._id]);

  useEffect(() => {
    let seasonId = filtersData.season?._id;
    let kpiId = '628495cd7984fd0012326c93';
    //getTeamMatchLeaderBoard({ onSuccessCB, onErrorCB, params: { seasonId, kpiId, limit: 1, page: 1 } });
    //getKpiWisePlayerLeaderBoard({ onSuccessCB, onErrorCB, params: { seasonId, kpiId, limit: 1, page: 1 } });
    // getTopPlayerDetails(seasonId, kpiId);
    // getTopTeamDetails(seasonId, kpiId)

  }, []);

  const onSuccessCB = (response) => {
    console.log("on onSuccessCB CB player Dashboard", response)
    setTopTeam(response)
    //setTrends(response)
    // if (response.kpi_id) {
    //   console.log("on onSuccessCB CB player Dashboard kpi_id", response.kpi_id)
    //   setKpi_id(response.kpi_id)
    // }

  }


  const getOverallOrgStats = (seasonId) => {
    setLoading(true);
    getOverallTrends({
      seasonId: seasonId,
      //params: { season_id: seasonId, limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        if (result) {
          console.log('get overall trends', result);
          // 
          //setTrends(result)


          const finalResult = result.map((item) => ({ ...item, actualsData: item.individual_actuals?.map(obj => obj.value), actualLabels: item.individual_actuals?.map(obj => obj.date) }));
          setTrends(finalResult)
          console.log('Final result overall graph', finalResult)




        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }

  const getTopPlayerDetails = (seasonId, kpi_id) => {
    setLoading(true);
    console.log('Get top player Details', seasonId, kpi_id)
    getKpiWisePlayerLeaderBoard({
      params: { limit: 1, page: 1, season_id: seasonId, kpi_id },
      onSuccessCB: (result, headers) => {
        if (result) {
          console.log('Set Top Player', result)
          setTopPlayer(result)
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }

  const getTopTeamDetails = (seasonId, kpi_id) => {
    setLoading(true);
    console.log('Get top Team Details', seasonId, kpi_id)
    getTeamMatchLeaderBoard({
      params: { limit: 1, page: 1, season_id: seasonId, kpi_id },
      onSuccessCB: (result, headers) => {
        if (result) {
          console.log('Set Top Player', result)
          setTopTeam(result)
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }


  const onErrorCB = (error) => {
    console.log("on Error CB Dashboard", error)
  }



  const handleChange = (kpi_id, i) => {
    setLoading(true);
    console.log('Expanded accordion', kpi_id, "i", i)
    setExpanded(i);
    let seasonId = filtersData.season?._id;
    getTopPlayerDetails(seasonId, kpi_id);
    getTopTeamDetails(seasonId, kpi_id);
    setLoading(false);

  }



  return (
    <div style={{ margin: '-12px -16px' }}>
      {trends.length > 0 ? trends.map((item, index) =>

        <Accordion onChange={() => handleChange(item.kpi_id, index)} expanded={expanded === index}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div key={index} style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', justifyContent: 'space-between', height: 60, width: '100%', margin: '-8px 0' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                <KeyboardArrowRight fontSize="small" />
                <span>{item.kpi_name}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>

                {item.achievement > 90 ? <PerformanceLineGraphGreen labels={item.actualLabels} actuals={item.actualsData} /> : item.achievement < 75 ? <PerformanceLineGraphRed labels={item.actualLabels} actuals={item.actualsData} /> : item.achievement <= 90 && item.achievement >= 75 ? <PerformanceLineGraphAmber labels={item.actualLabels} actuals={item.actualsData} /> : null}
                {/* <PerformanceLineGraph labels={item.actualLabels} actuals={item.actualsData} achievement={item.achievement} /> */}

                {/* <PerformanceLineGraph labels={[1, 2]} actuals={[3, 40, 5, 25, 7]} /> */}
                <p className={classes.actual}>{formattedValue(userDetails, item.total_actuals, item)}
                  <p className="achievmentPercent">{`${getNumberFormat(userDetails?.countryCode, item.achievement)}`}%</p></p>
              </div>
            </div>
          </AccordionSummary>
          <div className={classes.scoreCardsRoot}>
            <AccordionDetails>
              {topTeams.length > 0 && topTeams.map((i, index) => (
                <div className={classes.scoreCardsRoot}>
                  <div key={index} className={'scoreCard'}>
                    <Box
                      p={1}
                      display="flex"
                      justifyContent="flex-end"
                      flexDirection="row"
                      className={classes.viewRightTopContainer}
                    >
                      <Typography component="span" style={{ color: "#ffffff" }} > Top Team</Typography>
                    </Box>
                    <AvatarComponent name={i.team.name} username={i.team.name} url={''} onClick={() => { }} style={{ marginRight: 10, width: 32, height: 32, fontSize: 16 }} />
                    <p className={'scoreRoot'}><span className={'score'}>
                      <p className={'title'}>{`${i.team.name}`}</p>
                    </span></p>
                    <p className={'scoreRoot'}>{`${i.actual}`} {''}{i.unit}<span style={{ color: '#666666', fontSize: 10, fontWeight: 500, marginLeft: 5 }}>of {i.target}</span></p>
                    <p className={'scoreRoot'}>{`${getNumberFormat(userDetails?.countryCode, i.achieved)}`}% Achieved</p>
                  </div>
                </div>
              ))}
              {topPlayer.length > 0 && topPlayer.map((i, index) => (
                <div className={classes.scoreCardsRoot}>
                  <div key={index} className={'scoreCard'}>
                    <Box
                      p={1}
                      display="flex"
                      justifyContent="flex-end"
                      flexDirection="row"
                      className={classes.viewRightTopContainer}
                    >
                      <Typography component="span" style={{ color: "#ffffff" }} > Top Player</Typography>
                    </Box>
                    <AvatarComponent name={i.name} username={i.username} url={''} onClick={() => { }} style={{ marginRight: 10, width: 32, height: 32, fontSize: 16 }} />
                    <p className={'scoreRoot'}><span className={'score'}>
                      <p className={'title'}>{`${i.name}`}</p>
                    </span></p>
                    <p className={'scoreRoot'}>{`${i.actual}`} {''}{i.unit}<span style={{ color: '#666666', fontSize: 10, fontWeight: 500, marginLeft: 5 }}>of {i.target}</span></p>
                    <p className={'scoreRoot'}>{`${getNumberFormat(userDetails?.countryCode, i.achieved)}`}% Achieved</p>
                  </div>
                </div>
              ))}

            </AccordionDetails>
          </div>
        </Accordion>
      ) : <p style={{ padding: 24, textAlign: 'center' }}>No Data Available</p>}
    </div>
  )
}
const mapStateToProps = (state) => ({
  userDetails: state.user
});



const mapDispatchToProps = dispatch => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getOverallTrends: (options) => dispatch(getOverallTrends(options)),
    getTopTeam: (options) => dispatch(getTopTeam(options)),
    getTopPlayer: (options) => dispatch(getTopPlayer(options)),
    getTeamMatchLeaderBoard: (options) => dispatch(getTeamMatchLeaderBoard(options)),
    getKpiWisePlayerLeaderBoard: (options) => dispatch(getKpiWisePlayerLeaderBoard(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverallOrgStats));
