import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { ChevronLeft, Info } from "@material-ui/icons";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import loading, { setLoading } from "../../ducks/loading";
import { getRoundPlayerStatistics } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { groupBy } from "underscore";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { IconButton } from "@material-ui/core";
import { GetDates } from ".";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '8px 0',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
        fontWeight: 600
    }
}));

const RoundPlayerSummary = ({ setLoading, loading, seasonId = '', seasonFormat = '', roundId = '', filtersData, userDetails, getRoundPlayerStatistics, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (roundId) {
            setLoading(true);
            // if (seasonFormat === 'TEAM_RACE')
            //     getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
            // else
            getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
    }, [roundId]);

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                let totalPoints = 0;
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = (seasonFormat === 'TEAM_RACE' ? el.score : el.points);
                    obj['totalPoints'] = totalPoints += seasonFormat === 'TEAM_RACE' ? el.score : el.points;

                    if (index === 0) {
                        headers.push(el);
                    }
                    if (index === 0) {
                        obj.columns = [...(obj.columns || []), {
                            title: `${el.kpi_name}`,//-target
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,//-Ach %
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: isRuleBasedOnActuals(el),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        }, {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            // console.log('finalResult dynamicHeaders ', finalResult[0]?.columns);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                // if (filtersData.season?.divisions_enabled)
                //     setDataByDiv(groupBy(finalResult, 'division_name'));
                // else
                setData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setDataByDiv(null);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    const columns = [
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            hidden: seasonFormat === 'TEAM_RACE',
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            editable: "onAdd",
            hidden: seasonFormat === 'TEAM_RACE',
            emptyValue: '--',
            // render: (rowData) => <span style={{ fontSize: 12 }}>{rowData.role}</span>
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ fontWeight: 200 }} className={classes.nameRoot}>
                    <AvatarComponent name={rowData.team.name} username={rowData.team._id} url={rowData.team.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                    {rowData.team && rowData.team.name}
                </div>
            ) : '--'
        },
        {
            title: "Total points earned",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        }
    ]

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                search: false,
                actionsColumnIndex: -1,
                showTitle: false,
                addRowPosition: 'first',
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25],
                pageSize: 25,
                paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {
                                seasonFormat === 'TEAM_RACE' ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                    </>
                                    :
                                    seasonFormat === 'PLAYER_RACE' ?
                                        <>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>
                                        </>
                            }
                            {/* {console.log('dynamicHeaders ', dynamicHeaders)} */}
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={isRuleBasedOnActuals(obj) ? 2 : 4} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                        <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                        <TableCell align="center">Points</TableCell>
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const handleGoBack = () => {
        history.push(`/leagues/live-scores/${history.location?.search}`);
    }


    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
            </div>
            {
                (data.length === 0 && !dataByDiv) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    // [
                    //     (filtersData.season?.divisions_enabled && dataByDiv) ?
                    //         Object.values(dataByDiv).map((data, i) => (
                    //             <div className={classes.root} key={i} style={{ margin: 0 }}>
                    //                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    //                     <p style={{ margin: '30px 0px 5px', fontWeight: 600, color: '#000000c9' }}>Division : {data[0]?.division_name}</p>
                    //                     <p className={classes.title}><Info />{"Points will appear only after match is closed"}</p>
                    //                 </div>
                    //                 <SummaryTable data={data} />
                    //             </div>
                    //         ))
                    //         :
                    <div className={classes.root}>
                        <p className="table-title decimal-switch-root">
                            <p>
                                <span>Player performance : <span style={{ color: '#000', fontSize: 13 }}>{filtersData.round?.name}</span><GetDates filtersData={filtersData} /></span>
                            </p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                        {/* <p className={classes.title}><Info />{"Points will appear only after match is closed"}</p> */}
                        <SummaryTable data={data} />
                    </div>
                // ]
            }
        </div>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoundPlayerSummary));