const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, resize = { width: 550, height: 550 }) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  )

  if (resize) {
    const oc = document.createElement('canvas')
    const octx = oc.getContext('2d');
    oc.width = resize.width
    oc.height = resize.height
    // octx.fillStyle = '#FFF';
    // octx.fillRect(0, 0, canvas.width, canvas.height);
    octx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resize.width, resize.height);
    return oc.toDataURL('image/png');
  } else {
    return canvas.toDataURL('image/png');
  }
  // As a blob
  // return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //         resolve(URL.createObjectURL(file))
  //     }, 'image/jpeg')
  // })
}

const scalePreserveAspectRatio = (imgW, imgH, maxW, maxH) => {
  return (Math.min((maxW / imgW), (maxH / imgH)));
}

export const resizeImage = async (src, resize = { width: 1080, height: 1080 }) => {
  const image = await createImage(src);
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = resize.width;
  canvas.height = resize.height;
  let w = image.width;
  let h = image.height;
  let sizer = scalePreserveAspectRatio(w, h, canvas.width, canvas.height);
  ctx.drawImage(image, 0, 0, w, h, 0, 0, w * sizer, h * sizer);
  return canvas.toDataURL('image/png');
}