import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import classNames from 'classnames';
import { formatDate } from "../../helpers";
import gameIllustration from '../../assets/images/game_Illustration.png';
import IllustrationView from "./IllustrationView";

const useStyles = makeStyles((theme) => ({
    roundIconButton: {
        fontSize: 13,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: 4,
        pointerEvents: 'auto !important',
        color: '#000',
        '&:hover': {
            color: '#000'
        }
    },
    roundIconButtonSelected: {
        // color: 'black',
        // backgroundColor: '#C4C4C4'
        color: '#FFF',
        backgroundColor: theme.palette.primary.main
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
}));


const useStylesGroup = makeStyles((theme) => ({
    roundsRoot: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 14,
        // width: 'max-content',
        maxWidth: 200,
        overflow: 'hidden',
        marginLeft: 10
    },
    roundIconButton: {
        fontSize: 13,
        padding: '6px 15px',
        borderRadius: 14,
        pointerEvents: 'auto !important',
        color: '#000',
        '&:hover': {
            color: '#000'
        }
    },
    roundIconButtonSelected: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
}));

export const CustomListGroup = ({ data = [], canBeDisable = false, selectedItem, onClick = () => { }, style = {} }) => {
    const classes = useStylesGroup();
    return (
        <>
            {data.length > 0 &&
                <div style={{ display: data.length > 0 ? 'flex' : 'block', alignItems: 'center', width: 'max-content', margin: '20px auto', ...style }} className={classes.roundsRoot}>
                    {data.map((item, index) => (
                        <IconButton
                            key={index}
                            size="small"
                            title={item.label}
                            // disabled={canBeDisable ? item.round_status !== filtersData.status : false}
                            className={classNames(classes.roundIconButton, { [classes.roundIconButtonSelected]: selectedItem?.value === item.value })}
                            onClick={() => onClick(item)}
                        >
                            {item.label}
                        </IconButton>
                    ))}
                </div>
            }
        </>
    )
}

export const roundStatuses = [{ name: 'Upcoming', id: 'UPCOMING' }, { name: 'Live', id: 'LIVE' }, { name: 'Awaiting Results', id: 'COMPLETED' }, { name: 'Results', id: 'CLOSED' }];

export const RoundsListGroup = ({ rounds = [], canBeDisable = false, selectedRound, onRoundClick = () => { }, style = {} }) => {
    const classes = useStylesGroup();
    return (
        <>
            {rounds.length > 0 &&
                <div style={{ display: rounds.length > 0 ? 'flex' : 'block', alignItems: 'center', ...style }} className={classes.roundsRoot}>
                    {rounds.map((round, index) => (
                        <IconButton
                            key={index}
                            size="small"
                            title={roundStatuses.find(ele => ele.id === round.round_status)?.name}
                            // disabled={canBeDisable ? round.round_status !== filtersData.status : false}
                            className={classNames(classes.roundIconButton, { [classes.roundIconButtonSelected]: selectedRound?.name === round.name })}
                            onClick={() => onRoundClick(round)}
                        >
                            {round.name}
                        </IconButton>
                    ))}
                </div>
            }
        </>
    )
}

const RoundsListView = ({ rounds, loading, canBeDisable = false, filtersData, onRoundClick = () => { } }) => {
    const classes = useStyles();
    return (
        <>
            <div style={{ display: rounds.length > 0 ? 'flex' : 'block', alignItems: 'center', flexWrap: 'wrap' }}>
                <p className={classes.roundsLableStyle}>Rounds : </p>
                {rounds.length > 0 ?
                    rounds.map((round, index) => (
                        <IconButton
                            key={index}
                            size="small"
                            title={roundStatuses.find(ele => ele.id === round.round_status)?.name}
                            disabled={canBeDisable ? round.round_status !== filtersData.status : false}
                            className={classNames(classes.roundIconButton, { [classes.roundIconButtonSelected]: filtersData.round?.name === round.name })}
                            onClick={() => onRoundClick(round)}
                        >
                            {round.name}
                        </IconButton>
                    )) :
                    <IllustrationView loading={loading} data={rounds} />
                }
            </div>
            {
                rounds.length > 0 &&
                <p style={{ fontSize: 14, margin: '15px 0px 10px', color: '#0000008a' }}>
                    <span>Start Date : <span style={{ color: '#000', fontSize: 13 }}>{formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.start_date : filtersData.round?.start_date)}</span></span>
                    <span style={{ marginLeft: 30 }}>End Date : <span style={{ color: '#000', fontSize: 13 }}>{formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.end_date : filtersData.round?.end_date)}</span></span>
                </p>
            }

        </>
    )
};

export default RoundsListView;