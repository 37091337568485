import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ArrowDownward, ChevronLeft, Email } from '@material-ui/icons';
import { toJpeg } from 'html-to-image';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shareLiveScoreDialog } from '../../ducks/game';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { getMatchDetails } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import { historyPushWithSearch, downloadFiles, getBase64WithUrl } from "../../helpers"
import PlayersMatchSummary from './PlayersMatchSummary';
import TeamsMatchSummary from './TeamsMatchSummary';
import MatchPerformanceTemplate from '../../components/GlobalComponents/MatchPerformanceTemplate';

const useStyles = makeStyles((theme) => ({
	fieldSetRoot: {
		margin: '15px 0 20px'
	},
	fieldSetChildRoot: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 20px 10px',
		flexWrap: 'wrap',
		'& div': {
			margin: '0 20px 10px'
		}
	},
	fieldLabel: {
		color: '#58595b',
		fontSize: 12,
		margin: 0
	},
	fieldValue: {
		color: '#58595b',
		fontSize: 15,
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 5
	},
	backButton: {
		backgroundColor: '#FFF',
		border: '1px solid #bcbcbd !important',
	}, viewAllButton: {
		margin: '0 10px',
		color: 'blue', // #112386
		fontSize: 11,
		float: 'right',
		cursor: 'pointer',
		textDecoration: 'underline',
		// fontWeight: 600,
		'& svg': {
			fontSize: 14,
			marginLeft: 4,
			verticalAlign: 'sub'
		}
	}
}));

export const matchTypes = [
	{
		name: 'Team Match',
		id: 'TEAM_MATCH'
	},
	{
		name: 'Team Race',
		id: 'TEAM_SPRINT'
	},
	{
		name: 'Player Race',
		id: 'PLAYER_SPRINT'
	},
	{
		name: 'Team Bonus',
		id: 'TEAM_BONUS'
	},
	{
		name: 'Player Bonus',
		id: 'INDIVIDUAL_BONUS'
	},
];

const MatchDetails = ({ match, dialog, keycloak, history, location, userDetails, loading, setLoading, getMatchDetails, filtersData, shareLiveScoreDialog }) => {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [canDownload, setCanDownload] = useState(false);
	const [matchDetails, setMatchDetails] = useState(null);
	const [shareType,setShareType] = useState('EMAIL');

	const isAdmin = keycloak.decoded_token.realm_access.roles &&
		keycloak.decoded_token.realm_access.roles.length > 0 &&
		keycloak.decoded_token.realm_access.roles.includes('admin');

	const handleDownloadPerformance = async (id) => {
		setLoading(true);
		await setCanDownload(true);
		await setShareType('DOWNLOAD');
		toJpeg(document.getElementById(id))
			.then((dataUrl) => {
				setCanDownload(false);
				setLoading(false);
				let title = `match_Performances_${filtersData.season?.uid}_${matchDetails?.title}`;
				downloadFiles(dataUrl, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-'));
			})
	}

	const handleShareScore = (id) => {
		setShareType('EMAIL');
		shareLiveScoreDialog({ open: true, fixture: 'MATCH', type: 'TEAM', matchDetails: matchDetails, details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, matchId: filtersData?.match?._id, imageId: id }, filtersData: filtersData });
	}

	console.log('filtersData ', filtersData, match);
	// useEffect(() => {
	// 	if (match.params?.matchId)
	// 		getMatchDetailsData(match.params?.matchId);
	// }, [match.params?.matchId]);

	useEffect(() => {
		if (filtersData?.match?._id)
			getMatchDetailsData(filtersData?.match?._id);
	}, [filtersData?.match?._id]);

	const getMatchDetailsData = (matchId) => {
		setLoading(true);
		getMatchDetails({
			matchId,
			onSuccessCB: (result) => {
				Promise.all([getBase64WithUrl(result.teams[0]?.logo_url), getBase64WithUrl(result.teams[1]?.logo_url)]).then((res)=> {
					console.log('res match 2 ', res);
					setMatchDetails({ ...result, team1_logo: res[0], team2_logo: res[1] });
				}).catch((err) => console.log(err.response));
				setLoading(false);
			}, onErrorCB: (error) => {
				setLoading(false);
				setMatchDetails({});
				enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Matche details', {
					variant: 'error',
					preventDuplicate: true
				});
			}
		})
	}

	const handleViewChange = () => {
		historyPushWithSearch(`/arena/live-scores`, history, location.search);
	}

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 8 }}>
				{/* {matchDetails && <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange()}><ChevronLeft /></IconButton>} */}
				<div></div>
				{(isAdmin && matchDetails) && <span style={{ display: 'flex', alignItems: 'center' }}>
					<span className={classes.viewAllButton} onClick={() => handleShareScore('match-perfomances-table')}>Email <Email /></span>
					<span className={classes.viewAllButton} onClick={() => handleDownloadPerformance('match-perfomances-table')}>Download<ArrowDownward /></span>
				</span>}
			</div>
			{matchDetails && <MatchPerformanceTemplate
				id="match-perfomances-table"
				filtersData={filtersData}
				belongsTo='Match'
				match={matchDetails}
				shareType={shareType}
				render={(canDownload || dialog?.open)}
			/>}
			<TeamsMatchSummary loading={loading} matchId={filtersData?.match?._id} matchDetails={matchDetails} />
			<PlayersMatchSummary loading={loading} matchId={filtersData?.match?._id} matchDetails={matchDetails} />
		</div>
	)
}

const mapStateToProps = (state) => ({
	userDetails: state.user,
	loading: state.loading,
	keycloak: state.keycloak,
	dialog: state.game.liveScoresShare
});

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: value => dispatch(setLoading(value)),
		getMatchDetails: (options) => dispatch(getMatchDetails(options)),
		shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchDetails));
