import React from 'react';
import { makeStyles } from '@material-ui/core';
import { convertUnicode, handleSeeMore, urlify } from '../../helpers';
import UserProfileLink from '../UserProfileCard/UserProfileLink';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 16px 16px',
    },
    arenaText: {
        whiteSpace: "pre-wrap",
        fontSize: 14,
        color: "#535353",
        lineHeight: "20px",
        // marginTop: 5,
        wordBreak: "break-word"
    },
    userLink: {
        fontWeight: 600,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
}));

const ArenaText = ({ text = '', username, name, index, seeMoreCharLimit = 400, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.arenaText}>
                {text.length > seeMoreCharLimit ? (
                    <div>
                        <UserProfileLink username={username} name={name} />
                        <span className="short-text">
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(urlify(convertUnicode(text.substr(0, seeMoreCharLimit)))) }} />
                        </span>
                        <span className="long-text">
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(urlify(convertUnicode(text.substr(seeMoreCharLimit)))) }} />
                        </span>
                        <span className="text-dots">...</span>
                        <span
                            id={`show-more-button-${index}`}
                            className="show-more-button"
                            onClick={() => handleSeeMore(document.getElementById(`show-more-button-${index}`))}
                            data-more="0"
                        >
                            See More
                          </span>
                    </div>
                ) : (
                        <>
                            <UserProfileLink username={username} name={name} />
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(urlify(convertUnicode(text || ''))) }} />
                            {/* <span  >{urlify(convertUnicode(text || ''))}</span> */}
                        </>
                    )
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default ArenaText;