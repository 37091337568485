import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { formatDate, getBase64WithUrl } from '../../helpers';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    imageTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        margin: -25,
        '& div:first-child': {
            marginRight: 10,
            textAlign: 'right',
            '& p': {
                margin: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },
    tableTitle: {
        fontSize: 30,
        margin: '30px 0 0',
        '& p': {
            margin: 0,
            '&:last-child': {
                fontSize: 16,
                marginTop: -10,
                '& .divider': {
                    margin: '0 10px',
                    color: 'orange',
                    fontSize: 30,
                    fontWeight: 500,
                    verticalAlign: 'middle'
                }
            }
        }
    },
    tableRow: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderCollapse: 'seperate',
        fontSize: 16,
        height: '50px'
         },
}));

const PerformanceTableTemplate = ({ render = false, id = "", filtersData, data, columns, components, orgDetails, divisionName = '', belongsTo = 'Teams' }) => {
    const classes = useStyles();

    const [orgLogo, setOrgLogo] = useState("");

    useEffect(() => {
        if (orgDetails?.logo_url) {
            getBase64WithUrl(orgDetails?.logo_url).then((res) => {
                console.log('res org logo ', res);
                setOrgLogo(res);
            }).catch((err) => console.log(err.response));
        }
    }, [orgDetails?.logo_url]);

    return (
        <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }}> {/* to hide position: 'absolute', zIndex: -1 */}
            <div id={id} className="custom-table">
                <div className={classes.imageTitle} style={{marginRight: 50}}>
                    <div><p>{filtersData.league?.name}</p><p>{filtersData.season?.name}</p></div>
                    <div><img src={orgLogo} alt="logo" width="40" height="40" /></div>
                </div>
                {render &&
                    <>
                        <div className={classes.tableTitle}>
                            <p>{(filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? `Round-Top 5 ${belongsTo}` : `Sprint-Top 5 ${belongsTo}`)} ({`as on ${formatDate(moment.now())}`})</p>
                            <p>
                                <span>{filtersData.round ? filtersData.round?.name : filtersData.match ? filtersData.match?.title : ''}</span>
                                <span><span className="divider">|</span>{formatDate(filtersData.round ? filtersData.round?.start_date : filtersData.match?.start_date)} - {formatDate(filtersData.round ? filtersData.round?.end_date : filtersData.match?.end_date)}</span>
                                {(filtersData.season?.divisions_enabled && divisionName) && <span><span className="divider">|</span>{divisionName}</span>}
                            </p>
                        </div>
                        <table style={{maxWidth: 1080, maxHeight: 450, minWidth: 450, minHeight: 450, marginTop: -30, }}>
                            {
                                components?.Header ?
                                    [components.Header({ columns })]
                                    :
                                    <thead>
                                        <tr>{columns.map((col, i) => <th key={i}>{col.title}</th>)}</tr>
                                    </thead>
                            }
                            <tbody>
                                {
                                    data.map((row, rowIndex) =>
                                        <tr key={rowIndex} className={classes.tableRow}>
                                            {
                                                columns.map((col, i) => (
                                                    !col.hidden && <td style={{ ...col?.cellStyle, color: '#000' }} key={i} align={col.align}>{col.templateRender ? col.templateRender({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                                ))
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    orgDetails: state.common.orgDetails
});

export default connect(mapStateToProps, null)(PerformanceTableTemplate);
