import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import TeamStandings from "../TeamStandings";
import MyPerformance from "../MyPerformance";
import MyTeamPerformance from "../MyTeamPerformance";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";
import { CustomListGroup, RoundsListGroup } from "../../LiveScores/RoundsListView";
import { getSearchValue } from "../../../helpers";

const PlayerDashboard = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, history }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        if (rounds.length > 0) {
            const searchValue = getSearchValue(history, 'round');
            const round = rounds?.find(obj => obj._id === searchValue) || rounds[0];
            console.log('round ', searchValue, history, rounds, round);
            setMenuOptions([
                { label: round.name, value: round._id, pathname: 'round' },
                { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' }
            ]);
            setSelectedOption({ label: round.name, value: round._id, pathname: 'round' });
        }
    }, [rounds]);

    const handleOptionChange = (newValue) => {
        setSelectedOption(newValue);
    }

    const isSeasonLevel = selectedOption?.pathname === 'season';
    // console.log('isSeasonLevel ', isSeasonLevel, selectedOption);
    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <LiveMatches filtersData={filtersData} userInfo={userInfo} />
                    <CustomCard cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats} style={{ marginTop: 40 }}>
                        <OverallOrgStats filtersData={filtersData} />
                    </CustomCard>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={3}>
                        <Grid item md={7}>
                            <CustomCard cardTitle="My Performance"
                                filterTabs={<CustomListGroup data={menuOptions} selectedItem={selectedOption} onClick={handleOptionChange} style={{ margin: 0, marginTop: -8 }} />}
                                linkText="Live Score" onLinkClick={natigateToLiveScores} >
                                <MyPerformance isSeasonLevel={isSeasonLevel} roundId={!isSeasonLevel && selectedOption?.value} filtersData={filtersData} />
                            </CustomCard>
                            <CustomCard cardTitle="My Team Performance" linkText="Live Score" onLinkClick={natigateToLiveScores} style={{ marginTop: 40 }}>
                                <MyTeamPerformance isSeasonLevel={isSeasonLevel} roundId={!isSeasonLevel && selectedOption?.value} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                        <Grid item md={5}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={navigateToPlayerLeaderboard} >
                                {/* <p>Player Standings</p> */}
                                <PlayerStandings filtersData={filtersData} />
                            </CustomCard>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={navigateToTeamLeaderboard} style={{ marginTop: 40 }}>
                                {/* <p>Team Standings</p> */}
                                <TeamStandings filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerDashboard));
