import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getPlayersPointsList, getSeasonKpisList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import { TabBar } from '../TabBar';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import LeaderboardTable from './LeaderboardTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { commonColumnOptions } from '../../PointsTables/TeamPointsTable';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue } from '../../../helpers';
import { Grid } from '@material-ui/core';
import IllustrationView from '../../LiveScores/IllustrationView';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import { CustomListGroup } from '../../LiveScores/RoundsListView';

const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    division: null
};

const subTabs = [{ label: 'Core KPI', value: 0, icon: '', path: 'core' }, { label: 'Bonus KPI', value: 1, icon: '', path: 'bonus' }]

const TeamLeaderboard = ({ history, filtersData, location, setLoading, loading, getSeasonKpisList, userDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [kpis, setKpis] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('CARD');
    const [selectedKpi, setSelectedKpi] = useState(null);
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (filtersData.season && filtersData.season?.season_format !== 'PLAYER_RACE') {
            getSeasonKpis(filtersData.season?._id);
        }
        else {
            setKpis([]);
        }
        setSearchQuery(history, { viewBy: subTabs[currentTab]?.path });
    }, [filtersData.season]);

    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(subTabs.findIndex(obj => obj.path === groupByValue) || 0);
        }
    }, [groupByValue]);

    const handleSubTabChange = (newValue) => {
        setSearchQuery(history, { viewBy: subTabs[newValue?.value]?.path });
        setViewType('CARD');
        setCurrentTab(newValue?.value);
    }

    const handleViewChange = (type, kpi) => {
        setViewType(type);
        if (type === 'PAGE')
            setSelectedKpi(kpi);
        else
            setSelectedKpi(null);
    }

    const getSeasonKpis = (seasonId) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map(ele => {
                    ele.label = ele.name
                    return ele;
                })
                setKpis([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const columns = (kpi, tableData) => [
        {
            name: "rank", label: "Rank", options: { ...commonColumnOptions }
        },
        {
            name: "team", label: "Team", options: {
                customBodyRender: (team, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent name={team?.name} username={team?._id} url={team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} />
                        </div>
                        <div style={{ textAlign: 'left', fontWeight: 600 }}>
                            {team && team.name}
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                                {tableData[tableMeta.rowIndex]?.captain && <span style={{ marginRight: 10 }}>C : {tableData[tableMeta.rowIndex]?.captain}</span>}
                                {tableData[tableMeta.rowIndex]?.mentor && <span>M : {tableData[tableMeta.rowIndex]?.mentor}</span>}
                            </div>
                        </div>
                    </div>

                )
            }
        },
        // { name: "captain", label: "Captain" },
        // { name: "mentor", label: "Mentor" },
        {
            name: "target", label: "Target", options: {
                ...commonColumnOptions,
                display: (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION') ? false : true,
                customBodyRender: target => (target ? kpi.unit_of_measurement === 'CURRENCY' ?
                    getCurrencyFormat(userDetails?.countryCode, kpi.unit, target)
                    : `${getNumberFormat(userDetails?.countryCode, target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(target, kpi.unit) : ''}`
                    : 0)
            }
        },
        {
            name: "actual", label: "Actual", options: {
                ...commonColumnOptions,
                display: (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION') ? false : true,
                customBodyRender: actual => (actual ? kpi.unit_of_measurement === 'CURRENCY' ?
                    getCurrencyFormat(userDetails?.countryCode, kpi.unit, actual)
                    : `${getNumberFormat(userDetails?.countryCode, actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(actual, kpi.unit) : ''}`
                    : 0)
            }
        },
        {
            name: "achieved", label: "Achievement %", options: {
                ...commonColumnOptions,
                display: (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION') ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "totalpoints",
            label: ((subTabs[currentTab].label.toUpperCase()) === 'CORE KPI' && filtersData.season?.season_format === 'HEAD_TO_HEAD') ? 'Score' : ((subTabs[currentTab].label.toUpperCase()) === 'CORE KPI' ? "Core Pts" : "Bonus Pts"),
            options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => ((subTabs[currentTab].label.toUpperCase()) === 'CORE KPI' && filtersData.season?.season_format === 'HEAD_TO_HEAD') ? `${getNumberFormat(userDetails?.countryCode, totalpoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit) + (totalpoints === 1 ? '' : 's')}` : getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            }
        },
    ];

    const filteredKpis = kpis.filter(ele => ele.tags.includes(currentTab === 0 ? 'TEAM_MATCH' : 'TEAM_BONUS'));

    return (
        <>
            {/* <SeasonFilters filtersData={filtersData} setFiltersData={setFiltersData} defaultFilters={defaultFilters} /> */}
            {
                filtersData.season?.season_format === 'PLAYER_RACE' ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No team data is available, since selected season is of the format ‘Player race’</p>
                    </div> :
                    <div style={{ margin: '10px 0' }}>
                        {/* <TabBar tabs={subTabs} subTabVal={currentTab} handleSubTabChange={handleSubTabChange} /> */}
                        <CustomListGroup data={subTabs} selectedItem={subTabs.find((option) => option.value === currentTab)} onClick={handleSubTabChange} style={{ marginLeft: 0 }} />
                        <p className="table-title decimal-switch-root" style={{ marginBottom: -10 }}>
                            <p></p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                        {
                            filteredKpis.length > 0 ?
                                [
                                    viewType === 'CARD' ?
                                        <Grid container spacing={2} style={{ marginTop: 0 }} >
                                            {
                                                filteredKpis.map((kpi, i) => (
                                                    <Grid key={i} item md={filteredKpis.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                        <LeaderboardTable viewType={viewType} filtersData={filtersData} groupTab={currentTab} columns={columns} kpi={kpi} handleViewChange={handleViewChange} loading={loading} />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        :
                                        <LeaderboardTable viewType={viewType} filtersData={filtersData} groupTab={currentTab} columns={columns} kpi={selectedKpi} handleViewChange={handleViewChange} loading={loading} />
                                ]
                                :
                                < IllustrationView loading={loading} style={{ marginTop: '50px' }} />

                            // <p style={{ textAlign: 'center', fontSize: 12, color: '#000000a1', width: '100%', marginTop: 50 }}>** No data available **</p>
                        }
                    </div>
            }

        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamLeaderboard));
