import { createMuiTheme } from '@material-ui/core/styles';

const primaryMain = '#8A2BE2';
const primaryLight = '#FFF5D5';
const secondaryMain = '#EEEEEE';
const secondaryLight = '#F7F7F7';


export const theme = (theme = { primary_color: primaryMain, secondary_color: primaryLight }) => createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#F77D1D',
      // light: '#FFFAE9',
      light: theme.secondary_color,
      main: theme.primary_color,
      // main: '#FFCDEE',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px'
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          // backgroundColor: primaryLight,
          backgroundColor: theme.secondary_color,
          '&:hover': {
            // backgroundColor: 'rgba(255, 245, 213, 0.7)',
            backgroundColor: `${theme.secondary_color}b3`
          }
        }
      },
      button: {
        '&:hover': {
          // backgroundColor: '#fff5d5b3',
          // In RGBA
          // backgroundColor: 'rgba(255, 245, 213, 0.7)',
          backgroundColor: `${theme.secondary_color}b3`
        }
      },
    },
    MuiAvatar: {
      root: {
        textTransform: 'uppercase'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize'
      },
      containedPrimary: {
        color: "#FFFFFF",
        textTransform: "capitalize",
        boxShadow: "none",
        '&:hover': {
          boxShadow: "none",
        }
      },
      outlinedPrimary: {
        color: "#58595B",
        border: "1px solid #58595B !important",
        textTransform: "capitalize",
        boxShadow: "none",
        '&:hover': {
          border: "1px solid #58595B",
          boxShadow: "none",
        }
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: "none",
        }
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: '#FFEAA7',
        color: '#58595B',
        fontWeight: 600
      },
      head: {
        backgroundColor: `#FFF !important`
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'none'
      }
    },
    MUIDataTable: {
      liveAnnounce: {
        display: 'none'
      }
    },
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: 12
      }
    },
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${theme.primary_color}1A`,
          color: '#000',
          '&:hover': {
            backgroundColor: `${theme.primary_color}1A`,
          },
          '& td': {
            color: '#000'
          }
        },
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&.mui-row-selected': {
          backgroundColor: `${theme.primary_color}1A`,
          color: '#000',
          '&:hover': {
            backgroundColor: `${theme.primary_color}1A`,
          },
          '& td': {
            color: '#000'
          }
        }
      }
    },
    MUIDataTableToolbarSelect: {
      root: { display: 'none' }
    }
  }
});