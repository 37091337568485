import { Divider } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import UserProfileCard from "../UserProfileCard/UserProfileCard";

export const PublishTo = ({ classes, loading, groupsList, isAll = true, handleGroupsCheckbox, handleSubmitGroups }) => {

    return (
        <div className={classes.tagUsersListRoot}>
            <div className="title" >
                <p>Publish to</p>
                <span onClick={() => handleSubmitGroups()}>Done</span>
            </div>
            {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <Search />
                </div>
                <InputBase
                    placeholder="Search for Leagues"
                    classes={{
                        root: classes.searchInputRoot,
                        input: classes.searchInputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={query}
                    onChange={handleChangeQuery}
                    onKeyUp={handleKeyPress}
                />
            </div> */}
            <div className={classes.usersList} style={{ marginTop: 0 }}>
                <div key={"00010"} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ margin: "2px 10px 0px", color: '#262626', fontSize: 13 }}>All Leagues</p>
                    <Checkbox style={{ display: 'inline-table' }} checked={isAll} onChange={() => handleGroupsCheckbox('ALL', isAll)} color="primary" />
                </div>
                {
                    loading ?
                        [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={true} key={i} />)
                        :
                        groupsList.length > 0 ? groupsList.map((group, i) => (
                            <div key={i}>
                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <UserProfileCard
                                        key={`group-${i}`}
                                        name={group.groupName}
                                        avatarUrl={group.photo_url}
                                        avatarStyles={classes.avatarStyles}
                                        profileNameStyles={classes.profileName}
                                        timestamp={false}
                                        style={{ margin: '12px 2px', alignItems: 'center' }}
                                    />
                                    <Checkbox style={{ display: 'inline-table' }} checked={group.checked} onChange={() => handleGroupsCheckbox(i)} color="primary" />
                                </div>
                                <Divider style={{ margin: '0 12px 0 38px' }} variant="fullWidth" component="div" />
                            </div>
                        ))
                            :
                            <p style={{ fontSize: 14, textAlign: 'center' }}>No Groups found</p>
                }
            </div>
        </div>
    )
}